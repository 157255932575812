import { createSlice } from "@reduxjs/toolkit";
import { getPendingJobDetailThunk, getPharmacyDashboardDetailThunk, getPharmacyGapsDataThunk } from "../dashboardThunk";

const initialState = {
  pharmacyDashboardData: [],
  pharamcyGapsData: [],
  pendingJobApprovalData: []
};

const dashboardSlicer = createSlice({
  name: "dahboardSlicer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getPharmacyDashboardDetailThunk.fulfilled, (state, action) => {
        state.isloading = false;
        state.pharmacyDashboardData = action.payload;
      })

      .addCase(getPharmacyGapsDataThunk.fulfilled, (state, action) => {
        state.isloading = false;
        state.pharamcyGapsData = action.payload;
      })
      .addCase(getPendingJobDetailThunk.fulfilled, (state, action) => {
        state.isloading = false;
        state.pendingJobApprovalData = action.payload;
      })
  },
});

export const dashboardReducer = dashboardSlicer;
