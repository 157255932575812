import React from "react";
import "./style.scss";
import archiveIcon from "../../../assets/icons/whiteArchive.png";
import deleteIcon from "../../../assets/icons/whiteDelete.png";
import { TableTabsOptions } from "./tablesTab";
import { Button, Input, Select } from "antd";
import search from "../../../assets/icons/search.png";

const SearchIcon = () => {
  return (
    <div>
      <img src={search} alt="" />
    </div>
  );
};

export const TableToolbar = ({
  searchPlaceholder,
  showSelector,
  filterButton,
  deleteButton,
  archiveButton,
  primaryBtnHandler,
  FilterComponent,
  handleConfirmModal,
  primaryBtnText,
  searchQuery,
  setSearchQuery,
  onSearch,
  primaryIcon,
  showSearchField,
  headingText,
  staffTableView,
  tabsOptions,
  acceptButton,
  rejectButton,
  setActionType,
  showSecondarySearchField,
  selectorOptions,
  setSelectorOption,
  secondaryButtonText,
  ExtraComponent,
  branchStatus
}) => {
  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={{ height: 65, padding: "0px 24px" }}
    >
      {tabsOptions && (
        <TableTabsOptions
          tabsOptions={tabsOptions}
          staffTableView={staffTableView}
        />
      )}
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ height: 70, gap: 15, width: tabsOptions ? "" : "100%" }}
      >
        <div className="d-flex align-items-center gap-3">
          {headingText && <div className="heading4 fw-bold">{headingText}</div>}
          {rejectButton && (
            <Button
              onClick={() => setActionType("reject")}
              className="p-2 px-4 btn-style d-flex align-items-center gap-1"
              block
            >
              Reject
            </Button>
          )}
          {acceptButton && (
            <Button
              onClick={() => setActionType("accept")}
              className="p-2 px-4 btn-style d-flex align-items-center gap-1"
              block
              type="primary"
            >
              Accept
            </Button>
          )}
          {showSearchField && (
            <div>
              <Input.Search
                prefix={<SearchIcon />}
                className="searchField"
                style={{ minWidth: 300 }}
                value={searchQuery}
                placeholder={searchPlaceholder || "Search"}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                onSearch={onSearch}
              />
            </div>
          )}
        </div>
        <div
          className="d-flex align-items-center justify-content-between right-side-container"
          style={{ gap: 15 }}
        >
          {showSecondarySearchField && (
            <div>
              <Input.Search
                prefix={<SearchIcon />}
                style={{ minWidth: 300 }}
                className="searchField"
                value={searchQuery}
                placeholder="Branch"
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                onSearch={onSearch}
              />
            </div>
          )}
          {archiveButton && (
            <Button
              onClick={() => handleConfirmModal(branchStatus)}
              className="p-2 px-4 btn-style d-flex align-items-center gap-1"
              block
              type="primary"
            >
              {branchStatus} <img width={20} height={20} src={archiveIcon} alt="" />
            </Button>
          )}
          {deleteButton && (
            <Button
              onClick={() => handleConfirmModal("Delete")}
              className="p-2 px-4 btn-style d-flex align-items-center gap-1"
              block
              type="primary"
            >
              Delete <img width={20} height={20} src={deleteIcon} alt="" />
            </Button>
          )}
          {showSelector && (
            <Select
              style={{ minWidth: 150 }}
              dropdownStyle={{ zIndex: 2000 }}
              bordered={false}
              className="text-input-field-pill p-1 t1"
              options={selectorOptions}
              onChange={setSelectorOption}
            />
          )}
          {filterButton && FilterComponent}
          {
            ExtraComponent && ExtraComponent
          }
          {secondaryButtonText && (
            <Button
              onClick={handleConfirmModal}
              block
            >
              {secondaryButtonText}
            </Button>
          )}
          {primaryBtnText && (
            <Button
              onClick={primaryBtnHandler}
              className="p-2 px-4 btn-style rounded"
              block
              type="primary"
            >
              {primaryBtnText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
