import React, { memo } from "react";

export const FlexRow = memo(
    ({
        primaryTitle,
        primaryDescription,
        secondaryTitle,
        secondaryDescription,
    }) => {
        return (
            <div className="d-flex justify-content-between text-row">
                <div className="p3 primary-text">{primaryTitle}</div>
                <div className="p3 secondary-text" style={{ lineBreak: 'anywhere' }}>{primaryDescription}</div>
                <div className="p3 primary-text">{secondaryTitle}</div>
                <div className="p3 secondary-text " style={{ lineBreak: 'anywhere' }}>{secondaryDescription}</div>
            </div>
        );
    }
);

FlexRow.displayName = "FlexRow";
