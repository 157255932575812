import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import editIcon from "../../../assets/icons/edit.png";
import deleteIcon from "../../../assets/icons/delete.png";
import { useDispatch, useSelector } from "react-redux";
import { Heading } from "../../../components/UI/heading";
import { TableToolbar } from "../../../components/UI/table/tableToolBar";
import { TableComponet } from "../../../components/UI/table";
import {
  deleteRegionsThunk,
  getRegionsBranchesListThunk,
  getRegionsListThunk,
} from "../../../redux/regions/regionsThunk";
import { RegionForm } from "../../../components/regions/createRegion";
import { ConfirmModal } from "../../../components/UI/modals/confirmModal";
import { hasAccess } from "../../../utils/helper";
import { inviteApiKeys, regionApiKeys } from "../../../utils/apiKeyConstants";
import moment from "moment/moment";
import { EmptyData } from "../../../components/comon/EmptyData";

export const RegionsPageNew = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { regions, regionBranches } = useSelector((state) => state.regions);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);
  const [selectedRegion, setSelectedRegion] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [regionId, setRegionId] = useState();
  const [requestType, setRequestType] = useState("Add");
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();

  const [regionBranchesOptions, setRegionBranchesOptions] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Region",
        accessor: "title",
      },
      {
        Header: "Creation Date",
        accessor: "createdAt",
        Cell: ({ row: { original } }) => {
          return moment(original.createdAt).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Assigned Branches",
        accessor: "branches",
        Cell: ({ row: { original } }) => {
          return original?.branches?.length;
        },
      },
      {
        Header: "Assigned Staff",
        accessor: "staffCount",
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row: { original } }) => {
          return (
            <div>
              <Dropdown icon="ellipsis vertical" pointing="top right">
                <Dropdown.Menu direction="left">
                  {hasAccess(regionApiKeys.UPDATE_REGION) && (
                    <Dropdown.Item
                      icon={<img className="pt-2" src={editIcon} alt="" />}
                      text="Edit Region"
                      onClick={() => {
                        setSelectedRegion({
                          title: original.title,
                          branchIds: original.branches.map((i) => i?.id),
                        });
                        setRegionId(original.id);
                        setRequestType("Edit");
                        setShowCreateModal(!showCreateModal);
                      }}
                    />
                  )}
                  {hasAccess(regionApiKeys.DELETE_REGION) && (
                    <Dropdown.Item
                      style={{ color: "#EF5350" }}
                      icon={<img className="pt-2" src={deleteIcon} alt="" />}
                      onClick={() => {
                        setRequestType("Delete");
                        const id = [original.id];
                        setSelectedRows(id);
                        setShowConfirmModal(true);
                      }}
                      text="Delete Region"
                    />
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    []
  );

  const getRegionList = useCallback(() => {
    if (hasAccess(regionApiKeys.GET_REGION_LIST)) {
      dispatch(
        getRegionsListThunk({
          filters: {
            title: searchQuery.trim(),
          },
          pagination: {
            page: currentPageCount,
            perPage: perPageCount,
          },
        })
      );
    }
  }, [currentPageCount, dispatch, perPageCount, searchQuery]);

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: regionsData,
  } = useMemo(() => regions, [regions]);

  useEffect(() => {
    if (!regionBranches) return;
    const newRegions = regionBranches.map((reg) => ({
      value: reg.id,
      label: reg.title,
    }));
    setRegionBranchesOptions(newRegions);
  }, [regionBranches]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  useEffect(() => {
    if (searchQuery !== "") return;

    getRegionList();
    if (hasAccess(inviteApiKeys.GET_SHORT_BRANCHES))
      dispatch(getRegionsBranchesListThunk());
  }, [dispatch, searchQuery, getRegionList]);

  const clearStateHandler = () => {
    setShowConfirmModal(false);
    setRegionId(null);
    setShowCreateModal(false);
    setRequestType("");
  };

  const onRegionSubmit = useCallback(
    (values) => {
      dispatch(deleteRegionsThunk({ regionIds: selectedRows })).then((res) => {
        getRegionList();
        clearStateHandler();
      });
    },
    [dispatch, selectedRows, getRegionList]
  );

  const handleModalToggle = useCallback(() => {
    setRequestType("Add");
    setShowCreateModal(!showCreateModal);
  }, [showCreateModal]);

  const handleConfirmModal = useCallback(
    (med) => {
      setShowConfirmModal(!showConfirmModal);
      setRequestType(med);
    },
    [showConfirmModal]
  );

  const onSearch = useCallback(
    (e) => {
      getRegionList();
    },
    [getRegionList]
  );

  return (
    <>
      {showConfirmModal && (
        <ConfirmModal
          title={`Do you really want to Delete Region?`}
          description={`You are about to Delete the Region. This will Delete your Region and other information permanently.`}
          open={showConfirmModal}
          confirmButtonText="Delete"
          cancelButtonText="Cancel"
          onClose={() => setShowConfirmModal(!showConfirmModal)}
          onConfirm={onRegionSubmit}
        />
      )}
      <div>
        <Heading label="Regions" />
        <div className="">
          <TableToolbar
            searchPlaceholder="Region"
            showSearchField
            onSearch={onSearch}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            primaryBtnText={
              hasAccess(regionApiKeys.CREATE_REGION) ? "Add Region" : ""
            }
            primaryBtnHandler={handleModalToggle}
            deleteButton={
              selectedRows.length && hasAccess(regionApiKeys.DELETE_REGION)
                ? true
                : false
            }
            handleConfirmModal={handleConfirmModal}
          />
          <div style={{ padding: "5px 24px" }}>
            {regions?.rows?.length > 0 ? (
              <TableComponet
                rowCursor="pointer"
                isBorderShown
                setSelectedRows={setSelectedRows}
                currentPageCount={currentPageCount}
                perPageCount={perPageCount}
                totalDataCount={totalDataCount}
                setCurrentPage={setCurrentPageCount}
                setPerPageCount={setPerPageCount}
                showPagination
                showRowSelection={hasAccess(regionApiKeys.DELETE_REGION)}
                maxPageCount={totalPagesCount}
                columns={columns}
                data={regionsData ?? []}
              />
            ) : (
              <div style={{ width: "100%", marginTop: "60px" }}>
                <EmptyData title="Regions" />
              </div>
            )}
          </div>
        </div>
        <RegionForm
          requestType={requestType}
          selectedRegion={selectedRegion}
          regionBranchesOptions={regionBranchesOptions}
          showCreateModal={showCreateModal}
          handleModalToggle={() => setShowCreateModal(!handleModalToggle)}
          regionId={regionId}
        />
      </div>
    </>
  );
};
