import { createAsyncThunk } from "@reduxjs/toolkit";
import { addContract, addStaff, applyManualAttendance, deleteContract, deleteStaffAbsences, duplicateContract, editContract, editStaffAccrediations, editStaffProfile, editVerification, gapApplicationAction, getGphcInfo, getStaffAbsences, getStaffAccrediations, getStaffContract, getStaffContractList, getStaffData, getStaffGaps, getStaffRelatedData, getStaffSchedule, getStaffsList, getStaffTimeSheet, getStaffVerification, getUsersActiveContract, markStaffAttendance, resendStaffEmail, staffManualAttendanceAction, staffStatusChange, startContract, updateAttendance, yotiVerificationEnd, yotiVerificationStart } from "../staffsAPI";

export const getStaffsListThunk = createAsyncThunk("getStaffsList", async (payload) => {
    const request = await getStaffsList(payload)
    return request.data.data
})

export const resendStaffEmailThunk = createAsyncThunk("resendStaffEmail", async (payload) => {
    const request = await resendStaffEmail(payload)
    return request.data.data
})

export const staffStatusChangeThunk = createAsyncThunk("staffStatusChange", async (payload) => {
    const request = await staffStatusChange(payload)
    return request.data
})

export const getStaffRelatedDataThunk = createAsyncThunk("getStaffRelatedData", async () => {
    const request = await getStaffRelatedData()
    return request.data.data
})

export const addStaffThunk = createAsyncThunk("addStaff", async (payload) => {
    const request = await addStaff(payload)
    return request.data
})

export const getStaffDataThunk = createAsyncThunk("getStaffData", async (payload) => {
    const request = await getStaffData(payload)
    return request.data.data
})

export const getStaffAccrediationsThunk = createAsyncThunk("getStaffAccrediations", async (payload) => {
    const request = await getStaffAccrediations(payload)
    return request.data.data
})

export const getStaffAbsencesThunk = createAsyncThunk("getStaffAbsences", async (payload) => {
    const request = await getStaffAbsences(payload)
    return request.data.data
})

export const deleteStaffAbsencesThunk = createAsyncThunk("deleteStaffAbsences", async (payload) => {
    const request = await deleteStaffAbsences(payload)
    return request.data.data
})

export const editStaffProfileThunk = createAsyncThunk("editStaffProfile", async (payload) => {
    const request = await editStaffProfile(payload)
    return request.data.data
})

export const editStaffAccrediationsThunk = createAsyncThunk("editStaffAccrediations", async (payload) => {
    const request = await editStaffAccrediations(payload)
    return request.data
})

export const getStaffTimeSheetThunk = createAsyncThunk("getStaffTimeSheet", async (payload) => {
    const request = await getStaffTimeSheet(payload)
    return request.data.data
})

export const markStaffAttendanceThunk = createAsyncThunk("markStaffAttendance", async (payload) => {
    const request = await markStaffAttendance(payload)
    return request.data.data
})

export const updateAttendanceThunk = createAsyncThunk("updateAttendance", async (payload) => {
    const request = await updateAttendance(payload)
    return request.data.data
})

export const getUsersActiveContractThunk = createAsyncThunk("getUsersActiveContract", async (payload) => {
    const request = await getUsersActiveContract(payload)
    return request.data.data
})

export const staffManualAttendanceActionThunk = createAsyncThunk("staffManualAttendanceAction", async (payload) => {
    const request = await staffManualAttendanceAction(payload)
    return request.data.data
})
export const getStaffVerificationThunk = createAsyncThunk("getStaffVerification", async (payload) => {
    const request = await getStaffVerification(payload)
    return request.data.data
})


export const editVerificationThunk = createAsyncThunk("editVerification", async (payload) => {
    const request = await editVerification(payload)
    return request.data.data
})

export const getStaffContractListThunk = createAsyncThunk("getStaffContractList", async (payload) => {
    const request = await getStaffContractList(payload)
    return request.data.data
})
export const getStaffContractThunk = createAsyncThunk("getStaffContract", async (payload) => {
    const request = await getStaffContract(payload)
    return request.data.data
})


export const duplicateContractThunk = createAsyncThunk("duplicateContract", async (payload) => {
    const request = await duplicateContract(payload)
    return request.data.data
})

export const deleteContractThunk = createAsyncThunk("deleteContract", async (payload) => {
    const request = await deleteContract(payload)
    return request.data.data
})

export const startContractThunk = createAsyncThunk("startContract", async (payload) => {
    const request = await startContract(payload)
    return request.data.data
})

export const addContractThunk = createAsyncThunk("addContract", async (payload) => {
    const request = await addContract(payload)
    return request.data.data
})

export const editContractThunk = createAsyncThunk("editContract", async (payload) => {
    const request = await editContract(payload)
    return request.data.data
})


export const getStaffGapsThunk = createAsyncThunk("getStaffGaps", async (payload) => {
    const request = await getStaffGaps(payload)
    return request.data.data
})

export const gapApplicationActionThunk = createAsyncThunk("gapApplicationAction", async (payload) => {
    const request = await gapApplicationAction(payload)
    return request.data.data
})


export const getGphcInfoThunk = createAsyncThunk("getGphcInfo", async (payload) => {
    const request = await getGphcInfo(payload)
    return request.data.data
})

export const getStaffScheduleThunk = createAsyncThunk("getStaffSchedule", async (payload) => {
    const request = await getStaffSchedule(payload)
    return request.data.data
})

export const applyManualAttendanceThunk = createAsyncThunk("applyManualAttendance", async (payload) => {
    const response = await applyManualAttendance(payload)
    return response.data.data
})



export const yotiVerificationStartThunk = createAsyncThunk("yotiVerificationStart", async (payload) => {
    const response = await yotiVerificationStart(payload)
    return response.data.data
})

export const yotiVerificationEndThunk = createAsyncThunk("yotiVerificationEnd", async (payload) => {
    const response = await yotiVerificationEnd(payload)
    return response.data.data
})