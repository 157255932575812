import { Button, Form, Input, Popover } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { resetPasswordThunk } from "../../../redux/auth/authThunk";
import { notify } from "../../../utils/helper";
import "../style.scss";
import infoIcon from '../../../assets/icons/infoIcon.png'
import { PasswordCriteria } from "./loginForm";

export const ResetPassForm = () => {
  const [form] = Form.useForm();
  const dispatach = useDispatch();
  const { token } = useParams();
  const navigate = useNavigate();
  const [btndisabled, setbtndisabled] = useState(true);

  const onValuesChange = (changedValues, allValues) => {
    if (
      allValues.confirm !== undefined &&
      allValues.password !== undefined &&
      allValues.confirm !== "" &&
      allValues.password !== ""
    ) {
      setbtndisabled(false);
    } else {
      setbtndisabled(true);
    }
  };

  const onFinish = ({ password }) => {
    dispatach(resetPasswordThunk({ token, password })).then((res) => {
      if (res.payload.status === "success") {
        form.resetFields();
        notify("success", "Password Reset!");
        navigate(generatePath("login"));
      }
    });
  };

  return (
    <div
      className="d-flex flex-column m-auto"
      style={{ maxWidth: "50%", width: "450px", gap: "50px" }}
    >
      <div>
        <div className="heading2 fw-bolder">Update Password</div>
        <div className="t1">
          Enter the code sent to your registered email address.
        </div>
      </div>
      <Form
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          label="Password"
          name="password"
          className='position-relative'
          rules={[
            { required: true, message: 'Please Enter your password!' },
            {
              pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
              message: `Password doesn't match the Criteria!`
            }
          ]}
        >
          <Input.Password
            onBlur={e => form.setFieldsValue({ 'password': e.target.value.trim() })}
            prefix={
              <Popover
                content={PasswordCriteria} placement="left" title="Password Criteria">
                <img src={infoIcon} alt="" />
              </Popover>
            }
            placeholder='Password'
            className='text-input-field  p-2 pe-3 t1'
            maxLength={50}
          />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Confirm Password"
          className="mb-5"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords does not match!"));
              },
            }),
          ]}
        >
          <Input.Password
            onBlur={e => form.setFieldsValue({ 'confirm': e.target.value.trim() })}
            prefix={
              <Popover
                content={PasswordCriteria} placement="left" title="Password Criteria">
                <img src={infoIcon} alt="" />
              </Popover>
            }
            placeholder="Password"
            className="text-input-field  p-2 pe-3 t1"
            maxLength={256}
          />
        </Form.Item>
        <Form.Item>
          <Button
            disabled={btndisabled}
            type="primary"
            htmlType="submit"
            block
            className="btn-style"
          >
            Change Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
