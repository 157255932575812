import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import React from 'react'
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(isoWeek);

const { RangePicker } = DatePicker
export const DashboardDateFilter = ({
    dateRange, setDateRange, setFlag
}) => {
    const rangePresets = [
        {
            label: 'This Week',
            value: [dayjs().startOf('isoWeek'), dayjs().endOf('isoWeek')],
        },
        {
            label: 'Next Week',
            value: [dayjs().add(1, 'week').startOf("isoWeek"), dayjs().add(1, 'week').endOf("isoWeek")],
        },
        {
            label: 'This Month',
            value: [dayjs().startOf("month"), dayjs().endOf("month")],
        },
        {
            label: 'Next Month',
            value: [dayjs().add(1, 'month').startOf("month"), dayjs().add(1, 'month').endOf("month")],
        },
    ];

    return (
        <RangePicker
            allowClear={false}
            inputReadOnly
            value={[dateRange?.startDateRange, dateRange?.endDateRange]}
            className="text-input-field px-3 t1"
            style={{ width: 300, minWidth: 300, maxWidth: 300 }}
            format="DD MMM, YYYY"
            presets={rangePresets}
            onChange={value => {
                setDateRange({
                    startDateRange: value[0],
                    endDateRange: value[1]
                })
                setFlag(true)
            }}
        />
    );
};