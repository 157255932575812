import React, { useMemo, useState } from "react";
import { Card } from "semantic-ui-react";

import "./style.scss";
import { Popover } from "antd";
import _ from "lodash";

// Sample Data


// const data = {
//   "full_name": "PETER ONEIL",
//   "date_of_birth": "1966-12-16",
//   "given_names": "PETER",
//   "family_name": "ONEIL",
//   "place_of_birth": "ENGLAND",
//   "gender": "MALE",
//   "structured_postal_address": {
//     "address_format": 1,
//     "building_number": "92",
//     "address_line1": "92 SHEARWATER",
//     "address_line2": "WHITBURN",
//     "town_city": "SUNDERLAND",
//     "postal_code": "SR6 7SG",
//     "country_iso": "GBR",
//     "country": "United Kingdom",
//     "formatted_address": "92 SHEARWATER\nWHITBURN\nSUNDERLAND\nSR6 7SG\nUnited Kingdom"
//   },
//   "array": [
//     {
//       "saad": 'hello',
//       "basharat": "bye",
//       'third': 'ahemd',
//       'array3': [
//         {
//           "raja": 'gandu'
//         }
//       ]
//     },
//     {
//       "a": 'hello',
//       "b": "bye",
//       'c': 'ahemd'
//     },
//   ],
//   "array2": [
//     {
//       "c": 'hello',
//       "d": "bye",
//       'e': 'ahemd'
//     },
//     {
//       "f": 'hello',
//       "g": "bye",
//       'h': 'ahemd'
//     },
//   ],
//   "document_type": "DRIVING_LICENCE",
//   "issuing_country": "GBR",
//   "document_number": "ONEIL612166P99KF54",
//   "expiration_date": "2031-11-04",
//   "date_of_issue": "2021-11-05",
//   "issuing_authority": "DVLA"
// }

const ExtractedObject = ({ data }) => {
  return Object.keys(data).map((key) => {
    return (
      <div key={key} className="d-flex gap-2">
        <div style={{ textTransform: 'capitalize' }} className="fw-bold">
          {key?.split('_').join(' ')} :
        </div>
        <div>{data[key]}</div>
      </div>
    )
  })
}


export const BranchCard = ({ logo, primaryText, secondaryText, userStatus, hasRating, stats, isStaff, staffInfo }) => {
  let result = {}
  const [hovered, setHovered] = useState(false);

  const altText = useMemo(() => {
    return primaryText
      ?.split(" ")
      ?.map((d, idx) => idx < 2 ? d?.charAt(0).toUpperCase() : null)
      .join("");
  }, [primaryText]);

  const handleHoverChange = (open) => {
    setHovered(open);
  };

  const recursiveFunction = (obj) => {
    for (const key in obj) {
      if (typeof obj[key] === "object" &&
        Array.isArray(obj[key]) === false) {
        recursiveFunction(obj[key])
      }
      else if (
        Array.isArray(obj[key]) === true) {
        recursiveFunction(obj[key])
      }
      else {
        result = { ...result, [key]: obj[key] }
      }
    }
  }

  const jsonObject = useMemo(() => {
    if (_.isEmpty(staffInfo?.userExtractedData)) return
    recursiveFunction(JSON.parse(staffInfo?.userExtractedData))
    return result
  }, [staffInfo?.userExtractedData, result])

  return (
    <>
      <Card className="branch-card">
        {logo ? (
          <div
            style={{
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              border: "1px solid #96999C",
              marginBottom: "10px",
              position: "relative",
              padding: "3px"
            }}
          >
            <img
              src={logo}
              alt={altText}
              style={{
                width: logo ? "100%" : "23px",
                height: logo ? "100%" : "23px",
                borderRadius: logo ? "50%" : "0%",
              }}
            />
          </div>
        ) : (
          <div
            style={{
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              background: "white",
              fontSize: 35,
              color: " #065A4F",
              textAlign: "center",
              lineHeight: "134px",
              border: "1px solid #96999C",
              marginBottom: "10px",
              position: "relative",
              padding: "3px"

            }}
            text={altText}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                background: "#E1F4F4",
                fontSize: 35,
                color: " #065A4F",
                textAlign: "center",
                lineHeight: "134px",
                position: "relative",
              }}
            >
              {altText}
            </div>
          </div>
        )}
        <Popover
          className="header-component-container"
          title="Verification Extracted Data"
          placement="right"
          open={hovered}
          content={
            <div className="border-4 border-start ps-3" style={{ borderColor: 'red' }}>
              <ExtractedObject data={jsonObject} />
            </div>
          }
          trigger="hover"
          onOpenChange={staffInfo?.userExtractedData ? handleHoverChange : undefined}

        >
          {
            userStatus &&
            <span
              style={{
                position: "absolute",
                top: "150px",
                left: "12%",
                height: "35px",
                padding: "0px 17px",
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                color: `${userStatus === 'active' ? 'rgb(27, 94, 31)' : '#B71B1C'}`,
                border: "1px solid #FFFFFF",
                background: `${userStatus === 'active' ? 'rgb(232, 245, 233)' : "#FFEBEE"}`,
                borderRadius: "36px",
                width: "115px",
                justifyContent: "center",
                cursor: 'pointer'
              }}
            >
              {userStatus === 'active' ? 'Verified' : 'Not Verified'}
            </span>
          }
        </Popover>

        <Card.Content className="text-content">
          <Card.Header>
            <p className="h5 fw-bold" >{primaryText ?? "Demo"}</p>
          </Card.Header>
          <Card.Description>
            <p className="t2 " >{secondaryText} </p>
          </Card.Description>
        </Card.Content>
      </Card>
      {
        hasRating &&
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ margin: "20px" }}
        >
          <div
            className="d-flex flex-column  "
            style={{ maxWidth: "67px", overflow: "hidden" }}
          >
            <span className="b1">{stats.completed}</span>
            <div
              className=" t2 breadCrumps-role-detail"
              style={{ color: "#7B7B7B", }}
            >
              Completed
            </div>
          </div>
          <div
            style={{
              border: "1px solid  #D9D9D9",
              height: "24px",
              borderRadius: "24px",
              background: "#D9D9D9",
            }}
          >
            {" "}
          </div>
          <div className="d-flex flex-column  ">
            <span className="b1">{stats?.cancelled}</span>
            <div
              className=" t2 breadCrumps-role-detail"
              style={{ color: "#7B7B7B", }}
            >
              Cancelled
            </div>
          </div>
          <div
            style={{
              border: "1px solid  #D9D9D9",
              height: "24px",
              borderRadius: "24px",
              background: "#D9D9D9",
            }}
          >
            {" "}
          </div>
          <div className="d-flex flex-column  ">
            <span className="b1">{Number(stats?.rating)?.toFixed(1)}</span>
            <div
              className=" t2 breadCrumps-role-detail"
              style={{ color: "#7B7B7B", }}
            >
              Rating
            </div>
          </div>
        </div>
      }
    </>
  );
};
