import { useState } from "react";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Outlet } from "react-router-dom";
import { BranchCard } from "../../../components/UI/cards/profileCard";
import { ContentContainer } from "../../../components/UI/container";
import { Sidebar } from "../../../components/UI/sideBar";
import { getProfileDataThunk } from "../../../redux/profile/profileThunk";
import { RouterConfig } from "../../../routerConfig";
import { moduleKeys } from "../../../utils/modulesKeyConstants";

const sidebarLinks = [
  {
    text: "Personal Information",
    to: generatePath(RouterConfig.branchAdminProfileInfo),
    key: "",
    disabled: false,
    hidden: false,
  },
  {
    text: "Subscription & Billing",
    to: generatePath(RouterConfig.branchAdminProfileSubscription),
    key: moduleKeys.SUBSCRIPTION_AND_BILLING,
    disabled: false,
    hidden: false,
  },
  {
    text: "Bank Holidays",
    to: generatePath(RouterConfig.branchAdminProfileBankHolidays),
    key: moduleKeys.BANK_HOLIDAYS,
    disabled: false,
    hidden: false,
  },
  {
    text: "Accrediations",
    to: generatePath(RouterConfig.branchAdminProfileAccrediations),
    key: moduleKeys.ACCREDITATIONS,
    disabled: false,
    hidden: false,
  },
  {
    text: "Settings",
    to: generatePath(RouterConfig.branchAdminProfileSetting),
    key: moduleKeys.SETTINGS,
    disabled: false,
    hidden: false,
  },
  {
    text: "Admin Staff",
    to: generatePath(RouterConfig.branchAdminProfileAdminStaff),
    key: moduleKeys.ADMIN_STAFF,
    disabled: false,
    hidden: false,
  },
];

export const ProfileDetail = () => {
  const dispatch = useDispatch();
  const { profileInfo } = useSelector((state) => state.profile);
  const { user } = useSelector((state) => state.auth);

  const { accessibleModules: modules } = useSelector((state) => state.auth);
  const [displaySidebarLinks, setDisplaySidebarLinks] = useState([]);
  useEffect(() => {
    dispatch(getProfileDataThunk());
  }, [dispatch]);

  useEffect(() => {
    if (!modules) return;
    let modulesArray = Object.values(modules);
    let linksToDisplay = [
      {
        text: "Personal Information",
        to: generatePath(RouterConfig.branchAdminProfileInfo),
        key: moduleKeys.PROFILE,
        disabled: false,
        hidden: false,
      },
    ];

    for (let link of sidebarLinks) {
      for (let module of modulesArray) {
        if (link?.key?.toLowerCase() === module?.moduleKey?.toLowerCase()) {
          linksToDisplay.push(link);
        }
      }
    }
    linksToDisplay = [
      ...(user.roleId === 2 ? [
        {
          text: "Admin Staff",
          to: generatePath(RouterConfig.branchAdminProfileAdminStaff),
          key: moduleKeys.ADMIN_STAFF,
          disabled: false,
          hidden: false,
        },
      ] : []),
      ...linksToDisplay,
    ]
    setDisplaySidebarLinks(linksToDisplay);
  }, [modules, user.roleId]);

  return (
    <div className="content-view-page details-page">
      <div className="main-wrapper p-5">
        <div className="content-holder">
          <Sidebar links={displaySidebarLinks} visible>
            <BranchCard
              logo={profileInfo?.image}
              primaryText={`${profileInfo?.name ?? ""} ${profileInfo?.surname ?? ""}`}
              secondaryText={profileInfo?.label}
            />
          </Sidebar>
          <ContentContainer className="content-container">
            <Outlet />
          </ContentContainer>
        </div>
      </div>
    </div>
  );
};
