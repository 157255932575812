import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { BranchCard } from "../../components/UI/cards/profileCard";


import { ContentContainer } from "../../components/UI/container";
import { Sidebar } from "../../components/UI/sideBar";

export const StaffDetails = () => {
    const { user } = useSelector(state => state.auth)

    let sidebarLinks = useMemo(
        () => [
            {
                text: "Personal Information",
                to: 'information',
                disabled: false,
                hidden: false,
            },
            {
                text: "Schedule",
                to: 'schedule',
                disabled: false,
                hidden: false,
            },
            {
                text: "Time Sheet",
                to: 'time-sheet',
                disabled: false,
                hidden: false,
            },
            {
                text: "Leaves",
                to: 'leaves',
                disabled: false,
                hidden: false,
            },
            {
                text: "Contract",
                to: 'contract',
                disabled: false,
                hidden: false,
            },
            {
                text: "Verification",
                to: 'verifications',
                disabled: false,
                hidden: false,
            },
            {
                text: "Notification Settings",
                to: 'setting',
                disabled: false,
                hidden: false,
            },

        ],
        []
    );

    sidebarLinks = [
        ...(user.roleId === 6 ? [
            {
                text: "Shift Gaps",
                to: 'shift-gaps',
                disabled: false,
                hidden: false,
            },
        ] : []),
        ...sidebarLinks,
    ]

    return (
        <div className="content-view-page details-page">
            <div className="main-wrapper p-5">
                <div className="content-holder">
                    <Sidebar links={sidebarLinks} visible>
                        <BranchCard
                            // userStatus={user.status}
                            logo={user.image}
                            primaryText={`${user?.name} ${user?.surname}`}
                            secondaryText={user.label}
                        />
                    </Sidebar>
                    <ContentContainer className="content-container">
                        <Outlet />
                    </ContentContainer>
                </div>
            </div>
        </div>
    );
};
