import { API } from "../../../axios";

export const getStaffsList = (payload) => {
    const { filters, pagination } = payload;
    const request = API.post(`/staff/list`, {
        filters, pagination
    });
    return request
}

export const resendStaffEmail = (payload) => {
    const request = API.post(`/invites/resend`,
        payload
    );
    return request
}

export const getStaffRelatedData = () => {
    const request = API.get(`/invites/get-data`,);
    return request
}

export const staffStatusChange = (payload) => {
    const { data, id } = payload
    const request = API.post(`staff/${id}/change-status`, data);
    return request
}

export const addStaff = (payload) => {
    const request = API.post('/invites', payload)
    return request
}

export const getStaffData = (payload) => {
    const { staffId } = payload
    const request = API.get(`/staff/${staffId}/profile`)
    return request
}

export const getStaffAccrediations = (payload) => {
    const { staffId } = payload
    const request = API.get(`role/accreditation/${staffId}`)
    return request
}

export const getStaffAbsences = (payload) => {
    const { staffId, filters, pagination } = payload
    const request = API.post(`staff/${staffId}/absence/`,
        {
            filters, pagination
        })
    return request
}
export const deleteStaffAbsences = (payload) => {
    const { staffId, data } = payload
    const request = API.delete(`staff/${staffId}/absence/`,
        {
            data
        })
    return request
}

export const editStaffProfile = (formData) => {
    const request = API.post('profile/personal-info', formData, {
        headers: { "Content-Type": "multipart/form-data" },
    })
    return request
}

export const editStaffAccrediations = (formData) => {
    const request = API.post('profile/accreditations', formData, {
        headers: { "Content-Type": "multipart/form-data" },
    })
    return request
}

export const getStaffTimeSheet = (payload) => {
    const request = API.post('personal/attendance', payload)
    return request
}
export const markStaffAttendance = (payload) => {
    const request = API.post('personal/attendance/create-bulk', payload)
    return request
}

export const updateAttendance = (payload) => {
    const request = API.post('personal/attendance/update-record', payload)
    return request
}

export const staffManualAttendanceAction = (payload) => {
    const request = API.post('personal/mannual-attendance/actions', payload)
    return request
}


export const getStaffVerification = (payload) => {
    const { staffId } = payload
    const request = API.get(`profile/verification/${staffId}`)
    return request
}

export const editVerification = (payload) => {
    const { staffId, payload: _payload } = payload
    const request = API.post(`profile/verification/${staffId}`, _payload, {
        headers: { "Content-Type": "multipart/form-data" },
    })
    return request
}

export const getStaffContractList = (payload) => {
    const { staffId, filters, pagination } = payload
    const request = API.post(`staff/${staffId}/contract/list`,
        {
            filters, pagination
        })
    return request
}

export const getStaffContract = (payload) => {
    const { contractId } = payload
    const request = API.get(`staff/contract/${contractId}`)
    return request
}


export const duplicateContract = (payload) => {
    const { staffId, contractId, payload: _payload } = payload
    const request = API.post(`staff/${staffId}/contract/${contractId}/duplicate`, _payload)
    return request
}

export const deleteContract = (payload) => {
    const { staffId, contractId } = payload
    const request = API.delete(`staff/${staffId}/contract/${contractId}`)
    return request
}

export const startContract = (payload) => {
    const { staffId, contractId } = payload
    const request = API.post(`staff/${staffId}/contract/${contractId}/start`)
    return request
}

export const addContract = (payload) => {
    const { staffId, payload: _payload } = payload
    const request = API.post(`staff/${staffId}/contract/`, _payload)
    return request
}

export const getUsersActiveContract = (payload) => {
    const { staffId } = payload
    const request = API.get(`staff/${staffId}/contract/`)
    return request
}

export const editContract = (payload) => {
    const { staffId, contractId, payload: _payload } = payload
    const request = API.put(`staff/${staffId}/contract/${contractId}`, _payload)
    return request
}

export const getStaffGaps = (payload) => {
    const { filters, pagination } = payload;
    const request = API.post(`/branches/schedule/list/gaps`, {
        filters, pagination
    });
    return request
}


export const gapApplicationAction = (payload) => {
    const { id, action } = payload;
    const request = API.post(`gap/application/${id}/${action}`);
    return request
}

export const getGphcInfo = (payload) => {
    const { gphcNumber } = payload;
    const request = API.get(`locum-banks/gphc-info/${gphcNumber}`, {
        loader: false,
    });
    return request
}


export const getStaffSchedule = (payload) => {
    const { id, data } = payload;
    const request = API.post(`staff/${id}/schedule/`, data);
    return request
}


export const applyManualAttendance = (payload) => {
    const { data } = payload
    const request = API.post(`personal/mannual/attendance`, data)
    return request
}


export const yotiVerificationStart = (payload) => {
    const request = API.get(`profile/yoti-verification-start`)
    return request
}

export const yotiVerificationEnd = (payload) => {
    const request = API.get(`profile/yoti-verification-end`)
    return request
}