import moment from "moment"
import { useSelector } from "react-redux"
import { FlexRow } from "../../../../components/comon/flexRows"

export const InformationTab = () => {
    const { profileInfo } = useSelector((state) => state.profile)
    return (
        <div className="d-flex flex-column flex-1" style={{ gap: 30 }}>
            <div className="border rounded">
                <div className="s1 text-row m-0">Bio Details</div>
                <FlexRow
                    primaryTitle="First Name"
                    primaryDescription={profileInfo?.name ? profileInfo?.name : "-"}
                />
                <FlexRow
                    primaryTitle="Last Name"
                    primaryDescription={profileInfo?.surname || "-"}
                />
                <FlexRow
                    primaryTitle="Gender"
                    primaryDescription={profileInfo?.gender || "-"}
                />
                <FlexRow
                    primaryTitle="Date of Birth"
                    primaryDescription={profileInfo?.dob ? moment(profileInfo?.dob).format('DD-MM-YYYY') : '-'}
                />
                <FlexRow
                    primaryTitle="Email Address"
                    primaryDescription={profileInfo?.email || "-"}
                />
            </div>
        </div>
    )
}