import { createSlice } from "@reduxjs/toolkit";
import { addContractThunk, applyManualAttendanceThunk, editContractThunk, editStaffProfileThunk, editVerificationThunk, gapApplicationActionThunk, getGphcInfoThunk, getStaffAbsencesThunk, getStaffAccrediationsThunk, getStaffContractListThunk, getStaffContractThunk, getStaffDataThunk, getStaffGapsThunk, getStaffRelatedDataThunk, getStaffScheduleThunk, getStaffsListThunk, getStaffTimeSheetThunk, getStaffVerificationThunk, getUsersActiveContractThunk, staffStatusChangeThunk } from "../staffsThunk";


const staffRelatedData = {
    branches: [],
    regions: [],
    roles: [],
}

const initialState = {
    staffs: [],
    staffContractsList: [],
    staffContract: {},
    staffTimeSheet: {},
    staffInfo: {},
    staffAccrediations: [],
    staffAbsences: [],
    staffVerification: {},
    staffRelatedData,
    staffGaps: {},
    gphcInfo: {},
    staffSchedule: [],
    activeContract: {}
}

const staffsSlicer = createSlice({
    name: "staffsSlicer",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getStaffsListThunk.fulfilled, (state, action) => {
                state.staffs = action.payload
            })
            .addCase(getStaffRelatedDataThunk.fulfilled, (state, action) => {
                state.staffRelatedData = action.payload
            })
            .addCase(staffStatusChangeThunk.fulfilled, (state, action) => {
                if (action.payload.status === 'success') {
                    state.staffInfo.status = action.payload.data.status
                }
            })
            .addCase(getStaffDataThunk.fulfilled, (state, action) => {
                state.staffInfo = action.payload
            })
            .addCase(getStaffAccrediationsThunk.fulfilled, (state, action) => {
                state.staffAccrediations = action.payload
            })
            .addCase(getStaffAbsencesThunk.fulfilled, (state, action) => {
                state.staffAbsences = action.payload
            })
            .addCase(editStaffProfileThunk.fulfilled, (state, action) => {
                state.staffInfo = action.payload
            })
            .addCase(getStaffContractListThunk.fulfilled, (state, action) => {
                state.staffContractsList = action.payload
            })
            .addCase(addContractThunk.fulfilled, (state, action) => {
                state.staffContractsList.rows.push(action.payload)
            })
            .addCase(getStaffContractThunk.fulfilled, (state, action) => {
                state.staffContract = action.payload
            })
            .addCase(editContractThunk.fulfilled, (state, action) => {
                state.staffContract = action.payload
            })
            .addCase(getStaffTimeSheetThunk.fulfilled, (state, action) => {
                state.staffTimeSheet = action.payload
            })
            .addCase(applyManualAttendanceThunk.fulfilled, (state, action) => {
                const index = state.staffTimeSheet?.attendance.rows.findIndex((list) => list.id === action.payload.id)
                state.staffTimeSheet.attendance.rows[index] = action.payload
            })
            .addCase(getStaffVerificationThunk.fulfilled, (state, action) => {
                state.staffVerification = action.payload
            })
            .addCase(editVerificationThunk.fulfilled, (state, action) => {
                state.staffVerification = action.payload
            })
            .addCase(getStaffGapsThunk.fulfilled, (state, action) => {
                state.staffGaps = action.payload
            })
            .addCase(gapApplicationActionThunk.fulfilled, (state, action) => {
                state.staffGaps = action.payload
            })
            .addCase(getGphcInfoThunk.fulfilled, (state, action) => {
                state.gphcInfo = action.payload
            })
            .addCase(getStaffScheduleThunk.fulfilled, (state, action) => {
                state.staffSchedule = action.payload
            })
            .addCase(getUsersActiveContractThunk.fulfilled, (state, action) => {
                state.activeContract = action.payload
            })
    }
})

export const staffsReducer = staffsSlicer