import React, { useMemo, useState } from "react";
import { TableComponet } from "../UI/table";
import arrowRightIcon from '../../assets/icons/arrow-right.png'
import avatar from '../../assets/icons/Avatar.png'

import acceptIcon from '../../assets/icons/accept.png'
import rejecticon from '../../assets/icons/reject.png'
import { AddModal } from "../UI/modals/addModal";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { gapApplicationActionThunk, getStaffGapsThunk } from "../../redux/staffs/staffsThunk";
import moment from "moment";
import { hasAccess } from "../../utils/helper";
import { branchApiKeys } from "../../utils/apiKeyConstants";
import { EmptyData } from "../comon/EmptyData";

const getDuration = (startTime, endTime) => {
    const _startTime = moment(startTime, "hh:mm:ss a");
    const _endTime = moment(endTime, "hh:mm:ss a");
    const mins = moment.utc(moment(_endTime, "HH:mm:ss").diff(moment(_startTime, "HH:mm:ss"))).format("mm")
    return _endTime.diff(_startTime, 'hours') + "h " + mins + "m";
}


export const StaffGapsTable = () => {
    const dispatch = useDispatch()
    const { staffGaps } = useSelector(state => state.staffs)

    const [showApplicationModal, setShowApplicationModal] = useState(false)
    const [currentPageCount, setCurrentPageCount] = useState(1);
    const [perPageCount, setPerPageCount] = useState(10);
    const [seletcedGap, setSeletcedGap] = useState()

    const gapColumns = React.useMemo(
        () => [
            {
                Header: "Branch Name",
                accessor: "branchDetails.title",
            },
            {
                Header: "Date",
                accessor: "startDate",
            },
            {
                Header: "Time",
                accessor: "startTime",
                Cell: ({ row: { original } }) => {
                    return original.startTime + ' - ' + original.endTime
                },
            },
            {
                Header: "Duration",
                accessor: "duration",
                Cell: ({ row: { original } }) => getDuration(original.startTime, original.endTime)


            },
            {
                Header: "",
                accessor: "action",
                Cell: ({ row: { original } }) => {
                    return (
                        <img
                            onClick={() => {
                                setSeletcedGap(original)
                                setShowApplicationModal(true)
                            }}
                            style={{ cursor: "pointer" }}
                            src={arrowRightIcon}
                            alt=""
                        />
                    );
                },
            },
        ],
        []
    );

    useEffect(() => {
        if (hasAccess(branchApiKeys.GET_ALL_BRANCHES_GAP_LIST)) {
            dispatch(
                getStaffGapsThunk({
                    filters: {},
                    pagination: {
                        page: currentPageCount,
                        perPage: perPageCount,
                    }
                })
            )
        }
    }, [currentPageCount, dispatch, perPageCount])

    const {
        count: totalDataCount,
        page,
        pages: totalPagesCount,
        perPage,
        rows: gapsList,
    } = useMemo(() => staffGaps, [staffGaps]);

    useEffect(() => {
        if (!page && !perPage) return;
        setPerPageCount(perPage);
        setCurrentPageCount(page);
    }, [page, perPage]);

    const gapApplicationAction = (id, action) => {
        dispatch(
            gapApplicationActionThunk({
                id,
                action
            })
        ).then(res => {
            setShowApplicationModal(false)
            setSeletcedGap(undefined)
        })
    }

    return (
        <div style={{ padding: "0px 24px" }}>
            {staffGaps?.rows?.length > 0 ?
                <TableComponet
                    rowCursor="pointer"
                    isBorderShown
                    currentPageCount={currentPageCount}
                    perPageCount={perPageCount}
                    totalDataCount={totalDataCount}
                    setCurrentPage={setCurrentPageCount}
                    setPerPageCount={setPerPageCount}
                    showPagination
                    maxPageCount={totalPagesCount}
                    columns={gapColumns}
                    data={gapsList}
                /> :
                <div style={{ border: "1px solid #E9E9E9", paddingTop: "80px" , minHeight:"500px"  }}>
                    <EmptyData title="Staff Gaps " />
                </div>

            }
            <AddModal
                title={`Applications`}
                handleModal={showApplicationModal}
                setHandleModal={setShowApplicationModal}
                secondaryButtonHandler={() => setShowApplicationModal(false)}
                secondaryButtonText="Dismiss"
            >
                <div
                    className="d-flex flex-column align-items-start add-shift-modal-content"
                    style={{ gap: "15px", color: "#7B7B7B", paddingTop: "25px" }}
                >
                    <div className="d-flex flex-column gap-3" style={{ width: '100%' }}>
                        <div className="b1">Gap Detail</div>
                        <div className="border rounded p-4 d-flex flex-column gap-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="p3" style={{ color: '#7B7B7B' }}>Branch Name</div>
                                <div className="p3">{seletcedGap?.branchDetails.title}</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="p3" style={{ color: '#7B7B7B' }}>Duration</div>
                                <div className="p3">{getDuration(seletcedGap?.startTime, seletcedGap?.endTime)}</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="p3" style={{ color: '#7B7B7B' }}>Date</div>
                                <div className="p3">{seletcedGap?.startDate}</div>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: '100%' }}>
                        <div className="b1 mb-3">Applications List</div>
                        <div className="d-flex flex-column gap-3" >
                            {
                                seletcedGap?.applications?.map((data, index) => (
                                    <div className="p-4 border rounded d-flex align-items-center justify-content-between" key={index}>
                                        <div className="d-flex align-items-center" style={{ gap: 20 }}>
                                            {
                                                staffGaps?.image ?
                                                    <img width={50} height={50} className="border rounded-circle" src={staffGaps?.image || avatar} alt="avatar" />
                                                    :
                                                    <div
                                                        style={{
                                                            width: 50,
                                                            height: 50,
                                                            borderRadius: '50%',
                                                            background: '#E1F4F4',
                                                            // fontSize: 18,
                                                            color: ' #065A4F',
                                                            textAlign: 'center',
                                                            lineHeight: '50px',
                                                            padding: 1
                                                        }}
                                                        text={'altText'}
                                                    >
                                                        {`${data.staff?.name} ${data.staff?.surname}`
                                                            ?.split(" ")
                                                            ?.map((d, idx) => (idx < 2 ? d?.charAt(0).toUpperCase() : null))
                                                            .join("")
                                                        }
                                                    </div>
                                            }
                                            <div className="d-flex flex-column" style={{ gap: 5, maxWidth: "229px", overflow: "hidden", marginRight: "5px" }}>
                                                <div className="b1">{data?.staff.name + ' ' + data?.staff.surname}</div>
                                                <div className="t2" style={{ color: '#7B7B7B' }}>{data?.staff.userRole.label}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center" style={{ gap: 20 }}>
                                            {hasAccess(branchApiKeys.REJECT_GAP_APPLICATION) && <div
                                                onClick={() => gapApplicationAction(data.id, 'reject')}
                                                className=" d-flex align-items-center  cursor-pointer" >
                                                <img style={{ width: '100%' }} src={rejecticon} alt="rejecticon" />
                                            </div>}
                                            {hasAccess(branchApiKeys.ACCEPT_GAP_APPLICATION) && <div
                                                onClick={() => gapApplicationAction(data.id, 'accept')}
                                                className=" d-flex align-items-center  cursor-pointer" >
                                                <img style={{ width: '100%' }} src={acceptIcon} alt="acceptIcon" />
                                            </div>}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                </div>
            </AddModal>
        </div>
    );
};
