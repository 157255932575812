import React, { useState } from "react";
import { EmptyData } from "../../comon/EmptyData";
import BranchDetailTable from "./BranchDetailTable/BranchDetailTable";
import { DayViewSlotDetail } from "./slotDetail/DayViewSlotDetail";
import blankList from "../../../assets/img/blankList.png";

import "./style.scss";
import { getTotalMinutes } from "../../../utils/helper";

export default function DayView({
  branches,
  onDelete,
  handleAddShift,
  branchDayView,
  height
}) {
  const [isBranchDetail, setIsBranchDetail] = useState(false);
  const DayHourseList = [
    { id: 1, hour: "00:00" },
    { id: 2, hour: "01:00" },
    { id: 3, hour: "02:00" },
    { id: 4, hour: "03:00" },
    { id: 5, hour: "04:00" },
    { id: 6, hour: "05:00" },
    { id: 7, hour: "06:00" },
    { id: 8, hour: "07:00" },
    { id: 9, hour: "08:00" },
    { id: 10, hour: "09:00" },
    { id: 11, hour: "10:00" },
    { id: 12, hour: "11:00" },
    { id: 13, hour: "12:00" },
    { id: 14, hour: "13:00" },
    { id: 15, hour: "14:00" },
    { id: 16, hour: "15:00" },
    { id: 17, hour: "16:00" },
    { id: 18, hour: "17:00" },
    { id: 19, hour: "18:00" },
    { id: 20, hour: "19:00" },
    { id: 21, hour: "20:00" },
    { id: 22, hour: "21:00" },
    { id: 23, hour: "22:00" },
    { id: 24, hour: "23:00" },
  ];

  const _slots = [
    { id: 1, hour: "00:00" },
    { id: 2, hour: "01:00" },
    { id: 3, hour: "02:00" },
    { id: 4, hour: "03:00" },
    { id: 5, hour: "04:00" },
    { id: 6, hour: "05:00" },
    { id: 7, hour: "06:00" },
    { id: 8, hour: "07:00" },
    { id: 9, hour: "08:00" },
    { id: 10, hour: "09:00" },
    { id: 11, hour: "10:00" },
    { id: 12, hour: "11:00" },
    { id: 13, hour: "12:00" },
    { id: 14, hour: "13:00" },
    { id: 15, hour: "14:00" },
    { id: 16, hour: "15:00" },
    { id: 17, hour: "16:00" },
    { id: 18, hour: "17:00" },
    { id: 19, hour: "18:00" },
    { id: 20, hour: "19:00" },
    { id: 21, hour: "20:00" },
    { id: 22, hour: "21:00" },
    { id: 23, hour: "22:00" },
    { id: 24, hour: "23:00" },
  ];

  return (
    <div
      className={branchDayView ? " " : " day-view-container"}
      style={{ position: "relative", height: `calc(100% - ${height} )` }}
    >
      <div
        className="dayViewWrapper"
        style={{
          width: branchDayView ? "70vw" : "",
          maxHeight: branchDayView ? "690px" : "",
          height: "100%",
        }}
      >
        {/* {isBranchDetail ? (
          <BranchDetailTable handleBranchDetail={setIsBranchDetail} />
        ) : ( */}
        <>
          <table
            cellSpacing={0}
            cellPadding={0}
            style={{ width: "100%" }}
            className=""
          >
            <thead style={{ height: "58px", verticalAlign: "middle" }}>
              <tr
                className=""
                style={{
                  height: "100%",
                  width: "100%",
                  borderBottom: "1px solid #e9e9e9",
                }}
              >
                {branchDayView ? (
                  " "
                ) : (
                  <th className="sticky-col first-col branch-header">
                    <div className="branch-name-heading">
                      <span className="heading5">All Branches</span>
                      <span className="t2 badge">{branches.length}</span>
                    </div>
                  </th>
                )}

                {DayHourseList?.map((hourSlot, idx) => {
                  return (
                    <th
                      key={idx}
                      className="week-day-heading-cell-day-view"
                      style={{
                        minWidth: 166,
                        maxWidth: 166,
                        position: branchDayView && "inherit",
                      }}
                    >
                      <span className="s1" style={{ fontWeight: "bold" }}>{hourSlot.hour}</span>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody style={{ height: "100%", width: "100%" }}>
              {/* {branches?.length > 0 ? (
                <> */}
              {!branchDayView
                ?
                (branches?.length > 0 ? (
                  branches?.map((branch, i) => {
                    return (
                      <tr key={i} className=" table-row ">
                        <td className="sticky-col first-col ">
                          <div
                            className={`branch-name-cell`}
                            onClick={() => setIsBranchDetail(true)}
                          >
                            <div className="row-1">
                              <span className="t2"> # {branch.id} </span>

                              {/* {branch.region && <div className="row-badge"> </div>} */}
                              <span className="t2">{branch.region} </span>
                            </div>
                            <span className="p3" style={{ fontWeight: "bold" }}>{branch.title} </span>
                            <span className="t2 branch-status " style={{ backgroundColor: branch.active ? '#e8f5e9' : '#ffebee' }}> {branch.active ? 'Open' : 'Closed'} </span>
                          </div>
                        </td>
                        {_slots?.map((slot, i) =>
                          i < 1 ? (
                            branch.events?.map((slots, i) => {
                              return (
                                <td
                                  key={i}
                                  className="schedular-slot-container "
                                >
                                  <div>
                                    {slots.shifts?.map((event, i) => {
                                      const duration = getTotalMinutes(
                                        event?.startTime,
                                        event?.endTime
                                      );
                                      const margin = getTotalMinutes(
                                        "00:00",
                                        event?.startTime
                                      );
                                      return (
                                        <div key={i}>
                                          <DayViewSlotDetail
                                            handleAddShift={
                                              handleAddShift
                                            }
                                            onDelete={onDelete}
                                            margin={(166 * margin) / 60}
                                            time={`${event.startTime} - ${event.endTime}`}
                                            width={(166 * duration) / 60}
                                            label={event.label}
                                            data={event}
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                </td>
                              );
                            })
                          ) : (
                            <td
                              key={i}
                              className="schedular-slot-container schedular-slot-container-border"
                            >
                              <div key={i}>&nbsp;</div>
                            </td>
                          )
                        )}
                      </tr>
                    );
                  })
                )
                  :
                  <div
                    style={{
                      position: "absolute",
                      left: "calc(50% - 202px)",
                    }}
                  >
                    <EmptyData
                      //actionBtn
                      img={blankList}
                      title="Shifts/Jobs"
                    // description="We cant find any of your branches, head over to the branches section to add your first branch!"
                    />
                  </div>
                )
                : branches?.events?.length > 0 ? branches?.events?.map((branch, i) => {
                  return (
                    <tr key={i} className=" table-row ">
                      {_slots?.map((slot, i) =>
                        i < 1 ? (
                          <td
                            key={i}
                            className="schedular-slot-container "
                          >
                            <div>
                              {branch.shifts?.map((event, i) => {
                                const duration = getTotalMinutes(
                                  event?.startTime,
                                  event?.endTime
                                );
                                const margin = getTotalMinutes(
                                  "00:00",
                                  event?.startTime
                                );
                                return (
                                  <div key={i}>
                                    <DayViewSlotDetail
                                      handleAddShift={handleAddShift}
                                      onDelete={onDelete}
                                      margin={(166 * margin) / 60}
                                      time={`${event.startTime} - ${event.endTime}`}
                                      width={(166 * duration) / 60}
                                      label={event.label}
                                      data={event}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </td>
                        ) : (
                          <td
                            key={i}
                            className="schedular-slot-container schedular-slot-container-border"
                          >
                            <div key={i}>&nbsp;</div>
                          </td>
                        )
                      )}
                    </tr>
                  );
                })
                  :
                  <div
                    style={{
                      position: "absolute",
                      left: "calc(50% - 202px)",
                    }}
                  >
                    <EmptyData
                      //actionBtn
                      img={blankList}
                      title="Shifts/Jobs"
                    // description="We cant find any of your branches, head over to the branches section to add your first branch!"
                    />
                  </div>
              }
              {/*  </>
               ) : (
                <div
                  style={{
                    position: "absolute",
                    left: "calc(50% - 202px)",
                  }}
                >
                  <EmptyData
                    //actionBtn
                    img={blankList}
                    title="Shifts/Jobs"
                  // description="We cant find any of your branches, head over to the branches section to add your first branch!"
                  />
                </div>
              )} */}
            </tbody>
          </table>
        </>
        {/* )} */}
      </div>
    </div>
  );
}
