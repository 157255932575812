import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Outlet, useParams } from "react-router-dom";
import { BranchCard } from "../../../../components/UI/cards/profileCard";
import { ContentContainer } from "../../../../components/UI/container";
import { Sidebar } from "../../../../components/UI/sideBar";
import {
  getStaffAccrediationsThunk,
  getStaffDataThunk,
  getStaffRelatedDataThunk,
} from "../../../../redux/staffs/staffsThunk";
import { RouterConfig } from "../../../../routerConfig";
import { inviteApiKeys, staffApiKeys } from "../../../../utils/apiKeyConstants";
import { hasAccess } from "../../../../utils/helper";
import "./style.scss";
import { moduleKeys } from "../../../../utils/modulesKeyConstants";
import { getRegionsBranchesListThunk } from "../../../../redux/regions/regionsThunk";

export const StaffDetails = () => {
  const dispatch = useDispatch();
  const { staffId } = useParams();
  const { staffInfo } = useSelector((state) => state.staffs);

  useEffect(() => {
    if (hasAccess(staffApiKeys.GET_STAFF_PROFILE)) {
      dispatch(getStaffDataThunk({ staffId }));
    }
    if (hasAccess(inviteApiKeys.GET_SHORT_BRANCHES))
      dispatch(getRegionsBranchesListThunk());
    dispatch(getStaffRelatedDataThunk());
    dispatch(getStaffAccrediationsThunk({ staffId }));
  }, [dispatch, staffId]);

  const sidebarLinks = useMemo(
    () => [
      {
        text: "Personal Information",
        to: generatePath(RouterConfig.branchAdminStaffInformation, { staffId }),
        key: moduleKeys.STAFF_PERSONAL_INFORMATION,
        disabled: false,
        hidden: false,
      },
      {
        text: "Verification",
        to: generatePath(RouterConfig.branchAdminStaffVerification, {
          staffId,
        }),
        key: moduleKeys.STAFF_VERIFICATION,
        disabled: false,
        hidden: false,
      },
      {
        ...(staffInfo?.group === "staff" && {
          text: "Schedule",
          to: generatePath(RouterConfig.branchAdminStaffSchedule, { staffId }),
          key: moduleKeys.STAFF_SCHEDULE,
          disabled: false,
          hidden: false,
        }),
      },
      {
        ...(staffInfo?.group === "staff" && {
          text: "Leave",
          to: generatePath(RouterConfig.branchAdminStaffAbsence, { staffId }),
          key: moduleKeys.STAFF_LEAVES,
          disabled: false,
          hidden: false,
        }),
      },
      {
        ...(staffInfo?.group === "staff" && {
          text: "Contract",
          to: generatePath(RouterConfig.branchAdminStaffContract, { staffId }),
          key: moduleKeys.STAFF_CONTRACT,
          disabled: false,
          hidden: false,
        }),
      },
      {
        ...(staffInfo?.group === "staff" && {
          text: "Time Sheet",
          to: generatePath(RouterConfig.branchAdminStaffTimeSheet, { staffId }),
          key: moduleKeys.STAFF_TIME_SHEET,
          disabled: false,
          hidden: false,
        }),
      },
    ],
    [staffId, staffInfo?.group]
  );
  const { accessibleModules: modules } = useSelector((state) => state.auth);
  const [displaySidebarLinks, setDisplaySidebarLinks] = useState([]);
  useEffect(() => {
    if (!modules) return;
    let modulesArray = Object.values(modules);
    let linksToDisplay = [];

    for (let link of sidebarLinks) {
      for (let module of modulesArray) {
        if (link?.key?.toLowerCase() === module?.moduleKey?.toLowerCase()) {
          linksToDisplay.push(link);
        }
      }
    }

    setDisplaySidebarLinks(linksToDisplay);
  }, [modules, sidebarLinks]);

  return (
    <div className="content-view-page details-page">
      <div className="main-wrapper p-5">
        <div className="content-holder">
          <Sidebar links={displaySidebarLinks} visible>
            <BranchCard
              isStaff
              staffInfo={staffInfo}
              userStatus={
                staffInfo?.group === "locum" ? staffInfo?.status : null
              }
              logo={staffInfo?.image}
              primaryText={`${staffInfo?.name} ${staffInfo?.surname}`}
              secondaryText={staffInfo?.label}
              hasRating={staffInfo?.group === "locum"}
              stats={{
                completed: staffInfo?.stats?.completedShifts,
                cancelled: staffInfo?.stats?.cancelledShifts,
                rating: staffInfo?.rating?.value,
              }}
            />
          </Sidebar>
          <ContentContainer className="content-container">
            <Outlet />
          </ContentContainer>
        </div>
      </div>
    </div>
  );
};
