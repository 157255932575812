import { createAsyncThunk } from "@reduxjs/toolkit";
import { acceptJobRequest, cancelJob, declineJobInvite, downloadJobsCSV, getJobCancelReasons, getLocumShiftInfo, ratingShift, updateJobStatus, getLocumShiftsList, getWorkedLocum, inviteLocumForJob, rejectJobRequest, updateJob, updateMileage } from "../locumShiftsAPI";

export const getLocumShiftsListThunk = createAsyncThunk("getLocumShiftsList", async (payload) => {
    const request = await getLocumShiftsList(payload)
    return request.data.data
})

export const getLocumShiftInfoThunk = createAsyncThunk("getLocumShiftInfo", async (payload) => {
    const request = await getLocumShiftInfo(payload)
    return request.data.data
})
export const updateJobStatusThunk = createAsyncThunk("updateJobStatus", async (payload) => {
    const request = await updateJobStatus(payload)
    return request.data.data
})
export const ratingShiftThunk = createAsyncThunk("ratingShift", async (payload) => {
    const request = await ratingShift(payload)
    return request.data.data
})

export const getWorkedLocumThunk = createAsyncThunk("getWorkedLocum", async (payload) => {
    const request = await getWorkedLocum(payload)
    return request.data.data
})

export const inviteLocumForJobThunk = createAsyncThunk("inviteLocumForJob", async (payload) => {
    const request = await inviteLocumForJob(payload)
    return request.data.data
})

export const declineJobInviteThunk = createAsyncThunk("declineJobInvite", async (payload) => {
    const request = await declineJobInvite(payload)
    return request.data.data
})

export const acceptJobRequestThunk = createAsyncThunk("acceptJobRequest", async (payload) => {
    const request = await acceptJobRequest(payload)
    return request.data.data
})

export const rejectJobRequestThunk = createAsyncThunk("rejectJobRequest", async (payload) => {
    const request = await rejectJobRequest(payload)
    return request.data.data
})

export const cancelJobThunk = createAsyncThunk("cancelJob", async (payload) => {
    const request = await cancelJob(payload)
    return request.data.data
})

export const updateMileageThunk = createAsyncThunk("updateMileage", async (payload) => {
    const request = await updateMileage(payload)
    return request.data.data
})

export const updateJobThunk = createAsyncThunk("updateJob", async (payload) => {
    const request = await updateJob(payload)
    return request.data.data
})


export const downloadJobsCSVThunk = createAsyncThunk("downloadJobsCSV", async (payload) => {
    const request = await downloadJobsCSV(payload)
    return request.data
})

export const getJobCancelReasonsThunk = createAsyncThunk("getJobCancelReasons", async (payload) => {
    const request = await getJobCancelReasons(payload)
    return request.data.data
})
