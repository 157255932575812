import moment from "moment";
import React, { useEffect, useState } from "react";
import { BranchItemCell } from "../../../../components/schedule/schedule-Week-view/week-items/BranchItem";
import { weekDays } from "../../../../utils/dates";
import "./style.scss";

export const LocumMonthlyView = ({ scheduleData }) => {
  const chunks = [];
  const chunkSize = 7;
  const data = scheduleData?.length && [...scheduleData]

  const [updatedData, setUpdatedDate] = useState([])
  useEffect(() => {
    if (!scheduleData?.length) return
    for (var m = moment(scheduleData[0]?.startDate).subtract(1, 'day'); m.isSameOrAfter(moment(scheduleData[0]?.startDate).startOf('isoWeek')); m.subtract(1, 'day')) {
      data?.unshift({
      });
    }
    for (let i = 0; i < data?.length; i += chunkSize) {
      const chunk = data?.slice(i, i + chunkSize);
      const remainingTd = chunkSize - chunk?.length;
      if (remainingTd > 0) {
        for (let x = 0; x < remainingTd; x++) {
          chunk.push({});
        }
      }
      chunks.push(chunk);
    }
    setUpdatedDate(chunks)
  }, [scheduleData])


  return (
    <>
      <div className="month-view-wraper">
        <table
          cellSpacing={0}
          cellPadding={0}
          style={{ height: "100%", width: "100%", tableLayout: "fixed" }}
        >
          <thead
            style={{ height: "58px", width: "100%" }}
            className="sticky-row"
          >
            <tr className="" style={{ height: "100%", width: "100%" }}>
              {weekDays?.map((day) => {
                return (
                  <th key={day} className="month-day-heading-cell">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <span className="s2" style={{ color: "#7B7B7B" }}>
                        {day.charAt(0).toUpperCase() + day.slice(1)}
                      </span>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody style={{ height: "100%", width: "100%" }}>
            {updatedData?.map((chunk, i) => {
              return (
                <tr key={i} className="table-row">
                  {chunk?.map((branch, idx) => {
                    return (
                      <td key={idx} className="month-schedular-slot-container">
                        {Object.keys(branch).length > 0 && (
                          <BranchItemCell
                            locumFlow
                            dayNumber={branch.startDate}
                            day={branch}
                          />
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
