import { Button } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTimer } from "use-timer";
import { onMarkAttendanceThunk } from "../../redux/locum-flow/Thunk";
import { notify } from "../../utils/helper";
import { ConfirmModal } from "../UI/modals/confirmModal";
import locationSetting from '../../assets/img/locationSetting.png'

const getCurrentCordinates = new Promise((resolve, reject) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      resolve(position.coords);
    },
      (error) => {
        reject(error.message);
      }
    );
  } else {
    notify("error", "your browser doesn't support geolocation API");
    reject("your browser doesn't support geolocation API");
  }
});

function getDistanceFromLatLonInMeter(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d * 1000;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export const AttendanceTimer = ({ time: _time, selectedAttendance }) => {
  const { attedanceJobs } = useSelector((state) => state.locum);
  const dispatch = useDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [duration, setDuration] = useState(_time);
  const [oldTime, setOldTime] = useState(_time);
  const [flag, setFlag] = useState(false);
  const [showLocationErrorModal, setShowLocationErrorModal] = useState(false)

  const {
    time: timer,
    start,
    pause,
    reset,
    status,
    advanceTime,
  } = useTimer({
    initialTime: duration,
    timerType: "DECREMENTAL",
    endTime: 0,
    onTimeOver: () => {
      notify("success", "Time over");
      // onMarkAttendanceRequest()
    },
  });

  const onMarkAttendanceRequest = useCallback(() => {
    const data = attedanceJobs.find((item) => item.id === selectedAttendance);
    const reqData = {
      jobId: data.job?.id,
      shiftId: data.shift?.id,
      branchId: data.branch?.id,
      id: data.id,
      checkIn: !data.actualCheckIn
        ? dayjs().format("HH:mm:ss")
        : data.actualCheckIn,
      checkOut:
        !data.actualCheckIn && !data.actualCheckOut
          ? null
          : data.actualCheckIn && !data.actualCheckOut
            ? dayjs().format("HH:mm:ss")
            : null,
    };
    dispatch(onMarkAttendanceThunk(reqData)).then((res) => {
      if (res.payload) {
        setShowConfirmModal(false);
        if (status === "PAUSED" || status === "STOPPED") {
          start();
        } else {
          pause();
        }
      }
    });
  }, [attedanceJobs, dispatch, pause, selectedAttendance, start, status]);

  const onMarkAttendance = useCallback(() => {
    if (!selectedAttendance) {
      notify("error", "Please Select Job!");
      return;
    }
    const data = attedanceJobs.find((item) => item.id === selectedAttendance);
    if (data.actualCheckIn && data.actualCheckOut) {
      notify(
        "error",
        "Looks like you've checked out once, contact the administrator for further help!"
      );
      return;
    }
    getCurrentCordinates
      .then((origin) => {
        const distance = getDistanceFromLatLonInMeter(
          origin.latitude,
          origin.longitude,
          data?.branch?.branchDetails?.latitude,
          data?.branch?.branchDetails?.longitude
        );
        // const distance = getDistanceFromLatLonInMeter(origin.latitude, origin.longitude, 31.497293, 74.327092)
        if (data?.branch?.isLocationRequired === 1 && distance > 500) {
          notify(
            "error",
            "Please ensure you’re within the vicinity of your workplace before performing this operation!"
          );
          return;
        }
        onMarkAttendanceRequest();
      },
        (error) => {
          setShowLocationErrorModal(true)
        }
      )
      .catch((err) => {
        console.log(err);
      });
  }, [attedanceJobs, onMarkAttendanceRequest, selectedAttendance]);

  useEffect(() => {
    if (!_time) return;
    setDuration(_time);
    setFlag(true);
  }, [reset, _time]);

  useEffect(() => {
    if (!flag) return;
    const data = attedanceJobs.find((item) => item.id === selectedAttendance);
    if (data?.actualCheckIn && !data?.actualCheckOut) {
      if (status === "STOPPED" || status === "PAUSED") {
        advanceTime(oldTime - duration);
        start();
      } else {
        advanceTime(oldTime - duration);
      }
    } else {
      advanceTime(oldTime - duration);
      reset();
      pause();
    }
    setOldTime(_time);
    setFlag(false);
  }, [
    attedanceJobs,
    selectedAttendance,
    start,
    pause,
    reset,
    advanceTime,
    flag,
    timer,
    _time,
    status,
    oldTime,
    duration,
  ]);

  return (
    <div style={{ width: "100%" }}>
      <ConfirmModal
        title="Confirmation"
        open={showConfirmModal}
        onConfirm={onMarkAttendance}
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        onClose={() => setShowConfirmModal(false)}
      >
        <div>
          <div>Are you sure you want to Check Out?</div>
          <div>You won't be able to Check In again, If you Check Out!</div>
        </div>
      </ConfirmModal>
      <ConfirmModal
        title="We cant seem to figure out your location 😔"
        open={showLocationErrorModal}
        confirmButtonText="Close"
        onClose={() => setShowLocationErrorModal(false)}
        onConfirm={() => setShowLocationErrorModal(false)}
      >
        <div>
          <div>
            Seems like you've turned off your location access- and we can not track your location to help enable you check in and check out accurately.
            <br />
            <br />
            You can re-enable location tracking by changing the settings in your browser-
            <br />
            <img style={{ width: '100%' }} src={locationSetting} alt="locationSetting" />
            <br />
            <br />
            After you're done, just hit the refresh ♻️ button so everything works without a hitch.
          </div>
        </div>
      </ConfirmModal>
      <div
        className="d-flex align-items-center justify-content-center p-5"
        style={{ width: "100%" }}
      >
        <div
          className="cursor-pointer d-flex align-items-center justify-content-center rounded-circle"
          style={{
            width: "248px",
            height: "248px",
            border: "10px solid #F5F5F5",
          }}
        >
          <div className="d-flex align-items-center flex-column">
            <div className="heading4">
              {moment.utc(timer * 1000).format("HH:mm:ss")}
            </div>
            <div className="t2">Remaining Time</div>
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-between align-items-end gap-3"
        style={{ flex: "1" }}
      >
        <Button
          onClick={() => {
            if (status === "RUNNING") {
              setShowConfirmModal(true);
              return;
            }
            onMarkAttendance();
          }}
          className="p-2 btn-style"
          type="primary"
          danger={status === "RUNNING" ? true : false}
          htmlType="submit"
          block
        >
          {status === "RUNNING" ? "Check Out" : "Check In"}
        </Button>
      </div>
    </div>
  );
};
