import { DatePicker, Form, Input, Select } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { editStaffProfileThunk } from "../../redux/staffs/staffsThunk";
import { notify } from "../../utils/helper";
import { AddModal } from "../UI/modals/addModal";

export const PersonalInfoForm = ({
    setEditTab
}) => {
    const [showModal, setShowModal] = useState(true)
    const [form] = Form.useForm();
    const formId = useMemo(() => "InformationId", [])
    const { staffInfo } = useSelector(state => state.staffs)
    const { staffId } = useParams()
    const dispatch = useDispatch()
    const dob = Form.useWatch("dob", form);
    const { regionBranches } = useSelector((state) => state.regions);

    useEffect(() => {
        if (!staffInfo) return
        form.setFieldsValue({
            name: staffInfo?.name,
            surname: staffInfo?.surname,
            gender: staffInfo?.gender,
            dob: staffInfo?.dob ? dayjs(staffInfo?.dob) : '',
            email: staffInfo?.email
        });

    }, [staffInfo])

    const onFinish = useCallback((values) => {
        const reqData = {
            ...values,
            dob: dayjs(values?.dob).format('YYYY-MM-DD'),
            id: staffId
        };
        delete reqData['email']
        dispatch(editStaffProfileThunk(reqData)).then(res => {
            if (res.payload) {
                setEditTab('')
                notify('success', 'Staff Informations Updated!')
                setShowModal(false)
                form.resetFields()
            }
        })
    }, [dispatch, form, setEditTab, staffId])

    return (
        <AddModal
            title={`Edit Information`}
            formId={formId}
            handleModal={showModal}
            setHandleModal={() => {
                setEditTab('')
                setShowModal(!showModal)
            }
            }
            secondaryButtonHandler={() => {
                setEditTab('')
                setShowModal(!showModal)

            }}
            primaryButtonText="Update"
            secondaryButtonText="Cancel"
        >
            <Form
                onFinish={onFinish}
                form={form}
                id={formId}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    label="First Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input your First name!' }]}
                >
                    <Input
                        onBlur={e => form.setFieldsValue({ 'name': e.target.value.trim() })}
                        disabled
                        label="First Name"
                        className='text-input-field p-2 pe-3 t1'
                    />
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name="surname"
                    rules={[{ required: true, message: 'Please input your Last name!' }]}
                >
                    <Input
                        label="Last Name"
                        disabled
                        onBlur={e => form.setFieldsValue({ 'surname': e.target.value.trim() })}
                        className='text-input-field p-2 pe-3 t1'
                    />
                </Form.Item>
                <div className='d-flex align-items-center gap-3' style={{ width: '100%' }}>
                    <Form.Item
                        label="Gender"
                        name="gender"
                        style={{ width: '100%' }}
                        rules={[{ required: true, message: 'Please select your Gender!' }]}
                    >
                        <Select
                            dropdownStyle={{ zIndex: 2000 }}
                            placeholder="Select your gender" bordered={false} className='text-input-field pe-3 t1'>
                            <Select.Option value="Male">Male</Select.Option>
                            <Select.Option value="Female">Female</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={{ width: '100%' }}
                        label="Date of Birth"
                        name="dob"
                        rules={[{ required: true, message: 'Please select your Date of Birth!' }]}
                    >
                        <DatePicker
                            inputReadOnly
                            defaultPickerValue={dob ? dayjs(dob) : dayjs().subtract(18, 'years')}
                            format="DD-MM-YYYY"
                            getPopupContainer={(trigger) => trigger.parentElement}
                            className="text-input-field p-2 pe-3 t1"
                            style={{ width: '100%' }}
                            disabledDate={(current) => {
                                return dayjs().subtract(18, 'years') <= current
                            }}
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    label="Email"
                    name="email"
                >
                    <Input
                        disabled
                        label="Email"
                        className='text-input-field p-2 pe-3 t1'
                    />
                </Form.Item>

                <Form.Item
                    label="Replace Branch"
                    name="branchId"
                >
                    <Select
                        dropdownStyle={{ zIndex: 2000 }}
                        placeholder="Branch" bordered={false} className='text-input-field p-1 t1'>
                        {
                            regionBranches.map((data, index) => (
                                <Select.Option key={index} value={data.id}>{data.title}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Form>
        </AddModal >
    );
};
