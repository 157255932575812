/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import exportCSV from "../../assets/img/export.png";
import blankPharmacy from "../../assets/img/blankPharmacy.png";
import blankJobApproval from "../../assets/img/blankJobApproval.png";
import blankExpense from "../../assets/img/blankExpense.png";
import blankStaff from "../../assets/img/blankStaff.png";
import { PieChart } from "react-minimal-pie-chart";
import { TableComponet } from "../UI/table";
import "./style.scss";
import moment from "moment";
import { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { EmptyData } from "../comon/EmptyData";

export default function DashboardTable({
  isExportBtn,
  isBarChart,
  isPieChart,
  label,
  count,
  tableCols,
  tableData,
  onDetailHnadler,
  permanentStaff,
  locums,
  barChartData,
  isDougnutChart,
  dougnutData,
  exportCSVHandler
}) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (!barChartData) return;
    const result = barChartData?.map((day) => {
      return {
        name: moment(day?.startDate).format("ddd"),
        pv: day?.locum_expenses * 1,
      };
    });
    setChartData(result);
  }, [barChartData]);

  const segmentStyle = {
    width: "30px",
    cursor: "pointer",
  };

  const data = useMemo(() => {
    if (chartData.length) {
      return chartData
        .map((item) => item.pv)
        .reduce((partialSum, a) => partialSum + a, 0);
    }
    return dougnutData?.pharmacy_expenses
      ?.map((item) => item.total)
      .reduce((partialSum, a) => partialSum + a, 0);
  }, [dougnutData, chartData]);

  const CustomTooltip = ({ active, payload }) => {
    return (
      <div
        style={{
          height: "41px",
          width: "60px",
          background: "#51C2BC",
          borderRadius: "5px",
          border: "none",
        }}
      >
        <div className="d-flex flex-column align-items-center justify-content-center">
          <span
            style={{
              color: " #FFFFFF",
              fontWeight: "400px",
              fontSize: "8px",
              lineHeight: "12px",
              marginTop: "6px",
            }}
          >
            {payload[0]?.payload?.name}
          </span>
          <span className="t1" style={{ color: "#ffffff" }}>
            £ {payload[0]?.payload?.pv}
          </span>
        </div>
        <div className="tooltipArrow"></div>
      </div>
    );
  };
  Chart.register(ArcElement);
  var Colors = [
    "#51C2BC",
    "#63B4F6",
    "#81C784",
    "#84D4D0",
    "#8992B1",
    "#8992B1",
    "#8992B1",
    "#8992B1",
    "#8992B1",
    "#8992B1",
    "#8992B1",
    "#8992B1",
    "#8992B1",
    "#8992B1",
    "#8992B1",
    "#8992B1",
    "#8992B1",
    "#8992B1",
    "#8992B1",
    "#8992B1",
    "#8992B1",
  ];
  const semiDougnutData = {
    datasets: [
      {
        data: dougnutData?.pharmacy_expenses?.map((i) => i?.total),
        backgroundColor: Colors,
        display: true,
        border: "10px solid white ",
        borderRadius: "5",
        borderAlign: "inner",
        borderWidth: "3",
        hoverBorderColor: "white",
        outerWidth: "30px",
        innerWidth: "70",
      },
    ],
  };

  return (
    <div className="d-flex" style={{ gap: "15px" }}>
      <div className="table-graph-container" style={{ width: "65%", minHeight: "373.39px" }}>
        <div
          className="d-flex justify-content-between"
          style={{ width: "100%", padding: "24px" }}
        >
          <div className="d-flex table-container flex-column">
            <span className=" heading4 fw-bold">{label}</span>
            <span className=" heading1 fw-bold" style={{ fontSize: "38px" }}>
              {count ? count : " "}
            </span>
          </div>
          <div className="d-flex" style={{ height: "20px", gap: "15px" }}>
            {isExportBtn && (
              <span
                className="d-flex align-items-center "
                style={{ gap: "5px" }}
              >
                <img src={exportCSV} alt="" style={{ cursor: "pointer" }} />
                <span className="dashboard-table-links" onClick={exportCSVHandler}>
                  EXPORT CSV
                </span>
              </span>
            )}
            {/* <a href="#" onClick={onDetailHnadler} className="dashboard-table-links">
              DETAILS
            </a> */}
          </div>
        </div>
        <div style={{ height: "100%" }}>
          {tableData?.length > 0 ? (
            <TableComponet
              perPageCount={tableData.length}
              // rowCursor="pointer"
              columns={tableCols}
              data={tableData || []}
              height="350px"
            />
          ) : (
            <EmptyData img={label === "Pharmacy Gaps" ? blankPharmacy : blankJobApproval} description={label === "Pharmacy Gaps" ? "No Pharmacist Gaps" : "No Jobs In Pending Status"} />
          )}
        </div>
      </div>
      {isPieChart && (
        <div
          className="table-graph-container"
          style={{ width: "35%", padding: "24px" }}
        >
          <div
            className="d-flex justify-content-between"
            style={{ flexDirection: "column", gap: "10px", height: "100%" }}
          >
            <div
              className="d-flex table-container"
              style={{ flexDirection: "column" }}
            >
              <span className="heading4 fw-bold b3">Employees</span>
              {permanentStaff + locums > 0 && (
                <span className="heading1 fw-bold">
                  {permanentStaff + locums ?? 0}
                </span>
              )}
            </div>
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ width: "100%", height: "100%" }}
            >
              {permanentStaff + locums > 0 ? (
                <>
                  {" "}
                  <div style={{ height: "222px", marginTop: "20px" }}>
                    <PieChart
                      data={[
                        {
                          title: "Locums",
                          value: (locums / (permanentStaff + locums)) * 100,
                          color: "#B7CD42",
                          segmentsTabIndex: "1",
                          label: "Locums",
                        },
                        {
                          title: "Staffs",
                          value:
                            (permanentStaff / (permanentStaff + locums)) * 100,
                          color: "#51C2BC",
                          segmentsTabIndex: "2",
                        },
                      ]}
                      paddingAngle={0}
                      labelStyle={{
                        fontWeight: 700,
                        fontSize: "0.85rem",
                        fill: "var(--Dark-01)",
                      }}
                      labelPosition={0}
                      label={() => permanentStaff + locums}
                      totalValue={100}
                      lineWidth={50}
                      startAngle={180}
                      animate
                      segmentsStyle={{ segmentStyle }}
                    />
                  </div>
                  <div
                    className="d-flex flex-column "
                    style={{ marginTop: "-50px", marginRight: "32px" }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ marginBottom: "18px" }}
                    >
                      <div
                        className="round-icon"
                        style={{ backgroundColor: "#51C2BC" }}
                      ></div>
                      <span className="s2">
                        Permanent Staff ({permanentStaff})
                      </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <div
                        className="round-icon"
                        style={{ backgroundColor: "#B7CD42" }}
                      ></div>
                      <span className="s2">Locums ({locums})</span>
                    </div>
                  </div>
                </>
              ) : (
                <EmptyData img={blankStaff} description="No Employees" />
              )}
            </div>
          </div>
        </div>
      )}
      {(isBarChart || isDougnutChart) && (
        <div
          className="table-graph-container"
          style={{ width: "35%", padding: "24px" }}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              flexDirection: "column",
              gap: "10px",
              height: "100%",
              position: "relative",
              width: "100%",
            }}
          >
            <div
              className="d-flex table-container"
              style={{ flexDirection: "column" }}
            >
              <span className="heading4 fw-bold b3">Expense Overview</span>
              <div
                className="d-flex align-items-center"
                style={{ gap: "15px" }}
              >
                {data ? (
                  <span
                    className="heading1"
                    style={{ fontWeight: 700, fontSize: "38px" }}
                  >
                    £ {data?.toFixed(2) || ""}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            {chartData?.length || data ? (
              <>
                {isDougnutChart && (
                  <>
                    <Doughnut
                      style={{ maxHeight: "300px" }}
                      data={semiDougnutData}
                      options={{
                        plugins: {
                          legend: {
                            display: false,
                          },
                          tooltip: {
                            enabled: true,
                          },
                        },
                        rotation: -90,
                        circumference: 180,
                        cutout: "60%",
                        maintainAspectRatio: true,
                        responsive: true,
                      }}
                    />
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          position: "absolute",
                          top: "65%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          textAlign: "center",
                        }}
                      >
                        <div className="d-flex flex-column">
                          <div className="h3">
                            {
                              dougnutData?.pharmacy_expenses?.filter(
                                (i) => i.total > 0
                              ).length
                            }
                          </div>
                          <span className="s1" style={{ color: "#7B7B7B" }}>
                            Branches
                          </span>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-start justify-content-center flex-wrap gap-3"
                        style={{ width: "100%", marginBottom: "14px" }}
                      >
                        {dougnutData?.pharmacy_expenses?.map((i, idx) => {
                          if (i.total > 0)
                            return (
                              <div
                                key={idx}
                                className="d-flex align-items-center"
                              >
                                <div
                                  className="ellipse-flag"
                                  style={{ background: Colors[idx] }}
                                ></div>
                                <span
                                  className="t2"
                                  style={{ color: "#7B7B7B" }}
                                >
                                  {i.branchTitle}
                                </span>
                              </div>
                            );
                        })}
                      </div>
                    </div>
                  </>
                )}
                {isBarChart && chartData?.length && (
                  <div style={{ width: "100%" }}>
                    <BarChart
                      width={500}
                      height={350}
                      data={chartData}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 10,
                        bottom: 30,
                      }}
                      barSize={16}
                    >
                      <XAxis
                        dataKey="name"
                        scale="point"
                        padding={{ left: 10, right: 10 }}
                        border={{ left: "none", right: "none" }}
                        axisLine={false}
                        dy={20}
                        tick={{
                          fontSize: 14,
                          fontWeight: 400,
                          fill: "#888888",
                          lineHeight: "20",
                        }}
                      />
                      <YAxis
                        axisLine={false}
                        type="number"
                        // domain={[0, 5000]}
                        tickCount={11}
                        tick={{
                          fontSize: 14,
                          fontWeight: 400,
                          fill: "#888888",
                          lineHeight: "18",
                        }}
                        interval={0}
                        // tickFormatter={DataFormater}
                        dx={-20}
                      />

                      <Tooltip
                        content={<CustomTooltip />}
                        wrapperStyle={{ outline: "none" }}
                      />
                      <CartesianGrid
                        horizontal={true}
                        vertical={false}
                        strokeDasharray="3 3"
                        stroke="#E4E9F9"
                        background={{ fill: "#E4E9F9" }}
                      />

                      <Bar
                        dataKey="pv"
                        fill="#E1F4F4"
                        background={{ fill: "transparent" }}
                        radius={[25, 25, 25, 25]}
                      />
                    </BarChart>
                  </div>
                )}{" "}
              </>
            ) : (
              <EmptyData img={blankExpense} description="No Expenses " />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
