import { AddModal } from "../../UI/modals/addModal";
import { DatePicker, Form, Input } from "antd";
import { useMemo } from "react";
import { useDispatch, } from "react-redux";
import { duplicateContractThunk } from "../../../redux/staffs/staffsThunk";
import { useParams } from "react-router-dom";
import moment from "moment";



export const DuplicateContractForm = ({
    selectedId,
    showContractModal,
    setShowContractModal
}) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const { staffId } = useParams()
    const formId = useMemo(() => 'contractForm', [])

    const onFinish = (values) => {
        const reqBody = {
            title: values?.title,
            startDate: moment(values?.startDate).format('YYYY-MM-DD'),
            endDate: moment(values?.endDate).format('YYYY-MM-DD'),
        }
        dispatch(
            duplicateContractThunk({
                staffId,
                contractId: selectedId,
                payload: reqBody
            })
        ).then(res => {
            // setShowContractModal()
            // form.resetFields()
        })

    }

    return (
        <AddModal
            formId={formId}
            title={`Add Contract`}
            handleModal={showContractModal}
            setHandleModal={setShowContractModal}
            primaryButtonText="Create"
            secondaryButtonText="Cancel"
            secondaryButtonHandler={setShowContractModal}
        >
            <Form
                id={formId}
                onFinish={onFinish}
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'Please input your Title!' }]}
                >
                    <Input
                        onBlur={e => form.setFieldsValue({ 'title': e.target.value.trim() })}
                        placeholder="Title"
                        className='text-input-field p-2 pe-3 t1'
                    />
                </Form.Item>
                <div className="d-flex align-items-center" style={{ width: '100%', gap: 15 }}>
                    <Form.Item
                        label="Start Date"
                        name="startDate"
                        rules={[{ required: true, message: 'Please Enter Start Date!' }]}
                    >
                        <DatePicker
                            inputReadOnly
                            format="DD-MM-YYYY"
                            getPopupContainer={(trigger) => trigger.parentElement}
                            className="text-input-field p-2 pe-3 t1"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="End Date"
                        name="endDate"
                        rules={[{ required: true, message: 'Please Enter End Date!' }]}
                    >
                        <DatePicker
                            inputReadOnly
                            format="DD-MM-YYYY"
                            getPopupContainer={(trigger) => trigger.parentElement}
                            className="text-input-field p-2 pe-3 t1"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </div>
            </Form>
        </AddModal>
    )
}