import React, { useMemo, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./styles.scss";
import logout from "../../../assets/icons/logout.png";
import _time from "../../../assets/icons/time.png";
import { useDispatch, useSelector } from "react-redux";
import { logOutUserThunk } from "../../../redux/auth/authThunk";
import { RouterConfig } from "../../../routerConfig";
import { AttendanceModal } from "../../attendanceModal";
import { NotificationContent } from "../../notifications";
import { Popover } from "antd";
import bell from "../../../assets/icons/bell.png";
import { WarningBar } from "./warningBar";

const logo = "https://locum-app-files.s3.amazonaws.com/static/logo.png";

export const Header = ({
  tabs = [],
  showProfile,
  showNotification,
  showAttendance,
  superAdmin,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [showAttendanceModal, setShowAttendanceModal] = useState(false);
  const [isNotification, setNotification] = useState(false);

  const onLogout = (e) => {
    e.stopPropagation();
    dispatch(logOutUserThunk());
  };

  const altText = useMemo(() => {
    return `${user?.name} ${user?.surname}`
      ?.split(" ")
      ?.map((d, idx) => (idx < 2 ? d?.charAt(0).toUpperCase() : null))
      .join("");
  }, [user]);

  return (
    <div className="position-relative">
      <div
        className="d-flex align-items-center  border-bottom sticky-header header-component-container"
        style={{ height: "60px", padding: " 0px 24px" }}
      >
        <div style={{ width: "15%" }}>
          <Link to="/">
            <img className="logo" src={logo} alt="logo" />
          </Link>
        </div>
        <div className="d-flex align-items-center " style={{ width: "85%" }}>
          {tabs?.map((item) => (
            <div key={item.id} className="navbar-lender">
              <NavLink
                style={{ whiteSpace: "nowrap" }}
                key={item.id}
                className="t1 navbar-link text-decoration-none"
                to={item.to}
              >
                {item.title}
              </NavLink>
            </div>
          ))}
        </div>
        <div className="d-flex align-items-center justify-content-end header-component-container">
          {showAttendance && (
            <Popover
              className="header-component-container"
              placement="bottomRight"
              content={
                <AttendanceModal
                  showAttendanceModal={showAttendanceModal}
                  setShowAttendanceModal={setShowAttendanceModal}
                />
              }
              trigger="click"
            >
              <img
                src={_time}
                onClick={() => setNotification(false)}
                alt=" "
                style={{ paddingRight: "10px", cursor: "pointer" }}
              />
            </Popover>
          )}
          {showNotification && (
            <Popover
              open={isNotification}
              className="header-component-container"
              placement="bottomRight"
              content={<NotificationContent setNotification={setNotification} isNotification={isNotification} />}
              trigger="click"
            >
              <img
                src={bell}
                alt=" "
                style={{ cursor: "pointer" }}
                onClick={() => setNotification(!isNotification)}
              />
            </Popover>
          )}
          <div className="profile-box-border"> </div>
          {showProfile && (
            <div
              className="d-flex align-items-center"
              title={user?.name + " " + (user?.surname ?? "")}
              style={{ gap: "15px", maxWidth: 300 }}
              onClick={() => {
                navigate(
                  superAdmin
                    ? `${RouterConfig.superAdminInfo}/information`
                    : RouterConfig.branchAdminProfile
                );
              }}
            >
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "50%",
                  position: "relative",
                }}
              >
                {
                  user.image ?
                    <img
                      src={user.image}
                      alt={altText}
                      style={{
                        cursor: "pointer",
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                        border: "1px solid #F6F2F",
                        position: "relative",
                      }}
                    />
                    :
                    <div
                      style={{
                        width: 36,
                        height: 36,
                        borderRadius: '50%',
                        background: '#E1F4F4',
                        // fontSize: 18,
                        color: ' #065A4F',
                        textAlign: 'center',
                        lineHeight: '32px',
                        padding: "1px"
                      }}
                      text={altText}
                    >
                      {altText}
                    </div>
                }
              </div>
              <div
                className="p3 user-name text-nowrap"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {(user?.name ?? "") + " " + (user?.surname ?? "")}
              </div>
            </div>
          )}
          <img
            className="cursor-pointer"
            onClick={onLogout}
            src={logout}
            width={40}
            alt=" "
          />
        </div>
      </div>
      {
        user && user.group === 'pharmacy' && !user.hasBranches &&
        <WarningBar>
          To Reap The Full Benefits Of Lopic & Enjoy Un-Interrupted Access, Please Proceed By Creating Your First
          <u className="cursor-pointer" onClick={() => navigate('/branches')} >Branch</u>
          And
          <u className="cursor-pointer" onClick={() => navigate('/profile/subscription')} >Activating Your Subscription!</u>
        </WarningBar>
      }
    </div>
  );
};
