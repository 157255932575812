
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AddModal } from "../../../components/UI/modals/addModal";
import { Verification } from "../../../components/verifications";
import { VerificationForm } from "../../../components/verifications/verificationForm";
import { authUserRequestThunk } from "../../../redux/auth/authThunk";
import { editVerificationThunk, getStaffVerificationThunk, yotiVerificationStartThunk } from "../../../redux/staffs/staffsThunk";
import { notify } from "../../../utils/helper";
import verificationIcon from '../../../assets/icons/verificationIcon.png'

export const LocumVerification = () => {
    const formId = useMemo(() => "form", [])
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { staffVerification } = useSelector(state => state.staffs)
    const [showEditInfoModal, setShowEditInfoModal] = useState(false);
    const [actionType, setActionType] = useState('basic')
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()

    useEffect(() => {
        if (!searchParams.get('verify')) return
        if (searchParams.get('verify') === 'success') {
            notify('info', 'Our security checks might take a while. We\'d hate to keep you waiting, you can check back later!')
            // dispatch(
            //     yotiVerificationEndThunk()
            // ).then(res => {
            //     if (res.payload)
            // })
            navigate('/verifications')
            return
        }
        notify('error', 'Seems like there was an issue with you verification - please contact the Lopic administrator!')
        navigate('/verifications')
    }, [dispatch, navigate, searchParams])


    useEffect(() => {
        dispatch(getStaffVerificationThunk({ staffId: user.id }));
    }, [dispatch, user]);


    const onSubmit = (formData) => {
        dispatch(
            editVerificationThunk({
                staffId: user.id,
                payload: formData
            })
        ).then(res => {
            if (res.payload) {
                window.analytics.track('Verification Uploaded', {
                    profilePicture: res.payload.image ? true : false,
                    proofOfID: res.payload.proofOfId && res.payload.proofOfIdExp ? true : false,
                    indemnity:
                        res.payload.professionalInsurance && res.payload.professionalInsuranceExpDate ? true : false,
                    enhancedDBS: res.payload.DBSCertificate ? true : false,
                    workVise: res.payload.visa && res.payload.visaExpDate ? true : false
                });
                if (!user.isVerified) {
                    setActionType('yoti')
                    return
                }
                setShowEditInfoModal(false)
                setActionType('basic')
                dispatch(authUserRequestThunk())
            }
        })
    }

    const onVerifyIdentity = () => {
        dispatch(
            yotiVerificationStartThunk()
        ).then(res => {
            if (res.payload) {
                window.open(res.payload, '_self')
            }
        })
    }


    return (
        <>
            <Verification
                showEdit
                staffInfo={user}
                setShowEditInfoModal={setShowEditInfoModal}
                setActionType={setActionType}
                staffVerification={staffVerification}
            />
            <AddModal
                title="Edit Verifications"
                handleModal={showEditInfoModal}
                setHandleModal={setShowEditInfoModal}
                formId={actionType !== 'yoti' && formId}
                primaryButtonText={actionType !== 'yoti' && !user.isVerified ? "Next" : actionType === 'yoti' ? 'Verify User' : "Update"}
                secondaryButtonText={actionType !== 'yoti' ? "Cancel" : 'Back'}
                secondaryButtonHandler={() => {
                    if (actionType === 'yoti') {
                        setActionType('basic')
                        return
                    }
                    setShowEditInfoModal(false)
                }}
                primaryButtonHandler={() => {
                    if (actionType === 'yoti') {
                        onVerifyIdentity()
                    }
                }}
            >
                {
                    actionType !== 'yoti' ?
                        <VerificationForm
                            isGrouped
                            staffInfo={user}
                            onSubmit={onSubmit}
                            formId={formId}
                            setShowEditInfoModal={setShowEditInfoModal}
                            setActionType={setActionType}
                            actionType={actionType}
                        />
                        :
                        <div>
                            <div className="d-flex flex-column gap-1">
                                <ul>
                                    <li>
                                        Before you start, ensure that you have your identification document with you- you'll need to scan it during this process.
                                    </li>
                                    <li>
                                        You should be in a well lit background so you can take a great selfie that we'll use to verify your identity.
                                    </li>
                                </ul>
                                Let's get you verified and contribute towards making a safer and greater Locum community!
                            </div>
                            <div className="d-flex flex-column align-items-center gap-3 m-auto my-4" style={{ width: '70%' }}>
                                <img src={verificationIcon} alt="verificationIcon" />
                            </div>
                        </div>
                }
            </AddModal>
        </>
    );
};
