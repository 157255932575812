import { Form, Input } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddLocumBankThunk,
  getLocumByGphCThunk,
} from "../../redux/locum-bank/locumBankThunk";
import { locumBankApiKeys } from "../../utils/apiKeyConstants";
import { hasAccess } from "../../utils/helper";
import { AddModal } from "../UI/modals/addModal";

export const AddLocumForm = ({
  isOpen,
  handleModalToggle,
  onAddLocumSuccess,
}) => {
  const [form] = Form.useForm();
  const formId = useMemo(() => "addLocumBankForm", []);
  const dispatch = useDispatch();
  const gphcNumber = Form.useWatch("gphcNumber", form);
  const { locumData } = useSelector((state) => state.locumBank);

  useEffect(() => {
    if (Number(gphcNumber?.length) !== 7) {
      form.setFieldsValue({
        email: "",
        phone: "",
        locum: '',
      });
      return;
    }

    if (hasAccess(locumBankApiKeys.GET_GPHC_NUMBER)) {
      dispatch(getLocumByGphCThunk({ gphcNumber })).then((res) => {
        if (!res.payload) {
          form.setFieldsValue({
            email: "",
            phone: "",
            locum: '',
            gphcNumber: ''
          });
        }

      });
    }
  }, [gphcNumber]);

  useEffect(() => {
    if (_.isEmpty(locumData)) return;

    form.setFieldsValue({
      locum: `${locumData.forenames
        ? locumData.forenames
        : locumData.name
          ? locumData.name
          : ""
        } ${locumData?.surname ?? ""}`,
      email: locumData?.email,
      phone: locumData?.phone,
    });
  }, [locumData]);

  const onSubmit = useCallback(
    (values) => {
      const reqData = {
        gphcNumber: values.gphcNumber,
        email: values.email,
        phone: values.phone,
        userId: locumData && locumData.userId,
        gphcId: locumData && locumData.gphcId,
      };
      dispatch(AddLocumBankThunk(reqData)).then((res) => {
        if (res.payload) {
          window.analytics.track('Locum Bank Added', {
            gphcNumber: values.gphcNumber,
            email: values.email,
            phone: values.phone,
          });
          form.resetFields();
          handleModalToggle();
          onAddLocumSuccess();
        }
      });
    },
    [form, locumData]
  );

  return (
    <AddModal
      title="Add Locum"
      handleModal={isOpen}
      setHandleModal={handleModalToggle}
      primaryButtonText={
        hasAccess(locumBankApiKeys.ADD_LOCUM_BANK) ? "Add Locum" : undefined
      }
      secondaryButtonText="Cancel"
      secondaryButtonHandler={handleModalToggle}
      formId={formId}
    >
      <Form
        id={formId}
        onFinish={
          hasAccess(locumBankApiKeys.ADD_LOCUM_BANK) ? onSubmit : undefined
        }
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          label="GPhC Number"
          name="gphcNumber"
          normalize={(value) => {
            if (value.length > 7) return value.slice(0, 7);
            return value;
          }}
          rules={[
            {
              required: true,
              max: 7,
              message: "Please Enter Gphc No.!",
            },
          ]}
        >
          <Input
            maxLength={7}
            onBlur={(e) =>
              form.setFieldsValue({ gphcNumber: e.target.value.trim() })
            }
            type="number"
            placeholder="GPhC Number"
            className="text-input-field p-2 pe-3 t1"
          />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { type: "email", required: true, message: "Please Enter Email!" },
          ]}
        >
          <Input
            onBlur={(e) =>
              form.setFieldsValue({ email: e.target.value.trim() })
            }
            placeholder="Email"
            className="text-input-field p-2 pe-3 t1"
          />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[{ required: true, message: "Please Enter Phone No.!" }]}
        >
          <Input
            type="number"
            onBlur={(e) =>
              form.setFieldsValue({ phone: e.target.value.trim() })
            }
            placeholder="Phone"
            className="text-input-field p-2 pe-3 t1"
          />
        </Form.Item>
        <Form.Item label="Locum" name="locum">
          <Input
            disabled
            placeholder="Locum"
            className="text-input-field p-2 pe-3 t1"
          />
        </Form.Item>
      </Form>
    </AddModal>
  );
};
