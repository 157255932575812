import moment from "moment/moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EmptyData } from "../../../../../components/comon/EmptyData";
import { ConfirmModal } from "../../../../../components/UI/modals/confirmModal";
import { TableComponet } from "../../../../../components/UI/table";
import { TableToolbar } from "../../../../../components/UI/table/tableToolBar";
import { deleteStaffAbsencesThunk, getStaffAbsencesThunk } from "../../../../../redux/staffs/staffsThunk";
import { staffApiKeys } from "../../../../../utils/apiKeyConstants";
import { staffAbsencesType } from "../../../../../utils/constant";
import { hasAccess } from "../../../../../utils/helper";
import { AddAbsenceForm } from "../../../branches/details/branch-details-updated/schedule/components";

export const StaffAbsences = () => {
    const { staffId } = useParams()
    const dispatch = useDispatch();
    const { staffAbsences } = useSelector((state) => state.staffs);
    const [addAbsenceModal, setAddAbsenceModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [flag, setFlag] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showMultiSelectBtn, setShowMultiSelectBtn] = useState(false);

    const [currentPageCount, setCurrentPageCount] = useState(1);
    const [perPageCount, setPerPageCount] = useState(10);

    const columns = React.useMemo(
        () => [
            {
                Header: "Date",
                accessor: "from",
                Cell: ({ row: { original } }) => {
                    return original.from ? moment(original.from).format('DD-MM-YYYY') : '-'
                }
            },

            // {
            //     Header: "To",
            //     accessor: "to",
            //     Cell: ({ row: { original } }) => {
            //         return original.to ? moment(original.to).format('DD-MM-YYYY') : '-'
            //     }
            // },
            // {
            //     Header: "Total Hours",
            //     accessor: "calculatedTimeMS",
            // },
            {
                Header: "Absence Type",
                accessor: "type",
                Cell: ({ row: { original } }) => {
                    return staffAbsencesType[original.type]
                }
            },
            {
                Header: "Notes",
                accessor: "notes",
            },
        ],
        []
    );

    const {
        count: totalDataCount,
        page,
        pages: totalPagesCount,
        perPage,
        rows: staffAbsencesList,
    } = useMemo(() => staffAbsences, [staffAbsences]);

    useEffect(() => {
        if (!flag) return
        if (hasAccess(staffApiKeys.GET_STAFF_ABSENCE)) {
            dispatch(
                getStaffAbsencesThunk({
                    staffId,
                    filters: {},
                    pagination: { page: currentPageCount, perPage: perPageCount },
                })
            ).then(res => {
                if (res.payload) {
                    setFlag(false)
                }
            })
        }
    }, [dispatch, staffId, currentPageCount, perPageCount, flag]);


    useEffect(() => {
        setFlag(true)
    }, [currentPageCount, perPageCount])

    useEffect(() => {
        if (!page && !perPage) return;
        setPerPageCount(perPage);
        setCurrentPageCount(page);
    }, [page, perPage]);


    const onDeleteAbsence = () => {
        const data = staffAbsencesList.filter((elem) => {
            return selectedRows?.some((ele) => {
                return ele === elem.id
            });
        }).map(d => ({
            id: d.id,
            date: moment(d.from).format('YYYY-MM-DD')
        }))

        dispatch(
            deleteStaffAbsencesThunk({
                staffId,
                data
            })
        ).then(res => {
            setShowConfirmModal(false)
            setShowMultiSelectBtn(false)
            setSelectedRows([])
            setFlag(true)
        })
    }

    return (
        <div>
            <ConfirmModal
                title={`Do you really want to Delete Absences?`}
                description={`You are about to Delete the Absence. This will Delete your Selected Absences and other information permanently.`}
                open={showConfirmModal}
                confirmButtonText="Delete"
                cancelButtonText="Cancel"
                onClose={() => setShowConfirmModal(!showConfirmModal)}
                onConfirm={onDeleteAbsence}
            />
            {addAbsenceModal && (
                <AddAbsenceForm
                    hideEmployee
                    employeeId={staffId}
                    setFlag={setFlag}
                    setAddAbsenceModal={setAddAbsenceModal}
                    addAbsenceModal={addAbsenceModal}
                />
            )}
            <div className="">
                <TableToolbar
                    deleteButton={
                        showMultiSelectBtn
                    }
                    headingText="Leave"
                    primaryBtnText="Add Leaves"
                    handleConfirmModal={(val) => {
                        setShowConfirmModal(true)
                    }}
                    primaryBtnHandler={() => setAddAbsenceModal(true)}
                />
                <div style={{ padding: "5px 24px" }}>
                    {staffAbsences?.rows?.length <= 0 ? <EmptyData title="Leaves" /> : (
                        <TableComponet
                            showRowSelection
                            setSelectedRows={setSelectedRows}
                            setShowMultiSelectBtn={setShowMultiSelectBtn}
                            currentPageCount={currentPageCount}
                            rowCursor="pointer"
                            perPageCount={perPageCount}
                            totalDataCount={totalDataCount}
                            setCurrentPage={setCurrentPageCount}
                            setPerPageCount={setPerPageCount}
                            showPagination
                            maxPageCount={totalPagesCount}
                            isBorderShown
                            columns={columns}
                            data={staffAbsencesList || []}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
