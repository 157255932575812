import React from "react";
import "./style.scss";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { ProfileInfo } from "./ProfileInfo";
import editIcon from '../../../../assets/icons/editIcon.png';
import cross from '../../../../assets/icons/cross.png';
import arrow from '../../../../assets/icons/arrow-up.png';
import timeIcon from '../../../../assets/icons/time.png';
import priceIcon from '../../../../assets/icons/price.png';
import scheduleIcon from '../../../../assets/icons/schedule.png';
import { hasAccess, notify } from "../../../../utils/helper";
import { branchApiKeys, locumShiftApiKeys } from "../../../../utils/apiKeyConstants";
import moment from "moment";
import { rolesColor } from "../../SchedularToolbar";
import { useNavigate } from "react-router-dom";

export const SlotDetailModal = ({ show, isPreview, target, reference, data, setSlotDetailModal, onDelete, operatingTime, handleAddShift, setShowUserProfileModal, notEditable }) => {
  const navigate = useNavigate()
  // const [items, setItems] = useState([]);

  // useEffect(() => {

  //   let newItemsJobs = [];
  //   let newItemsShift = []

  //   if (data.isJob && hasAccess(locumShiftApiKeys.UPDATE_JOB)) {
  //     newItemsJobs.push({
  //       label:
  //         <div
  //           className="p3"
  //           onClick={() => {
  //             setSlotDetailModal(!show)
  //             handleAddShift(operatingTime, data?.startDate, data.branchId, data)
  //           }}
  //         >
  //           Edit
  //         </div>,
  //       key: 'edit',
  //     })
  //   }
  //   if (data.isJob && hasAccess(locumShiftApiKeys.DELETE_JOB)) {
  //     newItemsJobs.push({
  //       key: 'delete',
  //       label:
  //         <div
  //           className="p3"
  //           onClick={() => {
  //             setSlotDetailModal(!show)
  //             onDelete(data)
  //           }}
  //         >
  //           Delete
  //         </div>,
  //     },)
  //   }

  //   if (!data.isJob && hasAccess(branchApiKeys.UPDATE_SHIFT)) {
  //     newItemsShift.push({
  //       label:
  //         <div
  //           className="p3"
  //           onClick={() => {
  //             setSlotDetailModal(!show)
  //             handleAddShift(operatingTime, data?.startDate, data.branchId, data)
  //           }}
  //         >
  //           Edit
  //         </div>,
  //       key: 'edit',
  //     },)
  //   }
  //   if (!data.isJob && hasAccess(branchApiKeys.DELETE_SHIFT)) {
  //     newItemsShift.push({
  //       key: 'delete',
  //       label:
  //         <div
  //           className="p3"
  //           onClick={() => {
  //             setSlotDetailModal(!show)
  //             onDelete(data)
  //           }}
  //         >
  //           Delete
  //         </div>,
  //     },)
  //   }

  //   if (data.isJob)
  //     setItems(newItemsJobs)
  //   else
  //     setItems(newItemsShift)
  // }, [data])


  const onEditHandler = () => {
    if ((!data?.staff && data?.staff?.length && data.staff[0]?.id === 100) || data?.staff?.id === 101) {
      return
    }
    if (data.status === 'unfilled' || data.status === 'pending' || data.status === 'active') {
      setSlotDetailModal(!show)
      handleAddShift(operatingTime, data?.startDate, data.branchId, data)
      return
    }
    if (data.isJob) {
      if (data.status === 'completed') {
        notify('info', 'Hold Up, Jobs with \'Complete\' Status Can Not Be Edited Or Deleted.')
      }
      if (data.status === 'payment_approved') {
        notify('info', 'Hold Up, Jobs with \'Payment Approved\' Status Can Not Be Edited Or Deleted.')
      }
      if (data.status === 'paid') {
        notify('info', 'Hold Up, Jobs with \'Paid\' Status Can Not Be Edited Or Deleted.')
      }
    }
  }

  return (
    <Overlay
      className="slot-detail-modal-layout"
      show={show}
      target={target}
      placement="auto"
      container={reference}
      containerPadding={20}
      flip={true}
    >
      {
        data.status === 'unfilled' || data.status === 'pending' ?
          <Popover id="popover-contained">
            <div className="d-flex align-items-center justify-content-between poppover-container-header gap-3">
              <div
                onClick={() => {
                  if (data?.isJob) {
                    navigate(`/locum-jobs/${data?.id}/details`)
                  }
                }}
                className="d-flex align-items-center gap-3">
                <div className="d-flex flex-column  profile-info">
                  <div className="d-flex align-items-center">
                    <span className="s1">{data.branchTitle}</span>
                    <img
                      src={arrow}
                      alt=""
                      style={{ marginLeft: "8px", cursor: "pointer" }}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="t2" style={{ color: '#7B7B7B' }}>{data?.isJob ? 'Job' : 'Shift'} - {data?.roleType?.label}</span>
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-center gap-2"
              >
                {
                  !isPreview && (hasAccess(branchApiKeys.UPDATE_SHIFT) || hasAccess(locumShiftApiKeys.UPDATE_JOB) || hasAccess(locumShiftApiKeys.DELETE_JOB) || hasAccess(branchApiKeys.DELETE_SHIFT)) ?
                    <img
                      src={editIcon}
                      width={25}
                      alt=""
                      className="poniter"
                      onClick={onEditHandler}
                    />
                    : null

                }
                {/* <Dropdown
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placement="bottomRight" overlayStyle={{ zIndex: 20000 }} menu={{ items }} trigger={['click']}>
                  <img
                    src={colons}
                    alt=""
                    style={{ marginRight: "8px", cursor: "pointer" }}
                  />
                </Dropdown> */}
                <img
                  src={cross}
                  alt=""
                  className="poniter"
                  onClick={(e) => setSlotDetailModal(!show)}
                />
              </div>
            </div >
            <div className="d-flex flex-column poppover-content">
              {
                data?.isJob &&
                <div className="p3 m-2">
                  {data?.description}
                </div>
              }
              <ProfileInfo
                label={<img width={24} height={24} src={scheduleIcon} alt="date" />}
                value={moment(data?.startDate).format('DD-MM-YYYY')}
              />
              <ProfileInfo
                label={<img width={24} height={24} src={timeIcon} alt="date" />}
                value={`${data?.startTime} - ${data?.endTime}`}
              />
              {
                data?.isJob &&
                <ProfileInfo
                  label={<img width={24} height={24} src={priceIcon} alt="date" />}
                  value={`£${data?.hourlyRate}/h`}
                />
              }
            </div>
          </Popover >
          :
          (
            <Popover id="popover-contained">
              <div className="d-flex align-items-start justify-content-between poppover-container-header gap-3">
                <div onClick={() => {
                  if ((!data.staff && data.staff?.length && data.staff[0]?.id === 100) || data?.staff?.id === 101) {
                    return
                  }
                  setSlotDetailModal(false)
                  setShowUserProfileModal(true)
                }} className="d-flex align-items-center cursor-pointer">
                  {
                    data?.isJob ?
                      (
                        data?.staff?.length && data?.staff[0]?.image ?
                          (
                            <img
                              src={data?.staff[0]?.image}
                              className="rounded-circle"
                              width={40}
                              height={40}
                              alt=""
                              style={{ marginRight: "8px" }}
                            />
                          )
                          :
                          (
                            <div
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: '50%',
                                background: '#E1F4F4',
                                color: ' #065A4F',
                                textAlign: 'center',
                                lineHeight: '40px',
                                padding: 1
                              }}
                            >
                              {`${data?.staff[0]?.name} ${data?.staff[0]?.surname}`
                                ?.split(" ")
                                ?.map((d, idx) => (idx < 2 ? d?.charAt(0).toUpperCase() : null))
                                .join("")
                              }
                            </div>
                          )
                      )
                      :
                      (
                        data.staff?.image ?
                          <img src={data.staff?.image}
                            className="rounded-circle"
                            width={40}
                            height={40}
                            alt=""
                            style={{ marginRight: "8px" }}
                          />
                          :
                          <div
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: '50%',
                              background: '#E1F4F4',
                              color: ' #065A4F',
                              textAlign: 'center',
                              lineHeight: '40px',
                              padding: 1
                            }}
                            text={'altText'}
                          >
                            {`${data.staff?.name} ${data.staff?.surname}`
                              ?.split(" ")
                              ?.map((d, idx) => (idx < 2 ? d?.charAt(0).toUpperCase() : null))
                              .join("")
                            }
                          </div>
                      )
                  }
                  <div className="d-flex flex-column  profile-info">
                    <div className="d-flex align-items-center">
                      <span className="b1">{data?.isJob && (data?.status !== 'pending' || data?.status !== 'unfilled') ? data?.staff[0]?.name : data?.staff?.name} {data?.isJob && data?.status === 'active' ? data?.staff[0]?.surname : data?.staff?.surname}</span>
                      <img
                        src={arrow}
                        alt=""
                        style={{ marginLeft: "8px", cursor: "pointer" }}
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          width: 8, height: 8,
                          borderRadius: '50%',
                          marginRight: 8,
                          background: rolesColor[data?.roleType?.id]
                        }}
                      />
                      <span className="t4">{data?.roleType?.label}</span>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center gap-1"
                  style={{ marginTop: "8px" }}
                >
                  {
                    !isPreview && (hasAccess(branchApiKeys.UPDATE_SHIFT) || hasAccess(locumShiftApiKeys.UPDATE_JOB) || hasAccess(locumShiftApiKeys.DELETE_JOB) || hasAccess(branchApiKeys.DELETE_SHIFT)) ?
                      <img
                        src={editIcon}
                        width={25}
                        alt=""
                        className="poniter"
                        onClick={onEditHandler}
                      />
                      : null
                  }
                  {/* <Dropdown
                    getPopupContainer={(trigger) => trigger.parentElement}
                    placement="bottomRight" overlayStyle={{ zIndex: 20000 }} menu={{ items }} trigger={['click']}>
                    <img
                      src={colons}
                      alt=""
                      style={{ marginRight: "8px", cursor: "pointer" }}
                    />
                  </Dropdown> */}
                  <img
                    src={cross}
                    alt=""
                    className="poniter"
                    onClick={(e) => setSlotDetailModal(!show)}
                  />
                </div>
              </div>
              <div className="d-flex flex-column poppover-content">
                <ProfileInfo label="Email Address :" value={data?.isJob ? data?.staff[0]?.email : data?.staff?.email} color="#1A75D2" />
                <ProfileInfo label="Phone No. :" value={data?.isJob ? data?.staff[0]?.phone : data?.staff?.phone} />
                {
                  !(data?.roleType?.id === 8 || data?.roleType?.id === 9 || data?.roleType?.id === 10) &&
                  <ProfileInfo label="GPHC Number :" value={data?.isJob ? data?.staff[0]?.gphcNumber : data?.staff?.gphcNumber} />
                }
                <ProfileInfo label="Date :" value={moment(data?.startDate).format('DD-MM-YYYY')} />
                <ProfileInfo label={`${data?.isJob ? 'Job' : 'Shift'} Timings :`} value={`${data?.startTime} - ${data?.endTime}`} />
              </div>
            </Popover>
          )
      }
    </Overlay >
  );
}
