import { DatePicker, Form, Input, Popover, Radio } from "antd";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlexRow } from "../../../../components/comon/flexRows";
import { AddModal } from "../../../../components/UI/modals/addModal";
import {
  getAccommodationAndFoodRulesThunk,
  getPharmacySettingsThunk,
  getMileageRulesThunk,
  getParkingRulesThunk,
  getTransportRulesThunk,
  updatePharmacySettingsThunk,
} from "../../../../redux/profile/profileThunk";
import { settingsApiKeys } from "../../../../utils/apiKeyConstants";
import { hasAccess } from "../../../../utils/helper";
import { AccommodationAndFoodRules } from "./components/accommodationAndFoodRules";
import { MileageRules } from "./components/mileageRules";
import { PakringRules } from "./components/parkingRules";
import { TransportRules } from "./components/transportRules";
import infoIcon from '../../../../assets/icons/infoIcon.png'

export const ProfileSetting = () => {
  const dispatch = useDispatch();
  const formId = useMemo(() => "form", []);
  const [form] = Form.useForm();
  const isRequired = Form.useWatch("isRequired", form);

  const { pharmacySettings } = useSelector((state) => state.profile);

  const { user } = useSelector((state) => state.auth);

  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (hasAccess(settingsApiKeys.GET_PHARMACY_SETTINGS))
      dispatch(getPharmacySettingsThunk());
    if (hasAccess(settingsApiKeys.GET_SETTINGS_ALL_MILEAGE_RULES))
      dispatch(getMileageRulesThunk());
    if (hasAccess(settingsApiKeys.GET_SETTINGS_PARKING_RULES))
      dispatch(getParkingRulesThunk());
    if (hasAccess(settingsApiKeys.GET_ALL_TRANSPORT_RULES))
      dispatch(getTransportRulesThunk());
    if (hasAccess(settingsApiKeys.GET_ALL_FOOD_RULES))
      dispatch(getAccommodationAndFoodRulesThunk());
  }, [dispatch]);

  useEffect(() => {
    if (!pharmacySettings) return;
    form.setFieldsValue({
      masterDate: pharmacySettings?.masterDate
        ? dayjs(pharmacySettings?.masterDate)
        : null,
      planningPeriod: pharmacySettings?.planningPeriod,
      adminId: user?.id,
      termConditions: pharmacySettings?.termConditions,
      isRequired: pharmacySettings?.isRequired,
      occasionsPermitted: pharmacySettings?.occasionsPermitted,
      isLocationRequired: pharmacySettings?.isLocationRequired,
    });
  }, [pharmacySettings, user?.id]);

  const onSettingUpdate = (values) => {
    const reqData = {
      ...values,
      masterDate: dayjs(values?.masterDate).format("YYYY-MM-DD"),
    };
    dispatch(updatePharmacySettingsThunk(reqData)).then(setShowModal(false));
  };

  return (
    <div className="d-flex flex-column flex-1" style={{ gap: 30 }}>
      <div className="heading4 d-flex align-items-center justify-content-between">
        Settings
        <div
          className="s1 m-0 cursor-pointer border py-2 px-3 rounded"
          onClick={() => setShowModal(true)}
          style={{ color: "white", backgroundColor: "#51C2BC" }}
        >
          Edit
        </div>
      </div>
      <div className="border rounded">
        <div className="text-row d-flex align-items-center justify-content-between">
          <div>
            <div className="s1 mb-2">Planning & Master Date </div>
            <div className="p3" style={{ color: "#7B7B7B" }}>
              Master Date refers to the date when all annual leaves and other quotas are reset, it is also referred to as the year reset date. The 'Planning Period' enables you to decide how long your published templates and infinite shifts will be carried on in the future.
            </div>
          </div>
        </div>
        <FlexRow
          primaryTitle="Master Date"
          primaryDescription={
            pharmacySettings?.masterDate
              ? dayjs(pharmacySettings?.masterDate).format("DD-MM")
              : "-"
          }
        />
        <FlexRow
          primaryTitle="Planning Period Week"
          primaryDescription={pharmacySettings?.planningPeriod ?? "-"}
        />
        <FlexRow
          primaryTitle="Location Detaction Enabled"
          primaryDescription={
            pharmacySettings?.isLocationRequired === 1 ? "Yes" : "No"
          }
        />
      </div>

      <div className="border rounded">
        <div className="text-row d-flex align-items-center justify-content-between">
          <div>
            <div className="s1 mb-2">Preferred Travel Distance</div>
            <div className="p3" style={{ color: "#7B7B7B" }}>
              This field will determine the locum jobs shown by default, you can override this selection by using the filter in the locum market.
            </div>
          </div>
        </div>
        <FlexRow
          primaryTitle="Occasions Permitted"
          primaryDescription={pharmacySettings?.occasionsPermitted ?? "-"}
        />
      </div>

      <div className="border rounded">
        <div className="text-row d-flex align-items-center justify-content-between">
          <div>
            <div className="s1 mb-2">Terms & Conditions</div>
            <div className="p3" style={{ color: "#7B7B7B" }}>
              You can enter your customised terms and conditions which will be
              shown to the job applicant at the time of application. Note that
              this will be shown for all jobs once selected.
            </div>
          </div>
        </div>
        <FlexRow
          primaryTitle="Terms & Conditions"
          primaryDescription={pharmacySettings?.termConditions ?? '-'}
        />
      </div>
      <div className="heading4 d-flex align-items-center justify-content-between">
        Rules
      </div>
      <MileageRules />
      <AccommodationAndFoodRules />
      <PakringRules />
      <TransportRules />

      <AddModal
        title="General Settings"
        handleModal={showModal}
        setHandleModal={setShowModal}
        primaryButtonText="Update"
        secondaryButtonText="Cancel"
        formId={formId}
        secondaryButtonHandler={() => setShowModal(false)}
      >
        <Form
          onFinish={
            hasAccess(settingsApiKeys.UPDATE_PHARMACY_SETTINGS)
              ? onSettingUpdate
              : undefined
          }
          form={form}
          id={formId}
          layout="vertical"
          autoComplete="off"
        >
          <div className="d-flex flex-column gap-3">
            <div className="p-3 border rounded" style={{ backgroundColor: '#f5f5f5' }}>
              <Form.Item
                label="Planning Period Weeks"
                name="planningPeriod"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Planning Period Weeks!",
                  },
                  {
                    max: 2,
                    message: "Max Length Can't be greater than 99!",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Planning Period Weeks"
                  className="text-input-field p-2 pe-3 t1"
                />
              </Form.Item>
              <Form.Item
                label="Master Date"
                name="masterDate"
                rules={[{ required: true, message: "Please Select Master Date!" }]}
              >
                <DatePicker
                  inputReadOnly
                  format="DD-MM"
                  getPopupContainer={(trigger) => trigger.parentElement}
                  className="text-input-field p-2 pe-3 t1"
                  style={{ width: "100%" }}
                  disabledDate={(current) => {
                    return dayjs("01-01-1950").add(-1, "days") >= current;
                  }}
                />
              </Form.Item>
            </div>
            <div className="p-3 border rounded" style={{ backgroundColor: '#f5f5f5' }}>
              <Form.Item
                label="Enter Maximum Ocassions Permitted"
                name="occasionsPermitted"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Maximum Ocassions Permitted!",
                  },
                ]}
              >
                <Input
                  prefix={
                    <Popover
                      zIndex={2000}
                      content="You can disable locum accounts that serve particular amount of shifts within a 12 month rolling period in any pharamcy branch. However, It is to be noted that one shift a day will count as one ocassion."
                      placement="left"
                      title="IR-35"
                      overlayStyle={{ width: 350 }}
                    >
                      <img src={infoIcon} alt="" />
                    </Popover>
                  }
                  type="number"
                  placeholder="Maximum Ocassions Permitted"
                  className="text-input-field p-2 pe-3 t1"
                />
              </Form.Item>
            </div>
            <div className="p-3 border rounded" style={{ backgroundColor: '#f5f5f5' }}>
              <Form.Item
                label='Activate "Location Detaction" for Locum and Staff?'
                name="isLocationRequired"
                rules={[{ required: true, message: "Please Select Option!" }]}
              >
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="border rounded p-3" style={{ backgroundColor: '#f5f5f5' }}>
              <Form.Item
                label="Show for all Jobs?"
                name="isRequired"
              >
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Enter Terms & Conditions"
                name="termConditions"
              >
                <Input.TextArea
                  disabled={!isRequired}
                  rows={5}
                  onBlur={(e) =>
                    form.setFieldsValue({ termConditions: e.target.value.trim() })
                  }
                  maxLength="200"
                  placeholder="Terms & Conditions"
                  className="text-input-field p-2 pe-3 t1"
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </AddModal>
    </div>
  );
};
