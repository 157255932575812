import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EmptyData } from "../../../../../components/comon/EmptyData";
import { Heading } from "../../../../../components/UI/heading";
import { TableComponet } from "../../../../../components/UI/table";
import { archiveBranchThunk, deleteBranchThunk } from "../../../../../redux/branches/branchesThunk";
import { getPharmacyBranchesListThunk } from "../../../../../redux/super-admin/superAdminThunk";
import { notify } from "../../../../../utils/helper";

export const PharmacyBranchesListPage = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { pharmacyBranchesList } = useSelector((state) => state.superAdmin);

  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);

  const columns = useMemo(
    () => [
      {
        Header: "BRANCH ID",
        accessor: "id",
      },
      {
        Header: "Title",
        accessor: "title",
      },

      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row: { original } }) => {
          return (
            <>
              {original.active ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#E3F2FD", color: "#1B5E1F" }}
                  >
                    {"active"}
                  </span>
                </div>
              ) : (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#FEFDE9", color: "#EE872D" }}
                  >
                    {"inActive"}
                  </span>
                </div>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: pharmacyData,
  } = useMemo(() => pharmacyBranchesList, [pharmacyBranchesList]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  const getPharmacyBranchList = useCallback(
    async (values) => {
      try {
        dispatch(
          getPharmacyBranchesListThunk({
            filters: {
              ...values,
            },
            pagination: { page: currentPageCount, perPage: perPageCount },
            id: id,
          })
        );
      } catch (error) {
        notify("error", error.message);
      }
    },
    [currentPageCount, dispatch, id, perPageCount]
  );
  useEffect(() => {
    getPharmacyBranchList(id);
  }, [currentPageCount, getPharmacyBranchList, id, perPageCount]);


  return (
    <>
      <div>
        <Heading label="All branches" />
        <div className="">
          <div>
            {pharmacyBranchesList?.rows?.length > 0 ? (
              <TableComponet
                rowCursor="pointer"
                isBorderShown
                currentPageCount={currentPageCount}
                perPageCount={perPageCount}
                totalDataCount={totalDataCount}
                setCurrentPage={setCurrentPageCount}
                setPerPageCount={setPerPageCount}
                showPagination
                maxPageCount={totalPagesCount}
                columns={columns}
                data={pharmacyData ?? []}
              />
            ) : (
              <div style={{ width: "100%", marginTop: "60px" }}>
                <EmptyData title="Branches" subtitle="Branch" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
