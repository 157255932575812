import { Form, Input, Select, Switch } from "antd";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlexRow } from "../../../components/comon/flexRows";
import { AddModal } from "../../../components/UI/modals/addModal";
import {
  getLocumSettingDataThunk,
  updateLocumSettingDataThunk,
} from "../../../redux/locum-flow/Thunk";

export const LocumSetting = () => {
  const [form] = Form.useForm();
  const formId = useMemo(() => "form", []);
  const dispatch = useDispatch();
  const [showEditInfoModal, setShowEditInfoModal] = useState(false);
  const [actionType, setActionType] = useState("Preferred Travel Distance");
  const { settingInfo } = useSelector((state) => state.locum);

  useEffect(() => {
    dispatch(getLocumSettingDataThunk());
  }, [dispatch]);

  const _updateSettings = (value, name) => {
    dispatch(
      updateLocumSettingDataThunk({
        [name]: value,
      })
    ).then((res) => {
      if (res.payload) {
        setShowEditInfoModal(false);
      }
    });
  };

  useEffect(() => {
    if (!settingInfo) return;
    form.setFieldsValue({
      hourly_rate_from: settingInfo.hourly_rate_from,
      preferred_travel_distance: settingInfo.preferred_travel_distance,
    });
  }, [settingInfo]);

  const updateSettings = (values) => {
    dispatch(updateLocumSettingDataThunk(values)).then((res) => {
      if (res.payload) {
        setShowEditInfoModal(false);
        window.analytics.identify(res.payload.userId, {
          preferred_travel_distance: res.payload.preferred_travel_distance,
          push_notifications_enabled: res.payload.push_notifications_enabled,
          preferred_working_days: res.payload.preferred_working_days,
          preferred_hourly_rate: res.payload.hourly_rate_from
        });
      }
    });
  };

  return (
    <div className="d-flex flex-column flex-1" style={{ gap: 30 }}>
      <div className="heading4">Settings</div>
      <div className="border rounded">
        <div className="text-row d-flex align-items-center justify-content-between">
          <div className="s1 m-0">Preferred Travel Distance</div>
          <div
            className="s1 m-0 cursor-pointer"
            onClick={() => {
              setShowEditInfoModal(true);
              setActionType("Preferred Travel Distance");
            }}
            style={{ color: "#51C2BC" }}
          >
            EDIT
          </div>
        </div>
        <FlexRow
          primaryTitle="Radius"
          primaryDescription={
            settingInfo?.preferred_travel_distance || "Nationwide"
          }
        />
      </div>
      <div className="border rounded">
        <div className="text-row d-flex align-items-center justify-content-between">
          <div className="s1 m-0">Preferred Hourly Rate</div>
          <div
            className="s1 m-0 cursor-pointer"
            onClick={() => {
              setShowEditInfoModal(true);
              setActionType("Minimum hourly rate");
            }}
            style={{ color: "#51C2BC" }}
          >
            Edit
          </div>
        </div>
        <FlexRow
          primaryTitle="Hourly Rate"
          primaryDescription={`£${settingInfo?.hourly_rate_from}` || "-"}
        />
      </div>
      <div className="border rounded">
        <div className="text-row d-flex align-items-center justify-content-between">
          <div className="s1 m-0">Daily Job Summary Emails</div>
        </div>
        <FlexRow
          primaryTitle="On"
          primaryDescription={
            <Switch
              onChange={(value) => _updateSettings(value, "send_daily_emails")}
              checked={settingInfo.send_daily_emails}
              size="default"
            />
          }
        />
      </div>
      <div className="border rounded">
        <div className="text-row d-flex align-items-center justify-content-between">
          <div className="s1 m-0">
            Send me Emails When I am Invited to a Job
          </div>
        </div>
        <FlexRow
          primaryTitle="On"
          primaryDescription={
            <Switch
              onChange={(value) => _updateSettings(value, "send_invite_emails")}
              checked={settingInfo.send_invite_emails}
              size="default"
            />
          }
        />
      </div>
      <AddModal
        title="Edit Information"
        handleModal={showEditInfoModal}
        setHandleModal={setShowEditInfoModal}
        formId={formId}
        primaryButtonText="Update"
      >
        <Form
          onFinish={updateSettings}
          form={form}
          id={formId}
          style={{ width: "100%" }}
          layout="vertical"
          autoComplete="off"
        >
          {actionType === "Minimum hourly rate" && (
            <Form.Item
              label="Minimum hourly rate"
              name="hourly_rate_from"
              rules={[
                () => ({
                  validator(rule, value) {
                    if (value >= 1000) {
                      return Promise.reject(
                        "Locum Hourly Rate should be less than 1000"
                      ); // The validator should always return a promise on both success and error
                    } else if (value < 0) {
                      return Promise.reject(
                        "Locum Hourly Rate can not be less than 0"
                      ); // The validator should always return a promise on both success and error
                    }
                    return Promise.resolve(value);
                  },
                }),
              ]}
            >
              <Input
                type="number"
                placeholder="Hourly Rate"
                className="text-input-field p-2 pe-3 t1"
              />
            </Form.Item>
          )}
          {actionType === "Preferred Travel Distance" && (
            <Form.Item
              label="Preferred Travel Distance (radius)"
              name="preferred_travel_distance"
            >
              <Select
                dropdownStyle={{ zIndex: 2000 }}
                placeholder="Travel Distance"
                bordered={false}
                allowClear
                className="text-input-field pe-3 t1"
              >
                <Select.Option value={1}>1 Mile</Select.Option>
                <Select.Option value={5}>5 Mile</Select.Option>
                <Select.Option value={10}>10 Mile</Select.Option>
                <Select.Option value={20}>20 Mile</Select.Option>
                <Select.Option value={50}>50 Mile</Select.Option>
                <Select.Option value={100}>100 Mile</Select.Option>
                <Select.Option value={150}>150 Mile</Select.Option>
                <Select.Option value={200}>200 Mile</Select.Option>
                <Select.Option value={null}>Nationwide</Select.Option>
              </Select>
            </Form.Item>
          )}
        </Form>
      </AddModal>
    </div>
  );
};
