import React from "react";
import DatePicker from "../../schedule/SchedularToolbar/datePicker/DatePicker";
import { Button } from "antd";
import "./style.scss";

export const Heading = ({
  label,
  viewDetailsBtn,
  viewDetailsBtnHandler,
  showDatePicker,
  view,
  onDateChange,
  dateFilter,
  filterChildren,
  dateRange
}) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center  border-bottom-header"
      style={{ padding: " 24px" }}
    >
      <div className="d-flex flex-column">
        <span className="heading4" style={{ fontWeight: 700 }}>
          {label}
        </span>
      </div>
      <div className="d-flex " style={{ gap: "15px" }}>
        {viewDetailsBtn && (
          <Button
            onClick={viewDetailsBtnHandler}
            className="p-2 px-4 btn-style d-flex align-items-center gap-1"
            type="primary"
            block
          >
            View Details
          </Button>
        )}
        {showDatePicker && (

          <DatePicker
            dateRange={dateRange}
            view={view}
            onDateChange={onDateChange}
          />
        )}
        {dateFilter && filterChildren}
      </div>
    </div>
  );
};
