import moment from "moment";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Toolbar from "../../../components/schedule/SchedularToolbar";
import { getPeronalScheduleThunk } from "../../../redux/locum-flow/Thunk";
import { LocumMonthlyView } from "./monthView/MonthView";

export const LocumSchedule = () => {
  const { id } = useParams();
  const [dateRange, setDateRange] = useState();

  const [flag, setFlag] = useState(true);
  const { peronsalSchedule } = useSelector((state) => state.locum);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!dateRange || !flag) return;

    dispatch(
      getPeronalScheduleThunk({
        endDateRange: dateRange?.endDateRange,
        startDateRange: dateRange?.startDateRange,
        deviceType: "web",
      })
    );
  }, [dateRange, dispatch, flag, id]);

  useEffect(() => {
    setDateRange({
      startDateRange: moment(dateRange?.startDateRange)
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDateRange: moment(dateRange?.startDate)
        .endOf("month")
        .format("YYYY-MM-DD"),
    });

    setFlag(true);
  }, []);

  const onDateChange = useCallback(
    (startDate, endDate) => {
      setDateRange({
        startDateRange: startDate,
        endDateRange: endDate
      });
      setFlag(true);
      setFlag(true);
    },
    []
  );

  const dateLable = useMemo(() => {
    const startDate = `${moment(dateRange?.startDateRange, "YYYY-MM-DD").format(
      ` MMMM, Y`
    )}`;
    return startDate;
  }, [dateRange]);

  return (
    <div className="branch-schedule-view-container">
      <Toolbar
        primaryText="Schedule"
        view="monthly"
        dateRange={dateRange}
        showDatePicker
        onDateChange={onDateChange}
        dateLable={dateLable}
        setFlag={setFlag}
      />
      {!peronsalSchedule ? undefined : (
        <LocumMonthlyView scheduleData={peronsalSchedule} />
      )}
    </div>
  );
};
