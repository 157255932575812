import React, { useEffect } from "react";
import { GlobalLoader } from "./components/UI/spiner/globalLoader";
import { Root } from "./pages/root";
import "./style.scss";
import "semantic-ui-css/semantic.min.css";
import { Authentication } from "./pages/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  authUserRequestThunk, getAccessibleApisThunk, getAccessibleModulesThunk,
} from "./redux/auth/authThunk";
import { productFruits } from 'product-fruits';
import { ProductFruits } from 'react-product-fruits';

export const App = () => {
  const dispatch = useDispatch();
  const {
    isAuth,
    user
  } = useSelector((state) => state.auth);

  productFruits.init('flHZfHc5W7EPTklX', 'en', { username: `${user.name} ${user.surname}` });
  const { isloading } = useSelector((state) => state.loader);
  useEffect(() => {
    if (isAuth)
      dispatch(authUserRequestThunk());
  }, [dispatch, isAuth]);

  useEffect(() => {
    if (isAuth && user?.group === "pharmacy") {
      dispatch(getAccessibleModulesThunk(user?.id));
      dispatch(getAccessibleApisThunk(user?.roleId));
      return;
    }
  }, [dispatch, isAuth])

  return isAuth ? (
    <>
      <GlobalLoader color="blue" loaderState={isloading} />
      <ProductFruits workspaceCode="flHZfHc5W7EPTklX" language="en" user={{
        username: user.name + ' ' + user.surname,
        ...user
      }} />

      <Root />
    </>
  ) : (
    <>
      <GlobalLoader color="blue" loaderState={isloading} />
      <Authentication />
    </>
  );
};
