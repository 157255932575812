
import { Form, Input, Popover, Upload } from "antd";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlexRow } from "../../../../components/comon/flexRows";
import { LocationSearchInput } from "../../../../components/UI/input/locationSearchInput";
import { AddModal } from "../../../../components/UI/modals/addModal";
import { ConfirmModal } from "../../../../components/UI/modals/confirmModal";
import { editProfileDataThunk, editProfilePasswordThunk } from "../../../../redux/profile/profileThunk";
import { beforeUpload, compressImage, notify } from "../../../../utils/helper";
import infoIcon from '../../../../assets/icons/infoIcon.png'
import { PasswordCriteria } from "../../../../components/auth/login/loginForm";

export const ProfileInformation = () => {
    const dispatch = useDispatch()
    const { profileInfo } = useSelector(state => state.profile)
    const [showEditModal, setShowEditModal] = useState(false);

    const formId = useMemo(() => "form", [])
    const [form] = Form.useForm();
    const image = Form.useWatch('image', form);

    const [showSecurityModal, setShowSecurityModal] = useState(false)

    useEffect(() => {
        if (!profileInfo && !showEditModal) return
        form.setFieldsValue({
            name: profileInfo?.name,
            surname: profileInfo?.surname,
            phone: Number(profileInfo?.phone),
            address1: profileInfo?.address1,
            image: (profileInfo.image ? {
                fileList: [
                    {
                        uid: Math.random(),
                        name: 'image.png',
                        status: 'done',
                        url: profileInfo?.image,
                    }
                ]
            } : undefined),
            email: profileInfo?.email,
            pharmacyName: profileInfo?.pharmacyName,
            latitude: profileInfo?.latitude,
            longitude: profileInfo?.longitude,
        })
    }, [profileInfo, showEditModal])

    const handleAddressSelect = async ({ name, data: address }) => {
        form.setFieldsValue({
            ...form.getFieldsValue,
            [name]: address.line_1,
            latitude: address.latitude,
            longitude: address.longitude,
        });
    };

    const onEditProfile = (values) => {
        const reqBody = {
            ...values,
            image: values?.image?.file?.status === 'removed' || !values?.image ? "" : values?.image?.file ? values?.image?.file : values?.image?.fileList[0].url,
        }
        delete reqBody['email']
        let formData = new FormData();
        for (let key in reqBody) {
            if (reqBody[key] instanceof Blob) {
                formData.append(key, reqBody[key], reqBody[key]?.name);
            } else {
                formData.append(key, reqBody[key]);
            }
        }
        dispatch(editProfileDataThunk(
            formData
        )).then(res => {
            if (res.payload) {
                setShowEditModal(false)
                form.resetFields()
                window.analytics.identify(res.payload.id, {
                    ...res.payload,
                    device: "web",
                });
            }
        })
    }

    return (
        <div className="d-flex flex-column flex-1" style={{ gap: 30 }}>
            <div className="heading4 d-flex align-items-center justify-content-between">
                Personal Information
                <div className="s1 m-0 cursor-pointer border py-2 px-3 rounded" onClick={() => setShowEditModal(true)} style={{ color: 'white', backgroundColor: '#51C2BC' }}>Edit</div>
            </div>
            <div className="border rounded">
                <div className="text-row d-flex align-items-center justify-content-between">
                    <div className="s1 m-0">Basic Information</div>
                </div>
                <FlexRow
                    primaryTitle="Name"
                    primaryDescription={(profileInfo?.name ?? '') + " " + (profileInfo?.surname ?? '')}
                />
                <FlexRow
                    primaryTitle="Pharmacy"
                    primaryDescription={profileInfo?.pharmacyName || '-'}
                />

            </div>
            <div className="border rounded">
                <div className="text-row d-flex align-items-center justify-content-between">
                    <div className="s1 m-0">Contact Details</div>
                </div>
                <FlexRow
                    primaryTitle="Phone Number"
                    primaryDescription={profileInfo?.phone}
                />
                <FlexRow
                    primaryTitle="Email"
                    primaryDescription={profileInfo?.email}
                />
                <FlexRow
                    primaryTitle="Address"
                    primaryDescription={profileInfo?.address1 ?? '-'}
                />
            </div>
            <AddModal
                formId={formId}
                title="Edit Profile"
                handleModal={showEditModal}
                setHandleModal={setShowEditModal}
                primaryButtonText="Update"
            >
                <Form
                    onFinish={onEditProfile}
                    form={form}
                    id={formId}
                    layout="vertical"
                    autoComplete="off"
                >
                    <Form.Item label="Profile" name="image">
                        <Upload
                            accept="image/*"
                            showUploadList={{
                                showPreviewIcon: false,
                                showRemoveIcon: true,
                            }}
                            fileList={image?.fileList}
                            onPreview={() => { }}
                            beforeUpload={beforeUpload}
                            onChange={async ({ file, fileList }) => {
                                try {
                                    const compressedFile = await compressImage(file);
                                    form.setFieldsValue({ image: { file: compressedFile, fileList } });
                                } catch (error) {
                                    notify('error', 'Error compressing the image:');
                                }
                            }}
                            multiple={false}
                            maxCount={1}
                            listType="picture-card"
                        >
                            {
                                (!image || image?.file?.status === "removed") &&
                                <div>
                                    <div style={{ marginTop: 8 }}>Upload</div>
                                </div>
                            }
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        label="First Name"
                        name="name"
                        rules={[{ required: true, message: 'Please input your First name!' }]}
                    >
                        <Input
                            onBlur={e => form.setFieldsValue({ 'name': e.target.value.trim() })}
                            maxLength={50}
                            placeholder="First Name"
                            className='text-input-field p-2 pe-3 t1'
                        />
                    </Form.Item>
                    <Form.Item
                        label="Last Name"
                        name="surname"
                        rules={[{ required: true, message: 'Please input your last Name!' }]}
                    >
                        <Input
                            onBlur={e => form.setFieldsValue({ 'surname': e.target.value.trim() })}
                            maxLength={50}
                            placeholder="Last Name"
                            className='text-input-field p-2 pe-3 t1'
                        />
                    </Form.Item>
                    <Form.Item
                        label="Pharmacy Name"
                        name="pharmacyName"
                        rules={[{ required: true, message: 'Please input your Pharmacy Name!' }]}
                    >
                        <Input
                            maxLength={50}
                            onBlur={e => form.setFieldsValue({ 'pharmacyName': e.target.value.trim() })}
                            placeholder="Pharmacy Name"
                            className='text-input-field p-2 pe-3 t1'
                        />
                    </Form.Item>

                    <Form.Item
                        label="Phone"
                        name="phone"
                        rules={[
                            { required: true, message: 'Please Enter your Contact Phone!' },
                        ]}
                    >
                        <Input
                            type="number"
                            placeholder="Phone"
                            className='text-input-field p-2 pe-3 t1'
                        />
                    </Form.Item>

                    <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[{ type: 'email', required: true, message: 'Please input your Phone Number!' }]}
                    >
                        <Input
                            disabled
                            placeholder="Email Address"
                            className='text-input-field p-2 pe-3 t1'
                        />
                    </Form.Item>

                    <Form.Item
                        label="Address"
                        name="address1"
                        rules={[{ required: true, message: 'Please input your Address!' }]}
                    >
                        <LocationSearchInput
                            className='text-input-field p-2 pe-3 t1'
                            name="address1"
                            value={form?.address1}
                            onHandleSelect={handleAddressSelect}
                        />
                    </Form.Item>
                    <div className="d-flex align-items-center gap-3">
                        <Form.Item
                            hidden
                            label="Latitude"
                            name="latitude"
                        >
                            <Input
                                placeholder="Latitude"
                                className='text-input-field p-2 pe-3 t1'
                            />
                        </Form.Item>
                        <Form.Item
                            hidden
                            label="Longitude"
                            name="longitude"
                        >
                            <Input
                                placeholder="Longitude"
                                className='text-input-field p-2 pe-3 t1'
                            />
                        </Form.Item>
                    </div>
                </Form>
                <div className="" style={{ color: "#7B7B7B", width: "100%" }}>
                    <div className="mb-2">
                        Security
                    </div>
                    <div
                        onClick={() => { setShowSecurityModal(true) }}
                        className="p-2 text-center rounded cursor-pointer"
                        style={{ width: '100%', color: '#51C2BC', border: '2px dashed #51C2BC' }}
                    >
                        Change Password
                    </div>
                </div>
            </AddModal>
            <PasswordChangeModal email={profileInfo?.email} showSecurityModal={showSecurityModal} setShowSecurityModal={setShowSecurityModal} />
        </div >
    );
};


export const PasswordChangeModal = ({ email, showSecurityModal, setShowSecurityModal }) => {
    const [form] = Form.useForm();
    const formId = useMemo(() => "pass-form", [])
    const dispatch = useDispatch()
    const onPassword = (password) => {
        dispatch(
            editProfilePasswordThunk(
                password
            )
        ).then(res => {
            if (res.payload.status === 'success') {
                notify('success', 'Password Chnaged')
                setShowSecurityModal(false)
                form.resetFields()
            }
        })
    };
    return (
        <ConfirmModal
            formId={formId}
            title="Change Password"
            open={showSecurityModal}
            cancelButtonText="Cancel"
            confirmButtonText="Submit"
            onClose={() => setShowSecurityModal(false)}
        >
            <Form

                id={formId}
                onFinish={onPassword}
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item label="Email" name="email" style={{ width: '100%' }}>
                    <Input
                        disabled
                        defaultValue={email}
                        label="Email"
                        className='text-input-field p-2 pe-3 t1'
                    />
                </Form.Item>
                <Form.Item
                    style={{ width: '100%' }}
                    label="Password"
                    name="password"
                    rules={[
                        { required: true, message: 'Please Enter your password!' },
                        {
                            pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})",
                            message: `Password doesn't match the Criteria!`,
                        }
                    ]}
                >
                    <Input.Password
                        onBlur={e => form.setFieldsValue({ 'password': e.target.value.trim() })}
                        prefix={
                            <Popover
                                zIndex={2000}
                                autoAdjustOverflow
                                content={PasswordCriteria}
                                placement="left"
                                title="Password Criteria"
                            >
                                <img src={infoIcon} alt="" />
                            </Popover>
                        }
                        placeholder="Password"
                        className="text-input-field p-2 pe-3 t1"
                        maxLength={50}
                    />
                </Form.Item>
            </Form>
            <div className="mb-2" />
        </ConfirmModal>
    )
}
