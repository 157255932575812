import { DatePicker, Form, Input, Select, Switch } from "antd";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { FilterComponent } from "../../../components/Filter";
import { updateLocumSettingDataThunk } from "../../../redux/locum-flow/Thunk";

export const LocumShiftsFilterComponent = ({
    onApplyFilter
}) => {
    const [form] = Form.useForm();
    const formId = useMemo(() => "locum-shifts-filter-form", [])
    const dispatch = useDispatch()

    const clearFilterFormHandler = () => {
        form.resetFields()
        onApplyFilter()
    }
    const startDate = Form.useWatch("startDate", form);
    const endDate = Form.useWatch("endDate", form);

    const onSubmit = (values) => {
        onApplyFilter(values)
        if (values.alertsOn) {
            dispatch(updateLocumSettingDataThunk({
                hourly_rate_from: values?.minHourlyRate,
                preferred_travel_distance: values?.distance

            }))
        }
    }
    return (
        <FilterComponent formId={formId} onApplyFilter={form.submit} clearFormHandler={form.isFieldsTouched() && clearFilterFormHandler}>
            <Form
                id={formId}
                onFinish={onSubmit}
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    label="Preferred Travel Distance (radius)"
                    name="distance"
                >
                    <Select
                        dropdownStyle={{ zIndex: 2000 }}
                        placeholder="Travel Distance"
                        bordered={false}
                        allowClear
                        className='text-input-field p-1 t1'
                    >
                        <Select.Option value={1} >1 Mile</Select.Option>
                        <Select.Option value={5} >5 Mile</Select.Option>
                        <Select.Option value={10} >10 Mile</Select.Option>
                        <Select.Option value={20} >20 Mile</Select.Option>
                        <Select.Option value={50} >50 Mile</Select.Option>
                        <Select.Option value={100} >100 Mile</Select.Option>
                        <Select.Option value={150} >150 Mile</Select.Option>
                        <Select.Option value={200} >200 Mile</Select.Option>
                        <Select.Option value={null} >Nationwide</Select.Option>
                    </Select>
                </Form.Item>
                <div className="my-3 d-flex align-items-center gap-3">
                    <Form.Item
                        label="Start Date"
                        name="startDate"
                        rules={endDate ? [
                            { required: true, message: "Please Enter Start Date!" },
                        ] : []}
                    >
                        <DatePicker
                            inputReadOnly format="DD-MM-YYYY"
                            placeholder="Select"
                            getPopupContainer={(trigger) => trigger.parentElement}
                            className="text-input-field p-2 pe-3 t1"
                            style={{ width: "100%" }}
                            disabledDate={(current) => {
                                return (
                                    dayjs(startDate).add(-1, "days") >= current
                                );
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="End Date"
                        name="endDate"
                        rules={startDate ? [
                            { required: true, message: "Please Enter End Date!" },
                        ] : []}
                    >
                        <DatePicker
                            format="DD-MM-YYYY"
                            inputReadOnly placeholder="Select"
                            getPopupContainer={(trigger) => trigger.parentElement}
                            className="text-input-field p-2 pe-3 t1"
                            style={{ width: "100%" }}
                            disabledDate={(current) => {
                                return (
                                    dayjs(startDate).add(+1, "days") >= current
                                );
                            }}
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    label="Minimum Hourly Rate"
                    name="minHourlyRate"
                >
                    <Input
                        type="number"
                        className="text-input-field p-2 pe-3 t1"
                        style={{ width: "100%" }}
                    />
                </Form.Item>
                <div className="d-flex gap-3">
                    <div className="p3 primary-text">
                        Get Job Alert for this Search
                    </div>
                    <Form.Item name="alertsOn">
                        <Switch size="default" />
                    </Form.Item>
                </div>
            </Form>
        </FilterComponent>
    );
};
