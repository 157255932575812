import moment from "moment";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import Toolbar from "../../../components/schedule/SchedularToolbar";
import { getPeronalScheduleThunk } from "../../../redux/locum-flow/Thunk";
import { StaffMonthlyView } from "./monthView/MonthView";

export const StaffSchedule = () => {
  const { id } = useParams();
  const [dateRange, setDateRange] = useState();

  const [flag, setFlag] = useState(true);
  const { peronsalSchedule } = useSelector((state) => state.locum);
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!dateRange || !flag) return;
    dispatch(
      getPeronalScheduleThunk({
        endDateRange: dateRange?.endDateRange,
        startDateRange: dateRange?.startDateRange,
      })
    );
  }, [dateRange, dispatch, flag, id]);

  useEffect(() => {
    setDateRange({
      startDateRange:
        moment(searchParams.get('date') ? searchParams.get('date') : dateRange?.startDateRange)
          .startOf("month")
          .format("YYYY-MM-DD"),
      endDateRange: moment(searchParams.get('date') ? searchParams.get('date') : dateRange?.endDateRange)
        .endOf("month")
        .format("YYYY-MM-DD"),
    });

    setFlag(true)
  }, [searchParams])


  const onDateChange = useCallback(
    (startDate, endDate) => {
      setDateRange({
        startDateRange: startDate,
        endDateRange: endDate
      });
      setFlag(true);
      setFlag(true);
    },
    []
  );

  const dateLable = useMemo(() => {
    const startDate = `${moment(dateRange?.startDateRange, "YYYY-MM-DD").format(` MMMM, Y`)}`
    return startDate
  }, [dateRange])

  return (
    <div className="branch-schedule-view-container">
      <Toolbar
        primaryText="Schedule"
        view="monthly"
        dateRange={dateRange}
        dateLable={dateLable}
        onDateChange={onDateChange}
        showDatePicker
        setFlag={setFlag}
      />
      {!peronsalSchedule ? undefined : (
        <StaffMonthlyView
          scheduleData={peronsalSchedule}
        />
      )}
    </div>
  );
};
