import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { DocumentSlide } from "../../../../components/staffs/documentSlide"
import { sortAccredArray } from "../../../../utils/helper"

export const Accrediations = ({
    setShowAccrediationModal,
    setShowCustomAccredModal
}) => {
    const { staffAccrediations } = useSelector((state) => state.staffs)
    const { user } = useSelector(state => state.auth)



    const [additionalAcc, setAddidtionalAcc] = useState([])

    useEffect(() => {
        if (!staffAccrediations?.pharmacyAccreditation) return
        const data = staffAccrediations?.pharmacyAccreditation?.accreditationRules.filter(i => (i.roleId === user.roleId || !i.roleId)).map((item) => {
            const d = staffAccrediations?.pharmacyAccreditation?.staffAccreditations?.find((ot) => ot.accreditationRuleId === item.id);
            return ({
                ...item,
                answer: d?.answer,
                expiryDate: d?.expiryDate ? dayjs(d?.expiryDate).format('YYYY-MM-DD') : null
            });
        });
        setAddidtionalAcc(data)
    }, [staffAccrediations?.pharmacyAccreditation, user])
    return (
        <div className="d-flex flex-column gap-3">
            {
                additionalAcc?.length ?
                    <div className="border rounded">
                        <div className="d-flex justify-content-between">
                            <div className="s1 text-row m-0">Additional Accrediations</div>
                            <div
                                className="s1 p-4 m-0 cursor-pointer"
                                style={{ color: 'rgb(81, 194, 188)' }}
                                onClick={() => {
                                    setShowAccrediationModal(false)

                                    setShowCustomAccredModal(true)
                                }
                                }
                            >
                                Edit
                            </div>
                        </div>
                        <div className="d-flex flex-wrap gap-3 align-items-center m-3">
                            {
                                sortAccredArray(additionalAcc)?.map((data, index) => (
                                    <DocumentSlide expDate={data.expiryDate} isDocument={data.isDocument} name={user?.name} key={index} label={data.title} value={data.answer} />
                                ))
                            }
                        </div>
                    </div>
                    : null
            }
            <div className="border rounded">
                <div className="d-flex justify-content-between">
                    <div className="s1 text-row m-0">
                        System Accreditations
                    </div>
                    <div
                        className="s1 p-4 m-0 cursor-pointer"
                        style={{ color: 'rgb(81, 194, 188)' }}
                        onClick={() => {
                            setShowCustomAccredModal(false)
                            setShowAccrediationModal(true)
                        }}
                    >
                        Edit
                    </div>
                </div>
                <div className="d-flex flex-wrap gap-3 align-items-center m-3">
                    {
                        sortAccredArray(staffAccrediations?.systemAccreditation)?.map((data, index) => (
                            <DocumentSlide name={user?.name} key={index} label={data.accreditationName} value={data.filePath} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}