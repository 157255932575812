import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DetailTab } from "../../../components/dashboard/DetailTabs";
import { TableComponet } from "../../../components/UI/table";
import { TableToolbar } from "../../../components/UI/table/tableToolBar";
import {
  getLocumAttendanceStatsThunk,
  getLocumAttendanceThunk,
} from "../../../redux/locum-flow/Thunk";
import { StaffTimeSheetFilterComponent } from "../../admin-view/staffs/details/time-sheet/components/dateRangeFilter";
import arrowRightIcon from "../../../assets/icons/arrow-right.png";
import { AddModal } from "../../../components/UI/modals/addModal";
import { EmptyData } from "../../../components/comon/EmptyData";

export const LocumTimeSheet = () => {
  const dispatch = useDispatch();
  const { attendanceStats, attendance } = useSelector((state) => state.locum);
  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);
  const [attendanceData, setAttendanceData] = useState();
  const [selecteDate, setSelectedDate] = useState();
  const [showExpenseModal, setShowExpenseModal] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: dayjs().startOf('month'),
    endDate: dayjs()
  })

  const [flag, setFlag] = useState(true)

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: locumAttendance,
  } = useMemo(() => attendance, [attendance]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  useEffect(() => {
    if (!flag) return
    dispatch(
      getLocumAttendanceStatsThunk({
        filters: {
          from: dateRange.startDate.format('YYYY-MM-DD'),
          to: dateRange.endDate.format('YYYY-MM-DD'),
        },
      })
    ).then(res => {
      if (res.payload) {
        setFlag(false)
      }
    })
    dispatch(
      getLocumAttendanceThunk({
        filters: {
          from: dateRange.startDate.format('YYYY-MM-DD'),
          to: dateRange.endDate.format('YYYY-MM-DD'),
        },
        pagination: {
          page: currentPageCount,
          perPage: perPageCount,
        },
      })
    ).then(res => {
      if (res.payload) {
        setFlag(false)
      }
    })
  }, [currentPageCount, dateRange.endDate, dateRange.startDate, dispatch, flag, perPageCount]);

  useEffect(() => {
    setFlag(true)
  }, [currentPageCount, perPageCount]);

  const columns = useMemo(
    () => [
      {
        Header: "Pharmacy Name",
        accessor: "pharmacyName",
      },

      {
        Header: "Date",
        accessor: "startDate",
        Cell: ({ row: { original } }) => {
          return dayjs(original.startDate).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Check In",
        accessor: "startTime",
        Cell: ({ row: { original } }) => {
          return `${original.startTime}` ?? "00:00";
        },
      },
      {
        Header: "Check Out",
        accessor: "endTime",
        Cell: ({ row: { original } }) => {
          return ` ${original.endTime}` ?? "00:00";
        },
      },
      {
        Header: "Wokring Hours",
        accessor: "duration",
      },
      {
        Header: "Earned",
        accessor: "total",
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row: { original } }) => {
          return (
            <img
              onClick={() => {
                setSelectedDate(original);
                setShowExpenseModal(true);
              }}
              style={{ cursor: "pointer" }}
              src={arrowRightIcon}
              alt=""
            />
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (!locumAttendance?.length) return;
    const data = locumAttendance?.map((data) => data.data);
    setAttendanceData(data?.flat());
  }, [locumAttendance]);


  const onApplyFilter = useCallback(
    (values) => {
      setDateRange({
        startDate: values.startDate,
        endDate: values.endDate,
      })
      setFlag(true)
    },
    []
  );

  return (
    <div>
      <AddModal
        title="Job Rules"
        handleModal={showExpenseModal}
        setHandleModal={setShowExpenseModal}
        secondaryButtonHandler={() => setShowExpenseModal(false)}
        secondaryButtonText="Dismiss"
      >
        <div
          className="d-flex flex-column align-items-start add-shift-modal-content"
          style={{ gap: "15px", color: "#7B7B7B", paddingTop: "25px" }}
        >
          <div className="d-flex flex-column gap-3" style={{ width: "100%" }}>
            {!selecteDate?.mileageRule &&
              !selecteDate?.parkingRule &&
              !selecteDate?.transportRule &&
              !selecteDate?.accommodationAndFoodRule && (
                <EmptyData title="Rule" />
              )}

            {selecteDate?.mileageRule && (
              <>
                <div className="b1">Mileage Rule</div>
                <div className="border rounded p-4 d-flex flex-column gap-3">
                  {Object.entries(selecteDate?.mileageRule).map(
                    ([key, value]) => {
                      return (
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="p3" style={{ color: "#7B7B7B" }}>
                            {key}
                          </div>
                          <div className="p3">{value}</div>
                        </div>
                      );
                    }
                  )}
                </div>
              </>
            )}
            {selecteDate?.parkingRule && (
              <>
                <div className="b1">Parking Rule</div>
                <div className="border rounded p-4 d-flex flex-column gap-3">
                  {Object.entries(selecteDate?.parkingRule).map(
                    ([key, value]) => {
                      return (
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="p3" style={{ color: "#7B7B7B" }}>
                            {key}
                          </div>
                          <div className="p3">{value}</div>
                        </div>
                      );
                    }
                  )}
                </div>
              </>
            )}
            {selecteDate?.transportRule && (
              <>
                <div className="b1">Transport Rule</div>
                <div className="border rounded p-4 d-flex flex-column gap-3">
                  {Object.entries(selecteDate?.transportRule).map(
                    ([key, value]) => {
                      return (
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="p3" style={{ color: "#7B7B7B" }}>
                            {key}
                          </div>
                          <div className="p3">{value}</div>
                        </div>
                      );
                    }
                  )}
                </div>
              </>
            )}
            {selecteDate?.accommodationAndFoodRule && (
              <>
                <div className="b1">Accommodation And FoodRule Rule</div>
                <div className="border rounded p-4 d-flex flex-column gap-3">
                  {Object.entries(selecteDate?.accommodationAndFoodRule).map(
                    ([key, value]) => {
                      return (
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="p3" style={{ color: "#7B7B7B" }}>
                            {key}
                          </div>
                          <div className="p3">
                            {key === "foodCoverage"
                              ? value === true
                                ? "Yes"
                                : "NO"
                              : value}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </>
            )}
          </div>
          {/* <div className="d-flex flex-column gap-1 position-absolute p-3 border rounded shadow" style={{ width: 300, background: 'white', right: 25, top: -5, zIndex: 1 }}>
                        {
                            Object.entries(data).map(([key, value]) => {
                                return (
                                    <div className="d-flex justify-content-between align-items-center" >
                                        <div>
                                            {key}
                                        </div>
                                        <div>
                                            {value}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div > */}
        </div>
      </AddModal>
      <div className="">
        <TableToolbar
          headingText="Time Sheet"
          filterButton
          FilterComponent={
            <StaffTimeSheetFilterComponent dateRange={dateRange} onApplyFilter={onApplyFilter} />
          }
        />
        <div
          className="d-flex align-items-center gap-4"
          style={{ padding: "5px 24px" }}
        >
          <div
            className="d-flex align-items-center gap-4"
            style={{ width: "50%" }}
          >
            <DetailTab
              color="#CDF2EA"
              label="Branches Worked at"
              count={attendanceStats?.stats?.branches}
            />
            <DetailTab
              color="#FFCFC5"
              label="Avg Wage Rate"
              count={`£${Number(attendanceStats?.stats?.wageRate).toFixed(2)}`}
            />
          </div>
          <div
            className="d-flex align-items-center gap-4"
            style={{ width: "50%" }}
          >
            <DetailTab
              color="#FFDB75"
              label="Working Hours/Days "
              count={Number(attendanceStats?.stats?.hrsPerDay).toFixed(2)}
            />
            <DetailTab
              color="#C8DEAB"
              label="Overall Rating"
              count={Number(attendanceStats?.stats?.rating).toFixed(2)}
            />
          </div>
        </div>
        <div style={{ padding: "5px 24px" }}>
          {attendance?.rows?.length > 0 ? (
            <TableComponet
              isBorderShown
              rowCursor="pointer"
              columns={columns}
              data={attendanceData ?? []}
              currentPageCount={currentPageCount}
              perPageCount={perPageCount}
              totalDataCount={totalDataCount}
              setCurrentPage={setCurrentPageCount}
              setPerPageCount={setPerPageCount}
              showPagination
              maxPageCount={totalPagesCount}
            />
          ) : (
            <div style={{ paddingTop: "80px", height: "100%" }}>
              <EmptyData title="Time Sheets" secondaryText="Let's Get You A Locum Job To Populate This Time Sheet" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
