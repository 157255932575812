import { Checkbox, DatePicker, Dropdown, Form, Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ShiftJobForm } from "../../../../../../components/comon/form";
import { BranchItemCell } from "../../../../../../components/schedule/schedule-Week-view/week-items/BranchItem";
import {
  actionTemplateWeekThunk,
  deleteTemplateShiftThunk,
  getBranchTemplateThunk,
  publishTemplateThunk,
} from "../../../../../../redux/branches/branchesThunk";
import { getPharmacySettingsThunk } from "../../../../../../redux/profile/profileThunk";
import {
  getRolesThunk,
  getStaffsThunk,
} from "../../../../../../redux/schedule/scheduleThunk";
import { weekDays } from "../../../../../../utils/dates";
import colon from "../../../../../../assets/icons/colons.png";
import Toolbar from "../../../../../../components/schedule/SchedularToolbar";
import { ConfirmModal } from "../../../../../../components/UI/modals/confirmModal";
import dayjs from "dayjs";
import { hasAccess } from "../../../../../../utils/helper";
import {
  branchApiKeys,
  settingsApiKeys,
} from "../../../../../../utils/apiKeyConstants";
import infoIcon from "../../../../../../assets/icons/infoIcon1.png";
import moment from "moment";
import { MonthlyView } from "../schedule/monthView/MonthView";
import { ShiftJobFormUpdated } from "../../../../../../components/comon/addShiftForm";

export const BranchTemplate = () => {
  const [form] = Form.useForm();
  const formId = useMemo(() => "form", []);
  const startDate = Form.useWatch("startDate", form);
  const indefinite = Form.useWatch("indefinite", form);

  const { branchTemplate } = useSelector((state) => state.branches);
  const { pharmacySettings } = useSelector((state) => state.profile);
  const [errorData, setErrorData] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [previewMonth, setPreviewMonth] = useState(dayjs().format('YYYY-MM-DD'))

  const dispatch = useDispatch();
  const { id: branchId } = useParams();
  const { branchInfo } = useSelector((state) => state.branches);
  const [showAddShiftModal, setShowAddshiftModal] = useState(false);
  const [showPublishTemplateModal, setShowPublishTemplateModal] =
    useState(false);
  const [flag, setFlag] = useState(true);
  const [operatingTime, setOperatingTime] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectId, setSelectedId] = useState();
  const [selectedData, setSelectedData] = useState();
  const [selectedDay, setSelectedDay] = useState();
  const [selectedWeek, setSelectedWeek] = useState();
  const [weekNumber, setWeekNumber] = useState();

  const items = [
    {
      key: 1,
      label: (
        <div onClick={() => actionWeekTemplate("copy", 1)}>Copy Week 1</div>
      ),
    },
    {
      key: 2,
      label: (
        <div onClick={() => actionWeekTemplate("copy", 2)}>Copy Week 2</div>
      ),
    },
    {
      key: 3,
      label: (
        <div onClick={() => actionWeekTemplate("copy", 3)}>Copy Week 3</div>
      ),
    },
    {
      key: 4,
      label: (
        <div onClick={() => actionWeekTemplate("copy", 4)}>Copy Week 4</div>
      ),
    },
    {
      key: 5,
      danger: true,
      label: <div onClick={() => actionWeekTemplate("clear")}>Clear Week</div>,
    },
  ];

  useEffect(() => {
    if (!flag) return;
    dispatch(getStaffsThunk());
    if (hasAccess(settingsApiKeys.GET_PHARMACY_SETTINGS))
      dispatch(getPharmacySettingsThunk());
    if (hasAccess(branchApiKeys.GET_TEMPLATE_LIST))
      dispatch(
        getBranchTemplateThunk({
          id: branchId,
        })
      );
    dispatch(
      getRolesThunk({
        group: ["staff"],
        type: "Default",
      })
    );
    setFlag(false);
  }, [dispatch, branchId, flag]);

  useEffect(() => {
    if (!startDate) return;
    if (startDate && indefinite) {
      form.setFieldsValue({
        endDate: dayjs(startDate).add(
          pharmacySettings?.planningPeriod,
          "weeks"
        ),
      });
      return;
    }
    form.setFieldsValue({
      endDate: startDate,
    });
  }, [startDate, indefinite]);

  const handleAddShift = (operatingTime, date, id, data, day, week) => {
    if (!id) {
      setSelectedId(branchId);
    } else {
      setSelectedId(id);
    }
    setSelectedWeek(week);
    setSelectedDay(day);
    setShowAddshiftModal(!showAddShiftModal);
    setOperatingTime(operatingTime);
    setSelectedDate(date);
    setSelectedData(data);
  };

  const onDelete = (data) => {
    dispatch(
      deleteTemplateShiftThunk({
        branchId,
        shiftId: data.id,
      })
    ).then((res) => {
      if (res.payload === "success") {
        setFlag(true);
        setShowAddshiftModal(false);
      }
    });
  };

  const actionWeekTemplate = (type, number) => {
    const data = {
      name: type,
      value: number ?? null,
      weekNumber,
      branchId,
    };

    dispatch(
      actionTemplateWeekThunk({
        id: branchId,
        data,
      })
    ).then((res) => {
      if (res.payload) {
        setFlag(true);
      }
    });
  };

  const publishTemplate = (values) => {
    const data = {
      startDate: dayjs(values.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(values.endDate).format("YYYY-MM-DD"),
      indefinite: values.indefinite,
      branchId: branchId,
    };
    dispatch(
      publishTemplateThunk({
        id: branchId,
        data,
      })
    ).then((res) => {
      if (res.payload.code === "validation") {
        setShowErrorModal(true);
        setErrorData(res.payload);
      }
      if (res.payload) {
        setShowPublishTemplateModal(false);
        form.resetFields();
        setFlag(true);
      }
    });
  };

  const [updateData, setUpdatedDate] = useState()

  useEffect(() => {
    if (!branchTemplate) return
    let data = []
    for (var m = moment(previewMonth, 'YYYY-MM-DD').startOf('month'); m.isSameOrBefore(moment(previewMonth, 'YYYY-MM-DD').endOf('month')); m.add(1, 'day')) {
      const date = m.format('YYYY-MM-DD')
      const day = m.format('dddd')
      const weekNumber = m.isoWeek() - moment(m).startOf('month').isoWeek() + 1
      if (weekNumber === 1) {
        data.push({
          startDate: date,
          endDate: date,
          ...branchTemplate.week1?.events?.find((d) => (d.day.toUpperCase() === day.toUpperCase()))
        })
      } else if (m.isoWeek() - moment(m).startOf('month').isoWeek() + 1 === 2) {
        data.push({
          startDate: date,
          endDate: date,
          ...branchTemplate.week2?.events?.find((d) => (d.day.toUpperCase() === day.toUpperCase()))
        })
      } else if (m.isoWeek() - moment(m).startOf('month').isoWeek() + 1 === 3) {
        data.push({
          startDate: date,
          endDate: date,
          ...branchTemplate.week3?.events?.find((d) => (d.day.toUpperCase() === day.toUpperCase()))
        })
      } else if (m.isoWeek() - moment(m).startOf('month').isoWeek() + 1 === 4) {
        data.push({
          startDate: date,
          endDate: date,
          ...branchTemplate.week4?.events?.find((d) => (d.day.toUpperCase() === day.toUpperCase()))
        })
      } else if (m.isoWeek() - moment(m).startOf('month').isoWeek() + 1 === 5) {
        data.push({
          startDate: date,
          endDate: date,
          ...branchTemplate.week1?.events?.find((d) => (d.day.toUpperCase() === day.toUpperCase()))
        })
      }
      else {
        data.push({
          startDate: date,
          endDate: date,
          ...branchTemplate.week2?.events?.find((d) => (d.day.toUpperCase() === day.toUpperCase()))
        })
      }
    }
    setUpdatedDate(data)
  }, [branchTemplate, previewMonth])

  return (
    <div className="weekly-view-container" style={{ height: "100%" }}>
      <ConfirmModal
        backdrop="static"
        title="Hold up!"
        open={showErrorModal}
        confirmButtonText="Close"
        image={infoIcon}
        onConfirm={() => {
          setShowErrorModal(false)
          setFlag(true)
        }}
      >
        <div className="d-flex flex-column gap-3">
          <div>
            There seem to be a few conflicts within this schedule attempt.
            However, we've created shifts for dates prior to and following the
            date(s) of conflict.
          </div>
          <div>
            You can resolve these conflicts and schedule again for the
            following days,
          </div>
        </div>
        <div className="d-flex flex-column gap-3 align-items-center">
          {errorData?.array?.map((data, idx) => (
            <div className="d-flex align-items-center gap-2" key={idx}>
              <div style={{ whiteSpace: "nowrap" }}>{data.date}</div>
              <div style={{ border: '1px solid', height: 20 }} />
              <div>{data.day[0].toUpperCase() + data.day.slice(1)}</div>
              <div style={{ border: '1px solid', height: 20 }} />
              <div>{data.reason}</div>
            </div>
          ))}
        </div>
        {errorData?.successCount > 0 && (
          <div>
            {errorData?.successCount} Shifts/Jobs Were Scheduled Successfully
          </div>
        )}
      </ConfirmModal>
      {showPublishTemplateModal && (
        <ConfirmModal
          title="Publish"
          description={`Select a starting and ending date for the schdeule you just created.`}
          formId={formId}
          open={showPublishTemplateModal}
          confirmButtonText="Publish"
          cancelButtonText="Cancel"
          onClose={() => setShowPublishTemplateModal(false)}
        >
          <div className="d-flex flex-column gap-3">
            <Form
              form={form}
              id={formId}
              layout="vertical"
              autoComplete="off"
              onFinish={publishTemplate}
            >
              <div
                className="d-flex gap-3 align-items-center"
                style={{ width: "100%" }}
              >
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  style={{ width: "100%" }}
                  rules={[
                    { required: true, message: "Please Enter Start Date!" },
                  ]}
                >
                  <DatePicker
                    inputReadOnly
                    placeholder="Start Date"
                    disabledDate={(current) => {
                      return dayjs().add(-1, 'day') >= current;
                    }}
                    format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    className="text-input-field p-2 pe-3 t1"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  label="End Date"
                  style={{ width: "100%" }}
                  name="endDate"
                >
                  <DatePicker
                    inputReadOnly
                    disabledDate={(current) => {
                      return dayjs(startDate).add(-1, 'day') >= current;
                    }}
                    disabled={indefinite}
                    placeholder="End Date"
                    format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    className="text-input-field p-2 pe-3 t1"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </div>
              <Form.Item
                style={{ width: "100%" }}
                name="indefinite"
                valuePropName="checked"
              >
                <Checkbox>Indefinite</Checkbox>
              </Form.Item>
            </Form>
          </div>
        </ConfirmModal>
      )}
      {showAddShiftModal && (
        <ShiftJobFormUpdated
          onSuccess={() => setFlag(true)}
          isTemplate
          onDelete={
            hasAccess(branchApiKeys.DELETE_TEMPLATE) ? onDelete : undefined
          }
          weekNumber={selectedWeek}
          selectedDay={selectedDay}
          branchId={selectId}
          operatingTime={operatingTime}
          selectedDate={selectedDate}
          showModal={showAddShiftModal}
          setHandleModal={() => {
            setShowAddshiftModal(!showAddShiftModal);
            setSelectedDay(undefined);
          }}
          data={selectedData}
        />
      )}
      <Toolbar
        publishTemplateHandler={
          hasAccess(branchApiKeys.PUBLISH_TEMPLATE)
            ? () => setShowPublishTemplateModal(true)
            : undefined
        }
        showPreviewBtnHandler={() => setShowPreviewModal(true)}
        primaryText="Template"
      />
      <div className="week-view-wraper" style={{ height: "auto" }}>
        <table
          cellSpacing={0}
          cellPadding={0}
          style={{ width: "100%", tableLayout: "fixed" }}
        >
          <thead style={{ height: "58px" }}>
            <tr className="" style={{ height: "100%", width: "100%" }}>
              {weekDays?.map((day) => {
                return (
                  <th
                    key={day}
                    className="week-day-heading-cell"
                    style={{ verticalAlign: "middle" }}
                  >
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <span className="s1">
                        {day.charAt(0).toUpperCase() + day.slice(1)}
                      </span>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody style={{ height: "100%", width: "100%" }}>
            <tr style={{ height: "40px" }}>
              <td colspan="7">
                <div
                  style={{ width: "100%" }}
                  className="my-3 d-flex align-items-center justify-content-between"
                >
                  <div className="p3 ms-2">Week 1</div>
                  <div>
                    {hasAccess(branchApiKeys.HANDLE_TEMPLATE_ACTION) && (
                      <Dropdown
                        getPopupContainer={(trigger) => trigger.parentElement}
                        placement="bottomRight"
                        overlayStyle={{ zIndex: 20000 }}
                        menu={{ items: items.filter((i) => i.key !== 1) }}
                        trigger={["click"]}
                      >
                        <img
                          src={colon}
                          alt=""
                          onClick={() => setWeekNumber(1)}
                          style={{ marginRight: "8px", cursor: "pointer" }}
                        />
                      </Dropdown>
                    )}
                  </div>
                </div>
              </td>
            </tr>

            <tr className=" table-row">
              {branchTemplate?.week1?.events?.map((day, i) => {
                return (
                  <td key={i} className="week-schedular-slot-container">
                    <BranchItemCell
                      week={1}
                      isTemplate
                      addShift
                      branchName={branchInfo?.title}
                      handleAddShift={handleAddShift}
                      day={{ ...day, active: branchInfo.active }}
                      operatingTime={branchInfo?.operatingTime}
                    />
                  </td>
                );
              })}
            </tr>
            <tr style={{ height: "40px" }}>
              <td colspan="7">
                <div
                  style={{ width: "100%" }}
                  className="my-3 d-flex align-items-center justify-content-between"
                >
                  <div className="p3 ms-2">Week 2</div>
                  <div>
                    {hasAccess(branchApiKeys.HANDLE_TEMPLATE_ACTION) && (
                      <Dropdown
                        getPopupContainer={(trigger) => trigger.parentElement}
                        placement="bottomRight"
                        overlayStyle={{ zIndex: 20000 }}
                        menu={{ items: items.filter((i) => i.key !== 2) }}
                        trigger={["click"]}
                      >
                        <img
                          onClick={() => setWeekNumber(2)}
                          src={colon}
                          alt=""
                          style={{ marginRight: "8px", cursor: "pointer" }}
                        />
                      </Dropdown>
                    )}
                  </div>
                </div>
              </td>
            </tr>

            <tr className=" table-row">
              {branchTemplate?.week2?.events?.map((day, i) => {
                return (
                  <td key={i} className="week-schedular-slot-container">
                    <BranchItemCell
                      week={2}
                      addShift
                      branchName={branchInfo?.title}
                      isTemplate
                      handleAddShift={handleAddShift}
                      day={{ ...day, active: branchInfo.active }}
                      operatingTime={branchInfo?.operatingTime}
                    />
                  </td>
                );
              })}
            </tr>
            <tr style={{ height: "40px" }}>
              <td colspan="7">
                <div
                  style={{ width: "100%" }}
                  className="my-3 d-flex align-items-center justify-content-between"
                >
                  <div className="p3 ms-2">Week 3</div>
                  <div>
                    {hasAccess(branchApiKeys.HANDLE_TEMPLATE_ACTION) && (
                      <Dropdown
                        getPopupContainer={(trigger) => trigger.parentElement}
                        placement="bottomRight"
                        overlayStyle={{ zIndex: 20000 }}
                        menu={{ items: items.filter((i) => i.key !== 3) }}
                        trigger={["click"]}
                      >
                        <img
                          onClick={() => setWeekNumber(3)}
                          src={colon}
                          alt=""
                          style={{ marginRight: "8px", cursor: "pointer" }}
                        />
                      </Dropdown>
                    )}
                  </div>
                </div>
              </td>
            </tr>

            <tr className=" table-row">
              {branchTemplate?.week3?.events?.map((day, i) => {
                return (
                  <td key={i} className="week-schedular-slot-container">
                    <BranchItemCell
                      week={3}
                      isTemplate
                      branchName={branchInfo?.title}
                      addShift
                      handleAddShift={handleAddShift}
                      day={{ ...day, active: branchInfo.active }}
                      operatingTime={branchInfo?.operatingTime}
                    />
                  </td>
                );
              })}
            </tr>
            <tr style={{ height: "40px" }}>
              <td colspan="7">
                <div
                  style={{ width: "100%" }}
                  className="my-3 d-flex align-items-center justify-content-between"
                >
                  <div className="p3 ms-2">Week 4</div>
                  <div>
                    {hasAccess(branchApiKeys.HANDLE_TEMPLATE_ACTION) && (
                      <Dropdown
                        getPopupContainer={(trigger) => trigger.parentElement}
                        placement="bottomRight"
                        overlayStyle={{ zIndex: 20000 }}
                        menu={{ items: items.filter((i) => i.key !== 4) }}
                        trigger={["click"]}
                      >
                        <img
                          onClick={() => setWeekNumber(4)}
                          src={colon}
                          alt=""
                          style={{ marginRight: "8px", cursor: "pointer" }}
                        />
                      </Dropdown>
                    )}
                  </div>
                </div>
              </td>
            </tr>

            <tr className=" table-row">
              {branchTemplate?.week4?.events?.map((day, i) => {
                return (
                  <td key={i} className="week-schedular-slot-container">
                    <BranchItemCell
                      week={4}
                      isTemplate
                      addShift
                      handleAddShift={handleAddShift}
                      branchName={branchInfo?.title}
                      day={{ ...day, active: branchInfo.active }}
                      operatingTime={branchInfo?.operatingTime}
                    />
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>

      <Modal
        centered
        open={showPreviewModal}
        width={1000}
        onCancel={() => setShowPreviewModal(false)}
        footer={<></>}
      >
        <div className="d-flex gap-3 align-items-center">
          <div className="s3">Month of: </div>
          <DatePicker
            inputReadOnly
            style={{ maxWidth: 200 }}
            allowClear={false}
            className="text-input-field border p-1 t1"
            bordered={false}
            value={dayjs(previewMonth)}
            onChange={(value => {
              setPreviewMonth(value.format('YYYY-MM-DD'))
            })}
            format='MMMM, YYYY'
            picker={'month'}
            suffixIcon={<></>}
            showToday={false}
          />
        </div>
        <MonthlyView
          operatingTime={branchInfo?.operatingTime}
          scheduleData={{
            title: branchInfo?.title,
            events: updateData
          }}
          isPreview
          active={branchInfo.active}
        />
      </Modal >

    </div>
  );
};
