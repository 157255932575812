import React from "react";
import moment from "moment";
import "./style.scss";
import { BranchItemCell } from "./week-items/BranchItem";
import { EmptyData } from "../../comon/EmptyData";
import blankList from "../../../assets/img/blankList.png";
import { useNavigate } from "react-router-dom";

export default function WeeklyView({
  weekArray,
  addShift,
  onDelete,
  branches,
  handleAddShift,
}) {
  const navigate = useNavigate()
  return (
    <div className="weekly-view-container" style={{ padding: '0px 24px' }}>
      <div className="week-view-wraper">
        <table
          cellSpacing={0}
          cellPadding={0}
          style={{ width: "100%", tableLayout: 'fixed' }}
        >
          <thead style={{ height: "58px" }}>
            <tr
              className=""
              style={{
                height: "100%",
                width: "100%",
                borderBottom: "1px solid #e9e9e9",
              }}
            >
              <th className="sticky-col first-col branch-header">
                <div className="branch-name-heading">
                  <span className="heading5">All Branch</span>
                  <span className="t2 badge mb-1">{branches.length}</span>
                </div>
              </th>

              {weekArray?.length <= 7 &&
                weekArray?.map((day) => {
                  return (
                    <th key={day} className="week-day-heading-cell" style={{ verticalAlign: "middle" }}>
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <span className="s1" style={{ fontWeight: "bold" }}>{moment(day).format("dddd")}</span>
                        <span className="s2" style={{ color: "#7B7B7B" }}>
                          {moment(day).format("Do MMMM")}
                        </span>
                      </div>
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody style={{ height: "100%", width: "100%" }}>
            {branches?.length > 0 ? (
              <>
                {branches?.map((branch, i) => {
                  return (
                    <tr
                      key={i}
                      className=" table-row weeklyTableView"
                      style={{
                        height: "130px",
                        borderBottom: "1px solid #e9e9e9",
                      }}
                    >
                      <td className=" sticky-col first-col border-end" style={{ height: "130px" }}>
                        <div
                          className={`branch-name-cell`}
                          style={{ height: "100%" }}
                          onClick={() => navigate(`/branches/${branch?.id}`)}
                        >
                          <div className="row-1">
                            <span className="t2"> # {branch.id} </span>
                            {
                              branch.region &&
                              <>
                                <div
                                  style={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: "50%",
                                    margin: "0px 3px 0px 8px",
                                    background: "grey",
                                  }}
                                />
                                <span className="t2"> {branch.region || "-"} </span>
                              </>
                            }
                          </div>
                          <span className="p3" style={{ fontWeight: "bold" }}>{branch.title} </span>
                          <span className="t2 branch-status " style={{ backgroundColor: branch.active ? '#e8f5e9' : '#ffebee' }}> {branch.active ? 'Open' : 'Closed'} </span>
                        </div>
                      </td>
                      {branch.events?.map((day, i) => {
                        return (
                          <td
                            key={i}
                            className="week-schedular-slot-container"
                            style={{ height: "130px" }}
                          >
                            <BranchItemCell
                              viewDetails
                              addShift
                              onDelete={onDelete}
                              handleAddShift={handleAddShift}
                              branchName={branch.title}
                              branchId={branch.id}
                              day={{ ...day, active: branch.active }}
                              operatingTime={branch.operatingTime}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                <tr>
                  <td colSpan={8}>
                    <EmptyData
                      //actionBtn
                      img={blankList}
                      title="Branches"
                      description="We cant find any of your branches, head over to the branches section to add your first branch!"
                    />
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
