import cookies from "browser-cookies";
import axios from "axios";
import { store } from "../redux";
import { startLoading, stopLoading } from "../redux/loader";
import { notify } from "../utils/helper";
import jwt from "jwt-decode";
import { logOutUserThunk, setAccessToken } from "../redux/auth/authThunk";
import { refreshToken } from "../redux/auth/authAPI";
import { removeAuthToken } from "../redux/auth/authSlicer";

// const baseURL = "http://backend.wearelopic.com/api";
// const baseURL = "https://locum-dev-api.softermii.co/api/"
const baseURL = "https://backend.lopic.io/api/"
let numberOfAjaxCAllPending = 0;

export const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 60000,
});
API.interceptors.request.use(
  (config) => {
    const accessToken = cookies.get("accessToken");
    numberOfAjaxCAllPending++;
    if (config.loader !== false) {
      store.dispatch(startLoading());
    }
    if (!config.headers.Authorization) {
      config.headers.Authorization = accessToken;
    }
    return config;
  },
  (error) => {
    store.dispatch(stopLoading());
    notify("error", error.message);
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    numberOfAjaxCAllPending--;
    if (numberOfAjaxCAllPending === 0) {
      store.dispatch(stopLoading());
    }
    return response;
  },
  async (error) => {
    numberOfAjaxCAllPending--;
    if (numberOfAjaxCAllPending === 0) {
      store.dispatch(stopLoading());
    }
    const originalRequest = error.config;
    if (error.response.status === 401 && error.response.data.code === "Invalid token" && !originalRequest._retry) {
      originalRequest._retry = true;
      return refreshToken().then(
        (res) => {
          originalRequest.headers.Authorization = `${res.data.data.access}`;
          cookies.set('accessToken', res.data.data.access)
          return axios(originalRequest);
        },
        (error) => {
          cookies.erase('accessToken');
          cookies.erase('refreshToken');
          return store.dispatch(removeAuthToken());
        }
      )
    }
    else if (error.response.status === 401 && error.response.data.code === "user_deactivated") {
      notify("error", error.response.data.message ?? error.response.data.Message);
      store.dispatch(removeAuthToken());
      cookies.erase('accessToken');
      cookies.erase('refreshToken');
    }
    else if (error?.response?.data?.code === "permission_denied") {
      const accessToken = cookies.get("accessToken");
      const data = jwt(accessToken) ?? null;
      if (
        data.role !== store.getState().auth?.user.role
      ) {
        await store.dispatch(logOutUserThunk());
      }
    } else {
      notify("error", error.response.data.message ?? error.response.data.Message);
    }
    return Promise.reject(error);
  }
);
