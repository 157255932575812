import React, { memo, useEffect, useState } from "react";
import { useTable, usePagination, useRowSelect } from "react-table";
import Table from "react-bootstrap/Table";
import "./style.scss";
import { Icon } from "semantic-ui-react";
import Select from "react-select";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, disabled, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <label className="container">
        <input
          disabled={disabled ? true : false}
          type="checkbox"
          ref={resolvedRef}
          onClick={(e) => e.stopPropagation()}
          {...rest}
        />
        <span className="checkmark" onClick={(e) => e.stopPropagation()}></span>
      </label>
    );
  }
);

IndeterminateCheckbox.displayName = "IndeterminateCheckbox";

const colourStyles = {
  control: (styles) => {
    return {
      ...styles,
      border: "none",
      ":active": {
        ...styles[":active"],
        border: "none",

      },
    };
  },
  dropdownIndicator: (styles) => {
    return {
      ...styles,
      padding: "2px",
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      padding: "2px",
    };
  },


};

const options = [
  { id: 1, value: 10, label: "10" },
  { id: 2, value: 20, label: "20" },
  { id: 3, value: 30, label: "30" },
  { id: 4, value: 40, label: "40" },
  { id: 5, value: 50, label: "50" },
  { id: 6, value: 100, label: "100" },
];

export const TableComponet = memo(
  ({
    columns,
    data,
    height,
    maxPageCount,
    currentPageCount,
    perPageCount,
    totalDataCount,
    setCurrentPage,
    setPerPageCount,
    showPagination,
    showRowSelection,
    setSelectedRows,
    isBorderShown,
    formatRowProps,
    setShowMultiSelectBtn,
    rowCursor,
    disableSelectionBy
  }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      tableHeight,
      prepareRow,
      page,
      setPageSize,
      selectedFlatRows,
    } = useTable(
      {
        columns,
        data,
        height,
      },
      usePagination,
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns) => {
          const selectionColumn = {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox
                  disabled={row.original[disableSelectionBy] ? row.original[disableSelectionBy] : false}
                  {...row.getToggleRowSelectedProps()}
                />
              </div>
            )
          };
          if (showRowSelection) {
            columns.unshift(selectionColumn);
            return columns;
          }
          return columns;
        });
      }
    );

    const [jumpPageOptions, setJumpPageOptions] = useState([]);

    useEffect(() => {
      if (!perPageCount) return;
      setPageSize(perPageCount);
    }, [perPageCount, setPageSize]);

    useEffect(() => {
      if (!selectedFlatRows.length && !setSelectedRows && !setShowMultiSelectBtn)
        return;
      let result = selectedFlatRows?.map(({ original }) => original.id);
      setSelectedRows && setSelectedRows(result);
      setShowMultiSelectBtn &&
        (selectedFlatRows.length
          ? setShowMultiSelectBtn(true)
          : setShowMultiSelectBtn(false));
    }, [selectedFlatRows]);

    useEffect(() => {
      if (!maxPageCount) return;
      var list = [];
      for (var i = 1; i <= maxPageCount; i++) {
        const obj = {
          value: i,
          label: i,
        };
        list.push(obj);
      }
      setJumpPageOptions(list);
    }, [maxPageCount, currentPageCount, perPageCount]);

    const nextPage = () => {
      setCurrentPage(
        currentPageCount + 1 <= maxPageCount
          ? currentPageCount + 1
          : currentPageCount
      );
    };

    const prevPage = () => {
      setCurrentPage(
        currentPageCount - 1 > 0 ? currentPageCount - 1 : currentPageCount
      );
    };

    return (
      <div className="table-fix-header" style={{ overflow: `${height ? 'auto' : 'inherit'}`, height: height }}>
        <Table
          borderless
          className={`
            ${isBorderShown && "border"} ${showPagination ? "border-bottom-0" : ""
            }  main-table rounded-top `}
          {...getTableProps()}
        >
          <thead className="">
            {headerGroups.map((headerGroup, idx) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                {headerGroup.headers.map((column, idx) => (
                  <th {...column.getHeaderProps()} key={idx}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} >
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps(formatRowProps && formatRowProps(row))}
                  key={i}
                  style={{ cursor: rowCursor }}
                >
                  {row.cells.map((cell, idx) => {
                    return (
                      <td key={idx} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {showPagination && (
          <div className="d-flex justify-content-between border border-top-0 rounded-bottom table-paginations">
            <div className="d-flex p-2">
              <span className="py-2 ps-4 p2">Item Per Page:</span>
              <div
                className="  border-end-2 pe-4"
                style={{ borderRight: "1px solid #E9E9E9" }}
              >
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder="Jump"
                  menuPlacement="top"
                  closeMenuOnSelect
                  options={options}
                  defaultValue={options[0]}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#D9D9D9",
                    },
                  })}
                  onChange={({ value }) => {
                    setCurrentPage(
                      Math.ceil(
                        (currentPageCount * perPageCount - perPageCount + 1) /
                        value
                      )
                    );
                    setPerPageCount(value);
                  }}
                  styles={colourStyles}
                />
              </div>
              <span className="py-2 px-4" style={{ color: "#9D9D9D" }}>
                {currentPageCount === 1
                  ? 1
                  : currentPageCount * perPageCount - perPageCount + 1}{" "}
                -
                {currentPageCount * perPageCount > totalDataCount
                  ? totalDataCount
                  : currentPageCount * perPageCount}{" "}
                of {totalDataCount} items
              </span>
            </div>
            <div className="d-flex p-2" >
              <Select
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Jump"
                menuPlacement="top"
                closeMenuOnSelect
                defaultOptions={true}
                options={jumpPageOptions}
                value={jumpPageOptions[currentPageCount - 1]}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#D9D9D9",
                  },
                })}
                onChange={({ value }) => setCurrentPage(value)}
                styles={colourStyles}
              />
              <span
                className="py-2 pe-4  border-end-2"
                style={{ color: "#9D9D9D", borderRight: "1px solid #E9E9E9" }}
              >
                of {maxPageCount} pages
              </span>
              <button
                disabled={currentPageCount === 1 ? true : false}
                className="py-2 px-3 border-end-2 border-0 cursor-pointer"
                style={{
                  backgroundColor: "transparent",
                  borderRight: "1px solid #E9E9E9",
                }}
                onClick={prevPage}
              >
                <Icon disabled name="angle left" />
              </button>
              <button
                disabled={currentPageCount === maxPageCount ? true : false}
                className="py-2 px-3 cursor-pointer border-0"
                style={{ backgroundColor: "transparent" }}
                onClick={nextPage}
              >
                <Icon disabled name="angle right" />
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
);


TableComponet.displayName = "TableComponent";
