import { Form, Input, Select } from "antd";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  createRegionThunk,
  editRegionThunk,
} from "../../redux/regions/regionsThunk";
import { regionApiKeys } from "../../utils/apiKeyConstants";
import { hasAccess } from "../../utils/helper";
import { AddModal } from "../UI/modals/addModal";

export const RegionForm = ({
  requestType,
  selectedRegion,
  regionBranchesOptions,
  showCreateModal,
  handleModalToggle,
  regionId,
}) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const formId = useMemo(() => "regionForm", []);
  const [btndisabled, setbtndisabled] = useState(true);

  const onValuesChange = useCallback((changedValues, allValues) => {
    if (allValues.title !== undefined && allValues.title !== "") {
      setbtndisabled(false);
    } else {
      setbtndisabled(true);
    }
  }, []);

  useEffect(() => {
    if (!selectedRegion) return;
    form.setFieldsValue({
      title: selectedRegion?.title,
      branchIds: selectedRegion.branchIds,
    });
  }, [selectedRegion]);

  const onFinish = useCallback(
    (values) => {
      if (requestType === "Add") {
        dispatch(createRegionThunk(values)).then((res) => {
          if (res.payload) {
            const thirdArray = regionBranchesOptions.filter((elem) => {
              return res.payload.branches?.some((ele) => {
                return ele.id === elem.value
              });
            }).map(d => d.label)

            window.analytics.track('Region Added', {
              title: res.payload.title,
              branches: thirdArray,
            });
            handleModalToggle();
            form.resetFields();
          }
        });
      }

      if (requestType === "Edit") {
        dispatch(editRegionThunk({ id: regionId, payload: values })).then(
          (res) => {
            if (res.payload) {
              handleModalToggle();
              form.resetFields();
            }
          }
        );
      }
    },
    [dispatch, form, handleModalToggle, regionBranchesOptions, regionId, requestType]
  );

  return (
    <AddModal
      title={`${requestType} Region`}
      handleModal={showCreateModal}
      setHandleModal={handleModalToggle}
      primaryButtonText={
        requestType === "Edit" && hasAccess(regionApiKeys.UPDATE_REGION)
          ? `${requestType} Region`
          : requestType === "Add" && hasAccess(regionApiKeys.CREATE_REGION)
            ? `${requestType} Region`
            : undefined
      }
      secondaryButtonText="Cancel"
      disabledPrimaryBtn={btndisabled}
      secondaryButtonHandler={handleModalToggle}
      formId={formId}
    >
      <Form
        id={formId}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          label="Region Title"
          name="title"
          rules={[
            { required: true, message: "Please input your Region Title!" },
          ]}
        >
          <Input
            maxLength={50}
            onBlur={(e) =>
              form.setFieldsValue({ title: e.target.value.trim() })
            }
            placeholder="Region Title"
            className="text-input-field p-2 pe-3 t1"
          />
        </Form.Item>
        <Form.Item name="branchIds" label="Branches">
          <Select
            showSearch
            mode="multiple"
            optionFilterProp="label"
            dropdownStyle={{ zIndex: 2000 }}
            placeholder="Branches"
            bordered={false}
            className="text-input-field pt-1 t1"
          >
            {regionBranchesOptions.map((data, index) => (
              <Select.Option key={index} value={data.value}>
                {data.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </AddModal>
  );
};
