import React, { useState, useRef, useEffect } from "react";
import "./style.scss";
import { SlotDetailModal } from "../Slot-detail-component/SlotDetail";
import { MoreDetailModal } from "../Slot-detail-component/MoreDetail/MoreDetailModal";
import lock from "../../../../assets/icons/lock.png";
import addBtn from '../../../../assets/img/addButton.png'
import { hasAccess } from "../../../../utils/helper";
import { branchApiKeys } from "../../../../utils/apiKeyConstants";
import { rolesColor } from "../../SchedularToolbar";
import { Checkbox } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import warningIcon from "../../../../assets/icons/warningIcon.png";
import { staffAbsencesType } from "../../../../utils/constant";
import { UserProfileModal } from "../../../comon/userProfileModal";
import { useNavigate } from "react-router-dom";

export const useOutsideAlerter = (ref, setFunction) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setFunction(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
};

export const BranchItemCell = ({
  day,
  handleAddShift,
  operatingTime,
  onDelete,
  branchName,
  branchId,
  dayNumber,
  addShift,
  viewDetails,
  locumFlow,
  week,
  showDateCheckbox,
  onDaySelect,
  isTemplate,
  isPreview,
}) => {
  const [showUserProfileModal, setShowUserProfileModal] = useState(false)
  const navigate = useNavigate()
  const ref = useRef(null);
  const refPopover = useRef(null);
  const moreDetailRef = useRef(null);

  const [isSlotModal, setSlotDetailModal] = useState(false);
  const [target, setTarget] = useState(null);
  const [MoreDetailtarget, setMoreDetailTarget] = useState(null);
  const [isMoreDetail, setMoreDetail] = useState(false);
  useOutsideAlerter(refPopover, setSlotDetailModal);
  useOutsideAlerter(moreDetailRef, setMoreDetail);

  const [selectedShift, setSelectedShift] = useState();

  if (day?.isEmpty && day.staffLeave) {
    return (
      <>
        <div
          className="week-day-detail-cell-closed d-flex flex-column gap-1 justify-content-between"
          style={{ borderBottom: "none" }}
        >
          <div>
            {dayNumber && (
              <div className="d-flex align-items-center justify-content-between month-view-flex" >
                <span className=""> {moment(dayNumber).format('DD')}</span>
                {showDateCheckbox && !dayjs(day.startDate).isBefore(dayjs(), "days") && <Checkbox onChange={e => onDaySelect(e.target.checked, dayNumber)} />}
              </div>
            )}
          </div>
          <div className="d-flex flex-column align-items-center gap-2">
            <img src={warningIcon} alt="" />
            <span className="t2">{staffAbsencesType[day.staffLeave]}</span>
          </div>
          <div></div>
        </div>
      </>
    );
  }
  if (!day?.active && !locumFlow) {
    return (
      <>
        <div
          className="week-day-detail-cell-closed d-flex flex-column gap-1 justify-content-between cursor-pointer"
          style={{ borderBottom: "none" }}
          onClick={() => navigate(`/profile/subscription`)}
        >
          <div>
            {dayNumber && (
              <div className="d-flex align-items-center justify-content-between month-view-flex" >
                <span className=""> {moment(dayNumber).format('DD')}</span>
                {showDateCheckbox && !dayjs(day.startDate).isBefore(dayjs(), "days") && <Checkbox onChange={e => onDaySelect(e.target.checked, dayNumber)} />}
              </div>
            )}
          </div>
          <div className="d-flex flex-column align-items-center gap-2">
            <img src={warningIcon} alt="" />
            <span className="t2">Billing Not Active</span>
          </div>
          <div></div>
        </div>
      </>
    );
  }
  else if (day?.branchClosed) {
    return (
      <>
        <div
          className="week-day-detail-cell-closed d-flex flex-column gap-1 justify-content-between cursor-pointer"
          style={{ borderBottom: "none" }}
          onClick={() => navigate(`/branches/${branchId}/details/information?tab=branch-hours`)}
        >
          <div>
            {dayNumber && (
              <div className="d-flex align-items-center justify-content-between month-view-flex" >
                <span className=""> {moment(dayNumber).format('DD')}</span>
                {showDateCheckbox && !dayjs(day.startDate).isBefore(dayjs(), "days") && <Checkbox onChange={e => onDaySelect(e.target.checked, dayNumber)} />}
              </div>
            )}
          </div>
          <div className="d-flex flex-column align-items-center gap-2">
            <img src={lock} alt="" />
            <span className="t2">Closed</span>
          </div>
          <div></div>
        </div>
      </>
    );
  } else
    return (
      <>
        {
          showUserProfileModal &&
          <UserProfileModal
            showUserProfileModal={showUserProfileModal}
            selectedUser={selectedShift?.staff.length ? selectedShift?.staff[0] : selectedShift?.staff}
            setShowUserProfileModal={setShowUserProfileModal}
          />
        }
        <div className="d-flex flex-column" style={{ height: "100%" }}>
          <div>
            {dayNumber && (
              <div className="d-flex align-items-center justify-content-between month-view-flex ">
                <span className=""> {moment(dayNumber).format('DD')}</span>
                {showDateCheckbox && !dayjs(day.startDate).isBefore(dayjs(), "days") && <Checkbox onChange={e => onDaySelect(e.target.checked, dayNumber)} />}
              </div>
            )}
          </div>
          {day.shifts?.length ? (
            <div className="week-day-detail-cell">
              {day.shifts.map(
                (data, idx) =>
                  idx < 2 && (
                    <div
                      key={idx}
                      ref={ref}
                      className="branch-detail-row"
                      onClick={(e) => {
                        if (viewDetails) {
                          setSlotDetailModal(!isSlotModal)
                        }
                        if (isTemplate) {
                          handleAddShift(operatingTime, day?.startDate, branchId, data, day?.day, week)
                        }
                        setSelectedShift(data);
                        setTarget(e.target);
                      }}
                    >
                      <div
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          marginRight: 8,
                          background: rolesColor[data?.role ? data?.role : data?.roleType?.id ? data?.roleType?.id : data?.staff?.role],
                        }}
                      />
                      <span className="t2">
                        {" "}
                        {data?.startTime} - {data.endTime}
                      </span>
                    </div>
                  )
              )}
              {day.shifts.length <= 2 && addShift ? (
                <span
                  className="branch-detail-row text-align-center"
                  onClick={() => ((hasAccess(branchApiKeys.CREATE_BULK_JOB) || hasAccess(branchApiKeys.SAVE_SHIFT_BULK)) && handleAddShift(operatingTime, day?.startDate, branchId, undefined, day?.day, week))}
                >
                  <img
                    width={15}
                    className="m-auto"
                    src={addBtn}
                    alt="addBtn"
                  />
                </span>
              ) : (
                day.shifts.length > 2 && (
                  <span
                    className="s2 more-branch-detail-button"
                    ref={ref}
                    onClick={(e) => {
                      setMoreDetail(!isMoreDetail);
                      setMoreDetailTarget(e.target);
                    }}
                  >
                    {day.shifts.length - 2} more
                  </span>
                )
              )}
            </div>
          ) : (
            addShift && (
              <div
                onClick={() => (hasAccess(branchApiKeys.CREATE_BULK_JOB) || hasAccess(branchApiKeys.SAVE_SHIFT_BULK)) && handleAddShift(operatingTime, day?.startDate, branchId, undefined, day?.day, week)}
                className="week-day-detail-cell-no-shift"
              />
            )
          )}
        </div>
        {
          isSlotModal && (
            <div ref={refPopover}>
              <SlotDetailModal
                handleAddShift={handleAddShift}
                operatingTime={operatingTime}
                onDelete={onDelete}
                show={isSlotModal}
                isPreview={isPreview}
                target={target}
                data={selectedShift}
                reference={refPopover}
                setSlotDetailModal={setSlotDetailModal}
                setShowUserProfileModal={setShowUserProfileModal}
              />
            </div>
          )
        }
        {
          isMoreDetail && (
            <div ref={moreDetailRef} className={locumFlow ? 'slotDetailForLocumFlow' : ''}>
              <MoreDetailModal
                locumFlow={locumFlow}
                branchName={branchName}
                branchId={branchId}
                data={day}
                show={isMoreDetail}
                target={MoreDetailtarget}
                isTemplate={isTemplate}
                reference={moreDetailRef}
                setShow={setMoreDetail}
                operatingTime={operatingTime}
                handleAddShift={handleAddShift}
              />
            </div>
          )
        }
      </>
    );
};

