import { groupBy, mapValues, omit } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import DashboardTable from "../../../components/dashboard/dashboardTable";
import { DetailTab } from "../../../components/dashboard/DetailTabs";
import { Heading } from "../../../components/UI/heading";
import { getPendingJobDetailThunk } from "../../../redux/branches/branchesThunk";
import {
  exportGapsCSVThunk,
  getPharmacyDashboardDetailThunk,
  getPharmacyGapsDataThunk,
} from "../../../redux/dashboard/dashboardThunk";
import { RouterConfig } from "../../../routerConfig";

import addItemIcon from "../../../assets/icons/add.png";
import actionBtnArr from "../../../assets/icons/actionBtnArr.png";
import { ShiftJobForm } from "../../../components/comon/form";
import {
  getAccommodationAndFoodRulesThunk,
  getMileageRulesThunk,
  getParkingRulesThunk,
  getTransportRulesThunk,
} from "../../../redux/profile/profileThunk";
import { getRegionsListDropDownThunk } from "../../../redux/regions/regionsThunk";
import {
  getRolesThunk,
  getStaffsThunk,
} from "../../../redux/schedule/scheduleThunk";
import "./style.scss";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { hasAccess } from "../../../utils/helper";
import {
  branchApiKeys,
  staffApiKeys,
  regionApiKeys,
  settingsApiKeys,
  locumShiftApiKeys,
} from "../../../utils/apiKeyConstants";
import { DashboardDateFilter } from "../../../components/dashboard/dashboardDateFilter";
import { ShiftJobFormUpdated } from "../../../components/comon/addShiftForm";
dayjs.extend(isoWeek);

export const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAddShiftModal, setShowAddShiftModal] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [dateRange, setDateRange] = useState();
  const [gapData, setGapData] = useState();
  const [flag, setFlag] = useState(true);

  useEffect(() => {
    setDateRange({
      startDateRange: dayjs().startOf("isoWeek"),
      endDateRange: dayjs().endOf("isoWeek"),
    });
  }, []);

  const { pharmacyDashboardData, pharamcyGapsData, pendingJobApprovalData } =
    useSelector((state) => state.dashboard);
  const pendingJobsColumns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "startDate",
        Cell: ({ row: { original } }) => {
          return moment(original.startDate).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Branch",
        accessor: "branchTitle",
      },
      {
        Header: "Duration",
        accessor: "startTime",
        Cell: ({ row: { original } }) => {
          return original.startTime + " - " + original.endTime;
        },
      },
      {
        Header: "Total Rate",
        accessor: "hourlyRate",
        Cell: ({ row: { original } }) => {
          return `£${original?.hourlyRate}`;
        },
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row: { original } }) => {
          if (hasAccess(locumShiftApiKeys.GET_JOB_BY_ID))
            return (
              <img
                style={{ cursor: "pointer" }}
                src={actionBtnArr}
                alt=""
                onClick={() => {
                  navigate(
                    generatePath(RouterConfig.branchAdminJobPreview, {
                      shiftId: original.id,
                    })
                  );
                }}
              />
            );
        },
      },
    ],
    []
  );

  const brancGapColumns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",

        Cell: ({ row: { original } }) => {
          return moment(original.date).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Branch Name",
        accessor: "branchName",
      },

      {
        Header: "Time Slot",
        accessor: "startTime",
        Cell: ({ row: { original } }) => {
          return original.startTime + " - " + original.endTime;
        },
      },
      {
        Header: "Missing hours",
        accessor: "duration",
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row: { original } }) => {
          if (
            hasAccess(branchApiKeys.CREATE_BULK_JOB) ||
            hasAccess(branchApiKeys.SAVE_SHIFT_BULK)
          )
            return (
              <img
                style={{ cursor: "pointer" }}
                src={addItemIcon}
                alt=""
                onClick={() => {
                  setGapData(original);
                  setShowAddShiftModal(!showAddShiftModal);
                }}
              />
            );
          else return <></>;
        },
      },
    ],
    []
  );

  const ActiveJobsTab = (params) => {
    return (
      <>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ width: "100%" }}
        >
          <span className="heading2 fw-bold">
            {pharmacyDashboardData?.pharmacy_jobs?.find(
              (val) => val.status === "active"
            )?.total_jobs || 0}
          </span>
        </div>
      </>
    );
  };

  const UnfilledJobsTab = (params) => {
    return (
      <>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ width: "100%" }}
        >
          <span className="heading2 fw-bold">
            {pharmacyDashboardData?.pharmacy_jobs?.find(
              (val) => val.status === "unfilled"
            )?.total_jobs || 0}
          </span>
        </div>
      </>
    );
  };

  const ActiveLocums = () => {
    var grouped = mapValues(
      groupBy(
        pharmacyDashboardData?.activeLocums?.locum_jobs,
        "roleType.label"
      ),
      (staffList) => staffList.map((car) => car)
    );
    return (
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ width: "100%" }}
      >
        {Object.values(grouped)?.length > 0 ? (
          <>
            {Object.values(grouped)?.map((val, i) => {
              return val[0]?.roleType?.label === "Locum Technician" ||
                val[0]?.roleType?.label === "Locum Pharmacist" ? (
                <div key={i} className="d-flex align-items-center">
                  <span className="heading2 fw-bold">{val.length}</span>
                  <div
                    className=" t2 breadCrumps-role-detail"
                    style={{ color: "#7B7B7B", marginLeft: "5px" }}
                  >
                    {val[0]?.roleType?.label?.split(" ")[1]}
                  </div>
                </div>
              ) : (
                " "
              );
            })}
          </>
        ) : (
          <>
            <div className="d-flex align-items-center">
              <div className="heading2 fw-bold"> 0 </div>
              <div
                className=" t2 breadCrumps-role-detail"
                style={{ color: "#7B7B7B", marginLeft: "5px" }}
              >
                Pharmacist
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="heading2 fw-bold"> 0 </div>
              <div
                className=" t2 breadCrumps-role-detail"
                style={{ color: "#7B7B7B", marginLeft: "5px" }}
              >
                Technician
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const ActiveStaff = (params) => {
    return (
      <div
        className="d-flex align-items-center justify-content-between "
        style={{ width: "100%", position: "relative" }}
      >
        {staffData?.length > 0 ? (
          <>
            {staffData?.map((val, i) => {
              return val[0].roleTitle === "Staff Pharmacist" ||
                val[0].roleTitle === "Staff Technician" ||
                val[0].roleTitle === "Staff Dispenser" ? (
                <div key={i} className="d-flex align-items-center">
                  <span className="heading2 fw-bold">{val.length}</span>
                  <div
                    className=" t2 breadCrumps-role-detail"
                    style={{ color: "#7B7B7B", marginLeft: "5px" }}
                  >
                    {val[0].roleTitle?.split(" ")[1]}
                  </div>
                </div>
              ) : (
                " "
              );
            })}
          </>
        ) : (
          <>
            <div className="d-flex align-items-center">
              <div className="heading2 fw-bold"> 0 </div>
              <div
                className=" t2 breadCrumps-role-detail"
                style={{ color: "#7B7B7B", marginLeft: "5px" }}
              >
                Pharmacist
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="heading2 fw-bold"> 0 </div>
              <div
                className=" t2 breadCrumps-role-detail"
                style={{ color: "#7B7B7B", marginLeft: "5px" }}
              >
                Technician{" "}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="heading2 fw-bold"> 0 </div>
              <div
                className=" t2 breadCrumps-role-detail"
                style={{ color: "#7B7B7B", marginLeft: "5px" }}
              >
                Dispenser
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const { rows: pendingJobsTableData } = useMemo(
    () => pendingJobApprovalData,
    [pendingJobApprovalData]
  );

  useEffect(() => {
    var grouped = mapValues(
      groupBy(pharmacyDashboardData?.pharmacy_staff?.staff_list, "role"),
      (staffList) => staffList.map((car) => omit(car, "role"))
    );
    setStaffData(Object.values(grouped));
  }, [pharmacyDashboardData]);

  useEffect(() => {
    if (!dateRange || !flag) return;
    dispatch(
      getPharmacyDashboardDetailThunk({
        startDate: dayjs(dateRange?.startDateRange).format("YYYY-MM-DD"),
        endDate: dayjs(dateRange?.endDateRange).format("YYYY-MM-DD"),
      })
    );

    if (hasAccess(branchApiKeys.PHARMACY_GAPS))
      dispatch(
        getPharmacyGapsDataThunk({
          startDateRange: dayjs(dateRange?.startDateRange).format("YYYY-MM-DD"),
          endDateRange: dayjs(dateRange?.endDateRange).format("YYYY-MM-DD"),
        })
      );
    dispatch(
      getPendingJobDetailThunk({
        filters: {
          status: "pending",
          startDate: dayjs(dateRange?.startDateRange).format("YYYY-MM-DD"),
          endDate: dayjs(dateRange?.endDateRange).format("YYYY-MM-DD"),
        },
      })
    );
    setFlag(false);
  }, [dateRange, dispatch, flag]);


  const downloadGapsCSV = () => {
    dispatch(exportGapsCSVThunk({
      startDateRange: dateRange?.startDateRange.format('YYYY-MM-DD'),
      endDateRange: dateRange?.endDateRange.format('YYYY-MM-DD'),
    })).then(res => {
      if (res.payload.status === "success") {
        let fetchDataModified = `data:application/csv;base64,${res.payload.data}`;
        let a = document.createElement('a');
        a.href = fetchDataModified;
        a.download = 'result.csv';
        a.click();
        return;
      }
    })
  }

  useEffect(() => {
    if (hasAccess(settingsApiKeys.GET_SETTINGS_ALL_MILEAGE_RULES))
      dispatch(getMileageRulesThunk());
    if (hasAccess(settingsApiKeys.GET_SETTINGS_PARKING_RULES))
      dispatch(getParkingRulesThunk());
    if (hasAccess(settingsApiKeys.GET_ALL_TRANSPORT_RULES))
      dispatch(getTransportRulesThunk());
    if (hasAccess(settingsApiKeys.GET_ALL_FOOD_RULES))
      dispatch(getAccommodationAndFoodRulesThunk());
    if (hasAccess(regionApiKeys.GET_REGION_DROP_DOWN_LIST))
      dispatch(getRegionsListDropDownThunk());
    dispatch(
      getRolesThunk({
        group: ["staff", "locum"],
        type: "Default",
      })
    );
    if (hasAccess(staffApiKeys.GET_ALL_STAFF)) dispatch(getStaffsThunk());
  }, [dispatch]);

  return (
    <div className="content-view-page details-page">
      <div className="main-wrapper">
        <div className="dashboard-page">
          <Heading
            label="Dashboard"
            dateFilter
            filterChildren={<DashboardDateFilter dateRange={dateRange} setDateRange={setDateRange} setFlag={setFlag} />}
            view="weekly"
          />
          <div className="content-container-dashboard p-4">
            <div
              className="d-flex"
              style={{ flex: 1, gap: "15px", margin: "20px 0px" }}
            >
              <div
                className="d-flex "
                style={{
                  flex: 1,
                  gap: "15px",
                  justifyContent: "space-between",
                }}
              >
                <DetailTab label="Active Jobs " content={<ActiveJobsTab />} />
                <DetailTab
                  label="Unfilled Jobs"
                  content={<UnfilledJobsTab />}
                />
                <DetailTab label="Pending Invoices " count="0" />
              </div>
              <div
                className="d-flex"
                style={{
                  flex: 1,
                  gap: "15px",
                  justifyContent: "space-between",
                }}
              >
                <DetailTab label="Active Locums" content={<ActiveLocums />} />
                <DetailTab label="Active Staff" content={<ActiveStaff />} />
              </div>
            </div>
            <div className="d-flex flex-column" style={{ gap: "20px", paddingBottom: "23px" }}>
              <DashboardTable
                isExportBtn={hasAccess(branchApiKeys.EXPORT_PHARMACY_GAPS_CSV)}
                exportCSVHandler={downloadGapsCSV}
                label="Pharmacy Gaps"
                count={pharamcyGapsData?.length}
                isPieChart
                tableCols={brancGapColumns}
                tableData={pharamcyGapsData}
                locums={pharmacyDashboardData?.activeLocums?.total_locums}
                permanentStaff={
                  pharmacyDashboardData?.pharmacy_staff?.total_staff
                }
              />
              <DashboardTable
                label="Pending Jobs"
                count={pendingJobsTableData?.length}
                isDougnutChart
                tableCols={pendingJobsColumns}
                tableData={pendingJobsTableData}
                dougnutData={pharmacyDashboardData}
              />
            </div>
          </div>
        </div>
      </div>
      {showAddShiftModal && (
        <ShiftJobFormUpdated
          isGap={true}
          gapData={gapData}
          onSuccess={() => setFlag(true)}
          branchId={gapData?.branchId}
          selectedDate={gapData?.date}
          showModal={showAddShiftModal}
          setHandleModal={() => setShowAddShiftModal(!showAddShiftModal)}
        />
      )}
    </div>
  );
};
