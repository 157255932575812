import { Checkbox, Form, Input, Radio, Select, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { LocationSearchInput } from "../../UI/input/locationSearchInput";
import { beforeUpload, compressImage, notify } from "../../../utils/helper";

export const CreateBranchForm = ({
  regions,
  form,
  branchFormStep,
  handleAddressSelect,
  staffSupportLevels,
  pharmacySystems,
  branchServices,
}) => {
  const [regionOptions, setRegionOptions] = useState([]);
  const logo = Form.useWatch('logo', form);

  useEffect(() => {
    if (!regions) return;
    const newRegions = regions.map((reg) => ({
      label: reg.title,
      value: reg.id,
    }));
    setRegionOptions(newRegions);
  }, [regions]);

  return (
    <div>
      <Form.Item
        hidden={branchFormStep === 1 ? false : true}
        label="Branch Logo"
        name="logo"
      >
        <Upload
          accept="image/*"
          showUploadList={{
            showPreviewIcon: false,
            showRemoveIcon: true,
          }}
          fileList={logo?.fileList}
          onPreview={() => { }}
          beforeUpload={beforeUpload}
          onChange={async ({ file, fileList }) => {
            try {
              const compressedFile = await compressImage(file);
              form.setFieldsValue({ logo: { file: compressedFile, fileList } });
            } catch (error) {
              notify('error', 'Error compressing the image:');
            }
          }}
          multiple={false}
          maxCount={1}
          listType="picture-card"
        >
          {
            (logo?.file?.status === "removed" || !logo) &&
            <div>
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          }
        </Upload>
      </Form.Item>
      <Form.Item
        hidden={branchFormStep === 1 ? false : true}
        label="Branch Title"
        name="title"
        rules={branchFormStep === 1 ? [{ required: true, message: 'Please Enter Branch Title !' }, { min: 6, message: 'Branch Title must be at least 6 Characters !' }] : []}
      >
        <Input
          onBlur={e => form.setFieldsValue({ 'title': e.target.value.trim() })}
          maxLength={50}
          className='text-input-field p-2 t1'
          placeholder='Title'
        />
      </Form.Item>
      <Form.Item
        hidden={branchFormStep === 1 ? false : true}
        label="Region"
        name="regionId"
      >
        <Select
          dropdownStyle={{ zIndex: 2000 }}
          placeholder="Select Region"
          bordered={false}
          className='text-input-field p-1 t1'
          options={regionOptions}
        />
      </Form.Item>
      <div className="d-flex" style={{ gap: "15px", width: "100%" }}>
        <Form.Item
          hidden
          label="isNegotiationAllowed"
          name="isNegotiationAllowed"
        >
          <Input
            className='text-input-field p-2 t1'
          />
        </Form.Item>
        <Form.Item
          hidden
          label="latitude"
          name="latitude"
        >
          <Input
            className='text-input-field p-2 t1'
          />
        </Form.Item>
        <Form.Item
          label="Longitude"
          hidden
          name="longitude"
        >
          <Input
            className='text-input-field p-2 t1'
          />
        </Form.Item>
      </div>
      <Form.Item
        hidden={branchFormStep === 1 ? false : true}
        label="Address Line 1"
        rules={branchFormStep === 1 ? [{ required: true, message: 'Please Enter Address 1!' }] : []}
        name="address1"
      >
        <LocationSearchInput
          className='text-input-field p-2 pe-3 t1'
          name="address1"
          value={form?.address1}
          placeholder="Address Line 1"
          onHandleSelect={handleAddressSelect}
        />
      </Form.Item>
      <Form.Item
        hidden={branchFormStep === 1 ? false : true}
        label="Address Line 2"
        name="address2"
      >
        <LocationSearchInput
          className='text-input-field p-2 pe-3 t1'
          name="address2"
          value={form?.address2}
          placeholder="Address Line 2"
          onHandleSelect={handleAddressSelect}
        />
      </Form.Item>
      <div className="d-flex" style={{ gap: "15px", width: "100%" }}>
        <Form.Item
          label="City"
          hidden={branchFormStep === 1 ? false : true}
          style={{ width: '100%' }}
          name="city"
          rules={branchFormStep === 1 ? [{ required: true, message: 'Please Enter City!' }] : []}
        >
          <Input
            onBlur={e => form.setFieldsValue({ 'city': e.target.value.trim() })}
            maxLength={50}
            className='text-input-field p-2 t1'
            placeholder='City'
          />
        </Form.Item>
        <Form.Item
          label="Branch No."
          hidden={branchFormStep === 1 ? false : true}
          style={{ width: '100%' }}
          name="number"
          rules={branchFormStep === 1 ? [
            { required: true, message: 'Please Enter Branch No.!' },
            { max: 15, message: 'Maximum Character limit is 15!' }
          ] : []}
        >
          <Input
            onBlur={e => form.setFieldsValue({ 'number': e.target.value.trim() })}
            type="number"
            className='text-input-field p-2 t1'
            placeholder='Branch No.'
          />
        </Form.Item>
      </div>
      <div className="d-flex" style={{ gap: "15px", width: "100%" }}>
        <Form.Item
          label="Postal Code"
          hidden={branchFormStep === 1 ? false : true}
          rules={branchFormStep === 1 ? [{ required: true, message: 'Please Enter Postal Code!' }] : []}
          style={{ width: '100%' }}
          name="postalCode"
        >
          <Input
            maxLength={50}
            onBlur={e => form.setFieldsValue({ 'postalCode': e.target.value.trim() })}
            className='text-input-field p-2 t1'
            placeholder='Postal Code'
          />
        </Form.Item>
        <Form.Item
          label="Phone No."
          hidden={branchFormStep === 1 ? false : true}
          style={{ width: '100%' }}
          name="phone"
          rules={branchFormStep === 1 ? [
            { required: true, message: 'Please Enter Phone No.!' },
          ] : []}
        >
          <Input
            onBlur={e => form.setFieldsValue({ 'phone': e.target.value.trim() })}
            type="number"
            className='text-input-field p-2 t1'
            placeholder="Phone No."
          />
        </Form.Item>
      </div>
      <Form.Item
        hidden={branchFormStep === 1 ? false : true}
        label="Branch Description"
        name="description"
      >
        <Input.TextArea
          onBlur={e => form.setFieldsValue({ 'description': e.target.value.trim() })}
          rows={5}
          maxLength={256}
          className='p-2 t1'
          placeholder="Branch Description"
        />
      </Form.Item>
      <Form.Item
        rules={branchFormStep === 2 ? [{ required: true, message: 'Please Select Option!' }] : []}
        hidden={branchFormStep === 2 ? false : true}
        label="Can 'Hourly Rate' for a Job be Negotiable?"
        name="isNegotiationAllowed"
      >
        <Radio.Group className="mb-3"  >
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <div className="d-flex" style={{ gap: "15px", width: "100%" }}>
        <Form.Item
          hidden={branchFormStep === 2 ? false : true}
          label="Items Per Month"
          style={{ width: '50%' }}
          rules={branchFormStep === 2 ? [
            { required: true, message: 'Please Enter Items Per Month!' },
          ] : []}
          name="itemsPerMonth"
        >
          <Input
            onBlur={e => form.setFieldsValue({ 'itemsPerMonth': e.target.value.trim() })}
            type="number"
            className='text-input-field p-2 t1'
            placeholder='Items Per Month'
          />
        </Form.Item>
        <Form.Item
          hidden={branchFormStep === 2 ? false : true}
          style={{ width: '50%' }}
          label="MDS Per Month"
          name="mdsPerMonth"
          rules={branchFormStep === 2 ? [
            { required: true, message: 'Please Enter MDS Per Month!' },
          ] : []}
        >
          <Input
            onBlur={e => form.setFieldsValue({ 'mdsPerMonth': e.target.value.trim() })}
            type="number"
            className='text-input-field p-2 t1'
            placeholder='MDS Per Month'
          />
        </Form.Item>
      </div>
      <div className="d-flex" style={{ gap: "15px", width: "100%" }}>
        <Form.Item
          hidden={branchFormStep === 2 ? false : true}
          label="Methadone Patients Per Month"
          name="methadonePatientsPerDay"
          style={{ width: '50%' }}
          rules={branchFormStep === 2 ? [{ required: true, message: 'Please Enter Methadone Patients Per Month!' },
          ] : []}
        >
          <Input
            onBlur={e => form.setFieldsValue({ 'methadonePatientsPerDay': e.target.value.trim() })}
            type="number"
            className='text-input-field p-2 t1'
            placeholder='Methadone Patients Per Month'
          />
        </Form.Item>
        <Form.Item
          hidden={branchFormStep === 2 ? false : true}
          label="Care Home Supplied"
          name="careHomesSupplied"
          maxLength={256}
          style={{ width: '50%' }}
          rules={branchFormStep === 2 ? [{ required: true, message: 'Please Enter Methadone Care Home Supplied!' },
          ] : []}
        >
          <Input
            onBlur={e => form.setFieldsValue({ 'careHomesSupplied': e.target.value.trim() })}
            type="number"
            className='text-input-field p-2 t1'
            placeholder='Care Home Supplied'
          />
        </Form.Item>
      </div>
      <Form.Item
        hidden={branchFormStep === 2 ? false : true}
        label="Staff Support Level"
        name="staffSupportLevelId"
        style={{ width: '100%' }}
        rules={branchFormStep === 2 ? [{ required: true, message: 'Staff Support Level!' }] : []}
      >
        <Radio.Group className="mb-3"  >
          {
            staffSupportLevels?.map((data, idx) => (
              <Radio key={idx} value={data.id}>{data.title} staff</Radio>
            ))
          }
        </Radio.Group>
      </Form.Item>
      <div className="d-flex" style={{ gap: "15px", width: "100%" }}>
        <Form.Item
          hidden={branchFormStep === 2 ? false : true}
          label="Parking Information"
          name="parkingInformation"
          style={{ width: '50%' }}
          rules={branchFormStep === 2 ? [{ required: true, message: 'Please Enter Parking Information!' }] : []}
        >
          <Input
            onBlur={e => form.setFieldsValue({ 'parkingInformation': e.target.value.trim() })}
            className='text-input-field p-2 t1'
            maxLength={50}
            placeholder='Parking Information'
          />
        </Form.Item>
        <Form.Item
          style={{ width: '50%' }}
          label="Pharmacy System"
          hidden={branchFormStep === 2 ? false : true}
          name="pharmacySystemId"
          rules={branchFormStep === 2 ? [{ required: true, message: 'Please Select Pharmacy System!' }] : []}
        >
          <Select
            dropdownStyle={{ zIndex: 2000 }}
            placeholder="Pharmacy System"
            bordered={false}
            className='text-input-field pe-3 t1'
          >
            {
              pharmacySystems?.map((data, idx) => (
                <Select.Option key={idx} value={data.id} >{data.title}</Select.Option>
              ))
            }
          </Select>
        </Form.Item>
      </div>
      <Form.Item
        style={{ width: '100%' }}
        hidden={branchFormStep === 2 ? false : true}
        label="Providing Services"
        name="pharmacyServicesIds"
        rules={branchFormStep === 2 ? [{ required: true, message: 'Please Enter Providing Services!' }] : []}
      >
        <Checkbox.Group style={{ display: 'flex', flexDirection: 'column' }}  >
          {
            branchServices?.map((data, idx) => (
              <Checkbox key={idx} value={data.id} >{data.title}</Checkbox>
            ))
          }
        </Checkbox.Group>
      </Form.Item>
    </div>
  );
};
