import { Form, Input, Checkbox, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { AddModal } from "../../../../../components/UI/modals/addModal";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewRole,
  updateRolePermissions,
} from "../../../../../redux/profile/profileAPI";
import "./style.scss";
import { getAdminRolesThunk } from "../../../../../redux/profile/profileThunk";
import { hasAccess } from "../../../../../utils/helper.js";
import { userRoleApiKeys } from "../../../../../utils/apiKeyConstants.js";
import { getRolePermissionsById } from "../../../../../redux/profile/profileAPI";
export const CreateAdminRoles = ({
  showCreateModal,
  handleModalToggle,
  editRole,
  actionType,
  setActionType,
}) => {
  const [form] = Form.useForm();
  const formId = useMemo(() => "addadmin", []);
  const { pharmacyFlows } = useSelector((state) => state.profile);
  const { isloading } = useSelector((state) => state.loader);

  const [flowsToDisplay, setFlowsToDisplay] = useState([]);
  const { Panel } = Collapse;
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [defaultPermissionsRoles, setDefaultPermissionsRoles] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  // For Update Functionality
  useEffect(() => {
    form.resetFields();
    if (actionType === "Edit") {
      const fetchPermissionById = async () => {
        const response = await getRolePermissionsById(editRole?.id);
        setSelectedPermissions(response.data.data);
        let defaultPermissions = [];
        const selectedPermissionsArray = response.data.data.map((mod) => {
          const ModulePermissionsArray = mod.pharmacyFlow.map((flow) => {
            let permissions = {};

            permissions["moduleid"] = mod.id;
            permissions["moduleKey"] = mod.moduleKey;
            permissions["name"] = mod.name;
            permissions["flowid"] = flow.id;
            permissions["label"] = flow?.label;
            permissions["isGranted"] = flow.isGranted === 1 ? true : false;

            if (flow.isGranted) {
              defaultPermissions.push(mod.id.toString());
            }
            return permissions;
          });

          return ModulePermissionsArray;
        });

        defaultPermissions = [...new Set(defaultPermissions)];
        setDefaultPermissionsRoles(defaultPermissions);
        setLoading(false);
        selectedPermissionsArray.forEach((permissions) => {
          if (permissions.length > 0) {
            permissions.forEach((permission) => {
              const key =
                permission.moduleid +
                "|" +
                permission.moduleKey +
                "|" +
                permission.name.toLowerCase().replaceAll(" ", "") +
                "|" +
                permission.flowid +
                "|" +
                permission.label.toLowerCase().replaceAll(" ", "");
              form.setFieldValue(key, permission.isGranted);
            });
          }
        });
        form.setFieldValue("rolename", editRole.label);
      };

      if (editRole) fetchPermissionById();
    }
  }, [editRole?.id, actionType, showCreateModal]);
  useEffect(() => {
    const pharmacyFlowsDisplay = pharmacyFlows?.map((module) => {
      //preparing module obj
      let tempModuleObj = {};
      tempModuleObj["moduleKey"] = module["moduleKey"];
      tempModuleObj["id"] = module["id"];
      tempModuleObj["name"] = module["name"];

      // preparing flows for given object
      let tempFlowsArray = module.pharmacyFlow?.map((flow) => {
        let tempFlowObj = {};
        tempFlowObj["id"] = flow["id"];
        tempFlowObj["name"] = flow["id"];
        tempFlowObj["label"] = flow["label"];

        return tempFlowObj;
      });

      tempModuleObj["pharmacyFlow"] = tempFlowsArray;

      return tempModuleObj;
    });

    //modules and there flows to display
    setFlowsToDisplay(pharmacyFlowsDisplay);
  }, []);
  const onFinish = async (values) => {
    // combining permissions on the basis of moduleId
    const ModulesWithPermisisons = combinePermissions(values);

    if (actionType === "Edit") {
      //preparing payload for the post request
      const payload = preparingPayloadForEditingRole(
        ModulesWithPermisisons,
        values
      );
      payload.roleInfo["roleId"] = editRole?.id;
      updateRolePermissions(payload).then((res) => {
        if (res.status === 200) {
          form.resetFields();
          handleModalToggle();
          dispatch(getAdminRolesThunk());
        }
      });
    } else {
      //preparing payload for the post request.toString()]
      const payload = preparingPayloadForAddingRole(
        ModulesWithPermisisons,
        values
      );
      addNewRole(payload).then((res) => {
        if (res.status === 200) {
          form.resetFields();
          handleModalToggle();
        }
        dispatch(getAdminRolesThunk());
      });
    }
  };
  const preparingPayloadForEditingRole = (ModulesWithPermisisons, values) => {
    const payload = {
      roleInfo: {
        label: "",
      },
      rolePermissions: [],
    };

    payload.roleInfo.label = values.rolename;
    const keys = Object.keys(ModulesWithPermisisons);

    const reStructuredPermissions = flowsToDisplay.map((module) => {
      let ModuleObj = {};
      const permissionsArray = module.pharmacyFlow.map((flow) => {
        return {
          flowId: flow.id,
          label: flow.label,
          isAllowed: false,
        };
      });
      ModuleObj["moduleId"] = module.id;
      ModuleObj["moduleKey"] = module.moduleKey;
      ModuleObj["name"] = module.name;
      ModuleObj["pharmacyFlows"] = permissionsArray;

      return ModuleObj;
    });
    const permissionFlows = combineUpdatePermissions(
      reStructuredPermissions,
      values
    );
    payload.rolePermissions = permissionFlows;

    return payload;
  };

  const combineUpdatePermissions = (oldPermissions, selectedValues) => {
    const valuesKeys = Object.keys(selectedValues);

    const combinedPermissions = oldPermissions.map((module) => {
      let ModuleObj = {};
      const permissionsArray = module?.pharmacyFlows.map((flow) => {
        let moduleId = "";
        let flowId = "";
        for (let key of valuesKeys) {
          if (selectedValues[key] === true) {
            const moduleAndTheirPermissionsArray = key.split("|");

            if (
              moduleAndTheirPermissionsArray[0] == module.moduleId &&
              moduleAndTheirPermissionsArray[3] == flow.flowId
            ) {
              moduleId = moduleAndTheirPermissionsArray[0];
              flowId = moduleAndTheirPermissionsArray[3];
            }
          }
        }

        if (moduleId && flowId) {
          return {
            flowId: flow.flowId,
            label: flow.label,
            isAllowed: true,
          };
        } else
          return {
            flowId: flow.flowId,
            label: flow.label,
            isAllowed: false,
          };
      });
      ModuleObj["moduleId"] = module.moduleId;
      ModuleObj["moduleKey"] = module.moduleKey;
      ModuleObj["name"] = module.name;
      ModuleObj["pharmacyFlows"] = permissionsArray;
      return ModuleObj;
    });

    return combinedPermissions;
  };

  const preparingPayloadForAddingRole = (ModulesWithPermisisons, values) => {
    const payload = {
      roleInfo: {
        label: "",
      },
      rolePermissions: [],
    };

    payload.roleInfo.label = values.rolename;
    const keys = Object.keys(ModulesWithPermisisons);
    for (let key of keys) {
      let ModuleObj = {};

      const permissionsArray = ModulesWithPermisisons[key].map((flow) => {
        return {
          flowId: flow[3],
          label: flow[4],
          isAllowed: true,
        };
      });
      ModuleObj["moduleId"] = ModulesWithPermisisons[key][0][0];
      ModuleObj["moduleKey"] = ModulesWithPermisisons[key][0][1];
      ModuleObj["name"] = ModulesWithPermisisons[key][0][2];
      ModuleObj["pharmacyFlows"] = permissionsArray;

      payload.rolePermissions.push(ModuleObj);
    }
    return payload;
  };
  const combinePermissions = (values) => {
    const valuesKeys = Object.keys(values);
    const uniqueModulesObj = {};

    // combining permissions on the basis of moduleId in uniqueModulesObj
    for (let key of valuesKeys) {
      if (values[key] === true) {
        const moduleAndTheirPermissionsArray = key.split("|");
        let moduleId = moduleAndTheirPermissionsArray[0];
        if (uniqueModulesObj[moduleId]) {
          uniqueModulesObj[moduleId].push({
            ...moduleAndTheirPermissionsArray,
          });
        } else {
          uniqueModulesObj[moduleId] = [{ ...moduleAndTheirPermissionsArray }];
        }
      }
    }
    return uniqueModulesObj;
  };

  return (
    <AddModal
      formId={formId}
      title={actionType === "Edit" ? `Update the Role` : `Add New Role`}
      handleModal={showCreateModal}
      setHandleModal={handleModalToggle}
      primaryButtonText={
        actionType === "Edit" && hasAccess(userRoleApiKeys.UPDATE_USER_ROLE)
          ? `Update`
          : actionType === "Add" && hasAccess(userRoleApiKeys.ADD_USER_ROLE)
            ? "Add"
            : undefined
      }
      primaryButtonHandler={form.submit}
      secondaryButtonText="Cancel"
      isLoading={isloading}
      secondaryButtonHandler={handleModalToggle}
    >
      <div style={{ width: "100%" }}>
        <Form
          formId={formId}
          onFinish={onFinish}
          form={form}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            label="Role Name"
            name="rolename"
            rules={[{ required: true, message: "Role is Required" }]}
          >
            <Input
              type="text"
              onBlur={(e) =>
                form.setFieldsValue({ rolename: e.target.value.trim() })
              }
              className="text-input-field p-2 t1"
              placeholder="Role Name "
            />
          </Form.Item>
          {flowsToDisplay.length > 0 &&
            flowsToDisplay.map((module) => {
              return isLoading && actionType === "Edit" ? (
                ""
              ) : (
                <Collapse
                  expandIconPosition="end"
                  defaultActiveKey={
                    actionType === "Edit" ? defaultPermissionsRoles : ["1"]
                  }
                >
                  <Panel header={module.name} key={module.id}>
                    {
                      <div
                        className="region-detail-tab"
                        style={{ marginTop: "24px" }}
                      >
                        {module?.pharmacyFlow.map((flow) => {
                          return (
                            <Form.Item
                              name={
                                module?.id +
                                "|" +
                                module.moduleKey +
                                "|" +
                                module?.name.toLowerCase().replaceAll(" ", "") +
                                "|" +
                                flow.id +
                                "|" +
                                flow?.label.toLowerCase().replaceAll(" ", "")
                              }
                              valuePropName="checked"
                            >
                              <Checkbox>{flow.label}</Checkbox>
                            </Form.Item>
                          );
                        })}
                      </div>
                    }
                  </Panel>
                </Collapse>
              );
            })}
        </Form>
      </div>
    </AddModal>
  );
};
