import React, { useEffect } from "react";
import './style.scss'
import DashboardImage from '../../../assets/img/Dashboard.png'
import Logo from '../../../assets/icons/LopicLogo.png'
import { useState } from "react";
import { SignUpSelection } from "../../../components/auth/signup/signUpSelection";
import { PharmacySignUpForm } from "../../../components/auth/signup/pharmacySignUpForm";
import { LocumSignUpForm } from "../../../components/auth/signup/locumSignUpForm";
import { useSearchParams } from "react-router-dom";


export const SignUpPage = () => {
    const [signUpMode, setSignUpMode] = useState('')
    const [showSignUpForm, setShowSignUpForm] = useState(false)
    const [searchParams] = useSearchParams();
    const [convertedUser, setConvertedUser] = useState('')

    useEffect(() => {
        if (!searchParams.get('source')) return
        if (searchParams.get('source') === "get-started") {
            setConvertedUser(true)
            window.fbq('trackCustom', 'Get Started - Pharmacy Convert', {
            });
        }
    }, [searchParams])


    return (
        <div className="d-flex align-items-center p-4" style={{ height: '100vh', gap: '15px' }} >
            <div className="position-relative border rounded-4 d-flex align-items-center p-5 text-center" style={{ width: '50%', backgroundColor: '#032B26', height: '100%' }}>
                <div className="d-flex flex-column" style={{ width: '100%', gap: '50px' }}>
                    <div className="m-auto" style={{ maxWidth: '382px' }}>
                        <div className="h3 fw-bold" style={{ color: 'white' }} >Welcome</div>
                        <div className="t2" style={{ color: 'white' }}>Your all-in one solution for pharamcy management and locum hiring.</div>
                    </div>
                    <img width={500} className="m-auto" src={DashboardImage} alt="dashboard" />
                </div>
                <div className="position-absolute" style={{ bottom: '5%', left: "50%", marginLeft: '-47px' }}>
                    <img className="m-auto" src={Logo} alt="Logo" />
                </div>
            </div>
            <div className="d-flex flex-column m-auto" style={{ maxWidth: '50%', width: '450px', gap: '50px' }}>
                {
                    !showSignUpForm ?
                        <SignUpSelection
                            setSignUpMode={setSignUpMode}
                            signUpMode={signUpMode}
                            setShowSignUpForm={setShowSignUpForm}
                        />
                        :
                        (
                            signUpMode === 'locum' ?
                                <LocumSignUpForm />
                                :
                                <PharmacySignUpForm convertedUser={convertedUser} />
                        )
                }
            </div >
        </div >
    );
}
