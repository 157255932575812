import React from "react";
import './style.scss'

export const GlobalLoader = ({ color, size = "huge", loaderState }) => {
    return (
        <div className={`global-loader overlay ${loaderState ? "isActive" : ""}`}>
            <div class="load">
                <hr /><hr /><hr /><hr />
            </div>
        </div>
    );
};

