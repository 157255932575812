import { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { Heading } from "../../../../components/UI/heading";
import { TableComponet } from "../../../../components/UI/table";
import { TableToolbar } from "../../../../components/UI/table/tableToolBar";
import {
  archiveBranchThunk,
  deleteBranchThunk,
  getbranchesListThunk,
  getBranchRelatedDataThunk,
  unArchiveBranchThunk,
} from "../../../../redux/branches/branchesThunk";
import { RouterConfig } from "../../../../routerConfig";
import archiveIcon from "../../../../assets/icons/archive.png";
import deleteIcon from "../../../../assets/icons/delete.png";
import { CreateBranch } from "../../../../components/branches/createBranch";
import { BranchFilterComponent } from "../../../../components/branches/filter";
import { ConfirmModal } from "../../../../components/UI/modals/confirmModal";
import { notify } from "../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { hasAccess } from "../../../../utils/helper";
import { branchApiKeys } from "../../../../utils/apiKeyConstants";
import { EmptyData } from "../../../../components/comon/EmptyData";
import infoIcon from '../../../../assets/icons/infoIcon1.png'
import { Popover, Select } from "antd";
import _ from "lodash";
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons'

export const BranchesListPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { branchRelatedData: branchData } = useSelector(
    (state) => state.branches
  );
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorData, setErrorData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { branches } = useSelector((state) => state.branches);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteMedium, setDeleteMedium] = useState();
  const [branchStatus, setBranchStatus] = useState();
  const [updatedBranches, setUpdatedBranches] = useState();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);
  const [showMultiSelectBtn, setShowMultiSelectBtn] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Branch Number",
        accessor: "number",
      },
      {
        Header: "Branch Name",
        accessor: "title",
      },
      {
        Header: "Region",
        accessor: "regionName",
        Cell: ({ row: { original } }) => {
          return original.regionName ? original.regionName : "-";
        },
      },
      {
        Header: "Address",
        accessor: "address1",
      },
      {
        Header: "Status",
        accessor: "active",
        Cell: ({ row: { original } }) => {
          return (
            <>
              {original.isArchived ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#FEFDE9", color: "#EE872D" }}
                  >
                    Archived
                  </span>
                </div>
              ) : original.active ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#E8F5E9", color: "#1B5E1F" }}
                  >
                    Active
                  </span>
                </div>
              ) : (
                <span
                  className="px-4 rounded-pill py-2"
                  style={{ backgroundColor: "#FFEBEE", color: "#EF5350" }}
                >
                  In Active
                </span>
              )}
            </>
          );
        },
      },
      {
        Header: "Phone Number",
        accessor: "phone",
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row: { original } }) => {
          return (
            <div className="d-flex gap-2 align-items-center">
              <div onClick={(e) => {
                e.stopPropagation()
                navigate(`/branches/${original?.id}/details/schedule`)
              }}>

                <Popover
                  placement="left"
                  content="Jump To Branch Schedule"
                  trigger="hover"
                >
                  <CalendarOutlined style={{ fontSize: '27px', color: '#51c2bc' }} />
                </Popover>
              </div>
              <div onClick={(e) => {
                e.stopPropagation()
                navigate(`/branches/${original?.id}/details/information?tab=branch-hours`)
              }}>

                <Popover
                  placement="top"
                  content="Change Branch Operating Hours"
                  trigger="hover"
                >
                  <ClockCircleOutlined style={{ fontSize: '27px', color: '#51c2bc' }} />
                </Popover>
              </div>
              <Dropdown icon="ellipsis vertical" pointing="top right">
                <Dropdown.Menu direction="left">
                  {hasAccess(branchApiKeys.ARCHIVE_BRANCH) &&
                    !original.isArchived && (
                      <Dropdown.Item
                        icon={<img className="pt-2" src={archiveIcon} alt="" />}
                        text={"Archive Branch"}
                        onClick={() => {
                          const id = [original.id];
                          setSelectedRows(id);
                          handleConfirmModal("Archive");
                        }}
                      />
                    )}
                  {hasAccess(branchApiKeys.UN_ARCHIVE_BRANCH) &&
                    original.isArchived && (
                      <Dropdown.Item
                        icon={<img className="pt-2" src={archiveIcon} alt="" />}
                        text={"Un Archive Branch"}
                        onClick={() => {
                          const id = [original.id];
                          setSelectedRows(id);
                          handleConfirmModal("Un Archive");
                        }}
                      />
                    )}
                  {hasAccess(branchApiKeys.DELETE_BRANCH) && (
                    <Dropdown.Item
                      style={{ color: "#EF5350" }}
                      icon={<img className="pt-2" src={deleteIcon} alt="" />}
                      onClick={() => {
                        const id = [original.id];
                        setSelectedRows(id);
                        handleConfirmModal("Delete");
                      }}
                      text="Delete Branch"
                    />
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    []
  );

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: branchesData,
  } = useMemo(() => branches, [branches]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  const getBranchList = useCallback(
    async (values) => {
      try {
        if (hasAccess(branchApiKeys.GET_BRANCH_LIST)) {
          dispatch(
            getbranchesListThunk({
              filters: {
                title: searchQuery.trim(),
                ...values,
              },
              pagination: { page: currentPageCount, perPage: perPageCount },
            })
          );
        }
      } catch (error) {
        notify("error", error.message);
      }
    },
    [currentPageCount, dispatch, perPageCount, searchQuery]
  );

  useEffect(() => {
    if (searchQuery !== "") return;

    if (hasAccess(branchApiKeys.GET_BRANCH_DATA)) {
      dispatch(getBranchRelatedDataThunk());
    }
    getBranchList();
  }, [searchQuery, currentPageCount, perPageCount, getBranchList, dispatch]);

  const handleModalToggle = useCallback(() => {
    setShowCreateModal(!showCreateModal);
  }, [showCreateModal]);

  const handleConfirmModal = useCallback(
    (med) => {
      setDeleteMedium(med);
      setShowConfirmModal(!showConfirmModal);
    },
    [showConfirmModal]
  );
  const formatTrProps = ({ original }) => {
    return {
      onClick: () => {
        if (
          !hasAccess(branchApiKeys.BRANCH_DASHBOARD) &&
          hasAccess(branchApiKeys.GET_BRANCH_DATA)
        ) {
          navigate(
            generatePath(RouterConfig.branchAdminBranchInformation, {
              id: original.id,
            })
          );
        } else {
          navigate(
            generatePath(RouterConfig.branchAdminBranchRoot, {
              id: original.id,
            })
          );
        }
      },
    };
  };
  const clearFields = () => {
    setShowConfirmModal(false);
    setDeleteMedium(null);
    setSelectedRows([]);
    setShowMultiSelectBtn(false);
    <BranchFilterComponent regions={branchData?.regions || []} />;
  };

  const onConfirm = () => {
    if (deleteMedium === "Archive") {
      dispatch(
        archiveBranchThunk({
          branchIds: selectedRows,
        })
      ).then((res) => {
        if (res.payload) {
          clearFields();
          getBranchList()
        }
      });
      return;
    }
    if (deleteMedium === "Un Archive") {
      dispatch(
        unArchiveBranchThunk({
          branchIds: selectedRows,
        })
      ).then((res) => {
        if (res.payload) {
          clearFields();
          getBranchList()
        }
      });
      return;
    }
    dispatch(
      deleteBranchThunk({
        branchIds: selectedRows,
      })
    ).then((res) => {
      if (res.payload.code === "validation") {
        setShowErrorModal(true);
        setErrorData(res.payload);
        setShowConfirmModal(false)
        return
      }
      <BranchFilterComponent regions={branchData?.regions || []} />;
      clearFields();
      getBranchList();
    });
  };

  const onSearch = (e) => {
    getBranchList();
  };

  useEffect(() => {
    if (!selectedRows?.length) return
    const output = branchesData?.filter((data) => {
      const bookCount = selectedRows.find(b => b === data.id && data.isArchived);
      return bookCount
    })
    if (deleteMedium !== 'Delete') {
      if (output?.length === selectedRows?.length) {
        setBranchStatus('Un Archive')
        setDeleteMedium('Un Archive')
      } else {
        setBranchStatus('Archive')
        setDeleteMedium('Archive')
      }
    }
  }, [selectedRows])

  const [replacedUser, setReplacedUser] = useState([])

  useEffect(() => {
    if (_.isEmpty(errorData)) return
    const thirdArray = branchesData?.filter((elem) => {
      return !selectedRows?.find((ele) => {
        return ele === elem.id
      });
    });
    setUpdatedBranches(thirdArray)
  }, [errorData, branchesData])

  useEffect(() => {
    if (_.isEmpty(errorData)) return

    const data = errorData.array.map((d) => {
      return {
        branchId: d.id,
        employees: d.employees.map(a => ({
          id: a.id,
          deactivate: true
        }))
      }
    })
    setReplacedUser(data)

  }, [errorData])

  const onBranchChange = (newBranch, user, branchId) => {
    const data = _.cloneDeep(replacedUser)
    const index = data.findIndex(a => a.branchId === branchId)
    const idx = data[index].employees.findIndex(a => a.id === user)
    const d = _.cloneDeep(data[index].employees[idx])
    delete d.deactivate
    d.replaceBranchId = newBranch
    data[index].employees[idx] = d
    setReplacedUser(data)
  }

  const onProceed = () => {
    const data = {
      manageStaff: true,
      branches: replacedUser
    }

    dispatch(
      deleteBranchThunk(
        data
      )
    ).then((res) => {
      if (res) {
        clearFields()
        setShowErrorModal(false)
        setReplacedUser([])
        setErrorData([])
        getBranchList()
      }
    });
  }

  return (
    <>
      <ConfirmModal
        title={`Do you really want to ${deleteMedium}?`}
        description={`You are about to ${deleteMedium} ${showMultiSelectBtn ? "these" : "this"
          } Branch${showMultiSelectBtn ? "es" : ""}. ${deleteMedium === "Delete"
            ? `This will ${deleteMedium} your branch${showMultiSelectBtn ? "es" : ""
            } and other information permanently.`
            : ""
          }`}
        open={showConfirmModal}
        confirmButtonText={deleteMedium}
        cancelButtonText="Cancel"
        onConfirm={onConfirm}
        onClose={() => setShowConfirmModal(!showConfirmModal)}
      />
      <ConfirmModal
        backdrop="static"
        title="Hold up!"
        open={showErrorModal}
        confirmButtonText="Proceed"
        cancelButtonText="Cancel"
        image={infoIcon}
        onClose={() => setShowErrorModal(false)}
        onConfirm={onProceed}
      >
        <div className="d-flex flex-column gap-3">
          <div>
            There are few staffs assigned to these branches, Please assign them to some other branches or they will be deactivated.
          </div>
          {/* <div>
            You can resolve these conflicts and schedule again for the
            following days,
          </div> */}
        </div>
        <div className="d-flex flex-column gap-3 align-items-center my-4" style={{ width: '100%' }}>

          {errorData?.array?.length && errorData?.array.map((data, idx) => (
            <div key={idx} style={{ width: '100%' }}>
              <div className="b1 text-underline">{data.title}</div>
              {
                data.employees?.map((d, i) => (
                  <div key={i} className="d-flex align-items-center gap-3 justify-content-between my-2" style={{ width: '100%' }}>
                    <div>{d.name + ' ' + d.surname}</div>
                    <Select
                      style={{ width: 250 }}
                      placeholder="Branch"
                      bordered={false}
                      dropdownStyle={{ zIndex: 10000 }}
                      onChange={val => onBranchChange(val, d.id, data.id)}
                      className="text-input-field pe-3 p-1 t1"
                    >
                      {updatedBranches?.map((data, index) => (
                        <Select.Option key={index} value={data.id}>
                          {data.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                ))
              }
            </div>
          ))}
        </div>
        {errorData?.successCount > 0 && (
          <div>
            {errorData?.successCount} Branch/es were deleted Successfully.
          </div>
        )}
      </ConfirmModal>
      <div>
        <Heading label="All Branches" />
        <div className="">
          <TableToolbar
            searchPlaceholder="Branch Name"
            primaryBtnText={
              hasAccess(branchApiKeys.CREATE_BRANCH) ? "Add Branch" : ""
            }
            showSearchField
            FilterComponent={
              <BranchFilterComponent
                onApplyFilter={getBranchList}
                regions={branchData?.regions || []}
              />
            }
            filterButton
            onSearch={onSearch}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            primaryBtnHandler={handleModalToggle}
            branchStatus={branchStatus}
            archiveButton={
              hasAccess(branchApiKeys.ARCHIVE_BRANCH)
                ? showMultiSelectBtn
                : undefined
            }
            deleteButton={
              hasAccess(branchApiKeys.DELETE_BRANCH)
                ? showMultiSelectBtn
                : undefined
            }
            handleConfirmModal={handleConfirmModal}
          />

          <div style={{ padding: "5px 24px" }}>
            {branches?.rows?.length > 0 ? (
              <TableComponet
                rowCursor="pointer"
                isBorderShown
                setSelectedRows={setSelectedRows}
                setShowMultiSelectBtn={setShowMultiSelectBtn}
                currentPageCount={currentPageCount}
                perPageCount={perPageCount}
                totalDataCount={totalDataCount}
                setCurrentPage={setCurrentPageCount}
                setPerPageCount={setPerPageCount}
                showPagination
                showRowSelection={
                  hasAccess(branchApiKeys.DELETE_BRANCH) ||
                    hasAccess(branchApiKeys.ARCHIVE_BRANCH)
                    ? true
                    : false
                }
                maxPageCount={totalPagesCount}
                columns={columns}
                data={branchesData ?? []}
                formatRowProps={
                  hasAccess(branchApiKeys.GET_BRANCH_DATA) ||
                    hasAccess(branchApiKeys.BRANCH_DASHBOARD)
                    ? formatTrProps
                    : undefined
                }
              />
            ) : (
              <div style={{ width: "100%", marginTop: "60px" }}>
                <EmptyData title="Branches " />
              </div>
            )}
          </div>
        </div>
      </div>
      <CreateBranch
        setShowCreateModal={handleModalToggle}
        showCreateModal={showCreateModal}
      />
    </>
  );
};
