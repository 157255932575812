import { createSlice } from "@reduxjs/toolkit";
import { getRolesThunk, getScheduleListThunk, getStaffsThunk, searchLocumByFilterThunk } from "../scheduleThunk";

const initialState = {
  branches: [],
  isloading: false,
  roles: [],
  staffs: [],
  locums: []
};

const scheduleSlicer = createSlice({
  name: "scheduleSlicer",
  initialState: initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getScheduleListThunk.fulfilled, (state, action) => {
        state.branches = action.payload;
      })
      .addCase(getRolesThunk.fulfilled, (state, action) => {
        state.roles = action.payload;
      })
      .addCase(getStaffsThunk.fulfilled, (state, action) => {
        state.staffs = action.payload;
      })
      .addCase(searchLocumByFilterThunk.fulfilled, (state, action) => {
        state.locums = action.payload;
      })
  },
});

export const scheduleReducer = scheduleSlicer
