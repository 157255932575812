import React, { useState, useRef } from "react";
//import filterIcon from "../../assets/icons/filter.png";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import cross from "../../assets/icons/x.png";
import calender from "../../assets/icons/calender.png";
import dropDown from "../../assets/icons/arrowDown.png";
import { Button } from "antd";
import dayjs from "dayjs";
import "./style.scss";

export const FilterComponent = ({
  children,
  onApplyFilter,
  clearFormHandler,
  formId,
  dateFilter,
  dateRange,
}) => {
  const [showFilter, setShowFilter] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      {dateFilter ? (
        <div
          className="date-filter-btn"
          onClick={() => setShowFilter(!showFilter)}
        >
          <img src={calender} alt="" />
          <span className="t1" style={{ color: "#6F6F6F" }}>
            {" "}
            {dateRange?.startDateRange ? dayjs(dateRange?.startDateRange).format("DD MMM, YYYY") : ''} -{" "}
            {dateRange?.endDateRange ? dayjs(dateRange?.endDateRange).format("DD MMM, YYYY") : ''}
          </span>
          <img src={dropDown} alt="" />
        </div>
      ) : (
        <div style={{ minWidth: "108.75px", height: "40px" }}>
          <Button
            onClick={() => setShowFilter(!showFilter)}
            className=" d-flex align-items-center gap-1 filterActionBtn"
            block
            style={{ height: "100%", width: "100%" }}
          //icon={<FilterIcon/>}
          >
            Filter
            <FilterIcon />
          </Button>
        </div>

      )}

      {showFilter && (
        <div
          className="filter-box d-flex flex-column"
          style={{ zIndex: "8" }}
        >
          <div className="d-flex justify-content-between align-items-end fs-2">
            <div className="d-flex gap-3 align-items-center">
              <div className="heading4">Filter</div>

              {clearFormHandler && (
                <div
                  onClick={clearFormHandler}
                  className="px-3 py-1 mb-2 cursor-pointer rounded-pill s1 d-flex align-items-center gap-2"
                  style={{ backgroundColor: "#F7FAFC" }}
                >
                  Clear All
                  <img width={15} src={cross} alt="cross" />
                </div>
              )}
            </div>
            <span
              className="cursor-pointer"
              onClick={() => setShowFilter(!showFilter)}
            >
              <img src={cross} alt="" className="mb-2" />
            </span>
          </div>
          {children}
          <div
            className="d-flex justify-content-between align-items-end gap-3"
            style={{ flex: "1" }}
          >
            <Button
              onClick={() => {
                setShowFilter(!showFilter);
              }}
              className="p-2 btn-style"
              htmlType="submit"
              block
            >
              Cancel
            </Button>
            <Button
              form={formId && formId}
              onSubmit={onApplyFilter}
              onClick={!formId ? onApplyFilter : undefined}
              className="p-2 btn-style"
              type="primary"
              htmlType="submit"
              block
            >
              Apply Filter
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
