export const userRoles = {
  ADMIN: "admin",
  PHARMACY_ADMIN: "pharmacy",
  LOCUM: "locum",
  STAFF: "staff",
};

export const moduleKeys = {
  USER_ROLE_MANAGEMENT: "userRoles",
  REGION: "region",
  USER: "user",
  STAFF: "staff",
  SETTINGS: "setings",
  PERSONAL: "personal",
  PAYMENTS: "payments",
  LOCUM_BANK: "locumBank",
  LOCUMS: "locums",
  JOBS: "jobs",
  INVITE: "invites",
  FILES: "files",
  BRANCHES: "branches",
  AUTH: "authentication",
  ADMIN: "admin",
  DASHBOARD: "dashboard",
  SCHEDULER: "scheduler",
  PROFILE: "profile",
};

export const subModuleKeys = {
  PERSONAL_INFORMATION: "personalInformation",
  SUBSCRIPTION_AND_BILLING: "subscriptionAndBilling",
  BANK_HOLIDAYS: "bankHolidays",
  ACCREDITION: "accredition",
  SETTINGS: "settings",
  ADMIN_STAFF: "adminStaff",
  VERFICATION: "verification",
  SCHEDULE: "schedule",
  ABSENCES: "absences",
  CONTRACT: "contract",
  TIME_SHEET: "time_sheet",
  INFORMATION: "information",
  TEMPLATE: "template",
  STAFF: "staff",
  BLOCK_LIST: "blockList",
  LOCUM_JOBS: "locumJobs",
  NOTIFICATION_SETTINGS: "notificationSettings",
};
export const userStatuses = {
  new: "New",
  onboarding: "Onboarding",
  pending: "Pending",
  active: "Active",
  rejected: "Rejected",
  deactivated: "Deactivated",
};

export const userRolesEnum = {
  admin: "Admin",
  branch_admin: "Branch Admin",
  pharmacy_manager: "Pharmacy Manager",
  locum: "Locum",
  relief: "Relief",
  technician: "Technician",
  branch_manager: "Branch Manager",
  branch_pharmacist: "Branch Pharmacist",
  pharmacy_admin: "Pharmacy Admin",
  locum_coordinator: "Locum Coordinator",
  full_time_staff_coordinator: "Full Time Staff Coordinator",
};

export const userStatusesList = {
  ONBOARDING: "onboarding",
  PENDING: "pending",
  ACTIVE: "active",
  NEW: "new",
  REJECTED: "rejected",
  DEACTIVATED: "deactivated",
};

export const locumStatusesList = {
  ACCEPTED: "accepted",
  APPLIED: "applied",
  REJECTED: "rejected",
  INVITED: "invited",
  DECLINED: "declined",
};

// types of abilities
export const roleAbilityTypes = {
  CREATE_ABILITY: "create",
  READ_ABILITY: "read",
  UPDATE_ABILITY: "update",
  DELETE_ABILITY: "delete",
  MANAGE_ABILITY: "manage",
};

// user features abilities
export const roleFeatureTypes = {
  STAFF_EDIT_FEATURE: "staff_edit",
  STAFF_PERSONAL_INFO_EDIT: "staff_personal_info_edit",
  LOCUM_PERSONAL_INFO_EDIT: "locum_personal_info_edit",
  STAFF_PERSONAL_INFO_GPHC_VIEW: "staff_personal_info_gphc_view",
  PHARMACY_EDIT: "pharmacy_edit",
};

// google places api config
export const searchAddressConfig = {
  street_number: "short_name",
  route: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  administrative_area_level_2: "short_name",
  political: "short_name",
  country: "long_name",
  postal_code: "short_name",
  postal_town: "long_name",
};

// export const staffAbsencesType = {
//   sick_leave: "Sick Leave",
//   annual_leave: "Annual Leave",
//   maternity_leave: "Maternity Leave",
//   paternity_leave: "Paternity Leave",
//   bank_holiday: "Bank Holiday",
//   branch_swap: "Branch Swap",
//   sabatical: "Sabatical",
//   unpaid: "Unpaid",
//   lieu: "Lieu",
//   training: "Training",
//   double_cover: "Double Cover",
//   dispenser_cover: "Dispenser Cover",
//   none: "none",
// };

export const staffAbsencesType = {
  'sick_leave': 'Sick Leave',
  'annual_leave': 'Annual Leave',
  'maternity_leave': 'Maternity Leave',
  'paternity_leave': 'Paternity Leave',
  'bank_holiday': 'Bank Holiday',
  'branch_swap': 'Branch Swap',
  'sabatical': 'Sabatical',
  'unpaid': 'Unpaid',
  'lieu': 'Lieu',
  'training': 'Training',
  'double_cover': 'Double Cover',
  'dispenser_cover': 'Dispenser Cover'
};

export const leavesType = {
  absent: "Absent",
  leave: "Leave",
  present: "Present",
  shift_not_started: "Shift Not Started",
};
