import { Navigate, Route, Routes } from "react-router-dom"
import { BranchesListPage } from "./branchList"
import { BranchDetails } from "./details/branch-details-updated"
import { BranchBlockList } from "./details/branch-details-updated/blockList"
import { BranchInformation } from "./details/branch-details-updated/information"
import { BranchSchedule } from "./details/branch-details-updated/schedule"
import { BranchStaff } from "./details/branch-details-updated/staff"
import { BranchTemplate } from "./details/branch-details-updated/templates"
import { BranchDashboard } from "./details/branchDashboard"

export const BranchPage = () => {
    return (
        <Routes>
            <Route
                path={`/`}
                element={<BranchesListPage />}
            />
            <Route path={`/:id`} element={<BranchDashboard />} />
            <Route path="/:id/details/*" element={<BranchDetails />} >
                <Route path='information' element={<BranchInformation />} />
                <Route path='schedule' element={<BranchSchedule />} />
                <Route path='template' element={<BranchTemplate />} />
                <Route path='staff' element={<BranchStaff />} />
                <Route path='block-list' element={<BranchBlockList />} />
                <Route path="/:id/details/*" element={<Navigate to='information' />} />
            </Route>
        </Routes>
    )
}