import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Outlet, useNavigate, useParams } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { ContractForm, contractTypeOptions } from "../../../../../../components/staffs/contracts/contractForm";
import { DuplicateContractForm } from "../../../../../../components/staffs/contracts/duplicateContractForm";
import { TableComponet } from "../../../../../../components/UI/table";
import { TableToolbar } from "../../../../../../components/UI/table/tableToolBar";
import { deleteContractThunk, getStaffContractListThunk, startContractThunk } from "../../../../../../redux/staffs/staffsThunk";
import { RouterConfig } from "../../../../../../routerConfig";
import { hasAccess } from "../../../../../../utils/helper";
import { staffApiKeys } from "../../../../../../utils/apiKeyConstants";
import { EmptyData } from "../../../../../../components/comon/EmptyData";

const contractStatuses = {
    upcoming: 'Upcoming',
    closed: 'Closed',
    active: 'Active',
};

const contractOptions = [
    { id: '', value: "All", label: "All" },
    { id: 'upcoming', value: "Upcoming", label: "Upcoming" },
    { id: 'closed', value: "Closed", label: "Closed" },
    { id: 'active', value: "Active", label: "Active" },
];


export const StaffContracts = () => {
    const { staffId } = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { staffContractsList } = useSelector((state) => state.staffs);

    const [currentPageCount, setCurrentPageCount] = useState(1);
    const [perPageCount, setPerPageCount] = useState(10);
    const [showContractModal, setShowContractModal] = useState(false);
    const [actionType, setActionType] = useState('Create')

    const [selectedId, setSelectedId] = useState()
    const [contractOption, setContractOption] = useState('')

    const columns = React.useMemo(
        () => [
            {
                Header: "Contract Title",
                accessor: "title",
            },

            {
                Header: "Contract Type",
                accessor: "type",
                Cell: ({ row: { original } }) => {
                    return contractTypeOptions?.find((i) => i.value === original?.type)?.label
                },
            },
            {
                Header: "Start Date",
                accessor: "startDate",
            },
            {
                Header: "End Date",
                accessor: "endDate",

            },
            {
                Header: "Status",
                accessor: "status",
                Cell: ({ row: { original } }) => {
                    return (
                        <>
                            {original.status === 'active' ? (
                                <div>
                                    <span
                                        className="px-4 rounded-pill py-2"
                                        style={{ backgroundColor: "#E8F5E9", color: "#1B5E1F" }}
                                    >
                                        {contractStatuses[original.status]}
                                    </span>
                                </div>
                            ) : original.status === 'closed' ? (
                                <span
                                    className="px-4 rounded-pill py-2"
                                    style={{ backgroundColor: "#FFEBEE", color: "#EF5350" }}
                                >
                                    {contractStatuses[original.status]}
                                </span>
                            ) : (
                                <span
                                    className="px-4 rounded-pill py-2"
                                    style={{ backgroundColor: "#E3F2FD", color: "#1045A1" }}
                                >
                                    {contractStatuses[original.status]}
                                </span>
                            )}
                        </>
                    )
                },
            },
            {
                Header: "",
                accessor: "action",
                Cell: ({ row: { original } }) => {
                    return (
                        <div>
                            <Dropdown icon="ellipsis vertical" pointing="top right">
                                <Dropdown.Menu direction="left">
                                    {(hasAccess(staffApiKeys.START_CONTRACT)) && <Dropdown.Item
                                        text="Start Contract"
                                        onClick={() => {
                                            onStartContract(original.id)
                                        }}
                                    />}
                                    {hasAccess(staffApiKeys.DUPLICATE_CONTRACT) && <Dropdown.Item
                                        text={"Duplicate Contract"}
                                        onClick={() => {
                                            setShowContractModal(true)
                                            setActionType("Duplicate");
                                            setSelectedId(original.id)
                                        }}
                                    />}
                                    {hasAccess(staffApiKeys.DELETE_CONTRACT) && <Dropdown.Item
                                        style={{ color: "#EF5350" }}
                                        onClick={() => {
                                            onDeleteContract(original.id)
                                        }}
                                        text={"Delete Contract"}
                                    />}
                                    {hasAccess(staffApiKeys.CREATE_CONTRACT) && <Dropdown.Item
                                        text={"Create Contract"}
                                        onClick={() => {
                                            setShowContractModal(true)
                                            setActionType("Create");
                                            setSelectedId(original.id)
                                        }}
                                    />}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    );
                },
            },
        ],
        []
    );

    const {
        count: totalDataCount,
        page,
        pages: totalPagesCount,
        perPage,
        rows: staffContractList,
    } = useMemo(() => staffContractsList, [staffContractsList]);

    useEffect(() => {

        if (hasAccess(staffApiKeys.GET_CONTRACT_LIST)) {
            dispatch(
                getStaffContractListThunk({
                    staffId,
                    filters: {
                        status: contractOption
                    },
                    pagination: { page: currentPageCount, perPage: perPageCount },
                })
            );
        }
    }, [dispatch, staffId, currentPageCount, perPageCount, contractOption]);

    useEffect(() => {
        if (!page && !perPage) return;
        setPerPageCount(perPage);
        setCurrentPageCount(page);
    }, [page, perPage]);

    const formatTrProps = ({ original }) => {
        return {
            onClick: () => {
                navigate(
                    generatePath(`${RouterConfig.branchAdminStaffContractDetail}`, {
                        staffId,
                        contractId: original.id,
                    })
                )
            }
        };
    };

    const onDeleteContract = (id) => {
        dispatch(deleteContractThunk({
            staffId,
            contractId: id,
        })).then(res => {
            setShowContractModal(false)
            dispatch(
                getStaffContractListThunk({
                    staffId,
                    filters: {
                        status: contractOption
                    },
                    pagination: { page: currentPageCount, perPage: perPageCount },
                })
            );
        })
    }

    const onStartContract = (id) => {
        dispatch(startContractThunk({
            staffId,
            contractId: id,
        })).then(res => {
            setShowContractModal(false)
            dispatch(
                getStaffContractListThunk({
                    staffId,
                    filters: {
                        status: contractOption
                    },
                    pagination: { page: currentPageCount, perPage: perPageCount },
                })
            );
        })
    }

    return (
        <div className="">
            <TableToolbar
                showSelector
                headingText="Contracts"
                selectorOptions={contractOptions}
                setSelectorOption={setContractOption}
                primaryBtnHandler={() => {
                    setShowContractModal(true)
                    setActionType("Create")
                }}
                primaryBtnText={hasAccess(staffApiKeys.CREATE_CONTRACT) ? "Create Contract" : ""} />
            <div style={{ padding: "5px 24px" }}>
                {staffContractsList?.rows?.length <= 0 ? <EmptyData title="Contract" descriptio="Contract" /> : (
                    <TableComponet
                        currentPageCount={currentPageCount}
                        rowCursor="pointer"
                        perPageCount={perPageCount}
                        totalDataCount={totalDataCount}
                        setCurrentPage={setCurrentPageCount}
                        setPerPageCount={setPerPageCount}
                        showPagination
                        maxPageCount={totalPagesCount}
                        isBorderShown
                        columns={columns}
                        formatRowProps={hasAccess(staffApiKeys.GET_CONTRACT) ? formatTrProps : ""}
                        data={staffContractList || []}
                    />
                )}
            </div>
            {
                actionType === 'Duplicate' &&
                <DuplicateContractForm
                    selectedId={selectedId}
                    setShowContractModal={() => setShowContractModal(!showContractModal)}
                    showContractModal={showContractModal}
                />
            }
            {
                actionType === 'Create' &&
                <ContractForm
                    mode="Create"
                    setShowContractModal={() => setShowContractModal(!showContractModal)}
                    showContractModal={showContractModal}
                />
            }
            <Outlet />
        </div>
    );
};
