
import { Button, DatePicker, Form, Input, Upload } from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import { LocationSearchInput } from "../UI/input/locationSearchInput";
import { beforeUpload, compressImage, notify } from "../../utils/helper";


export const VerificationForm = ({
    staffInfo,
    onSubmit,
    setActionType,
    actionType,
    formId,
    areSteps,
    isGrouped
}) => {
    const [form] = Form.useForm();
    const { staffVerification } = useSelector(state => state.staffs)

    const image = Form.useWatch('image', form);
    const DBSCertificate = Form.useWatch('DBSCertificate', form);
    const identification = Form.useWatch('identification', form);
    const visa = Form.useWatch('visa', form);
    const professionalInsurance = Form.useWatch('professionalInsurance', form)
    const insuranceNumberDoc = Form.useWatch('insuranceNumberDoc', form)
    const NVQLevelTwo = Form.useWatch('NVQLevelTwo', form)
    const visaExpDate = Form.useWatch('visaExpDate', form)

    useEffect(() => {
        if (!staffVerification) return;
        form.setFieldsValue({
            address: staffVerification?.address,
            address1: staffVerification?.address1 ?? '',
            address2: staffVerification?.address2 ?? '',
            city: staffVerification?.city ?? '',
            DBSAnnualUpdate: staffVerification?.DBSAnnualUpdate ?? '',
            gphcNumber: staffVerification?.gphcInfo?.gphcNumber ?? '',
            id: staffVerification?.id,
            insuranceNumber: staffVerification?.insuranceNumber ?? '',
            latitude: staffVerification?.latitude,
            longitude: staffVerification?.longitude,
            name: staffVerification?.name,
            pharmacyName: staffVerification?.pharmacyName ?? '',
            phone: staffVerification?.phone ?? '',
            professionalInsuranceExpDate: staffVerification?.professionalInsuranceExpDate ? dayjs(staffVerification?.professionalInsuranceExpDate) : null,
            surname: staffVerification?.surname ?? '',
            visaExpDate: staffVerification?.visaExpDate ? dayjs(staffVerification?.visaExpDate) : null,
            zipCode: staffVerification?.zipCode ?? '',
            image: staffVerification?.image ? {
                fileList: [
                    {
                        uid: Math.random(),
                        name: 'image.png',
                        status: 'done',
                        url: staffVerification?.image,
                    }
                ]
            } : undefined,
            professionalInsurance: staffVerification?.professionalInsurance ? {
                fileList: [
                    {
                        uid: Math.random(),
                        name: `${staffVerification?.name}.${staffVerification?.professionalInsurance?.split('.').pop()}`,
                        status: 'done',
                        url: staffVerification?.professionalInsurance,
                    }
                ]
            } : undefined,
            DBSCertificate: staffVerification?.DBSCertificate ? {
                fileList: [
                    {
                        uid: Math.random(),
                        name: 'image.png',
                        status: 'done',
                        url: staffVerification?.DBSCertificate,
                    }
                ]
            } : undefined,
            identification: staffVerification?.proofOfId ? {
                fileList: [
                    {
                        uid: Math.random(),
                        name: 'image.png',
                        status: 'done',
                        url: staffVerification?.proofOfId,
                    }
                ]
            } : undefined,
            visa: staffVerification?.visa ? {
                fileList: [
                    {
                        uid: Math.random(),
                        name: 'image.png',
                        status: 'done',
                        url: staffVerification?.visa,
                    }
                ]
            } : undefined,
            insuranceNumberDoc: staffVerification?.insuranceNumberDoc ? {
                fileList: [
                    {
                        uid: Math.random(),
                        name: 'image.png',
                        status: 'done',
                        url: staffVerification?.insuranceNumberDoc,
                    }
                ]
            } : undefined,
            rightToWork: staffVerification?.rightToWork ? {
                fileList: [
                    {
                        uid: Math.random(),
                        name: 'image.png',
                        status: 'done',
                        url: staffVerification?.rightToWork,
                    }
                ]
            } : undefined,
            NVQLevelTwo: staffVerification?.NVQLevelTwo ? {
                fileList: [
                    {
                        uid: Math.random(),
                        name: 'image.png',
                        status: 'done',
                        url: staffVerification?.NVQLevelTwo,
                    }
                ]
            } : undefined,
            identificationExpDate: staffVerification?.proofOfIdExp ? dayjs(staffVerification?.proofOfIdExp) : null,

        })

    }, [staffVerification]);

    const handleAddressSelect = async ({ name, data: address }) => {
        form.setFieldsValue({
            ...form,
            address1: address.line_1,
            address2: address.line_2 ?? '',
            latitude: address.latitude,
            longitude: address.longitude,
            city: address.town_or_city,
            postalCode: address.postcode,
        });
    };

    const _onSubmit = (values) => {
        if (areSteps) {
            if (actionType === 'other') {
                setActionType('basic')
                return
            }
            if (actionType === 'basic') {
                setActionType('docs')
                return
            }
        }
        const reqBody = {
            ...values,
            id: staffVerification.id,
            image: !values?.image || values?.image?.file?.status === 'removed' ? "" : values?.image.file ? values?.image.file : values?.image?.fileList[0].url,
            insuranceNumberDoc: !values?.insuranceNumberDoc || values?.insuranceNumberDoc?.file?.status === 'removed' ? "" : values?.insuranceNumberDoc.file ? values?.insuranceNumberDoc.file : values?.insuranceNumberDoc?.fileList[0].url,
            DBSCertificate: !values?.DBSCertificate || values?.DBSCertificate?.file?.status === 'removed' ? "" : values?.DBSCertificate?.file ? values?.DBSCertificate?.file : values?.DBSCertificate?.fileList[0].url,
            rightToWork: !values?.rightToWork || values?.rightToWork?.file?.status === 'removed' ? "" : values?.rightToWork?.file ? values?.rightToWork?.file : values?.rightToWork?.fileList[0].url,
            identification: !values?.identification || values?.identification?.file?.status === 'removed' ? "" : values?.identification.file ? values?.identification.file : values?.identification?.fileList[0].url,
            professionalInsurance: !values?.professionalInsurance || values?.professionalInsurance?.file?.status === 'removed' ? "" : values?.professionalInsurance.file ? values?.professionalInsurance.file : values?.professionalInsurance?.fileList[0].url,
            visa: !values?.visa || values?.visa?.file?.status === 'removed' ? "" : values?.visa?.file ? values?.visa?.file : values?.visa?.fileList[0].url,
            NVQLevelTwo: !values?.NVQLevelTwo || values?.NVQLevelTwo?.file?.status === 'removed' ? "" : values?.NVQLevelTwo?.file ? values?.NVQLevelTwo?.file : values?.NVQLevelTwo?.fileList[0].url,
            visaExpDate: values.visaExpDate ? dayjs(values.visaExpDate).format('YYYY-MM-DD') : '',
            identificationExpDate: values.identificationExpDate ? dayjs(values.identificationExpDate).format('YYYY-MM-DD') : '',
            professionalInsuranceExpDate: values.professionalInsuranceExpDate ? dayjs(values.professionalInsuranceExpDate).format('YYYY-MM-DD') : ''
        }
        let formData = new FormData();
        for (let key in reqBody) {
            if (reqBody[key] instanceof Blob) {
                formData.append(key, reqBody[key], reqBody[key]?.name);
            } else {
                formData.append(key, reqBody[key]);
            }
        }
        onSubmit(formData)
    }

    return (
        <Form
            onFinish={_onSubmit}
            form={form}
            id={formId}
            style={{ width: '100%' }}
            layout="vertical"
            autoComplete="off"
        >
            <Form.Item
                label="First Name"
                name="name"
                hidden
            >
                <Input
                    defaultValue={undefined}
                    className='text-input-field p-2 pe-3 t1'
                />
            </Form.Item>
            <Form.Item
                label="Last name"
                name="surname"
                hidden
            >
                <Input
                    defaultValue={undefined}
                    className='text-input-field p-2 pe-3 t1'
                />
            </Form.Item>
            <Form.Item
                label="Last name"
                name="longitude"
                hidden
            >
                <Input
                    defaultValue={undefined}
                    className='text-input-field p-2 pe-3 t1'
                />
            </Form.Item>
            <Form.Item
                label="Last name"
                name="latitude"
                hidden
            >
                <Input
                    defaultValue={undefined}
                    className='text-input-field p-2 pe-3 t1'
                />
            </Form.Item>
            <Form.Item
                label="Address Line 1"
                hidden={isGrouped ? false : actionType === 'basic' ? false : true}
                name="address1"
                rules={[{
                    required: (isGrouped || actionType === 'basic') ? true : false, message: "Address Line 1 No is Required"
                }]}
            >
                <LocationSearchInput
                    className='text-input-field p-2 pe-3 t1'
                    name="address1"
                    value={form?.address1}
                    onHandleSelect={handleAddressSelect}
                />
            </Form.Item>
            <Form.Item
                label="Address Line 2"
                defaultValue=''
                name="address2"
                hidden={isGrouped ? false : actionType === 'basic' ? false : true}
            >
                <LocationSearchInput
                    value={form?.address2}
                    className='text-input-field p-2 pe-3 t1'
                    name="address2"
                    onHandleSelect={handleAddressSelect}
                />
            </Form.Item>
            <Form.Item
                label="Town/City"
                hidden={actionType === 'basic' ? false : true}
                name="city"
            >
                <Input
                    defaultValue={undefined}
                    onBlur={e => form.setFieldsValue({ 'city': e.target.value.trim() })}
                    placeholder="Town/City"
                    className='text-input-field p-2 pe-3 t1'
                />
            </Form.Item>
            <Form.Item
                label="Postal Code"
                hidden={isGrouped ? false : actionType === 'basic' ? false : true}
                name="zipCode"
            >
                <Input
                    max={20}
                    onBlur={e => form.setFieldsValue({ 'zipCode': e.target.value.trim() })}
                    placeholder="Postal Code"
                    defaultValue={undefined}
                    className='text-input-field p-2 pe-3 t1'
                />
            </Form.Item>
            <Form.Item
                label="Phone"
                name="phone"
                hidden={isGrouped ? false : actionType === 'basic' ? false : true}
                rules={[{
                    required: (isGrouped || actionType === 'basic') ? true : false, message: "Phone No is Required"
                }]}
            >
                <Input
                    type="number"
                    placeholder="Phone"
                    defaultValue={undefined}
                    className='text-input-field p-2 pe-3 t1'
                />
            </Form.Item>
            <Form.Item
                label="GPhC Number"
                hidden={isGrouped ? false : actionType === 'other' && !areSteps ? false : true}
                name="gphcNumber"
                normalize={value => {
                    if (value.length > 7)
                        return value.slice(0, 7)
                    return value
                }}
                rules={[
                    {
                        len: 7,
                        message: 'GPhC Number should be 7 character long.'
                    }
                ]}
            >
                <Input
                    disabled
                    type="number"
                    defaultValue={undefined}
                    placeholder="GPhC Number"
                    className='text-input-field p-2 pe-3 t1'
                />
            </Form.Item>
            {
                areSteps &&
                <Form.Item
                    label="Profile Picture"
                    name="image"
                    hidden={isGrouped ? false : actionType === 'other' ? false : true}
                    rules={[{
                        required: (isGrouped || actionType === 'other') ? true : false,
                        message: 'Profile Picture is required'
                    }]}
                >
                    <Upload
                        accept={'image/*'}
                        fileList={image?.fileList}
                        showUploadList={{
                            showPreviewIcon: false,
                            showRemoveIcon: true,
                        }}
                        onPreview={() => { }}
                        beforeUpload={beforeUpload}
                        onChange={async ({ file, fileList }) => {
                            try {
                                const compressedFile = await compressImage(file);
                                form.setFieldsValue({ image: { file: compressedFile, fileList } });
                            } catch (error) {
                                notify('error', 'Error compressing the image:');
                            }
                        }}
                        multiple={false}
                        maxCount={1}
                        listType="picture-card"
                        className="text-center"
                    >
                        {
                            (image?.file?.status === "removed" || !image) &&
                            <div
                                className="my-3"
                            >
                                Upload
                            </div>
                        }
                    </Upload>
                </Form.Item>
            }
            <Form.Item
                hidden={isGrouped ? false : actionType === 'other' ? false : true}
                label="DBS Annual Update Service Number"
                name="DBSAnnualUpdate"
                rules={[
                    {
                        max: 20,
                        message: 'DBS Annual Update Service Number can not be longer than 20 characters!'
                    }
                ]}
            >
                <Input
                    defaultValue={undefined}
                    placeholder="DBS Annual Update Service Number"
                    className='text-input-field p-2 pe-3 t1'
                />
            </Form.Item>
            <Form.Item
                hidden={isGrouped ? false : actionType === 'other' ? false : true}
                label="National Insurance Number"
                name="insuranceNumber"
                rules={[
                    {
                        max: 20,
                        message: 'National Insurance Number can not be longer than 20 characters!'
                    }
                ]}
            >
                <Input
                    defaultValue={undefined}
                    placeholder="National Insurance Number"
                    className='text-input-field p-2 pe-3 t1'
                />
            </Form.Item>
            {
                !areSteps &&
                <Form.Item
                    label="Profile Picture"
                    name="image"
                    hidden={isGrouped ? false : actionType === 'docs' ? false : true}
                    rules={[{
                        required: (isGrouped || actionType === 'docs') ? true : false,
                        message: 'Profile Picture is required'
                    }]}
                >
                    <Upload
                        accept={'image/*'}
                        fileList={image?.fileList}
                        onPreview={() => { }}
                        beforeUpload={beforeUpload}
                        onChange={async ({ file, fileList }) => {
                            try {
                                const compressedFile = await compressImage(file);
                                form.setFieldsValue({ image: { file: compressedFile, fileList } });
                            } catch (error) {
                                notify('error', 'Error compressing the image:');
                            }
                        }}
                        multiple={false} maxCount={1} listType="picture">
                        {
                            (image?.file?.status === "removed" || !image) &&
                            <Button
                                className="my-3"
                                style={{
                                    width: "100%",
                                    height: "40px",
                                    color: "#51C2BC",
                                    border: "2px dashed #51C2BC",
                                    backgroundColor: "#F0F9F9",
                                }}
                                icon={<UploadOutlined />}
                            >
                                Upload Document
                            </Button>
                        }
                    </Upload>
                </Form.Item>
            }
            <Form.Item
                style={{ width: '100%' }}
                hidden={isGrouped ? false : actionType === 'docs' ? false : true}
                label="Proof Of ID Expiry Date"
                name="identificationExpDate"
                rules={[{
                    required: (isGrouped || actionType === 'docs') ? true : false,
                    message: 'Please Select Proof of Id Expire Date'
                }]}
            >
                <DatePicker
                    inputReadOnly format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    className="text-input-field p-2 pe-3 t1"
                    style={{ width: '100%' }}
                />
            </Form.Item>
            <Form.Item
                hidden={isGrouped ? false : actionType === 'docs' ? false : true}
                label="Proof Of ID (Passport or Driving License)"
                name="identification"
                rules={[{
                    required: (isGrouped || actionType === 'docs') ? true : false,
                    message: 'Proof of ID is required'
                }]}
            >
                <Upload
                    accept={'image/*, .doc, .pdf'}
                    fileList={identification?.fileList}
                    onPreview={() => { }}
                    beforeUpload={beforeUpload}
                    onChange={async ({ file, fileList }) => {
                        try {
                            const compressedFile = await compressImage(file);
                            form.setFieldsValue({ identification: { file: compressedFile, fileList } });
                        } catch (error) {
                            notify('error', 'Error compressing the image:');
                        }
                    }}
                    multiple={false} maxCount={1} listType="picture">

                    {
                        (identification?.file?.status === "removed" || !identification) &&
                        <Button
                            className="my-3"
                            style={{
                                width: "100%",
                                height: "40px",
                                color: "#51C2BC",
                                border: "2px dashed #51C2BC",
                                backgroundColor: "#F0F9F9",
                            }}
                            icon={<UploadOutlined />}
                        >
                            Upload Document
                        </Button>
                    }
                </Upload>
            </Form.Item>
            <Form.Item
                style={{ width: '100%' }}
                hidden={isGrouped ? false : actionType === 'docs' ? false : true}
                label="Work Visa Expiry Date"
                rules={[{
                    required: (isGrouped || actionType === 'docs') && !(visa?.file?.status === "removed" || !visa) ? true : false,
                    message: 'Work Visa Expiry Date is required'
                }]}
                name="visaExpDate"
            >
                <DatePicker
                    inputReadOnly format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    className="text-input-field p-2 pe-3 t1"
                    style={{ width: '100%' }}
                />
            </Form.Item>
            <Form.Item
                hidden={isGrouped ? false : actionType === 'docs' ? false : true}
                label="Work Visa (British Passport or BRP)"
                rules={[{
                    required: (isGrouped || actionType === 'docs') && visaExpDate ? true : false,
                    message: 'Work Visa is required'
                }]}
                name="visa"
            >
                <Upload
                    accept={'image/*, .doc, .pdf'}
                    fileList={visa?.fileList}
                    onPreview={() => { }}
                    beforeUpload={beforeUpload}
                    onChange={async ({ file, fileList }) => {
                        try {
                            const compressedFile = await compressImage(file);
                            form.setFieldsValue({ visa: { file: compressedFile, fileList } });
                        } catch (error) {
                            notify('error', 'Error compressing the image:');
                        }
                    }}
                    multiple={false} maxCount={1} listType="picture">
                    {
                        (visa?.file?.status === "removed" || !visa) &&
                        <Button
                            className="my-3"
                            style={{
                                width: "100%",
                                height: "40px",
                                color: "#51C2BC",
                                border: "2px dashed #51C2BC",
                                backgroundColor: "#F0F9F9",
                            }}
                            icon={<UploadOutlined />}
                        >
                            Upload Document
                        </Button>
                    }
                </Upload>
            </Form.Item>


            <Form.Item
                style={{ width: '100%' }}
                hidden={staffInfo?.role === 'locum' ? (isGrouped ? false : actionType === 'docs' ? false : true) : true}
                label="Professional Insurance Expiry Date"
                name="professionalInsuranceExpDate"
                rules={[{
                    required: (isGrouped || actionType === 'docs') && staffInfo?.role === 'locum' ? true : false,
                    message: 'Please Select Proof of Id Expire Date'
                }]}
            >
                <DatePicker
                    inputReadOnly format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    className="text-input-field p-2 pe-3 t1"
                    style={{ width: '100%' }}
                />
            </Form.Item>
            <Form.Item
                hidden={staffInfo?.role === 'locum' ? (isGrouped ? false : actionType === 'docs' ? false : true) : true}
                label="Professional Insurance (Indemnity)"
                name="professionalInsurance"
                rules={[{
                    required: (isGrouped || actionType === 'docs') && staffInfo?.role === 'locum' ? true : false,
                    message: 'Indemnity is required'
                }]}
            >
                <Upload
                    accept={'image/*, .doc, .pdf'}
                    fileList={professionalInsurance?.fileList}
                    onPreview={() => { }}
                    beforeUpload={beforeUpload}
                    onChange={async ({ file, fileList }) => {
                        try {
                            const compressedFile = await compressImage(file);
                            form.setFieldsValue({ professionalInsurance: { file: compressedFile, fileList } });
                        } catch (error) {
                            notify('error', 'Error compressing the image:');
                        }
                    }}
                    multiple={false} maxCount={1} listType="picture">

                    {
                        (professionalInsurance?.file?.status === "removed" || !professionalInsurance) &&
                        <Button
                            className="my-3"
                            style={{
                                width: "100%",
                                height: "40px",
                                color: "#51C2BC",
                                border: "2px dashed #51C2BC",
                                backgroundColor: "#F0F9F9",
                            }}
                            icon={<UploadOutlined />}
                        >
                            Upload Document
                        </Button>
                    }
                </Upload>
            </Form.Item>

            <Form.Item
                label="Enhanced DBS Certificate"
                hidden={(staffInfo?.roleId === 4 || staffInfo?.roleId === 6) ? (isGrouped ? false : actionType === 'docs' ? false : true) : true}
                name="DBSCertificate"
            >
                <Upload
                    accept={'image/*, .doc, .pdf'}
                    fileList={DBSCertificate?.fileList}
                    onPreview={() => { }}
                    beforeUpload={beforeUpload}
                    onChange={async ({ file, fileList }) => {
                        try {
                            const compressedFile = await compressImage(file);
                            form.setFieldsValue({ DBSCertificate: { file: compressedFile, fileList } });
                        } catch (error) {
                            notify('error', 'Error compressing the image:');
                        }
                    }}
                    multiple={false} maxCount={1} listType="picture">
                    {
                        (DBSCertificate?.file?.status === "removed" || !DBSCertificate) &&
                        <Button
                            className="my-3"
                            style={{
                                width: "100%",
                                height: "40px",
                                color: "#51C2BC",
                                border: "2px dashed #51C2BC",
                                backgroundColor: "#F0F9F9",
                            }}
                            icon={<UploadOutlined />}
                        >
                            Upload Document
                        </Button>
                    }
                </Upload>
            </Form.Item>

            <Form.Item
                hidden={isGrouped ? false : actionType === 'docs' ? false : true}
                label="National Insurance"
                name="insuranceNumberDoc"
                rules={[{
                    required: (isGrouped || actionType === 'docs') ? true : false,
                    message: 'National Insurance is required'
                }]}
            >
                <Upload
                    accept={'image/*, .doc, .pdf'}
                    fileList={insuranceNumberDoc?.fileList}
                    onPreview={() => { }}
                    beforeUpload={beforeUpload}
                    onChange={async ({ file, fileList }) => {
                        try {
                            const compressedFile = await compressImage(file);
                            form.setFieldsValue({ insuranceNumberDoc: { file: compressedFile, fileList } });
                        } catch (error) {
                            notify('error', 'Error compressing the image:');
                        }
                    }}
                    multiple={false} maxCount={1} listType="picture">

                    {
                        (insuranceNumberDoc?.file?.status === "removed" || !insuranceNumberDoc) &&
                        <Button
                            className="my-3"
                            style={{
                                width: "100%",
                                height: "40px",
                                color: "#51C2BC",
                                border: "2px dashed #51C2BC",
                                backgroundColor: "#F0F9F9",
                            }}
                            icon={<UploadOutlined />}
                        >
                            Upload Document
                        </Button>
                    }
                </Upload>
            </Form.Item>
            <Form.Item
                label="NVQ Level 2"
                hidden={(staffInfo?.roleId === 8) ? (isGrouped ? false : actionType === 'docs' ? false : true) : true}
                name="NVQLevelTwo"
            >
                <Upload
                    accept={'image/*, .doc, .pdf'}
                    fileList={NVQLevelTwo?.fileList}
                    onPreview={() => { }}
                    beforeUpload={beforeUpload}
                    onChange={async ({ file, fileList }) => {
                        try {
                            const compressedFile = await compressImage(file);
                            form.setFieldsValue({ NVQLevelTwo: { file: compressedFile, fileList } });
                        } catch (error) {
                            notify('error', 'Error compressing the image:');
                        }
                    }}
                    multiple={false} maxCount={1} listType="picture">
                    {
                        (NVQLevelTwo?.file?.status === "removed" || !NVQLevelTwo) &&
                        <Button
                            className="my-3"
                            style={{
                                width: "100%",
                                height: "40px",
                                color: "#51C2BC",
                                border: "2px dashed #51C2BC",
                                backgroundColor: "#F0F9F9",
                            }}
                            icon={<UploadOutlined />}
                        >
                            Upload Document
                        </Button>
                    }
                </Upload>
            </Form.Item>
        </Form>
    );
};
