import moment from "moment";
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import addItemIcon from "../../../../../assets/icons/add.png";
import actionBtnArr from "../../../../../assets/icons/actionBtnArr.png";
import DashboardTable from "../../../../../components/dashboard/dashboardTable";
import { DetailTab } from "../../../../../components/dashboard/DetailTabs";
import { Heading } from "../../../../../components/UI/heading";
import { RouterConfig } from "../../../../../routerConfig";
import {
  exportBranchGapsCSVThunk,
  getBranchDashboardDetailThunk,
  getBranchGapsDataThunk,
  getPendingJobDetailThunk,
} from "../../../../../redux/branches/branchesThunk";
import { groupBy, mapValues, omit } from "lodash";
import { hasAccess } from "../../../../../utils/helper";
import {
  branchApiKeys,
  locumShiftApiKeys,
  regionApiKeys,
  settingsApiKeys,
  staffApiKeys,
} from "../../../../../utils/apiKeyConstants";
import { ShiftJobForm } from "../../../../../components/comon/form";
import {
  getRolesThunk,
  getStaffsThunk,
} from "../../../../../redux/schedule/scheduleThunk";
import {
  getAccommodationAndFoodRulesThunk,
  getMileageRulesThunk,
  getParkingRulesThunk,
  getTransportRulesThunk,
} from "../../../../../redux/profile/profileThunk";
import { getRegionsListDropDownThunk } from "../../../../../redux/regions/regionsThunk";
import { moduleKeys } from "../../../../../utils/modulesKeyConstants";
import dayjs from "dayjs";
import { WarningBar } from "../../../../../components/UI/header/warningBar";
import { DashboardDateFilter } from "../../../../../components/dashboard/dashboardDateFilter";
import { ShiftJobFormUpdated } from "../../../../../components/comon/addShiftForm";

const branchSideBarModulesArray = [
  {
    key: moduleKeys.BRANCH_INFORMATION,
  },
  {
    key: moduleKeys.BRANCH_SCHEDULE,
  },
  {
    key: moduleKeys.BRANCH_TEMPLATE,
  },
  {
    key: moduleKeys.BRANCH_STAFF,
  },
  {
    key: moduleKeys.BRANCH_BLOCK_LIST,
  },
];
export const BranchDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [showAddShiftModal, setShowAddShiftModal] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [dateRange, setDateRange] = useState();
  const [flag, setFlag] = useState(true);
  const [gapData, setGapData] = useState();
  const { accessibleModules: modules } = useSelector((state) => state.auth);
  const [showViewButton, setViewButton] = useState(false);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setDateRange({
      startDateRange: dayjs().startOf("isoWeek"),
      endDateRange: dayjs().endOf("isoWeek"),
    });
    showViewBtnFunction();
  }, []);

  const { branchDashboardData, branchGapsData, pendingJobApprovalData } =
    useSelector((state) => state.branches);

  const pendingJobsColumns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "startDate",
        Cell: ({ row: { original } }) => {
          return moment(original.startDate).format("DD-MM-YYYY");
        },
      },

      {
        Header: "Job ID",
        accessor: "id",
      },
      {
        Header: "Branch",
        accessor: "branchTitle",
      },
      {
        Header: "Duration",
        accessor: "startTime",
        Cell: ({ row: { original } }) => {
          return original.startTime + " - " + original.endTime;
        },
      },
      {
        Header: "Total Rate",
        accessor: "hourlyRate",
        Cell: ({ row: { original } }) => {
          return `£${original?.hourlyRate}`;
        },
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row: { original } }) => {
          return (
            <img
              style={{ cursor: "pointer" }}
              src={actionBtnArr}
              alt=""
              onClick={() => {
                navigate(
                  generatePath(RouterConfig.branchAdminJobPreview, {
                    shiftId: original.id,
                  })
                );
              }}
            />
          );
        },
      },
    ],
    []
  );

  const brancGapColumns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row: { original } }) => {
          return moment(original.date).format("DD-MM-YYYY");
        },
      },

      {
        Header: "Time Slot",
        accessor: "startTime",
        Cell: ({ row: { original } }) => {
          return original.startTime + " - " + original.endTime;
        },
      },
      {
        Header: "Missing hours",
        accessor: "duration",
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row: { original } }) => {
          return (
            <img
              style={{ cursor: "pointer" }}
              src={addItemIcon}
              alt=""
              onClick={() => {
                setGapData(original);
                setShowAddShiftModal(!showAddShiftModal);
              }}
            />
          );
        },
      },
    ],
    []
  );

  const showViewBtnFunction = () => {
    if (!modules) return false;
    let modulesArray = Object.values(modules);
    let linksToDisplay = [];

    for (let link of branchSideBarModulesArray) {
      for (let module of modulesArray) {
        if (link?.key?.toLowerCase() === module?.moduleKey?.toLowerCase()) {
          linksToDisplay.push(link);
        }
      }
    }
    setViewButton(linksToDisplay.length > 0 ? true : false);
    setLoading(false);
  };
  const ActiveJobsTab = (params) => {
    return (
      <>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ width: "100%" }}
        >
          <span className="heading2 fw-bold">
            {branchDashboardData?.branch_jobs?.find(
              (val) => val.status === "active"
            )?.total_jobs || 0}
          </span>
        </div>
      </>
    );
  };

  const UnfilledJobsTab = (params) => {
    return (
      <>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ width: "100%" }}
        >
          <span className="heading2 fw-bold">
            {branchDashboardData?.branch_jobs?.find(
              (val) => val.status === "unfilled"
            )?.total_jobs || 0}
          </span>
        </div>
      </>
    );
  };

  const ActiveLocums = () => {
    var grouped = mapValues(
      groupBy(branchDashboardData?.activeLocums?.locum_jobs, "roleType.label"),
      (staffList) => staffList.map((car) => car)
    );
    return (
      <div
        className="d-flex align-items-center  justify-content-between"
        style={{ width: "100%" }}
      >
        {Object.values(grouped)?.length > 0 ? (
          <>
            {Object.values(grouped)?.map((val, i) => {
              return val[0]?.roleType?.label === "Locum Technician" ||
                val[0]?.roleType?.label === "Locum Pharmacist" ? (
                <div key={i} className="d-flex align-items-center">
                  <span className="heading2 fw-bold">{val.length}</span>
                  <div
                    className=" t2 breadCrumps-role-detail"
                    style={{ color: "#7B7B7B", marginLeft: "5px" }}
                  >
                    {val[0]?.roleType?.label?.split(" ")[1]}
                  </div>
                </div>
              ) : (
                " "
              );
            })}
          </>
        ) : (
          <>
            <div className="d-flex align-items-center">
              <div className="heading2 fw-bold"> 0 </div>
              <div
                className=" t2 breadCrumps-role-detail"
                style={{ color: "#7B7B7B", marginLeft: "5px" }}
              >
                Pharmacists
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="heading2 fw-bold"> 0 </div>
              <div
                className=" t2 breadCrumps-role-detail"
                style={{ color: "#7B7B7B", marginLeft: "5px" }}
              >
                Technician{" "}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const ActiveStaff = (params) => {
    return (
      <div
        className="d-flex align-items-center justify-content-between "
        style={{ width: "100%", gap: "15px", position: "relative" }}
      >
        {staffData?.length > 0 ? (
          <>
            {staffData?.map((val, i) => {
              return val[0].roleTitle === "Staff Pharmacist" ||
                val[0].roleTitle === "Staff Technician" ||
                val[0].roleTitle === "Staff Dispenser" ? (
                <div key={i} className="d-flex align-items-center">
                  <div className="heading2 fw-bold">{val.length}</div>
                  <div
                    className=" t2 breadCrumps-role-detail"
                    style={{ color: "#7B7B7B", marginLeft: "5px" }}
                  >
                    {val[0].roleTitle?.split(" ")[1]}
                  </div>
                </div>
              ) : (
                " "
              );
            })}
          </>
        ) : (
          <>
            <div className="d-flex align-items-center">
              <div className="heading2 fw-bold"> 0 </div>
              <div
                className=" t2 breadCrumps-role-detail"
                style={{ color: "#7B7B7B", marginLeft: "5px" }}
              >
                Pharmacists
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="heading2 fw-bold"> 0 </div>
              <div
                className=" t2 breadCrumps-role-detail"
                style={{ color: "#7B7B7B", marginLeft: "5px" }}
              >
                Technician{" "}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="heading2 fw-bold"> 0 </div>
              <div
                className=" t2 breadCrumps-role-detail"
                style={{ color: "#7B7B7B", marginLeft: "5px" }}
              >
                Dispenser
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const viewDetailsBtnHandler = () => {
    navigate(generatePath(RouterConfig.branchAdminBranchInformation, { id }));
  };

  const { rows: pendingJobsTableData } = useMemo(
    () => pendingJobApprovalData,
    [pendingJobApprovalData]
  );

  useEffect(() => {
    var grouped = mapValues(
      groupBy(branchDashboardData?.branch_staff?.rows, "role"),
      (staffList) => staffList.map((car) => omit(car, "role"))
    );
    setStaffData(Object.values(grouped));
  }, [branchDashboardData]);

  useEffect(() => {
    if (!dateRange || !flag) return;

    if (hasAccess(branchApiKeys.BRANCH_DASHBOARD)) {
      dispatch(
        getBranchDashboardDetailThunk({
          id,
          startDate: dateRange?.startDateRange.format("YYYY-MM-DD"),
          endDate: dateRange?.endDateRange.format("YYYY-MM-DD"),
        })
      );
    }

    if (hasAccess(branchApiKeys.BRANCH_GAPS)) {
      dispatch(
        getBranchGapsDataThunk({
          id,
          startDateRange: dateRange?.startDateRange.format("YYYY-MM-DD"),
          endDateRange: dateRange?.endDateRange.format("YYYY-MM-DD"),
        })
      );
    }

    if (hasAccess(locumShiftApiKeys.GET_JOB_LIST)) {
      dispatch(
        getPendingJobDetailThunk({
          filters: {
            branchId: Number(id),
            status: "pending",
            startDate: dateRange?.startDateRange.format("YYYY-MM-DD"),
            endDate: dateRange?.endDateRange.format("YYYY-MM-DD"),
          },
        })
      );
      setFlag(false);
    }
  }, [dateRange, dispatch, id, flag]);

  useEffect(() => {
    if (hasAccess(settingsApiKeys.GET_SETTINGS_ALL_MILEAGE_RULES))
      dispatch(getMileageRulesThunk());
    if (hasAccess(settingsApiKeys.GET_SETTINGS_PARKING_RULES))
      dispatch(getParkingRulesThunk());
    if (hasAccess(settingsApiKeys.GET_ALL_TRANSPORT_RULES))
      dispatch(getTransportRulesThunk());
    if (hasAccess(settingsApiKeys.GET_ALL_FOOD_RULES))
      dispatch(getAccommodationAndFoodRulesThunk());
    if (hasAccess(regionApiKeys.GET_REGION_DROP_DOWN_LIST))
      dispatch(getRegionsListDropDownThunk());
    dispatch(
      getRolesThunk({
        group: ["staff", "locum"],
        type: "Default",
      })
    );
    if (hasAccess(staffApiKeys.GET_ALL_STAFF)) dispatch(getStaffsThunk());
  }, [dispatch]);

  const downloadGapsCSV = () => {
    dispatch(exportBranchGapsCSVThunk({
      id,
      filter: {
        startDateRange: dateRange?.startDateRange?.format('YYYY-MM-DD'),
        endDateRange: dateRange?.endDateRange?.format('YYYY-MM-DD'),
      }
    })).then(res => {
      if (res.payload.status === "success") {
        let fetchDataModified = `data:application/csv;base64,${res.payload.data}`;
        let a = document.createElement('a');
        a.href = fetchDataModified;
        a.download = 'result.csv';
        a.click();
        return;
      }
    })
  }

  return isLoading ? (
    ""
  ) : (
    <div className="content-view-page details-page">
      {
        branchDashboardData && !branchDashboardData.active &&
        <WarningBar>
          This Branch Does Not Seem To Have An Active Subscription, To Enjoy Un-Interrupted Access, Please
          <u className="cursor-pointer" onClick={() => navigate('/profile/subscription')} >Activate Your Subscription!</u>
        </WarningBar>
      }
      <div className="main-wrapper">
        <div className="dashboard-page">
          <Heading
            viewDetailsBtnHandler={viewDetailsBtnHandler}
            label={branchDashboardData?.title || "Branch Name"}
            viewDetailsBtn={showViewButton}
            dateFilter
            filterChildren={<DashboardDateFilter dateRange={dateRange} setDateRange={setDateRange} setFlag={setFlag} />}
            view="weekly"
          />
          <div className="content-container-dashboard  p-4">
            <div
              className="d-flex"
              style={{ flex: 1, gap: "15px", margin: "20px 0px" }}
            >
              <div
                className="d-flex "
                style={{
                  flex: 1,
                  gap: "15px",
                  justifyContent: "space-between",
                }}
              >
                <DetailTab label="Active Jobs " content={<ActiveJobsTab />} />
                <DetailTab label="Unfilled Job" content={<UnfilledJobsTab />} />
              </div>
              <div
                className="d-flex"
                style={{
                  flex: 1,
                  gap: "15px",
                  justifyContent: "space-between",
                }}
              >
                <DetailTab label="Active Locums " content={<ActiveLocums />} />

                <DetailTab label="Active Staff " content={<ActiveStaff />} />
              </div>
            </div>
            <div className="d-flex flex-column" style={{ gap: "20px", paddingBottom: "23px" }}>
              <DashboardTable
                isExportBtn={hasAccess(branchApiKeys.EXPORT_BRANCH_GAPS_CSV)}
                exportCSVHandler={downloadGapsCSV}
                label="Branch Gaps"
                count={branchGapsData?.length}
                isPieChart
                tableCols={brancGapColumns}
                tableData={branchGapsData}
                locums={branchDashboardData?.activeLocums?.total_locums}
                permanentStaff={branchDashboardData?.branch_staff?.count}
              />
              <DashboardTable
                label="Pending Jobs Approval"
                count={pendingJobsTableData?.length}
                isBarChart
                tableCols={pendingJobsColumns}
                tableData={pendingJobsTableData}
                barChartData={branchDashboardData?.locum_expenses}
              />
            </div>
          </div>
        </div>
      </div>
      {showAddShiftModal && (
        <ShiftJobFormUpdated
          isGap={true}
          gapData={gapData}
          onSuccess={() => setFlag(true)}
          branchId={gapData?.branchId}
          selectedDate={gapData?.date}
          showModal={showAddShiftModal}
          setHandleModal={() => setShowAddShiftModal(!showAddShiftModal)}
        />
      )}
    </div>
  );
};
