import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Outlet, useNavigate, useParams } from "react-router-dom";
import { BranchCard } from "../../../../../components/UI/cards/profileCard";
import { ContentContainer } from "../../../../../components/UI/container";
import { Sidebar } from "../../../../../components/UI/sideBar";
import {
  getBranchDataThunk,
  getBranchRelatedDataThunk,
} from "../../../../../redux/branches/branchesThunk";
import { RouterConfig } from "../../../../../routerConfig";
import { hasAccess } from "../../../../../utils/helper";
import { branchApiKeys } from "../../../../../utils/apiKeyConstants";
import { moduleKeys } from "../../../../../utils/modulesKeyConstants";
import "./style.scss";
import { WarningBar } from "../../../../../components/UI/header/warningBar";

export const BranchDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { id } = useParams();
  const { branchInfo } = useSelector((state) => state.branches);

  useEffect(() => {
    if (hasAccess(branchApiKeys.GET_BRANCH_DATA)) {
      dispatch(getBranchRelatedDataThunk());
    }
    if (hasAccess(branchApiKeys.GET_BRANCH)) {
      dispatch(getBranchDataThunk({ id }));
    }
  }, [dispatch, id]);

  const sidebarLinks = useMemo(
    () => [
      {
        text: "Information",
        to: generatePath(RouterConfig.branchAdminBranchInformation, { id }),
        key: moduleKeys.BRANCH_INFORMATION,
        disabled: false,
        hidden: false,
      },
      {
        text: "Schedule",
        to: generatePath(RouterConfig.branchAdminBranchSchedule, { id }),
        key: moduleKeys.BRANCH_SCHEDULE,
        disabled: false,
        hidden: false,
      },
      {
        text: "Template",
        to: generatePath(RouterConfig.branchAdminBranchTemplate, { id }),
        key: moduleKeys.BRANCH_TEMPLATE,
        disabled: false,
        hidden: false,
      },
      {
        text: "Staff",
        to: generatePath(RouterConfig.branchAdminBranchStaff, { id }),
        key: moduleKeys.BRANCH_STAFF,
        disabled: false,
        hidden: false,
      },
      {
        text: "Block List",
        to: generatePath(RouterConfig.branchAdminBranchBlockList, { id }),
        key: moduleKeys.BRANCH_BLOCK_LIST,
        disabled: false,
        hidden: false,
      },
    ],
    [id]
  );

  const { accessibleModules: modules } = useSelector((state) => state.auth);
  const [displaySidebarLinks, setDisplaySidebarLinks] = useState([]);
  useEffect(() => {
    if (!modules) return;
    let modulesArray = Object.values(modules);
    let linksToDisplay = [];

    for (let link of sidebarLinks) {
      for (let module of modulesArray) {
        if (link?.key?.toLowerCase() === module?.moduleKey?.toLowerCase()) {
          linksToDisplay.push(link);
        }
      }
    }

    setDisplaySidebarLinks(linksToDisplay);
  }, [modules]);

  return (
    <div className="content-view-page details-page">
      {
        branchInfo && !branchInfo.active &&
        <WarningBar>
          This Branch Does Not Seem To Have An Active Subscription, To Enjoy Un-Interrupted Access, Please
          <u className="cursor-pointer" onClick={() => navigate('/profile/subscription')} >Activate Your Subscription!</u>
        </WarningBar>
      }
      <div className="main-wrapper p-5">
        <div className="content-holder">
          <Sidebar links={displaySidebarLinks} visible>
            <BranchCard
              // isUser
              logo={branchInfo?.logo}
              primaryText={branchInfo?.title}
              secondaryText={branchInfo?.city}
            />
          </Sidebar>
          <ContentContainer className="content-container" fullWidth>
            <Outlet />
          </ContentContainer>
        </div>
      </div>
    </div>
  );
};
