import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ShiftJobForm } from "../../../components/comon/form";
import DayView from "../../../components/schedule/DayView/DayView";
import ToolBar from "../../../components/schedule/SchedularToolbar";
import WeeklyView from "../../../components/schedule/schedule-Week-view/WeeklyView";
import { Heading } from "../../../components/UI/heading";
import {
  getAccommodationAndFoodRulesThunk,
  getMileageRulesThunk,
  getParkingRulesThunk,
  getTransportRulesThunk,
} from "../../../redux/profile/profileThunk";
import { getRegionsListDropDownThunk } from "../../../redux/regions/regionsThunk";
import {
  deleteJobThunk,
  deleteShiftThunk,
  getRolesThunk,
  getScheduleListThunk,
  getStaffsThunk,
} from "../../../redux/schedule/scheduleThunk";
import {
  branchApiKeys,
  regionApiKeys,
  settingsApiKeys,
  staffApiKeys,
} from "../../../utils/apiKeyConstants";
import { getCurrentWeekDays } from "../../../utils/dates";
import { hasAccess } from "../../../utils/helper";
import { ShiftJobFormUpdated } from "../../../components/comon/addShiftForm";

export const SchedulePage = () => {
  const [view, setView] = useState("weekly");
  const [showAddShiftModal, setShowAddshiftModal] = useState(false);
  const [operatingTime, setOperatingTime] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectId, setSelectedId] = useState();
  const [dateRange, setDateRange] = useState();
  const [filterValues, setFilterValue] = useState({
    regions: [],
    roleId: "",
    status: "",
    branch: "",
  });

  const [selectedData, setSelectedData] = useState();

  const [flag, setFlag] = useState(true);

  const { branches } = useSelector((state) => {
    return state.schedule;
  });
  const handleAddShift = (operatingTime, date, id, data) => {
    setShowAddshiftModal(!showAddShiftModal);
    setOperatingTime(operatingTime);
    setSelectedDate(date);
    setSelectedId(id);
    setSelectedData(data);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasAccess(settingsApiKeys.GET_SETTINGS_ALL_MILEAGE_RULES))
      dispatch(getMileageRulesThunk());
    if (hasAccess(settingsApiKeys.GET_SETTINGS_PARKING_RULES))
      dispatch(getParkingRulesThunk());
    if (hasAccess(settingsApiKeys.GET_ALL_TRANSPORT_RULES))
      dispatch(getTransportRulesThunk());
    if (hasAccess(settingsApiKeys.GET_ALL_FOOD_RULES))
      dispatch(getAccommodationAndFoodRulesThunk());
    if (hasAccess(regionApiKeys.GET_REGION_DROP_DOWN_LIST))
      dispatch(getRegionsListDropDownThunk());
    dispatch(
      getRolesThunk({
        group: ["staff", "locum"],
        type: "Default",
      })
    );
    if (hasAccess(staffApiKeys.GET_ALL_STAFF)) dispatch(getStaffsThunk());
  }, [dispatch]);

  useEffect(() => {
    if (!dateRange || !flag) return;
    if (hasAccess(branchApiKeys.GET_ALL_BRANCHES_SCHEDULE_LIST)) {
      dispatch(
        getScheduleListThunk({
          endDateRange: dateRange?.endDateRange,
          startDateRange: dateRange?.startDateRange,
          filters: filterValues,
        })
      ).then(res => {
        if (res.payload) {
          setFlag(false);
        }
      })

    }
  }, [dateRange, dispatch, filterValues, flag, view]);

  const getWeekArray = useMemo(
    () =>
      getCurrentWeekDays(
        dateRange?.startDateRange,
        (dateRange?.startDateRange, "YYYY-MM-DD"),
        ""
      ),
    [dateRange?.startDateRange]
  );

  useEffect(() => {
    if (view === "weekly") {
      setDateRange({
        startDateRange: moment(dateRange?.startDateRange)
          .startOf("isoWeek")
          .format("YYYY-MM-DD"),
        endDateRange: moment(dateRange?.startDateRange)
          .endOf("isoWeek")
          .format("YYYY-MM-DD"),
      });
    }
    if (view === "daily") {
      setDateRange({
        startDateRange: moment(dateRange?.startDateRange).format("YYYY-MM-DD"),
        endDateRange: moment(dateRange?.startDateRange).format("YYYY-MM-DD"),
      });
    }
    setFlag(true);
  }, [view]);

  const onDateChange = useCallback(
    (startDate, endDate) => {
      setDateRange({
        startDateRange: startDate,
        endDateRange: view === 'weekly' ? endDate : startDate
      });
      setFlag(true);
    },
    [view]
  );


  const onDelete = (data) => {
    if (data.isJob) {
      dispatch(
        deleteJobThunk({
          jobId: data.id,
        })
      ).then((res) => {
        if (res.payload === "success") {
          setFlag(true);
          setShowAddshiftModal(false);
        }
      });
    } else {
      dispatch(
        deleteShiftThunk({
          shiftId: data.id,
          branchId: data.branchId,
        })
      ).then((res) => {
        if (res.payload === "success") {
          setFlag(true);
          setShowAddshiftModal(false);
        }
      });
    }
  };

  return (
    <div className="schedule-page" style={{ height: "calc(100vh - 60px) " }}>
      <div
        className="main-wrapper"
        style={{ height: "100%", paddingBottom: "20px" }}
      >
        <Heading label="Schedule" />
        <ToolBar
          searchPlaceholder="Branch Name"
          showJobFilter
          showRegionFilter
          showRolesFilter
          dateRange={dateRange}
          showSearchField
          showViewFilter
          view={view}
          showDatePicker
          onDateChange={onDateChange}
          handleViewChange={setView}
          filterValues={filterValues}
          setFilterValue={setFilterValue}
          setFlag={setFlag}
        />
        {
          showAddShiftModal &&
          <ShiftJobFormUpdated
            onSuccess={() => setFlag(true)}
            branchId={selectId}
            onDelete={onDelete}
            operatingTime={operatingTime}
            selectedDate={selectedDate}
            showModal={showAddShiftModal}
            setHandleModal={() => setShowAddshiftModal(!showAddShiftModal)}
            data={selectedData}
          />
        }
        {!branches ? undefined : (
          <>
            {view === "daily" ? (
              <DayView
                handleAddShift={handleAddShift}
                onDelete={onDelete}
                branches={branches ?? []}
                height="165px"
              />
            ) : (
              view === "weekly" && (
                <WeeklyView
                  addShift
                  onDelete={onDelete}
                  handleAddShift={handleAddShift}
                  weekArray={getWeekArray}
                  branches={branches ?? []}
                />
              )
            )}
          </>
        )}
      </div>
    </div>
  );
};
