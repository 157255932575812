import { Navigate, Route, Routes } from "react-router-dom"
import { StaffDetails } from "./details"
import { StaffAbsences } from "./details/absences"
import { StaffContractPage } from "./details/contracts"
import { StaffInformation } from "./details/information"
import { StaffSchedule } from "./details/schedule"
import { StaffTimeSheet } from "./details/time-sheet"
import { StaffVerification } from "./details/verification"
import { StaffListPage } from "./staffList"

export const StaffPage = () => {
    return (
        <Routes>
            <Route
                path={`/`}
                element={<StaffListPage />}
            />
            <Route path="/:staffId/details/*" element={<StaffDetails />}>
                <Route path="information" element={<StaffInformation />} />
                <Route path="schedule" element={<StaffSchedule />} />
                <Route path="leaves" element={<StaffAbsences />} />
                <Route path="contract/*" element={<StaffContractPage />} />
                <Route path="time-sheet" element={<StaffTimeSheet />} />
                <Route path="verification" element={<StaffVerification />} />
                <Route path="/:staffId/details/*" element={<Navigate to='information' />} />
            </Route>
        </Routes>
    )
}