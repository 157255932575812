import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EmptyData } from "../../../../../../components/comon/EmptyData";
import { TableComponet } from "../../../../../../components/UI/table";
import { TableToolbar } from "../../../../../../components/UI/table/tableToolBar";
import { getBranchStaffListThunk } from "../../../../../../redux/branches/branchesThunk";

export const BranchStaff = () => {
  const { id } = useParams()
  const dispatch = useDispatch();
  const { branchStaffList } = useSelector((state) => state.branches);

  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row: { original } }) => {
          return original.name + ' ' + original.surname
        },
      },

      {
        Header: "Role",
        accessor: "roleTitle",
      },
      {
        Header: "Phone Number",
        accessor: "phone",
      },
      {
        Header: "Status",
        accessor: "active",
        Cell: ({ row: { original } }) => {
          return (
            <>
              {original.status === 'active' ?
                (
                  <div>
                    <span
                      className="px-4 rounded-pill py-2"
                      style={{ backgroundColor: "#E8F5E9", color: "#1B5E1F" }}
                    >
                      Active
                    </span>
                  </div>

                ) : original.status === 'pending' ?
                  (<div>
                    <span
                      className="px-4 rounded-pill py-2"
                      style={{ backgroundColor: "#FEFDE9", color: "#EE872D" }}
                    >
                      Pending
                    </span>
                  </div>
                  ) :
                  (
                    <span
                      className="px-4 rounded-pill py-2"
                      style={{ backgroundColor: "#FFEBEE", color: "#EF5350" }}
                    >
                      InActive
                    </span>
                  )}
            </>
          );
        },
      },
    ],
    []
  );

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: staffList,
  } = useMemo(() => branchStaffList, [branchStaffList]);

  useEffect(() => {
    dispatch(
      getBranchStaffListThunk({
        id,
        pagination: { page: currentPageCount, perPage: perPageCount },
      })
    );
  }, [dispatch, id, currentPageCount, perPageCount]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  return (
    <div>
      <div className="">
        <TableToolbar headingText="Staff List" />
        <div style={{ padding: "5px 24px" }}>
          {branchStaffList?.rows?.length > 0 ? (
            <TableComponet
              rowCursor="pointer"
              currentPageCount={currentPageCount}
              perPageCount={perPageCount}
              totalDataCount={totalDataCount}
              setCurrentPage={setCurrentPageCount}
              setPerPageCount={setPerPageCount}
              showPagination
              maxPageCount={totalPagesCount}
              isBorderShown
              columns={columns}
              data={staffList || []}
            />
          ) :
            <div style={{ width: "100%", marginTop: "60px" }}>
              <EmptyData title="Staff Members" />
            </div>}
        </div>
      </div>
    </div>
  );
};
