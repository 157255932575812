import { Button, Form, Input, Popover } from "antd"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { signUpThunk } from "../../../redux/auth/authThunk"
import { RouterConfig } from "../../../routerConfig"
import { notify } from "../../../utils/helper"
import { PasswordCriteria } from "../login/loginForm"
import infoIcon from '../../../assets/icons/infoIcon.png'
import "../style.scss";

export const PharmacySignUpForm = ({ convertedUser }) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [btndisabled, setbtndisabled] = useState(true);

    const onValuesChange = (changedValues, allValues) => {
        if (
            allValues.pharmacyName !== "" &&
            allValues.email !== "" &&
            allValues.password !== "" &&
            allValues.name !== "" &&
            allValues.surname !== "" &&
            (allValues.phone !== undefined && allValues.phone !== "")
        ) {
            setbtndisabled(false);
        } else {
            setbtndisabled(true);
        }
    };

    const onFinish = (value) => {
        const reqData = {
            ...value,
            roleId: 2
        }
        dispatch(
            signUpThunk(reqData)
        ).then((res) => {
            if (res.payload.status === 'success') {
                notify('success', 'Sign In link sent to your Email!')
                form.resetFields()
                navigate(RouterConfig.login)
                window.analytics.identify(res.payload.data.id, {
                    id: res.payload.data.id,
                    email: res.payload.data.email,
                    userType: res.payload.data.group,
                    name: res.payload.data.name,
                    surname: res.payload.data.surname,
                    pharmacyName: res.payload.data.pharmacyName,
                    phone: res.payload.data.phone,
                    device: "web",
                });

                window.analytics.track('Sign Up Confirmed- Pharmacy', {
                    id: res.payload.data.id,
                    email: res.payload.data.email,
                    userType: res.payload.data.group,
                    name: res.payload.data.name,
                    surname: res.payload.data.surname,
                    pharmacyName: res.payload.data.pharmacyName,
                    phone: res.payload.data.phone,
                    device: "web",
                });
                if (!convertedUser) {
                    window.fbq('trackCustom', 'SignUp Pharmacy', {
                        id: res.payload.data.id,
                        email: res.payload.data.email,
                        userType: res.payload.data.group,
                        name: res.payload.data.name,
                        surname: res.payload.data.surname,
                        pharmacyName: res.payload.data.pharmacyName,
                        phone: res.payload.data.phone,
                        device: "web",
                    });
                    return
                }
                window.fbq('trackCustom', 'SignUp Pharmacy - Convert', {
                    id: res.payload.data.id,
                    email: res.payload.data.email,
                    userType: res.payload.data.group,
                    name: res.payload.data.name,
                    surname: res.payload.data.surname,
                    pharmacyName: res.payload.data.pharmacyName,
                    phone: res.payload.data.phone,
                    device: "web",
                });
            }
        })
    };

    return (
        <>
            <div>
                <div className="heading2">Sign Up</div>
                <div className="t1">Create an account to continue.</div>
            </div>
            <Form
                onFinish={onFinish}
                form={form}
                onValuesChange={onValuesChange}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    label="Pharmacy Name"
                    name="pharmacyName"
                    rules={[{ required: true, message: 'Please Enter your Pharmacy Name!' }]}
                >
                    <Input
                        onBlur={e => form.setFieldsValue({ 'pharmacyName': e.target.value.trim() })}
                        placeholder="Pharmacy Name"
                        className='text-input-field p-2 pe-3 t1'
                        maxLength={50}
                    />
                </Form.Item>
                <Form.Item
                    label="Email Address"
                    name="email"
                    rules={[{ type: 'email', required: true, message: 'Please Enter your Email!' }]}
                >
                    <Input
                        onBlur={e => form.setFieldsValue({ 'email': e.target.value.trim() })}
                        placeholder="Email Address"
                        className='text-input-field p-2 pe-3 t1'
                        maxLength={50}
                    />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        { required: true, message: 'Please Enter your password!' },
                        {
                            pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
                            message: `Password doesn't match the Criteria!`,
                        }
                    ]}
                >
                    <Input.Password
                        onBlur={e => form.setFieldsValue({ 'password': e.target.value.trim() })}
                        prefix={
                            <Popover
                                content={PasswordCriteria} placement="left" title="Password Criteria">
                                <img src={infoIcon} alt="" />
                            </Popover>
                        }
                        placeholder="Password"
                        className="text-input-field p-2 pe-3 t1"
                        maxLength={50}
                    />
                </Form.Item>
                <div
                    className="d-flex align-items-center gap-3"
                    style={{ width: "100%" }}
                >
                    <Form.Item
                        label="First Name"
                        style={{ width: "100%" }}
                        name="name"
                        rules={[
                            { required: true, message: "Please Enter your First Name !" },
                        ]}
                    >
                        <Input
                            onBlur={e => form.setFieldsValue({ 'name': e.target.value.trim() })}
                            placeholder="First Name"
                            className="text-input-field p-2 pe-3 t1"
                            maxLength={50}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{ width: "100%" }}
                        label="Last Name"
                        name="surname"
                        rules={[
                            { required: true, message: "Please Enter your Last Name !" },
                        ]}
                    >
                        <Input
                            onBlur={e => form.setFieldsValue({ 'surname': e.target.value.trim() })}
                            placeholder="Last Name"
                            className="text-input-field p-2 pe-3 t1"
                            maxLength={50}
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    label="Contact Phone"
                    name="phone"
                    rules={[
                        { required: true, message: 'Please Enter the Phone No. for Contact Person !' },
                    ]}
                >
                    <Input
                        onBlur={e => form.setFieldsValue({ 'phone': e.target.value.trim() })}
                        type="number"
                        placeholder="Contact Phone"
                        className='text-input-field p-2 pe-3 t1'
                    />
                </Form.Item>
                <Form.Item>
                    <Button disabled={btndisabled} id="signUpPharmacy_confirmed" className="p-2 btn-style" type="primary" htmlType="submit" block>
                        Sign Up
                    </Button>
                </Form.Item>
            </Form>
            <div className="p2 text-center" style={{ color: '#9D9D9D' }}>
                <span className=" m-auto">
                    Already have an account?
                    <Link to={RouterConfig.login} className="text-decoration-none" style={{ color: '#51C2BC' }}> Login</Link>
                </span>
            </div>
        </>
    )
}