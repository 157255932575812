import { Form } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { CreateBranchForm } from "../../../../../../components/branches/createBranch/createBranchFormStep1";
import { AddModal } from "../../../../../../components/UI/modals/addModal";
import { CustomTabs } from "../../../../../../components/UI/tabs";
import { updateBranchThunk } from "../../../../../../redux/branches/branchesThunk";
import {
  hasAccessToModule,
} from "../../../../../../utils/helper";
import { BranchHoursForm } from "./components/branchHoursForm";
import {
  branchGeneralInfoTab,
  branchHoursTab,
  pharmacyInformationTab,
} from "./components/utils";
import "./style.scss";
import { moduleKeys } from "../../../../../../utils/modulesKeyConstants";

const getTabsData = (data, branchRelatedData) => {
  const tabs = [
    {
      title: "General Information",
      content: branchGeneralInfoTab(data, branchRelatedData),
    },
    {
      title: "Branch Information",
      content: pharmacyInformationTab(data, branchRelatedData),
    },
    {
      title: "Branch Hours",
      content: branchHoursTab(data),
    },
  ];
  return tabs;
};

export const BranchInformation = () => {
  const { id } = useParams();
  const formId = useMemo(() => "form", []);
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditTimingModal, setShowEditTimingModal] = useState(false);
  const { branchInfo, branchRelatedData } = useSelector(
    (state) => state.branches
  );
  const [editTab, setEditTab] = useState("");
  const [tabs, setTabs] = useState([]);

  const [currentActiveTab, setCurrentActiveTab] = useState(0);

  useEffect(() => {
    if (!searchParams.get('tab')) return
    if (searchParams.get('open') === 'true') {
      setShowEditTimingModal(true);
    }
    setEditTab('Branch Hours')
    setCurrentActiveTab(2)

  }, [searchParams])


  useEffect(() => {
    if (!branchInfo) return;
    form.setFieldsValue({
      title: branchInfo.title,
      regionId: branchInfo.regionId,
      address1: branchInfo.address1,
      address2: branchInfo.address2,
      city: branchInfo.city,
      postalCode: branchInfo.postalCode,
      phone: branchInfo.phone,
      description: branchInfo.description,
      logo: branchInfo.logo
        ? {
          fileList: [
            {
              uid: Math.random(),
              name: "image.png",
              status: "done",
              url: branchInfo?.logo,
            },
          ],
        }
        : undefined,
      itemsPerMonth: branchInfo.itemsPerMonth,
      mdsPerMonth: branchInfo.mdsPerMonth,
      methadonePatientsPerDay: branchInfo.methadonePatientsPerDay,
      careHomesSupplied: branchInfo.careHomesSupplied,
      staffSupportLevelId: branchInfo.staffSupportLevelId,
      parkingInformation: branchInfo.parkingInformation,
      pharmacySystemId: branchInfo.pharmacySystemId,
      pharmacyServicesIds: branchInfo.branchServices,
      number: branchInfo.number,
      latitude: branchInfo.latitude,
      longitude: branchInfo.longitude,
      isNegotiationAllowed:
        branchInfo?.isNegotiationAllowed === 1 ? true : false,
    });
  }, [branchInfo]);

  useEffect(() => {
    if (!branchInfo) return;
    const _tabs = getTabsData(branchInfo, branchRelatedData);
    setTabs(hasAccessToModule(moduleKeys.BRANCH_INFORMATION) ? _tabs : []);
  }, [branchInfo, branchRelatedData]);

  const handleAddressSelect = ({ name, data: address }) => {
    form.setFieldsValue({
      ...form,
      address1: address.line_1,
      address2: address.line_2 ?? '',
      latitude: address.latitude,
      longitude: address.longitude,
      city: address.town_or_city,
      postalCode: address.postcode,
    });
  };

  const onEditHandler = useCallback((data) => {
    setEditTab(data);
    if (data === "Branch Hours") {
      setShowEditTimingModal(true);
      return;
    }
    setShowEditModal(true);
  }, []);

  const { operatingTime } = branchInfo;
  const onGeneralInformationUpdate = (values) => {
    const reqData = {
      ...values,
      logo:
        !values?.logo || values?.logo?.file?.status === "removed"
          ? ""
          : values?.logo?.file
            ? values?.logo?.file
            : values?.logo?.fileList[0].url,
    };

    let formData = new FormData();

    for (let key in reqData) {
      if (reqData[key] instanceof Blob) {
        formData.append(key, reqData[key], reqData[key]?.name);
      } else {
        formData.append(key, reqData[key]);
      }
    }

    dispatch(
      updateBranchThunk({
        id,
        formData,
      })
    ).then((res) => {
      if (res.payload) {
        setShowEditModal(false);
      }
    });
  };

  return (
    <div className="branch-info-page border rounded-4">
      <CustomTabs
        currentActiveTab={currentActiveTab}
        setCurrentActiveTab={setCurrentActiveTab}
        onEditHandler={onEditHandler}
        tabs={tabs}
      />
      <AddModal
        id={formId}
        title={`Edit ${editTab}`}
        handleModal={showEditModal}
        setHandleModal={setShowEditModal}
        primaryButtonText="Update"
        primaryButtonHandler={form.submit}
      >
        {(editTab === "General Information" ||
          editTab === "Branch Information") && (
            <div
              className="d-flex flex-column align-items-start add-shift-modal-content"
              style={{ gap: "15px", color: "#7B7B7B", paddingTop: "25px" }}
            >
              <Form
                onFinish={onGeneralInformationUpdate}
                form={form}
                id={formId}
                style={{ width: "100%" }}
                layout="vertical"
                autoComplete="off"
              >
                <CreateBranchForm
                  form={form}
                  branchFormStep={editTab === "General Information" ? 1 : 2}
                  handleAddressSelect={handleAddressSelect}
                  staffSupportLevels={branchRelatedData?.staffSupportLevels}
                  pharmacySystems={branchRelatedData?.pharmacySystems}
                  branchServices={branchRelatedData?.branchServices}
                  regions={branchRelatedData?.regions || []}
                />
              </Form>
            </div>
          )}
      </AddModal>
      {showEditTimingModal && (
        <BranchHoursForm
          handleModal={showEditTimingModal}
          setHandleModal={setShowEditTimingModal}
          editTab={editTab}
          operatingTime={operatingTime}
        />
      )}
    </div>
  );
};
