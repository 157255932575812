import moment from "moment"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { EmptyData } from "../../../components/comon/EmptyData";
import { FlexRow } from "../../../components/comon/flexRows"
import { contractTypeOptions } from "../../../components/staffs/contracts/contractForm";
import { getUsersActiveContractThunk } from "../../../redux/staffs/staffsThunk";

export const StaffContractDetails = () => {
    const dispatch = useDispatch()
    const { activeContract } = useSelector((state) => state.staffs);
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        dispatch(
            getUsersActiveContractThunk({
                staffId: user.id
            })
        )
    }, [user, dispatch])

    return (
        <>
            {
                activeContract ?
                    <div className="d-flex flex-column flex-1" style={{ gap: 30 }}>
                        <div className="heading4">
                            {activeContract?.title}
                        </div>
                        <div className="border rounded">
                            <div className="text-row d-flex align-items-center justify-content-between">
                                <div className="s1 m-0">Basic Information</div>
                            </div>
                            <FlexRow
                                primaryTitle="Start Date"
                                primaryDescription={moment(activeContract?.startDate).format('YYYY-MM-DD') || "-"}
                            />
                            <FlexRow
                                primaryTitle="End Date"
                                primaryDescription={moment(activeContract?.endDate).format('YYYY-MM-DD') || "-"}
                            />
                            <FlexRow
                                primaryTitle="Contract Type"
                                primaryDescription={contractTypeOptions?.find((i) => i.value === activeContract?.type)?.label || '-'}
                            />
                            <FlexRow
                                primaryTitle="Working Hours"
                                primaryDescription={activeContract?.workingOurs}
                            />

                            <FlexRow
                                primaryTitle="Absence Days"
                                primaryDescription={activeContract?.absenceDays}
                            />
                        </div>
                        <div className="border rounded">
                            <div className="text-row d-flex align-items-center justify-content-between">
                                <div className="s1 m-0">Earned Wage</div>
                                {/* <div className="s1 m-0 cursor-pointer" onClick={() => setShowEditInfoModal(true)} style={{ color: '#51C2BC' }}>Edit</div> */}
                            </div>
                            <FlexRow
                                primaryTitle="Gross Salary"
                                primaryDescription={`${activeContract?.grossSalaryPay}/${activeContract?.grossSalaryPeriodType}` || '-'}
                            />
                            <FlexRow
                                primaryTitle="Overtime Salary"
                                primaryDescription={`${activeContract?.overTimePayHourly}/hour` || "-"}
                            />
                        </div>
                        {
                            activeContract?.contractAdditionCompensations?.length ?
                                <div className="border rounded">
                                    <div className="text-row d-flex align-items-center justify-content-between">
                                        <div className="s1 m-0">Compensation</div>
                                    </div>
                                    {
                                        activeContract?.contractAdditionCompensations?.map((data, index) => {
                                            return (
                                                <FlexRow
                                                    key={index}
                                                    primaryTitle={data.name}
                                                    primaryDescription={`${data.payAmount}/${data.timePeriod}`}
                                                />
                                            )
                                        })
                                    }
                                </div>
                                : null
                        }
                    </div>
                    :
                    <div>
                        <EmptyData title="Active Contract" secondaryText="Request Your Administrator For A Contract To Get Started" />
                    </div>
            }
        </>

    )
}