import { useSelector } from "react-redux"
import { DocumentSlide } from "../../../../components/staffs/documentSlide"
import { sortAccredArray } from "../../../../utils/helper"

export const Accrediations = () => {
    const { staffAccrediations } = useSelector((state) => state.staffs)
    const { user } = useSelector(state => state.auth)
    return (
        <div className="d-flex flex-column gap-3">
            {/* {
                staffAccrediations?.pharmacyAccreditation?.staffAccreditations?.length ?
                    <div className="border rounded">
                        <div className="s1 text-row m-0">Additional Accrediations</div>
                        <div className="d-flex flex-wrap align-items-center gap-4 p-4">
                            {
                                staffAccrediations?.pharmacyAccreditation?.staffAccreditations?.map((data, index) => (
                                    <DocumentSlide name={user?.name} key={index} label={data.accreditationName} value={data.filePath} />
                                ))
                            }
                        </div>
                    </div>
                    : null
            } */}
            <div className="border rounded">
                <div className="s1 text-row m-0">System Accreditation</div>
                <div className="d-flex flex-wrap gap-3 align-items-center m-3">
                    {
                        sortAccredArray(staffAccrediations)?.map((data, index) => (
                            <DocumentSlide key={index} label={data.accreditationName} name={user?.name} value={data.filePath} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}