import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PersonalInfoForm } from "../../../../../components/staffs/personalInfoForm";
import { AddModal } from "../../../../../components/UI/modals/addModal";
import { CustomTabs } from "../../../../../components/UI/tabs";
import {
  editStaffAccrediationsThunk,
  getStaffAccrediationsThunk,
  getStaffDataThunk,
} from "../../../../../redux/staffs/staffsThunk";
import "./style.scss";
import { Accrediations } from "./components/accrediations";
import { InformationTab } from "./components/infromation";
import { beforeUpload, compressImage, hasAccess, notify } from "../../../../../utils/helper";
import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { AddLocumBankThunk } from "../../../../../redux/locum-bank/locumBankThunk";
import { staffApiKeys } from "../../../../../utils/apiKeyConstants";

export const StaffInformation = () => {
  const dispatch = useDispatch();
  const { staffId } = useParams();
  const [showAccrediationModal, setShowAccrediationModal] = useState();
  const { staffAccrediations } = useSelector((state) => state.staffs);
  const [editTab, setEditTab] = useState("");
  const [accrediationData, setAccreditationData] = useState();
  const { staffInfo } = useSelector((state) => state.staffs);
  const [currentActiveTab, setCurrentActiveTab] = useState(0);

  const [tabs, setTabs] = useState([]);

  const getTabsData = useCallback(() => {
    const tabs = [
      {
        title: "Personal Information",
        content: <InformationTab />,
      },
      {
        title: "Accrediations",
        content: <Accrediations
          setShowAccrediationModal={setShowAccrediationModal}
        />,
      },
    ];
    return tabs;
  }, []);

  useEffect(() => {
    const _tabs = getTabsData();
    setTabs(_tabs);
  }, []);

  useEffect(() => {
    if (!staffAccrediations) return;
    const data = staffAccrediations?.systemAccreditation?.map((data) => {
      return {
        name: data.accreditationLabel,
        label: data.accreditationName,
        filePath: {
          fileList: data?.filePath
            ? [
              {
                uid: Math.random(),
                name: `${staffInfo?.name}_${data?.accreditationName
                  }.${data?.filePath?.split(".").pop()}`,
                status: "done",
                url: data?.filePath,
              },
            ]
            : [],
        },
      };
    });

    setAccreditationData(data);
  }, [staffAccrediations]);

  const onEditHandler = useCallback((data) => {
    setEditTab(data);
  }, []);

  const _onChangeHandler = (value, name) => {
    const allowedAccreditaionList = [...accrediationData];
    const index = allowedAccreditaionList.findIndex((obj) => obj.name === name);
    allowedAccreditaionList[index] = {
      ...allowedAccreditaionList[index],
      filePath: value,
    };
    setAccreditationData([...allowedAccreditaionList]);
  };

  const onAccrediationEdit = useCallback(() => {
    const data = accrediationData?.map((item) => {
      if (item.filePath.fileList.length && item.filePath.file) {
        return {
          [item.name]: item.filePath.file,
        };
      } else if (!item.filePath.fileList.length) {
        return {
          [item.name]: "",
        };
      }
    });

    let finalObj = {};
    for (let i = 0; i < data.length; i++) {
      Object.assign(finalObj, data[i]);
    }

    let _formData = new FormData();
    for (let key in finalObj) {
      if (finalObj[key] instanceof Blob) {
        _formData.append(key, finalObj[key], finalObj[key]?.name);
      } else {
        _formData.append(key, finalObj[key]);
      }
    }
    _formData.append("id", staffId);

    dispatch(editStaffAccrediationsThunk(_formData)).then((res) => {
      if (res.payload.status === "success") {
        setShowAccrediationModal(false);
        notify("success", "Staff Accrediations Updated!");
        setEditTab("");
        dispatch(getStaffAccrediationsThunk({ staffId }));
      }
    });
  }, [dispatch, staffId, accrediationData]);


  const addLocumBank = (data) => {
    const reqData = {
      gphcNumber: data?.gphcInfo?.gphcNumber,
      email: data.email,
      phone: data.phone,
      userId: data.id,
      gphcId: data?.gphcInfo?.id,
    };

    dispatch(AddLocumBankThunk(reqData)).then((res) => {
      if (res.payload) {
        window.analytics.track('Locum Bank Added', {
          gphcNumber: reqData.gphcNumber,
          email: reqData.email,
          phone: reqData.phone,
        });
        if (hasAccess(staffApiKeys.GET_STAFF_PROFILE)) {
          dispatch(getStaffDataThunk({ staffId }));
        }
      }
    });
  }

  return (
    <div className="branch-info-page border rounded-4">
      <CustomTabs
        tabs={tabs}
        currentActiveTab={currentActiveTab}
        setCurrentActiveTab={setCurrentActiveTab}
        onEditHandler={staffInfo?.group === "staff" && currentActiveTab === 0 && onEditHandler}
        staffInfo={staffInfo}
        addLocumBank={staffInfo?.group === "locum" && currentActiveTab === 0 && addLocumBank}
      />
      {editTab === "Personal Information" ? (
        <div
          className="d-flex flex-column align-items-start add-shift-modal-content"
          style={{ gap: "15px", color: "#7B7B7B", paddingTop: "25px" }}
        >
          <PersonalInfoForm setEditTab={setEditTab} />
        </div>
      ) : (
        <AddModal
          title="Edit Accrediations"
          handleModal={showAccrediationModal}
          setHandleModal={() => {
            setEditTab("");
            setShowAccrediationModal(!showAccrediationModal);
          }}
          primaryButtonHandler={onAccrediationEdit}
          primaryButtonText="Update"
          secondaryButtonText="Cancel"
        >
          <div className="d-flex gap-3 flex-column">
            {accrediationData?.map((data, idx) => (
              <div key={idx}>
                <div className="p3" style={{ color: "#7B7B7B" }}>
                  {data.label}
                </div>
                <Upload
                  accept={"image/*, .doc, .pdf"}
                  fileList={data?.filePath?.fileList}
                  onChange={async ({ file, fileList }) => {
                    try {
                      const compressedFile = await compressImage(file);
                      _onChangeHandler({ file: compressedFile, fileList }, data.name);
                    } catch (error) {
                      notify('error', 'Error compressing the image:');
                    }
                  }}
                  onPreview={() => { }}
                  beforeUpload={beforeUpload}
                  multiple={false}
                  maxCount={1}
                  listType="picture"
                >
                  {(!data?.filePath?.fileList.length ||
                    data?.filePath?.fileList[0]?.status === "removed") && (
                      <Button
                        className="my-3"
                        style={{
                          width: "100%",
                          height: "40px",
                          color: "#51C2BC",
                          border: "2px dashed #51C2BC",
                          backgroundColor: "#F0F9F9",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        icon={<UploadOutlined />}
                      >
                        Upload Document
                      </Button>
                    )}
                </Upload>
              </div>
            ))}
          </div>
        </AddModal>
      )}
    </div>
  );
};
