import { API } from "../../../axios";

export const getScheduleList = (payload) => {
    const request = API.post(`/branches/schedule/list`, payload);
    return request
}

export const getRoles = (payload) => {
    const request = API.post(`role`, payload);
    return request
}


export const getStaffs = () => {
    const request = API.get(`staff/all-staff`);
    return request
}

export const createShiftBulk = (payload) => {
    const { branchId, data } = payload
    const request = API.post(`branches/${branchId}/schedule-save-bulk/`, data);
    return request
}

export const createJobBulk = (payload) => {
    const { branchId, data } = payload
    const request = API.post(`branches/${branchId}/job-create-bulk/`, data);
    return request
}

export const createShift = (payload) => {
    const { branchId, data } = payload
    const request = API.post(`/branches/${branchId}/schedule`, data);
    return request
}

export const searchLocumByFilter = (payload) => {
    const request = API.post(`jobs/external-locums-by-filter`, payload, {
        loader: false
    });
    return request
}

export const deleteJob = (payload) => {
    const { jobId } = payload
    const request = API.delete(`jobs/${jobId}`);
    return request
}

export const deleteShift = (payload) => {
    const { shiftId, branchId } = payload
    const request = API.delete(`branches/${branchId}/schedule/${shiftId}`);
    return request
}

export const editJob = (payload) => {
    const { jobId, data } = payload
    const request = API.post(`jobs/${jobId}`, data);
    return request
}

export const editShift = (payload) => {
    const { shiftId, branchId, data } = payload
    const request = API.post(`branches/${branchId}/schedule/${shiftId}`, data);
    return request
}

export const deleteBulkShift = (payload) => {
    const { staffId, branchId, data } = payload
    const request = API.delete(`branches/${branchId}/delete-bulk/${staffId}`, { data });
    return request
}