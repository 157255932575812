import { DatePicker, Form, Input, Select, TimePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EmptyData } from "../../../../../components/comon/EmptyData";
import { DetailTab } from "../../../../../components/dashboard/DetailTabs";
import { AddModal } from "../../../../../components/UI/modals/addModal";
import { ConfirmModal } from "../../../../../components/UI/modals/confirmModal";
import { TableComponet } from "../../../../../components/UI/table";
import { TableToolbar } from "../../../../../components/UI/table/tableToolBar";
import { getRegionsBranchesListThunk } from "../../../../../redux/regions/regionsThunk";
import { getStaffTimeSheetThunk, markStaffAttendanceThunk, updateAttendanceThunk } from "../../../../../redux/staffs/staffsThunk";
import { personalApiKeys } from "../../../../../utils/apiKeyConstants";
import { leavesType, staffAbsencesType } from "../../../../../utils/constant";
import { hasAccess, notify } from "../../../../../utils/helper";
import { StaffTimeSheetFilterComponent } from "./components/dateRangeFilter";
import { MultipleDatePicker } from "./components/multiDatePicker";
import infoIcon from '../../../../../assets/icons/infoIcon1.png'

import acceptIcon from '../../../../../assets/icons/accept.png'
import rejectIcon from '../../../../../assets/icons/reject.png'

const absenceType = [
  { value: "absent", label: "Absent" },
  { value: "present", label: "Present" },
];

export const StaffTimeSheet = () => {
  const { staffId } = useParams();
  const dispatch = useDispatch();
  const [showAddAttendanceModal, setShowAddAttendanceModal] = useState(false)
  const { staffTimeSheet, staffInfo } = useSelector((state) => state.staffs);
  const [form] = Form.useForm();
  const startDate = Form.useWatch("startDate", form);
  const endDate = Form.useWatch("endDate", form);
  const excludeDays = Form.useWatch("excludeDays", form);
  const [errorData, setErrorData] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { regionBranches } = useSelector((state) => state.regions);

  const [dateRange, setDateRange] = useState({
    startDate: dayjs().startOf('month'),
    endDate: dayjs()
  })
  const [flag, setFlag] = useState(true)

  const [isEdit, setIsEdit] = useState()
  const [activeTab, setActiveTab] = useState('checkIn')
  const [checkIn, setCheckIn] = useState('')
  const [checkOut, setCheckOut] = useState('')
  const [type, setType] = useState('')
  const [APIFlag, setAPIFlag] = useState(false)
  const [selectedAttendance, setSelectedAttendance] = useState()


  useEffect(() => {
    if (!APIFlag) return
    if (type === 'present' && (!checkIn || !checkOut)) {
      notify('error', 'Please Enter both Check In and Check Out!')
      setAPIFlag(false)
      return
    }
    if (!type) {
      notify('error', 'Type Can\'t be Empty!')
      setAPIFlag(false)
      return
    }
    const data = {
      recordId: selectedAttendance?.id,
      checkIn: selectedAttendance?.type === 'leave' || type === 'absent' ? undefined : checkIn,
      checkOut: selectedAttendance?.type === 'leave' || type === 'absent' ? undefined : checkOut,
      type: selectedAttendance?.type === 'leave' ? 'leave' : type,
      leaveId: selectedAttendance?.type === 'leave' ? selectedAttendance?.leaveId : undefined,
      leaveType: selectedAttendance?.type === 'leave' ? type : undefined
    }

    dispatch(
      updateAttendanceThunk(
        data
      )
    ).then(res => {
      if (res.payload) {
        setFlag(true)
        setCheckIn('')
        setCheckOut('')
        setType('')
        setIsEdit('')
        setAPIFlag(false)
      }
    })
    setAPIFlag(false)
  }, [APIFlag, checkIn, checkOut, dispatch, isEdit, selectedAttendance, type])

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row: { original } }) => {
          return moment(original.date).format("DD-MM-YYYY");
        },
      },

      {
        Header: "Check In",
        accessor: "actualCheckIn",
        Cell: ({ row: { original } }) => {
          return (
            <div style={{ width: 100 }}>
              {
                isEdit && selectedAttendance?.id === original.id && original.type !== 'leave' ?
                  <Input
                    value={checkIn}
                    autoFocus={activeTab === 'checkIn'}
                    onFocus={(e) => setActiveTab('checkIn')}
                    onChange={(e) => {
                      e.stopPropagation()
                      const input = e.target.value;
                      const digitsOnly = input.replace(/\D/g, ''); // remove non-digits
                      let formattedTime = '';

                      if (digitsOnly.length < 3) {
                        formattedTime = digitsOnly;
                      } else if (digitsOnly.length === 3) {
                        formattedTime = `${digitsOnly[0]}:${digitsOnly.substring(1, 3)}`;
                      } else if (digitsOnly.length > 3 && digitsOnly.length < 5) {
                        const hours = digitsOnly.substring(0, 2);
                        const minutes = digitsOnly.substring(2, 4);
                        if (hours > 23 && minutes > 59) {
                          formattedTime = `23:59`;
                        } else if (hours > 23) {
                          formattedTime = `23:${minutes}`;
                        } else if (minutes > 59) {
                          formattedTime = `${hours}:59`;
                        } else {
                          formattedTime = `${hours}:${minutes}`;
                        }
                      } else {
                        const hours = digitsOnly.substring(0, 2);
                        const minutes = digitsOnly.substring(2, 4);
                        if (hours > 23 && minutes > 59) {
                          formattedTime = `23:59`;
                        } else if (hours > 23) {
                          formattedTime = `23:${minutes}`;
                        } else if (minutes > 59) {
                          formattedTime = `${hours}:59`;
                        } else {
                          formattedTime = `${hours}:${minutes}`;
                        }
                      }
                      setCheckIn(formattedTime)
                    }}
                    className="border rounded p-2 pe-3 t1"
                  />
                  :
                  original.actualCheckIn ? moment(original.actualCheckIn, 'HH:mm').format('HH:mm') : "-"
              }
            </div>
          )
        },
      },
      {
        Header: "Check Out",
        accessor: "actualCheckOut",
        Cell: ({ row: { original } }) => {
          return (
            <div style={{ width: 100 }}>
              {
                isEdit && selectedAttendance?.id === original.id && original.type !== 'leave' ?
                  <Input
                    value={checkOut}
                    autoFocus={activeTab === 'checkOut'}
                    onFocus={(e) => setActiveTab('checkOut')}
                    onChange={(e) => {
                      e.stopPropagation()
                      const input = e.target.value;
                      const digitsOnly = input.replace(/\D/g, ''); // remove non-digits
                      let formattedTime = '';
                      if (digitsOnly.length < 3) {
                        formattedTime = digitsOnly;
                      } else if (digitsOnly.length === 3) {
                        formattedTime = `${digitsOnly[0]}:${digitsOnly.substring(1, 3)}`;
                      } else if (digitsOnly.length > 3 && digitsOnly.length < 5) {
                        const hours = digitsOnly.substring(0, 2);
                        const minutes = digitsOnly.substring(2, 4);
                        if (hours > 23 && minutes > 59) {
                          formattedTime = `23:59`;
                        } else if (hours > 23) {
                          formattedTime = `23:${minutes}`;
                        } else if (minutes > 59) {
                          formattedTime = `${hours}:59`;
                        } else {
                          formattedTime = `${hours}:${minutes}`;
                        }
                      } else {
                        const hours = digitsOnly.substring(0, 2);
                        const minutes = digitsOnly.substring(2, 4);
                        if (hours > 23 && minutes > 59) {
                          formattedTime = `23:59`;
                        } else if (hours > 23) {
                          formattedTime = `23:${minutes}`;
                        } else if (minutes > 59) {
                          formattedTime = `${hours}:59`;
                        } else {
                          formattedTime = `${hours}:${minutes}`;
                        }
                      }

                      setCheckOut(formattedTime)

                    }}
                    className="border rounded p-2 pe-3 t1"
                  />
                  :
                  original.actualCheckOut ? moment(original.actualCheckOut, 'HH:mm').format('HH:mm') : "-"
              }
            </div>
          )
        },
      },
      {
        Header: "Working Hours",
        accessor: "hour",
        Cell: ({ row: { original } }) => {
          if (!original.actualCheckIn || !original.actualCheckOut) return "-";

          const _startTime = moment(original.actualCheckIn, "hh:mm");
          const _endTime = original.actualCheckOut
            ? moment(original.actualCheckOut, "hh:mm a")
            : moment();
          const mins = moment(_endTime.diff(_startTime)).format("mm");
          return _endTime.diff(_startTime, "hours") + "h " + mins + "m";
        },
      },
      {
        Header: "Absence Type",
        accessor: "type",
        Cell: ({ row: { original } }) => {
          return (
            <div onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}>
              {
                isEdit && selectedAttendance?.id === original.id ?
                  <Select
                    dropdownStyle={{ zIndex: 2000 }}
                    placeholder="Absence Type"
                    bordered={false}
                    className="border rounded p-1 t1"
                    options={
                      original.type === 'leave' ? Object.keys(staffAbsencesType).map((key) => ({ value: key, label: staffAbsencesType[key] })) : absenceType
                    }
                    style={{ width: 150 }}
                    value={type}
                    onSelect={(value) => setType(value)}
                  />
                  :
                  (
                    original.id ?
                      <div>
                        {
                          original.type === "leave"
                            ? staffAbsencesType[original.leaveType]
                            : original.status === "pending"
                              ? "Pending"
                              : leavesType[original.type] || "-"
                        }
                      </div>
                      :
                      <div style={{ color: 'red' }}>No Shift</div>
                  )
              }
            </div>
          )
        },
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row: { original } }) => {
          return (
            <>
              {
                isEdit && selectedAttendance?.id === original.id &&
                <div className="d-flex" style={{ gap: 5 }}>
                  <div className=" cursor-pointer" onClick={(e) => {
                    e.stopPropagation()
                    setAPIFlag(true)
                  }}>
                    <img style={{ width: '100%' }} src={acceptIcon} alt="acceptIcon" />
                  </div>
                  <div className=" cursor-pointer" onClick={(e) => {
                    e.stopPropagation()
                    setIsEdit('')
                  }}>
                    <img style={{ width: '100%' }} src={rejectIcon} alt="rejectIcon" />
                  </div>
                </div>
              }
            </>
          )
        },
      },
    ],
    [activeTab, checkIn, checkOut, isEdit, selectedAttendance?.id, type]
  );

  const {
    attendance,
    avgChecInTime,
    avgChecOutTime,
    sick_leaves,
    annual_leaves,
  } = useMemo(() => staffTimeSheet, [staffTimeSheet]);

  const {
    rows: staffTimeSheetList,
  } = useMemo(() => attendance ?? {}, [attendance]);

  useEffect(() => {
    dispatch(
      getRegionsBranchesListThunk()
    )
  }, [dispatch])

  useEffect(() => {
    if (!flag) return
    if (hasAccess(personalApiKeys.ATTENDANCE_LIST))
      dispatch(
        getStaffTimeSheetThunk({
          filters: {
            from: dateRange.startDate.format('YYYY-MM-DD'),
            to: dateRange.endDate.format('YYYY-MM-DD'),
            staffIds: [staffId],
            deviceType: "web",
            status: "",
            name: "",
          },
          pagination: { page: 1, perPage: (dateRange.endDate.diff(dateRange.startDate, 'days') + 1) * 10 },
        })
      ).then(res => {
        if (res.payload) {
          setFlag(false)
        }
      })
  }, [dateRange.endDate, dateRange.startDate, dispatch, flag, staffId]);

  // useEffect(() => {
  //   setFlag(true)
  // }, [currentPageCount, perPageCount]);

  // useEffect(() => {
  //   if (!page && !perPage) return;
  //   setPerPageCount(perPage);
  //   setCurrentPageCount(page);
  // }, [page, perPage]);

  const onApplyFilter = useCallback(
    (values) => {
      setDateRange({
        startDate: values.startDate,
        endDate: values.endDate,
      })
      setFlag(true)
    },
    []
  );

  const onMarkAttendance = (values) => {
    const data = {
      ...values,
      startDate: values.startDate.format('YYYY-MM-DD'),
      endDate: values.endDate.format('YYYY-MM-DD'),
      startTime: values.startTime.format('HH:mm'),
      endTime: values.endTime.format('HH:mm'),
      staffId: staffInfo?.id,
      excludeDays: !values?.excludeDays?.length ? [] : values?.excludeDays,
      roleId: staffInfo?.roleId
    }

    dispatch(
      markStaffAttendanceThunk(data)
    ).then(res => {
      if (res.payload) {
        if (res.payload.code === "validation") {
          setShowErrorModal(true);
          setErrorData(res.payload);
          return
        }
        setFlag(true)
        setShowAddAttendanceModal(false)
        form.resetFields()
      }
    })
  }


  const formatTrProps = ({ original }) => {
    return {
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (original.id) {
          setIsEdit(true)
          setCheckIn(original.actualCheckIn ? moment(original.actualCheckIn, 'HH:mm').format('HH:mm') : "")
          setCheckOut(original.actualCheckOut ? moment(original.actualCheckOut, 'HH:mm').format('HH:mm') : "")
          setSelectedAttendance(original)
          // if (original.type === 'leave') {
          //   setSelectedAttendance(true)
          // }
        }
      },
    };
  };


  return (
    <div>
      <ConfirmModal
        backdrop="static"
        title="Hold up!"
        open={showErrorModal}
        confirmButtonText="Close"
        image={infoIcon}
        onConfirm={() => {
          setShowErrorModal(false)
          setShowAddAttendanceModal(false)
          setFlag(true)
        }}
      >
        <div className="d-flex flex-column gap-3">
          <div>
            There seem to be a few conflicts within this schedule attempt.
            However, we've created shifts for dates prior to and following the
            date(s) of conflict.
          </div>
          <div>
            You can resolve these conflicts and schedule again for the
            following days,
          </div>
        </div>
        <div className="d-flex flex-column gap-3 align-items-center">
          {errorData?.array?.map((data, idx) => (
            <div className="d-flex align-items-center gap-2" key={idx}>
              {
                data.date ?
                  <>
                    <div style={{ whiteSpace: "nowrap" }}>{data.date}</div>
                    <div style={{ border: '1px solid', height: 20 }} />
                  </>
                  : null
              }
              <div>{data.day[0].toUpperCase() + data.day.slice(1)}</div>
              <div style={{ border: '1px solid', height: 20 }} />
              <div>{data.reason}</div>
            </div>
          ))}
        </div>
        {errorData?.successCount > 0 && (
          <div>
            {errorData?.successCount} Attendance Were Marked Successfully
          </div>
        )}
      </ConfirmModal>
      {
        showAddAttendanceModal &&
        <AddModal
          title="Add Attendance"
          handleModal={showAddAttendanceModal}
          primaryButtonText="Add"
          secondaryButtonText="Cancel"
          formId={form}
          setHandleModal={() => setShowAddAttendanceModal(false)}
          primaryButtonHandler={form.submit}
        >
          <Form
            onFinish={onMarkAttendance}
            form={form}
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              label="Branch"
              name="branchId"
              rules={[{
                required: true,
                message: 'Please Select a branch!'
              }]}
            >
              <Select
                dropdownStyle={{ zIndex: 2000 }}
                placeholder="Select Branch"
                bordered={false}
                className='text-input-field p-1 t1'
              >
                {
                  regionBranches?.map((data, idx) => (
                    <Select.Option key={idx} value={data.id} >
                      {data.title}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
            <div className="d-flex gap-3">
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[{
                  required: true,
                  message: 'Please Select Start Date!'
                }]}
              >
                <DatePicker
                  allowClear={false}
                  inputReadOnly
                  format="DD-MM-YYYY"
                  getPopupContainer={(trigger) => trigger.parentElement}
                  className="text-input-field p-2 pe-3 t1"
                  style={{ width: '100%' }}
                  onChange={(val) => {
                    form.setFieldsValue({
                      endDate: val
                    })
                  }}
                  disabledDate={(current) => {
                    return dayjs() <= current;
                  }}
                />
              </Form.Item>
              <Form.Item
                label="End Date"
                name="endDate"
                rules={[{
                  required: true,
                  message: 'Please Select Start Date!'
                }]}
              >
                <DatePicker
                  allowClear={false}
                  inputReadOnly
                  format="DD-MM-YYYY"
                  getPopupContainer={(trigger) => trigger.parentElement}
                  className="text-input-field p-2 pe-3 t1"
                  style={{ width: '100%' }}
                  disabledDate={(current) => {
                    return dayjs(startDate).add(-1, 'day') >= current || dayjs() <= current;
                  }}
                />
              </Form.Item>
            </div>
            <div className="d-flex gap-3">
              <Form.Item
                label="Start Time"
                name="startTime"
                rules={[{
                  required: true,
                  message: 'Please Select Start Date!'
                }]}
              >
                <TimePicker
                  inputReadOnly
                  allowClear={false}
                  format="HH:mm"
                  minuteStep={5}
                  onSelect={value => form.setFieldsValue({ startTime: value })}
                  className="text-input-field p-2 pe-3 t1"
                  style={{ width: "100%" }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </Form.Item>
              <Form.Item
                label="End Time"
                name="endTime"
                rules={[{
                  required: true,
                  message: 'Please Select Start Date!'
                }]}
              >
                <TimePicker
                  inputReadOnly
                  allowClear={false}
                  format="HH:mm"
                  minuteStep={5}
                  onSelect={value => form.setFieldsValue({ endTime: value })}
                  className="text-input-field p-2 pe-3 t1"
                  style={{ width: "100%" }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </Form.Item>
            </div>
            <Form.Item
              label="Exclude Days"
              name="excludeDays"
            >
              <MultipleDatePicker
                onChange={(days) => form.setFieldsValue({ excludeDays: days })}
                startDate={startDate}
                endDate={endDate}
                excludeDays={excludeDays}
                form={form}
              />
            </Form.Item>
          </Form>
        </AddModal>
      }
      <div className="">
        <TableToolbar
          headingText="Time Sheet"
          filterButton
          primaryBtnText="Add Attendance"
          primaryBtnHandler={() => setShowAddAttendanceModal(true)}
          FilterComponent={
            <StaffTimeSheetFilterComponent dateRange={dateRange} onApplyFilter={onApplyFilter} />
          }
        />
        <div
          className="d-flex align-items-center gap-4"
          style={{ padding: "5px 24px" }}
        >
          <div
            className="d-flex align-items-center gap-4"
            style={{ width: "50%" }}
          >
            <DetailTab
              color="#CDF2EA"
              label="Avg Check-in "
              count={avgChecInTime}
            />
            <DetailTab
              color="#FFDB75"
              label="Avg Check-in "
              count={avgChecOutTime}
            />
          </div>
          <div style={{ width: "50%" }}>
            <DetailTab
              color="#FFCFC5"
              label="Absences "
              count2={sick_leaves?.count}
              count={annual_leaves?.count}
              role1="Annual"
              role2="Sick "
            />
          </div>
        </div>
        <div style={{ padding: "5px 24px" }}>
          {attendance?.rows.length <= 0 ? (
            <EmptyData title="Time Sheet" description="Time sheet" />
          ) : (
            <TableComponet
              rowCursor="pointer"
              perPageCount={(dateRange.endDate.diff(dateRange.startDate, 'days') + 1) * 10}
              isBorderShown
              columns={columns}
              data={staffTimeSheetList ?? []}
              formatRowProps={formatTrProps}
            />
          )}
        </div>
      </div>
    </div>
  );
};
