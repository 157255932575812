import React from "react";
import DashboardImage from '../../../assets/img/Dashboard.png'
import Logo from '../../../assets/icons/LopicLogo.png'
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ResetPassForm } from "../../../components/auth/login/resetPassForm";
import { ResetPassEmailForm } from "../../../components/auth/login/resetPassEmailForm";
import { LoginForm } from "../../../components/auth/login/loginForm";


export const LoginPage = () => {
    const { token } = useParams()
    const [formView, setFormView] = useState("login")
    return (
        <div className="d-flex align-items-center p-4" style={{ height: '100vh', gap: '15px' }} >
            <div className="position-relative border rounded-4 d-flex align-items-center p-5 text-center" style={{ width: '50%', backgroundColor: '#032B26', height: '100%' }}>
                <div className="d-flex flex-column" style={{ width: '100%', gap: '50px' }}>
                    <div className="m-auto" style={{ maxWidth: '382px' }}>
                        <div className="h3 fw-bold" style={{ color: 'white' }} >Welcome</div>
                        <div className="t2" style={{ color: 'white' }}>Your all-in one solution for pharamcy management and locum hiring.</div>
                    </div>
                    <img width={500} className="m-auto" src={DashboardImage} alt="dashboard" />
                </div>
                <div className="position-absolute" style={{ bottom: '5%', left: "50%", marginLeft: '-47px' }}>
                    <img className="m-auto" src={Logo} alt="Logo" />
                </div>
            </div>
            {
                token ?
                    <ResetPassForm /> :
                    formView === 'login' ?
                        <LoginForm setFormView={setFormView} />
                        :
                        <ResetPassEmailForm setFormView={setFormView} />
            }

        </div >
    );
}
