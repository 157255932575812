import React, { memo, useCallback, useEffect, useState } from "react";
import { getDuration, getDurationFormat, weekDays } from "../../../../../../../utils/dates";
import { CustomCheckbox } from "../../../../../../../components/UI/checkboxes/checkbox";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { AddModal } from "../../../../../../../components/UI/modals/addModal";
import { useDispatch } from "react-redux";
import { getBranchDataThunk, updateBranchOperatingTimeThunk } from "../../../../../../../redux/branches/branchesThunk";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmModal } from "../../../../../../../components/UI/modals/confirmModal";
import infoIcon from "../../../../../../../assets/icons/infoIcon1.png";

const TIME_FORMAT = "HH:mm";

export const BranchHoursForm = memo(
  ({
    handleModal,
    setHandleModal,
    editTab,
    operatingTime,
  }) => {
    const [weekData, setWeekData] = useState();
    const dispatch = useDispatch()
    const { id } = useParams()
    const navigate = useNavigate()
    const [errorData, setErrorData] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);

    useEffect(() => {
      if (operatingTime && operatingTime.length > 0) {
        const getOperatingDays = weekDays.map((day) => {
          const getDaysFormat = operatingTime?.find(
            (operatingDay) => operatingDay.dayOfWeek === day
          );
          if (getDaysFormat) {
            return {
              day,
              selected: true,
              startTime: dayjs(getDaysFormat.startTime, "HH:mm"),
              endTime: dayjs(getDaysFormat.endTime, "HH:mm"),
              duration: getDuration(
                dayjs(getDaysFormat.startTime, "HH:mm"),
                dayjs(getDaysFormat.endTime, "HH:mm")
              ),
              lunch: !!getDaysFormat.lunchStartTime,
              lunchStartTime: getDaysFormat.lunchStartTime
                ? dayjs(getDaysFormat.lunchStartTime, "HH:mm")
                : null,
              lunchEndTime: getDaysFormat.lunchEndTime
                ? dayjs(getDaysFormat.lunchEndTime, "HH:mm")
                : null,
              lunchDuration: getDuration(
                dayjs(getDaysFormat.lunchStartTime, "HH:mm"),
                dayjs(getDaysFormat.lunchEndTime, "HH:mm")
              ),
            };
          } else
            return {
              day,
              selected: false,
              startTime: null,
              endTime: null,
              duration: "",
            };
        });
        setWeekData(getOperatingDays);
      } else {
        const data = weekDays.map((day) => {
          return {
            day,
            selected: false,
            startTime: null,
            endTime: null,
            duration: "",
            lunch: false,
            lunchStartTime: null,
            lunchEndTime: null,
            lunchDuration: "",
          };
        });
        setWeekData(data);
      }
    }, [operatingTime]);

    const onSelectDay = (e, day, isLunch) => {
      e.preventDefault();

      const updatedState = weekData.map((el) => {
        if (el.day !== day) return el;

        if (isLunch)
          return {
            ...el,
            lunch: !el.lunch,
          };

        return {
          ...el,
          selected: !el.selected,
        };
      });

      setWeekData(updatedState);
    };

    const onStatTimeChange = (time, timeStart, timeFinish, duration, day) => {
      let setDuration = timeFinish ? getDuration(time, timeFinish) : "";
      const updatedState = weekData.map((el) => {
        if (el.day !== day) return el;
        return {
          ...el,
          [timeStart]: time,
          [duration]: setDuration,
        };
      });
      setWeekData(updatedState);
    };

    const onFinishTimeChange = (time, timeFinish, timeStart, duration, day) => {
      let setDuration = timeStart ? getDuration(timeStart, time) : "";
      const updatedState = weekData.map((el) => {
        if (el.day !== day) return el;
        return {
          ...el,
          [timeFinish]: time,
          [duration]: setDuration,
        };
      });
      setWeekData(updatedState);
    };


    const onBranchTimeUpdate = useCallback(
      (deleteShift) => {
        let operatingTimeData = weekData
          ?.map(
            ({
              selected,
              day,
              startTime,
              endTime,
              lunch,
              lunchStartTime,
              lunchEndTime,
            }) => {
              if (!selected || !startTime) return null;
              let result = {};
              if (selected && startTime) {
                result = {
                  dayOfWeek: day,
                  startTime: startTime.format("HH:mm"),
                  endTime: endTime.format("HH:mm"),
                };
              }
              if (lunch && lunchStartTime) {
                result.lunchStartTime = lunchStartTime.format("HH:mm");
                result.lunchEndTime = lunchEndTime.format("HH:mm");
              }
              return result;
            }
          )
          .filter((item) => !!item);

        dispatch(
          updateBranchOperatingTimeThunk({
            id,
            data: {
              deleteShift,
              operatingTimeData
            },
          })
        )
          .then(res => {
            if (res.payload.code === "validation") {
              setShowErrorModal(true);
              setErrorData(res.payload);
              return
            }
            if (res.payload) {
              dispatch(getBranchDataThunk({ id }))
              setHandleModal()
            }
          })
      },
      [dispatch, id, setHandleModal, weekData]
    );

    const _disabledHours = (disableFor, startTime, endTime, lunchStartTime, lunchEndTime) => {
      let disabledHours = []
      let disabledHours2 = []

      if (disableFor === 'startTime') {
        for (let i = Number(endTime?.format('H')) + 1; i < 24; i++) {
          disabledHours.push(i)
        }
        return [...disabledHours, ...disabledHours2]
      }
      if (disableFor === 'lunchStartTime') {
        for (let i = Number(startTime?.format('H')) - 1; i >= 0; i--) {
          disabledHours.push(i)
        }
        for (let i = Number(endTime?.format('H')) + 1; i < 24; i++) {
          disabledHours.push(i)
        }
        for (let i = Number(lunchEndTime?.format('H')) + 1; i < 24; i++) {
          disabledHours.push(i)
        }
        return [...disabledHours, ...disabledHours2]
      }
      if (disableFor === 'endTime') {
        for (let i = 0; i < Number(startTime?.format('H')); i++) {
          disabledHours.push(i)
        }

        return [...disabledHours, ...disabledHours2]
      }
      if (disableFor === 'lunchEndTime') {
        for (let i = Number(startTime?.format('H')) - 1; i >= 0; i--) {
          disabledHours.push(i)
        }
        for (let i = Number(endTime?.format('H')) + 1; i < 24; i++) {
          disabledHours.push(i)
        }
        for (let i = Number(lunchStartTime?.format('H')) - 1; i >= 0; i--) {
          disabledHours.push(i)
        }
        return [...disabledHours, ...disabledHours2]
      }
    }

    const _disabledMinute = (disableFor, selectedHour, startTime, endTime, lunchStartTime, lunchEndTime) => {
      const minutes = [];
      if (disableFor === 'startTime') {
        if (selectedHour === Number(endTime?.format('H'))) {
          const currentMinute = Number(endTime?.format('mm'));
          for (let i = currentMinute + 1; i <= 60; i++) {
            minutes.push(i);
          }
          return minutes;
        }
      }
      if (disableFor === 'lunchStartTime') {
        if (selectedHour === Number(startTime?.format('H'))) {
          const currentMinute = Number(startTime?.format('mm'));
          for (let i = currentMinute - 1; i >= 0; i--) {
            minutes.push(i);
          }
          return minutes;
        }
        if (selectedHour === Number(endTime?.format('H'))) {
          const currentMinute = Number(endTime?.format('mm'));
          for (let i = currentMinute + 1; i <= 60; i++) {
            minutes.push(i);
          }
          return minutes;
        }
        if (selectedHour === Number(lunchEndTime?.format('H'))) {
          const currentMinute = Number(lunchEndTime?.format('mm'));
          for (let i = currentMinute + 1; i <= 60; i++) {
            minutes.push(i);
          }
          return minutes;
        }
      }
      if (disableFor === 'endTime') {
        if (selectedHour === Number(startTime?.format('H'))) {
          const currentMinute = Number(startTime?.format('mm'));
          for (let i = currentMinute - 1; i >= 0; i--) {
            minutes.push(i);
          }
          return minutes;
        }
      }
      if (disableFor === 'lunchEndTime') {
        if (selectedHour === Number(endTime?.format('H'))) {
          const currentMinute = Number(endTime?.format('mm'));
          for (let i = currentMinute + 1; i <= 60; i++) {
            minutes.push(i);
          }
          return minutes;
        }
        if (selectedHour === Number(startTime?.format('H'))) {
          const currentMinute = Number(startTime?.format('mm'));
          for (let i = currentMinute - 1; i >= 0; i--) {
            minutes.push(i);
          }
          return minutes;
        }
        if (selectedHour === Number(lunchStartTime?.format('H'))) {
          const currentMinute = Number(lunchStartTime?.format('mm'));
          for (let i = currentMinute - 1; i >= 0; i--) {
            minutes.push(i);
          }
          return minutes;
        }
      }
    }

    return (
      <>
        <ConfirmModal
          backdrop="static"
          title="Hold up!"
          open={showErrorModal}
          confirmButtonText="Proceed and Delete"
          cancelButtonText="Close"
          image={infoIcon}
          onClose={() => setShowErrorModal(false)}
          onConfirm={() => onBranchTimeUpdate(true)}
        >
          <div className="d-flex flex-column gap-3">
            <div>
              There seem to be a few conflicts within this schedule attempt.
              However, we've created shifts for dates prior to and following the
              date(s) of conflict.
            </div>
            <div>
              You can resolve these conflicts and schedule again for the
              following days,
            </div>
          </div>
          <div className="d-flex flex-column gap-3 align-items-center">
            {errorData?.array?.map((data, idx) => (
              <div className="d-flex align-items-center gap-2" key={idx}>
                {
                  data.jobsConflicts ?
                    <>
                      <div style={{ whiteSpace: "nowrap" }}>{data.jobsConflicts} jobs</div>
                      <div style={{ border: '1px solid', height: 20 }} />
                    </>
                    : null
                }
                {
                  data.shiftsConflicts ?
                    <>
                      <div style={{ whiteSpace: "nowrap" }}>{data.shiftsConflicts} Shifts</div>
                      <div style={{ border: '1px solid', height: 20 }} />
                    </>
                    : null
                }
                <div>{data.day[0].toUpperCase() + data.day.slice(1)}</div>
                <div style={{ border: '1px solid', height: 20 }} />
                <div>{data.reason}</div>
              </div>
            ))}
          </div>
          {errorData?.successCount > 0 && (
            <div>
              {errorData?.successCount} Shifts/Jobs Were Scheduled Successfully
            </div>
          )}
        </ConfirmModal>
        <AddModal
          title={`Edit ${editTab}`}
          handleModal={handleModal}
          setHandleModal={setHandleModal}
          primaryButtonHandler={() => onBranchTimeUpdate(false)}
          primaryButtonText="Update"
        >
          <div
            className="d-flex flex-column align-items-start add-shift-modal-content"
            style={{ gap: "15px", color: "#7B7B7B", paddingTop: "25px" }}
          >
            {weekData?.map((data, index) => {
              const {
                day,
                selected,
                startTime,
                endTime,
                lunch,
                lunchStartTime,
                lunchEndTime,
              } = data;

              return (
                <div
                  key={index}
                  className="d-flex flex-column border-bottom"
                  style={{ width: "100%", gap: "15px", minHeight: 40 }}
                >
                  <div
                    className="d-flex justify-content-between"
                    style={{ width: "100%" }}
                  >

                    {/* <Checkbox checked={selected} onChange={e => onSelectDay(e, day)} >
                  {day}
                </Checkbox> */}
                    <CustomCheckbox
                      name={day}
                      label={day}
                      className="end-date-checkbox"
                      checked={selected}
                      onChange={(e) => onSelectDay(e, day)}
                    />
                    {!data.selected ? (
                      <div>Closed</div>
                    ) : (
                      getDurationFormat(
                        getDuration(
                          dayjs(startTime, "HH:mm"),
                          dayjs(endTime, "HH:mm")
                        )
                      )
                    )}
                  </div>
                  <div
                    className={`border rounded p-3 d-flex flex-column day-box ${!selected ? "d-none" : "d-block"
                      } `}
                    style={{
                      width: "100%",
                      gap: "15px",
                    }}
                  >
                    {selected && (
                      <>
                        <div
                          className="d-flex justify-content-between "
                          style={{ gap: 15 }}
                        >
                          <div
                            className="day-row__time"
                            style={{ background: "light-grey", width: "100%" }}
                          >
                            <TimePicker
                              inputReadOnly
                              disabledHours={() => _disabledHours('startTime', startTime, endTime)}
                              disabledMinutes={(time) => _disabledMinute('startTime', time, startTime, endTime)}
                              style={{ width: '100%' }}
                              className="text-input-field p-2 pe-3 t1"
                              label="Start Time"
                              placeholder="Start Time"
                              onSelect={(value) => {
                                onStatTimeChange(
                                  value,
                                  "startTime",
                                  endTime,
                                  "duration",
                                  day
                                )
                              }}
                              onChange={(value) => {
                                onStatTimeChange(
                                  value,
                                  "startTime",
                                  endTime,
                                  "duration",
                                  day
                                )
                              }}
                              format={TIME_FORMAT}
                              getPopupContainer={(trigger) => trigger.parentElement}
                              minuteStep={5}
                              value={startTime}
                            />
                          </div>
                          <div
                            style={{ background: "light-grey", width: "100%" }}
                            className="day-row__time"
                          >
                            <TimePicker
                              inputReadOnly
                              disabledHours={() => _disabledHours('endTime', startTime, endTime)}
                              disabledMinutes={(time) => _disabledMinute('endTime', time, startTime, endTime)}
                              style={{ width: '100%' }}
                              className="text-input-field p-2 pe-3 t1"
                              label="End Time"
                              placeholder="End Time"
                              onSelect={(value) =>
                                onFinishTimeChange(
                                  value,
                                  "endTime",
                                  startTime,
                                  "duration",
                                  day
                                )
                              }
                              onChange={(value) =>
                                onFinishTimeChange(
                                  value,
                                  "endTime",
                                  startTime,
                                  "duration",
                                  day
                                )
                              }
                              format={TIME_FORMAT}
                              getPopupContainer={(trigger) => trigger.parentElement}
                              minuteStep={5}
                              value={endTime}
                            />
                          </div>
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ width: "100%" }}
                        >
                          <CustomCheckbox
                            name="lunch"
                            label="Lunch Break"
                            className="end-date-checkbox"
                            checked={lunch}
                            onChange={(e) => onSelectDay(e, day, true)}
                          />
                          {!data.lunch ? (
                            <div>Closed</div>
                          ) : (
                            getDurationFormat(
                              getDuration(
                                dayjs(lunchStartTime, "HH:mm"),
                                dayjs(lunchEndTime, "HH:mm")
                              )
                            )
                          )}
                        </div>
                      </>
                    )}
                    {lunch && (
                      <div
                        className="d-flex justify-content-between"
                        style={{
                          background: "light-grey",
                          width: "100%",
                          gap: "15px",
                        }}
                      >
                        <div
                          className="day-row__time"
                          style={{ background: "light-grey", width: "100%" }}
                        >
                          <TimePicker
                            disabledHours={() => _disabledHours('lunchStartTime', startTime, endTime, lunchStartTime, lunchEndTime)}
                            disabledMinutes={(time) => _disabledMinute('lunchStartTime', time, startTime, endTime, lunchStartTime, lunchEndTime)}
                            style={{ width: '100%' }}
                            label="Start Time"
                            placeholder="Start Time"
                            className="text-input-field p-2 pe-3 t1"
                            onSelect={(value) =>
                              onStatTimeChange(
                                value,
                                "lunchStartTime",
                                lunchEndTime,
                                "lunchDuration",
                                day
                              )
                            }
                            onChange={(value) =>
                              onStatTimeChange(
                                value,
                                "lunchStartTime",
                                lunchEndTime,
                                "lunchDuration",
                                day
                              )
                            }
                            format={TIME_FORMAT}
                            minuteStep={5}
                            getPopupContainer={(trigger) => trigger.parentElement}
                            value={lunchStartTime}
                          />
                        </div>
                        <div
                          style={{ background: "light-grey", width: "100%" }}
                          className="day-row__time"
                        >
                          <TimePicker
                            disabledHours={() => _disabledHours('lunchEndTime', startTime, endTime, lunchStartTime, lunchEndTime)}
                            disabledMinutes={(time) => _disabledMinute('lunchEndTime', time, startTime, endTime, lunchStartTime, lunchEndTime)}
                            style={{ width: '100%' }}
                            label="End Time"
                            placeholder="End Time"
                            className="text-input-field p-2 pe-3 t1"
                            onSelect={(value) =>
                              onFinishTimeChange(
                                value,
                                "lunchEndTime",
                                lunchStartTime,
                                "lunchDuration",
                                day
                              )
                            }
                            onChange={(value) =>
                              onFinishTimeChange(
                                value,
                                "lunchEndTime",
                                lunchStartTime,
                                "lunchDuration",
                                day
                              )
                            }
                            getPopupContainer={(trigger) => trigger.parentElement}
                            format={TIME_FORMAT}
                            minuteStep={5}
                            value={lunchEndTime}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </AddModal>
      </>

    );
  }
);
BranchHoursForm.displayName = BranchHoursForm;
