import { Button } from "antd";
import React, { memo } from "react";
import { Modal } from "react-bootstrap";
import "./style.scss";

export const AddModal = memo(
  ({
    handleModal,
    title,
    setHandleModal,
    children,
    primaryButtonText,
    secondaryButtonText,
    primaryButtonHandler,
    secondaryButtonHandler,
    disabledPrimaryBtn,
    formId,
    isLoading,
    isDelete,
    width,
    fixWidth
  }) => {
    return (
      <Modal
        show={handleModal}
        onHide={setHandleModal}
        className={`right create-client-modal modal-dialog-scrollable ${fixWidth ? "fixWidthContent" : ""}`}
       
      >
        <Modal.Header closeButton>
          <Modal.Title className="heading2">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <div className="b1 d-flex justify-content-between align-items-center add-shift-footer">
            {secondaryButtonText && (
              <Button
                onClick={secondaryButtonHandler}
                className="p-2 btn-style"
                block
                danger={isDelete}
                type={isDelete ? "primary" : "default"}
              >
                {secondaryButtonText}
              </Button>
            )}
            {primaryButtonText && (
              <Button
                loading={isLoading}
                disabled={disabledPrimaryBtn}
                form={formId}
                onClick={primaryButtonHandler}
                className="p-2 btn-style"
                type="primary"
                htmlType="submit"
                block
              >
                {primaryButtonText}
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
);
