import { RouterConfig } from "../routerConfig";
import moment from "moment";
import cookies from "browser-cookies";
import { toast } from "react-toastify";
import { searchAddressConfig, userRoles, userStatusesList } from "./constant";
import { store } from "../redux";
import successIcon from '../assets/icons/check-circle.png'
import errorIcon from '../assets/icons/warningIcon.png'
import { Upload } from "antd";
import imageCompression from 'browser-image-compression';

export const notify = (type, text) => {
  toast(text, {
    position: "top-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: type,
    icon: ({ light, type }) => <img src={type === 'success' ? successIcon : errorIcon} alt="" />
  });
};

export const copyToClipBoard = (text) => {
  navigator.clipboard.writeText(text);
}

export const getTotalMinutes = (startTime, endTime) => {
  var _startTime = moment(startTime, "hh:mm:ss a");
  var _endTime = moment(endTime, "hh:mm:ss a");
  var mins = moment.duration(moment(_endTime, "HH:mm:ss").diff(moment(_startTime, "HH:mm:ss"))).asMinutes()

  return mins
}

export const beforeUpload = async (file) => {
  // const isLt2M = file.size / 1024 <= 1024;
  // if (!isLt2M) {
  //   notify('error', 'Image must smaller than 1MB!');
  //   return Upload.LIST_IGNORE || isLt2M
  // } else {
  //   return false
  // }
  return false
};

export const compressImage = async (file, options = {}) => {
  if (file?.status === 'removed' || file.type.split('/')[0] !== 'image') return file
  try {
    const defaultOptions = {
      maxSizeMB: 1, // Maximum allowed size in megabytes
      useWebWorker: true, // Enable Web Worker for faster compression
    };

    const compressionOptions = { ...defaultOptions, ...options };
    return await imageCompression(file, compressionOptions);
  } catch (error) {
    console.error('Error compressing the image:', error);
  }
};

export const genRandonString = (length) => {
  var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
  var charLength = chars.length;
  var result = '';
  for (var i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
}

export const sortAccredArray = (array = []) => {
  const acc = [...array]
  return acc.sort((a, b) => (b.filePath !== '') - (a.filePath !== ''))
}

export function getExtention(fileName, formatsArray) {
  let i = fileName.lastIndexOf(".");
  let extension = fileName.slice(i);
  let error = null;

  if (i === -1) {
    return false;
  }
  if (!formatsArray.some((format) => format === extension.toLowerCase())) {
    error = `please provide image with one of these extensions: ${formatsArray.join(
      ", "
    )}`;
  }

  return error;
}

export const getListOfYears = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export function getSize(fileName) {
  // check if field is empty
  if (fileName === undefined) {
    return;
  }
  let FileSize = fileName.size / 1024 / 1024; // in MB
  let error;
  if (FileSize > 15) {
    error = "File size exceeds 15 MB";
  } else {
    error = null;
  }
  return error;
}

export const getType = (value) => {
  return Object.prototype.toString
    .call(value)
    .replace(/^\[object |\]$/g, "")
    .toLowerCase();
};

export const isManagerRole = (role) =>
  role !== userRoles.TECHNICIAN &&
  role !== userRoles.DISPENSER &&
  role !== userRoles.MEDICINE_COUNTER;

export const getTotalRate = (startDate, endDate, hourlyRate, workDay) => {
  let getHours = 0;
  if (endDate) {
    const duration = moment.duration(moment(endDate).diff(startDate));
    const getDaysDiff = duration.asDays() + 1;
    getHours = (workDay / 60) * getDaysDiff;
  } else {
    getHours = workDay / 60;
  }
  return (hourlyRate * getHours).toFixed(2);
};

export const paginate = (array, page_size, page_number) => {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const checkFirstShiftGap = (shift, index, branchStartWorkingTime) => {
  const { isJob, startTime } = shift;
  if (
    index === 0 &&
    !isJob &&
    moment(startTime, "HH:mm").diff(
      moment(branchStartWorkingTime, "HH:mm"),
      "minutes"
    ) >= 120
  )
    return "has-gap";
  return "";
};

export const sortByStartTime = (a, b) =>
  moment(a.startTime, "HH:mm").diff(moment(b.startTime, "HH:mm"), "minutes") > 0
    ? 1
    : -1;

export const getDayStatus = (shifts) => {
  let status = "";
  shifts.forEach((shift) => {
    if (!shift.isDraft && !shift.isJob && !shift.hourlyRate) {
      status = "shift";
    } else if (
      shift.isJob &&
      shift.hourlyRate &&
      (status === "is-draft" || !status)
    ) {
      status = "job-posted";
    } else if (shift.isDraft && !status) {
      status = "is-draft";
    }
  });
  return status;
};

// export const getStaffEducationList = (complianceInfo) => {
//     const formatedInfoArray = [];
//     for (let key in complianceInfo) {
//         for (let certificate in userCertificates) {
//             if (complianceInfo[key] && key === certificate) {
//                 formatedInfoArray.push({
//                     title: userCertificates[certificate],
//                     value: complianceInfo[key],
//                 });
//             }
//         }
//     }

//     return formatedInfoArray;
// };

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const isOnboardingFileUploaded = (name, form) => {
  let result = false;

  for (let key in form) {
    if (key === name && !form[key]) {
      result = true;
    }
  }

  return result;
};

export function saveFile({ contentType, file }, fileName) {
  const linkSource = `data:${contentType};base64,${file}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export const getUniqueEvents = (dayEvents) => {
  /* creating new array with shifts and spliting shifts objects
        themselves and staff object to get rid of duplicates and
        sattle all events for one person to one item */
  const settled = new Map();
  if (dayEvents && dayEvents.shifts) {
    dayEvents.shifts
      .sort((a, b) => sortByStartTime(a, b))
      .forEach((shift) => {
        if (shift.staff && !shift.isJob) {
          const { id } = shift.staff;
          if (settled.has(id)) {
            // check if there is staff duplicate
            settled.set(id, {
              shifts: [shift, ...settled.get(id).shifts].sort((a, b) =>
                sortByStartTime(a, b)
              ), // add new shift object to existing one with the same staff
              staff: shift.staff,
            });
          } else {
            // if no duplicates create new object with staff and shift info
            settled.set(id, {
              shifts: [shift],
              staff: shift.staff,
            });
          }
        } else if (!shift.staff && !shift.isJob) {
          // shift with no staff
          settled.set(shift.id, { shifts: [shift], staff: shift });
        } else {
          // create job object
          settled.set(`${shift.id}-j`, { shifts: [shift], staff: shift });
        }
      });
  }

  return settled;
};

export const isEmptyFieldsRemoved = (object) =>
  Object.entries(object).reduce((a, [k, v]) => (!v ? a : { ...a, [k]: v }), {});

export const getUrlPathRapams = (pathname) => {
  const parts = pathname.split("/");
  return {
    path: parts[1],
    param: parts.pop(),
  };
};

export const getPayloadData = (payload, ignoredFields = []) => {
  let result = {};

  for (let key in payload) {
    if (!ignoredFields.some((field) => field === key)) {
      result = {
        ...result,
        [key]: payload[key],
      };
    }
  }

  return result;
};

export const getDataPropsLength = (dataObject) =>
  Object.values(dataObject).filter((item) => item).length;

export const userStatusCheck = (userStatus, roleView) => {
  let path = "";
  if (
    userStatus === userStatusesList.ONBOARDING ||
    userStatus === userStatusesList.PENDING ||
    userStatus === userStatusesList.REJECTED
  ) {
    path = RouterConfig[roleView].onboarding;
  } else if (userStatus === userStatusesList.ACTIVE) {
    if (roleView === "manager") {
      path = RouterConfig[roleView].personalInfo;
    } else {
      path = RouterConfig[roleView].jobOffers;
    }
  } else if (userStatus === userStatusesList.NEW) {
    path = RouterConfig[roleView].onboarding;
  } else {
    cookies.erase("accessToken");
    path = RouterConfig.root;
  }

  return path;
};

export const getAddressFields = (addressObj, outputStateProps) => {
  let result = {};
  let getAddressFields = {};

  for (let i = 0; i < addressObj.address_components.length; i++) {
    let addressType = addressObj.address_components[i].types[0];
    if (searchAddressConfig[addressType]) {
      let val =
        addressObj.address_components[i][searchAddressConfig[addressType]];
      getAddressFields[addressType] = val;
    }
  }

  for (let stateProp in outputStateProps) {
    for (let key in getAddressFields) {
      if (
        key === outputStateProps[stateProp] ||
        outputStateProps[stateProp].includes(key)
      ) {
        result[stateProp] = getAddressFields[key];
      }
    }
  }

  return result;
};

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const calculatePages = (totalItems, itemsPerPage) => {
  const perPage = itemsPerPage === 0 ? 1 : itemsPerPage;
  return Math.ceil(totalItems / perPage);
};

export const hasAccess = (key) => {
  let access;
  let matchedKey;
  let {
    auth: { accessibleApis },
  } = store.getState();

  try {
    matchedKey = accessibleApis?.find((apikey) => apikey.apiKey === key);
  } catch (e) {
    console.log("hasAccess function:", e);
  }

  if (matchedKey) access = matchedKey.isGranted === 1 ? true : false;

  return access;
};

export const hasAccessToModule = (moduleKey) => {
  const {
    auth: { accessibleModules: modules },
  } = store.getState();
  const isAccessible = () => {
    let flag = false;
    let modulesArray = Object.values(modules);

    for (let module of modulesArray) {
      if (moduleKey === module.moduleKey) {
        flag = true;
        break;
      }
    }
    return flag;
  };

  return isAccessible();
};
