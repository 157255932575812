import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addBankHoliday,
  deleteAccommodationAndFoodRules,
  deleteMileageRules,
  deleteParkingRules,
  deleteProfileAccrediations,
  deleteTransportRules,
  editProfileData,
  editProfilePass,
  getAccommodationAndFoodRules,
  getBankHolidays,
  getPharmacySettings,
  getMileageRules,
  getParkingRules,
  getProfileAccrediations,
  getProfileData,
  getTransportRules,
  saveAccommodationAndFoodRules,
  saveMileageRules,
  saveParkingRules,
  saveProfileAccrediations,
  saveTransportRules,
  updatePharmacySettings,
  getPharmacyFlows,
  getAdminRoles,
  getAdminStaff,
  paymentInvoices,
  paymentBranches,
  getSubscriptions,
  paymentSubscriptionPlan,
  numarkAction,
  updateBranchSubscription,
  updateHostedPage,
  cancelBranchSubscription,
} from "../profileAPI";

export const getProfileDataThunk = createAsyncThunk(
  "getProfileData",
  async () => {
    const response = await getProfileData();
    return response.data.data;
  }
);

export const editProfileDataThunk = createAsyncThunk(
  "editProfileData",
  async (payload) => {
    const response = await editProfileData(payload);
    return response.data.data;
  }
);

export const editProfilePasswordThunk = createAsyncThunk(
  "editProfilePass",
  async (payload) => {
    const response = await editProfilePass(payload);
    return response.data;
  }
);

export const getProfileAccrediationsThunk = createAsyncThunk(
  "getProfileAccrediations",
  async (payload) => {
    const response = await getProfileAccrediations(payload);
    return response.data.data;
  }
);

export const saveProfileAccrediationsThunk = createAsyncThunk(
  "saveProfileAccrediations",
  async (payload) => {
    const response = await saveProfileAccrediations(payload);
    return response.data.data;
  }
);

export const delteProfileAccrediationsThunk = createAsyncThunk(
  "deleteProfileAccrediations",
  async (payload) => {
    const response = await deleteProfileAccrediations(payload);
    return response.data;
  }
);

export const addBankHolidayThunk = createAsyncThunk(
  "addBankHoliday",
  async (payload) => {
    const response = await addBankHoliday(payload);
    return response.data;
  }
);

export const getPharmacySettingsThunk = createAsyncThunk(
  "getPharmacySettings",
  async () => {
    const response = await getPharmacySettings();
    return response.data.data;
  }
);

export const updatePharmacySettingsThunk = createAsyncThunk(
  "updatePharmacySettings",
  async (payload) => {
    const response = await updatePharmacySettings(payload);
    return response.data.data;
  }
);

export const getMileageRulesThunk = createAsyncThunk(
  "getMileageRules",
  async () => {
    const response = await getMileageRules();
    return response.data.data;
  }
);

export const saveMileageRulesThunk = createAsyncThunk(
  "saveMileageRules",
  async (payload) => {
    const response = await saveMileageRules(payload);
    return response.data.data;
  }
);

export const deleteMileageRulesThunk = createAsyncThunk(
  "deleteMileageRules",
  async (payload) => {
    const response = await deleteMileageRules(payload);
    return response.data.data;
  }
);

export const getParkingRulesThunk = createAsyncThunk(
  "getParkingRules",
  async () => {
    const response = await getParkingRules();
    return response.data.data;
  }
);

export const saveParkingRulesThunk = createAsyncThunk(
  "saveParkingRules",
  async (payload) => {
    const response = await saveParkingRules(payload);
    return response.data.data;
  }
);

export const deleteParkingRulesThunk = createAsyncThunk(
  "deleteParkingRules",
  async (payload) => {
    const response = await deleteParkingRules(payload);
    return response.data.data;
  }
);

export const getAccommodationAndFoodRulesThunk = createAsyncThunk(
  "getAccommodationAndFoodRules",
  async () => {
    const response = await getAccommodationAndFoodRules();
    return response.data.data;
  }
);

export const saveAccommodationAndFoodRulesThunk = createAsyncThunk(
  "saveAccommodationAndFoodRules",
  async (payload) => {
    const response = await saveAccommodationAndFoodRules(payload);
    return response.data.data;
  }
);

export const deleteAccommodationAndFoodRulesThunk = createAsyncThunk(
  "deleteAccommodationAndFoodRules",
  async (payload) => {
    const response = await deleteAccommodationAndFoodRules(payload);
    return response.data.data;
  }
);

export const getTransportRulesThunk = createAsyncThunk(
  "getTransportRules",
  async () => {
    const response = await getTransportRules();
    return response.data.data;
  }
);

export const saveTransportRulesThunk = createAsyncThunk(
  "saveTransportRules",
  async (payload) => {
    const response = await saveTransportRules(payload);
    return response.data.data;
  }
);

export const deleteTransportRulesThunk = createAsyncThunk(
  "deleteTransportRules",
  async (payload) => {
    const response = await deleteTransportRules(payload);
    return response.data.data;
  }
);

export const getBankHolidaysThunk = createAsyncThunk(
  "getBankHolidays",
  async () => {
    const response = await getBankHolidays();
    return response.data;
  }
);

export const getPharmacyFlowsThunk = createAsyncThunk(
  "getPharmacyFlows",
  async () => {
    const response = await getPharmacyFlows();
    return response.data.data;
  }
);

export const getAdminRolesThunk = createAsyncThunk(
  "getAdminRoles",
  async () => {
    const response = await getAdminRoles();
    return response.data.data;
  }
);
export const getAdminStaffThunk = createAsyncThunk(
  "getAdminStaffThunk",
  async (payload) => {
    const response = await getAdminStaff(payload);
    return response.data.data;
  }
);

export const paymentInvoicesThunk = createAsyncThunk(
  "paymentInvoices",
  async (payload) => {
    const response = await paymentInvoices(payload);
    return response.data.data;
  }
);

export const paymentBranchesThunk = createAsyncThunk(
  "paymentBranches",
  async (payload) => {
    const response = await paymentBranches(payload);
    return response.data.data;
  }
);

export const getSubscriptionsThunk = createAsyncThunk(
  "getSubscriptions",
  async () => {
    const response = await getSubscriptions();
    return response.data.data;
  }
);

export const paymentSubscriptionPlanThunk = createAsyncThunk(
  "paymentSubscriptionPlan",
  async (payload) => {
    const response = await paymentSubscriptionPlan(payload);
    return response.data.data;
  }
);

export const numarkActionThunk = createAsyncThunk(
  "numarkAction",
  async (payload) => {
    const response = await numarkAction(payload);
    return response.data.data;
  }
);

export const updateBranchSubscriptionThunk = createAsyncThunk(
  "updateBranchSubscription",
  async (payload) => {
    const response = await updateBranchSubscription(payload);
    return response.data.data;
  }
);

export const cancelBranchSubscriptionThunk = createAsyncThunk(
  "cancelBranchSubscription",
  async (payload) => {
    const response = await cancelBranchSubscription(payload);
    return response.data;
  }
);

export const updateHostedPageThunk = createAsyncThunk(
  "updateHostedPage",
  async (payload) => {
    const response = await updateHostedPage(payload);
    return response.data.data;
  }
);
