
import dayjs from "dayjs";
import { FlexRow } from "../comon/flexRows";
import { DocumentSlide } from "../staffs/documentSlide";

export const Verification = ({
    showEdit,
    setShowEditInfoModal,
    setActionType,
    staffInfo,
    staffVerification
}) => {
    return (
        <div className="d-flex flex-column flex-1" style={{ gap: 30 }}>
            <div className="d-flex align-items-center justify-content-between">
                <div className="heading4">
                    Verification
                </div>
                {
                    showEdit &&
                    <div
                        className="s1 m-0 cursor-pointer border py-2 px-3 rounded"
                        onClick={() => {
                            setShowEditInfoModal(true)
                            setActionType('basic')
                        }}
                        style={{ color: 'white', backgroundColor: '#51C2BC' }}
                    >
                        EDIT
                    </div>
                }
            </div>
            <div className="border rounded">
                <div className="text-row d-flex align-items-center justify-content-between">
                    <div className="s1 m-0">Contact Information</div>
                </div>
                <FlexRow
                    primaryTitle="Home Address Line 1"
                    primaryDescription={staffVerification?.address1 || "-"}
                />
                <FlexRow
                    primaryTitle="Home Address Line 2"
                    primaryDescription={staffVerification?.address2 ? staffVerification?.address2 : "-"}
                />
                <FlexRow
                    primaryTitle="Town/City"
                    primaryDescription={staffVerification?.city || '-'}
                />
                <FlexRow
                    primaryTitle="Postal Code"
                    primaryDescription={staffVerification?.zipCode || "-"}
                />
                <FlexRow
                    primaryTitle="Phone Number"
                    primaryDescription={staffVerification?.phone || "-"}
                />
            </div>
            <div className="border rounded">
                <div className="text-row d-flex align-items-center justify-content-between">
                    <div className="s1 m-0">Other Information</div>
                    {/* {
                        showEdit &&
                        <div
                            className="s1 m-0 cursor-pointer"
                            onClick={() => {
                                setShowEditInfoModal(true)
                                setActionType('other')
                            }}
                            style={{ color: '#51C2BC' }}
                        >
                            EDIT
                        </div>
                    } */}
                </div>
                <FlexRow
                    primaryTitle="GPhC Number"
                    primaryDescription={staffVerification?.gphcNumber || "-"}
                />
                <FlexRow
                    primaryTitle="DBS Annual Update Service Number"
                    primaryDescription={staffVerification?.DBSAnnualUpdate || '-'}
                />
                <FlexRow
                    primaryTitle="National Insurance Number"
                    primaryDescription={staffVerification?.insuranceNumber || "-"}
                />
            </div>
            <div className="border rounded">
                <div className="text-row d-flex align-items-center justify-content-between">
                    <div className="s1 m-0">Documents</div>
                    {/* {
                        showEdit &&
                        <div
                            className="s1 m-0 cursor-pointer"
                            onClick={() => {
                                setShowEditInfoModal(true)
                                setActionType('docs')
                            }}
                            style={{ color: '#51C2BC' }}
                        >
                            EDIT
                        </div>
                    } */}
                </div>
                <div className="d-flex flex-wrap gap-3 align-items-center m-3">
                    <DocumentSlide label="Profile Picture" name={staffVerification?.name} value={staffVerification?.image} />
                    <DocumentSlide label="Proof Of ID (Passport or Driving License)" name={staffVerification?.name} value={staffVerification?.proofOfId} expDate={staffVerification?.proofOfIdExp ? dayjs(staffVerification?.proofOfIdExp).format('DD-MM-YYYY') : null} />
                    <DocumentSlide label="Work Visa (British Passport or BRP)" value={staffVerification?.visa} name={staffVerification?.name} expDate={staffVerification?.visaExpDate ? dayjs(staffVerification?.visaExpDate).format('DD-MM-YYYY') : null} />
                    {
                        (staffInfo?.roleId === 4 || staffInfo?.roleId === 5) &&
                        <DocumentSlide label="Professional Insurance (Indemnity)" name={staffVerification?.name} value={staffVerification?.professionalInsurance} expDate={staffVerification?.professionalInsuranceExpDate ? dayjs(staffVerification?.professionalInsuranceExpDate).format('DD-MM-YYYY') : null} />
                    }
                    {
                        (staffInfo?.roleId === 4 || staffInfo?.roleId === 6) &&
                        <DocumentSlide label="Enhanced DBS Certificate" name={staffVerification?.name} value={staffVerification?.DBSCertificate} />
                    }
                    <DocumentSlide label="National Insurance Number" value={staffVerification?.insuranceNumberDoc} name={staffVerification?.name} />
                    {
                        staffInfo?.roleId === 8 &&
                        <DocumentSlide label="NVQ Level 2 Or Equivalent" value={staffVerification?.NVQLevelTwo} name={staffVerification?.name} />
                    }
                </div>
            </div>
        </div >
    );
};
