import { Button } from "antd";
import React from "react";
import Modal from "react-bootstrap/Modal";

export const ConfirmModal = ({
    open,
    onClose,
    description,
    title,
    confirmButtonText,
    cancelButtonText,
    onConfirm,
    children,
    primaryBtnDisable,
    formId,
    image,
    backdrop
}) => {
    return (
        <Modal centered show={open} onHide={onClose} backdrop={backdrop} >
            <Modal.Body
                className={`${image ? 'align-items-center gap-2' : 'gap-4'} rounded  d-flex flex-column t1`}
                style={{ padding: 30, width: 550 }}
            >
                {
                    image &&
                    <img width={100} src={image} alt="" />
                }
                <div className="heading4">{title}</div>
                <div className="s1" style={{ color: "#7B7B7B" }}>{description}</div>

                {children}
                <div
                    className="d-flex align-content-between align-items-end"
                    style={{ gap: "15px", flex: "1", width: '100%' }}
                >
                    {cancelButtonText && (
                        <Button
                            onClick={onClose}
                            block
                            size="large"
                            style={{ width: '100%' }}
                        >
                            {cancelButtonText}
                        </Button>
                    )}
                    {confirmButtonText && (
                        <Button
                            onClick={onConfirm}
                            type="primary"
                            htmlType="submit"
                            style={{ width: '100%' }}
                            form={formId}
                            block
                            size="large"
                        >
                            {confirmButtonText}
                        </Button>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}
