import { Form, Input, Button, Select, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signUpThunk } from "../../../redux/auth/authThunk";
import { getGphcInfoThunk } from "../../../redux/staffs/staffsThunk";
import { RouterConfig } from "../../../routerConfig";
import { genRandonString, notify } from "../../../utils/helper";
import { PasswordCriteria } from "../login/loginForm";
import "../style.scss";
import infoIcon from "../../../assets/icons/infoIcon.png";
import _ from "lodash";

const roleOptions = [
  { value: 4, label: "Locum Pharmacist" },
  { value: 5, label: "Locum Technician" },
];

export const LocumSignUpForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [btndisabled, setbtndisabled] = useState(true);
  const gphcNumber = Form.useWatch("gphcNumber", form);

  const onValuesChange = (changedValues, allValues) => {
    if (
      allValues.email !== "" &&
      allValues.password !== "" &&
      (allValues.gphcNumber !== undefined && allValues.gphcNumber !== "") &&
      allValues.name !== "" &&
      allValues.surname !== "" &&
      (allValues.roleId !== null && allValues.roleId !== undefined) &&
      (allValues.phone !== undefined && allValues.phone !== "")
    ) {
      setbtndisabled(false);
    } else {
      setbtndisabled(true);
    }
  };

  const { gphcInfo } = useSelector((state) => state.staffs);

  useEffect(() => {
    if (Number(gphcNumber?.length) !== 7) {
      form.setFieldsValue({
        name: "",
        surname: "",
        roleId: null,
      });
      return;
    }

    dispatch(getGphcInfoThunk({ gphcNumber })).then((res) => {
      if (!res.payload) {
        form.setFieldsValue({
          name: "",
          surname: "",
          roleId: null,
        });
      }
    });
  }, [dispatch, gphcNumber]);

  useEffect(() => {
    if (_.isEmpty(gphcInfo)) return;
    if (!gphcInfo.type) {
      notify(
        "error",
        "There seems to be a problem with the GPHC number you entered; contact the system administrator for support"
      );
      form.setFieldsValue({
        name: "",
        surname: "",
        roleId: null,
      });
      return;
    }
    if (gphcInfo.type === "pharmacist") {
      form.setFieldsValue({
        roleId: 4,
      });
    } else {
      form.setFieldsValue({
        roleId: 5,
      });
    }

    form.setFieldsValue({
      name: gphcInfo.forenames,
      surname: gphcInfo.surname,
    });
  }, [gphcInfo]);

  const onFinish = (value) => {
    const reqData = {
      ...value,
      deviceToken: genRandonString(20),
    };
    dispatch(signUpThunk(reqData)).then((res) => {
      if (res.payload.status === "success") {
        window.analytics.identify(res.payload.data.id, {
          id: res.payload.data.id,
          email: res.payload.data.email,
          userType: res.payload.data.group,
          name: res.payload.data.name,
          surname: res.payload.data.surname,
          role: res.payload.data.role,
          gphcNumber: res.payload.data.gphcNumber,
          device: "web",
        });

        window.analytics.track(res.payload.data.id, {
          id: res.payload.data.id,
          email: res.payload.data.email,
          userType: res.payload.data.group,
          name: res.payload.data.name,
          surname: res.payload.data.surname,
          role: res.payload.data.role,
          gphcNumber: res.payload.data.gphcNumber,
          device: "web",
        });
        window.fbq('trackCustom', 'SignUp Locum', {
          id: res.payload.data.id,
          email: res.payload.data.email,
          userType: res.payload.data.group,
          name: res.payload.data.name,
          surname: res.payload.data.surname,
          role: res.payload.data.role,
          gphcNumber: res.payload.data.gphcNumber,
          device: "web",
        });
        notify("success", "Sign In link sent to your Email!");
        form.resetFields()
        navigate(RouterConfig.login);

      }
    });
  };


  return (
    <>
      <div>
        <div className="heading2">Getting Started</div>
        <div className="t1">Create an account to continue.</div>
      </div>
      <Form
        onFinish={onFinish}
        form={form}
        onValuesChange={onValuesChange}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          label="GPhC Number"
          name="gphcNumber"
          normalize={(value) => {
            if (value.length > 7) return value.slice(0, 7);
            return value;
          }}
          rules={[
            { required: true, message: "Please Enter your GPhC Number!" },
            { len: 7, message: "GPhC Number should be 7 digits!" },
          ]}
        >
          <Input
            onBlur={(e) =>
              form.setFieldsValue({ gphcNumber: e.target.value.trim() })
            }
            type="number"
            placeholder="GPhC Number"
            className="text-input-field p-2 pe-3 t1"
          />
        </Form.Item>
        <div
          className="d-flex align-items-center gap-3"
          style={{ width: "100%" }}
        >
          <Form.Item
            label="First Name"
            style={{ width: "100%" }}
            onBlur={(e) => form.setFieldsValue({ name: e.target.value.trim() })}
            name="name"
            rules={[
              { required: true, message: "Please Enter your First Name !" },
            ]}
          >
            <Input
              placeholder="First Name"
              disabled
              onBlur={(e) =>
                form.setFieldsValue({ name: e.target.value.trim() })
              }
              className="text-input-field p-2 pe-3 t1"
              maxLength={50}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            label="Last Name"
            name="surname"
            rules={[
              { required: true, message: "Please Enter your Last Name !" },
            ]}
          >
            <Input
              disabled
              onBlur={(e) =>
                form.setFieldsValue({ surname: e.target.value.trim() })
              }
              placeholder="Last Name"
              className="text-input-field p-2 pe-3 t1"
              maxLength={50}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="roleId"
          label="Role"
          rules={[{ required: true, message: "Please Enter In A Correct GPhC Number To Populate This Field!" }]}
        >
          <Select
            disabled
            placeholder="Role"
            bordered={false}
            className="text-input-field pe-3 p-1 t1"
          >
            {roleOptions.map((data, index) => (
              <Select.Option key={index} value={data.value}>
                {data.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Please Enter your Email Address!",
            },
          ]}
        >
          <Input
            onBlur={(e) =>
              form.setFieldsValue({ email: e.target.value.trim() })
            }
            placeholder="Email Address"
            className="text-input-field p-2 pe-3 t1"
            maxLength={50}
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: "Please Enter your password!" },
            {
              pattern:
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
              message: `Password doesn't match the Criteria!`,
            },
          ]}
        >
          <Input.Password
            onBlur={(e) =>
              form.setFieldsValue({ password: e.target.value.trim() })
            }
            prefix={
              <Popover
                content={PasswordCriteria}
                placement="left"
                title="Password Criteria"
              >
                <img src={infoIcon} alt="" />
              </Popover>
            }
            placeholder="Password"
            className="text-input-field p-2 pe-3 t1"
            maxLength={50}
          />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phone"
          rules={[
            { required: true, message: 'Please Enter the Phone No.!' },
          ]}
        >
          <Input
            onBlur={e => form.setFieldsValue({ 'phone': e.target.value.trim() })}
            type="number"
            placeholder="Phone Number"
            className='text-input-field p-2 pe-3 t1'
          />
        </Form.Item>

        <Form.Item>
          <Button
            disabled={btndisabled}
            className="p-2 btn-style"
            type="primary"
            htmlType="submit"
            block
            id="signUpLocum_confirmed"
          >
            Sign Up
          </Button>
        </Form.Item>
      </Form>
      <div className="p2 text-center" style={{ color: "#9D9D9D" }}>
        <span className=" m-auto">
          Already have an account?
          <Link
            to={RouterConfig.login}
            className="text-decoration-none"
            style={{ color: "#51C2BC" }}
          >
            {" "}
            Login
          </Link>
        </span>
      </div>
    </>
  );
};
