import { createSlice } from "@reduxjs/toolkit";
import { authUserRequestThunk, logOutUserThunk, signinThunk, getAccessibleModulesThunk, getAccessibleApisThunk, deleteUserThunk } from "../authThunk";

const initialState = {
    isAuth: undefined,
    isPharmacyRegister: false,
    user: {},
    accessibleModules: [],
    accessibleApis: []
}

const authSlicer = createSlice({
    name: "authReducer",
    initialState: initialState,
    reducers: {
        removeAuthToken(state, action) {
            state.isAuth = false
            state.user = initialState.user
        }
    },
    extraReducers: (builder) => (
        builder
            .addCase(signinThunk.fulfilled, (state, action) => {
                state.isAuth = true
                state.user = action.payload
            })
            .addCase(signinThunk.rejected, (state, action) => {
                state.isloading = false
                state.errorMessageSignIn = action.payload
            })
            .addCase(authUserRequestThunk.fulfilled, (state, action) => {
                state.isAuth = true
                state.user = action.payload
            })
            .addCase(logOutUserThunk.fulfilled, (state, action) => {
                state.isAuth = false
                state.user = {}
                state.isPharmacyRegister = false
            })
            .addCase(deleteUserThunk.fulfilled, (state, action) => {
                state.isAuth = false
                state.user = {}
            })
            .addCase(getAccessibleModulesThunk.fulfilled, (state, action) => {
                state.accessibleModules = [{
                    moduleId: 0,
                    name: "Profile",
                    moduleKey: "profile",
                    isGranted: true
                }, ...action.payload]
            }).addCase(getAccessibleApisThunk.fulfilled, (state, action) => {
                state.accessibleApis = action.payload
            })
    )
})

export const { removeAuthToken } = authSlicer.actions

export const authReducer = authSlicer