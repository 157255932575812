import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Route, Routes } from "react-router-dom"
import { getRolesThunk } from "../../../../../redux/schedule/scheduleThunk"
import { StaffContracts } from "./contractList"
import { ContractDetails } from "./details"
export const StaffContractPage = () => {
    const disatch = useDispatch()

    useEffect(() => {
        disatch(getRolesThunk({
            group: ["staff"],
            type: "Default"
        }))
    }, [])
    return (
        <Routes>
            <Route
                path={`/`}
                element={<StaffContracts />}
            />
            <Route path="/:contractId" element={<ContractDetails />} />
        </Routes>

    )
}