import React from "react";
import { useNavigate } from "react-router-dom";

export const PageNotFound = () => {
    const navigate = useNavigate();

    return (
        <div className="error-container">
            <div className="error-container__inner">
                {/* <ErrorImage /> */}
                <span className="heading1 error-title">Oooops....Page not found</span>
                <span onClick={() => navigate('/')} >Return Back</span>
            </div>
        </div>
    );
}
