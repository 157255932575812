import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { BranchCard } from "../../components/UI/cards/profileCard";


import { ContentContainer } from "../../components/UI/container";
import { Sidebar } from "../../components/UI/sideBar";

export const LocumDetails = () => {
    const { user } = useSelector(state => state.auth)

    const sidebarLinks = useMemo(
        () => [
            {
                text: "Locum Market",
                to: 'locum-market',
                disabled: false,
                hidden: false,
            },
            {
                text: "Personal Information",
                to: 'information',
                disabled: false,
                hidden: false,
            },
            {
                text: "Schedule",
                to: 'schedule',
                disabled: false,
                hidden: false,
            },
            {
                text: "Notification Settings",
                to: 'setting',
                disabled: false,
                hidden: false,
            },
            {
                text: "Time Sheet",
                to: 'time-sheet',
                disabled: false,
                hidden: false,
            },
            {
                text: "Verification",
                to: 'verifications',
                disabled: false,
                hidden: false,
            },
        ],
        []
    );

    return (
        <div className="content-view-page details-page">
            <div className="main-wrapper p-5">
                <div className="content-holder">
                    <Sidebar links={sidebarLinks} visible>
                        <BranchCard
                            isStaff
                            staffInfo={user}
                            userStatus={user.status}
                            logo={user.image}
                            primaryText={`${user?.name} ${user?.surname}`}
                            secondaryText={user.label}
                            hasRating={user.group === 'locum'}
                            stats={{
                                completed: user?.completed,
                                cancelled: user?.cancelled,
                                rating: user?.rating,
                            }}
                        />
                    </Sidebar>
                    <ContentContainer className="content-container">
                        <Outlet />
                    </ContentContainer>
                </div>
            </div>
        </div>
    );
};
