import React from "react"
import { Link } from "react-router-dom"
import { RouterConfig } from "../../../routerConfig"

import locumIcon from '../../../assets/icons/locum.png'
import pharmacyIcon from '../../../assets/icons/pharmacy.png'
import { Button } from "antd"
import "../style.scss";


export const SignUpSelection = ({
    setSignUpMode,
    signUpMode,
    setShowSignUpForm
}) => {
    return (
        <>
            <div>
                <div className="heading2">Sign Up</div>
                <div className="t1">Choose an option to continue further.</div>
            </div>
            <div className="d-flex  justify-content-between" style={{ width: '100$', gap: 20 }}>
                <div style={{ width: '100%' }} id="signUpLocum" onClick={() => setSignUpMode('locum')} className={`d-flex flex-column selection-tab rounded-3 p-3 cursor-pointer ${signUpMode === 'locum' && 'active-tab'}`}>
                    <img width={35} height={35} src={locumIcon} alt="locumIcon" />
                    <div>
                        <div className="heading4">Locum</div>
                        <div className="t1">You're a pharmacist, technicians looking for jobs you can work on your will at your own terms. </div>
                    </div>
                </div>
                <div style={{ width: '100%' }} id="signUpPharmacy" onClick={() => setSignUpMode('pharmacy')} className={`d-flex flex-column selection-tab rounded-3 p-3 cursor-pointer ${signUpMode === 'pharmacy' && 'active-tab'}`}>
                    <img width={35} height={35} src={pharmacyIcon} alt="locumIcon" />
                    <div>
                        <div className="heading4">Pharmacy</div>
                        <div className="t1">You're a pharmacy looking to source locums at a moment's notice, manage your staff and do all your shift scheduling from one place.</div>
                    </div>
                </div>
            </div>
            <div>
                <Button disabled={!signUpMode} onClick={() => setShowSignUpForm(true)} type="primary" htmlType="submit" block className="btn-style p-2">
                    Continue
                </Button>
            </div>
            <div className="p2 text-center" style={{ color: '#9D9D9D' }}>
                <span className=" m-auto">
                    Already have an account?
                    <Link to={RouterConfig.login} className="text-decoration-none" style={{ color: '#51C2BC' }}> Login</Link>
                </span>
            </div>
        </>
    )
}