import { Popover, Switch } from "antd"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { FlexRow } from "../../../../../../components/comon/flexRows"
import { staffStatusChangeThunk } from "../../../../../../redux/staffs/staffsThunk"
import warningIcon from '../../../../../../assets/icons/warningIcon.png'

export const InformationTab = () => {
    const { staffInfo } = useSelector((state) => state.staffs)
    const dispatch = useDispatch()

    const onStatusChange = (activate, id) => {
        dispatch(
            staffStatusChangeThunk({
                id,
                data: {
                    activate
                }
            })
        )
    }

    return (
        <div className="d-flex flex-column flex-1" style={{ gap: 30 }}>
            <div className="border rounded">
                <div className="s1 text-row m-0">Bio Details</div>
                <FlexRow
                    primaryTitle="First Name"
                    primaryDescription={staffInfo?.name ? staffInfo?.name : "-"}
                />
                <FlexRow
                    primaryTitle="Last Name"
                    primaryDescription={staffInfo?.surname || "-"}
                />
                <FlexRow
                    primaryTitle="Gender"
                    primaryDescription={staffInfo?.gender || "-"}
                />
                <FlexRow
                    primaryTitle="Date of Birth"
                    primaryDescription={staffInfo?.dob ? moment(staffInfo?.dob).format('DD-MM-YYYY') : '-'}
                />
                <FlexRow
                    primaryTitle="Email Address"
                    primaryDescription={staffInfo?.email || "-"}
                />
            </div>
            {
                staffInfo?.group === 'staff' &&
                <div className="border rounded">
                    <div className="s1 text-row m-0">Status</div>
                    <FlexRow
                        primaryTitle={
                            <div className="d-flex gap-2 align-items-center">
                                <div>
                                    Activate
                                </div>
                                <Popover
                                    placement="left"
                                    overlayStyle={{ width: 300 }}
                                    content={
                                        <div>
                                            This action (De-Activation) will DELETE all shifts this Staff member has scheduled for all branches along with any shifts they have lined up in the templates (Published or Non-Published)
                                        </div>
                                    }
                                    trigger="hover"
                                >
                                    <img src={warningIcon} alt="" />
                                </Popover>
                            </div>
                        }
                        longDescription
                        primaryDescription={
                            <Switch size="default"
                                checked={staffInfo.status === 'active'}
                                onChange={(val, e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    onStatusChange(val, staffInfo.id)
                                }}
                            />
                        }
                    />
                </div>
            }
        </div>
    )
}