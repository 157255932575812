import deleteIcon from '../../../assets/icons/delete.png'
import { AddModal } from "../../UI/modals/addModal";
import { Checkbox, DatePicker, Form, Input, Select } from "antd";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addContractThunk, editContractThunk } from "../../../redux/staffs/staffsThunk";
import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import dayjs from 'dayjs'
import { useState } from 'react';

export const contractTypeOptions = [
    { value: 'full_time', label: "Full Time" },
    { value: 'part_time', label: "Part Time" },
    { value: 'zero_hour', label: "Zero Hour" },
    { value: 'other', label: "Other" },
];

const durationOptions = [
    { value: 'day', label: "Day" },
    { value: 'week', label: "Week" },
    { value: 'month', label: "Month" },
    { value: 'year', label: "Year" },
];

export const ContractForm = ({
    mode,
    showContractModal,
    setShowContractModal
}) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const { roles } = useSelector((state) => {
        return state.schedule;
    });
    const { staffContract } = useSelector((state) => state.staffs);

    const { staffId, contractId } = useParams()
    const formId = useMemo(() => 'contractForm', [])

    const [jobRoles, setJobRoles] = useState()

    useEffect(() => {
        if (!roles) return
        const _locumRoles = roles.map((i) => {
            return {
                label: i.label,
                value: i.id
            }
        })
        setJobRoles(_locumRoles)
    }, [roles])

    useEffect(() => {
        if (mode !== 'Edit') return
        form.setFieldsValue({
            jobRoleId: staffContract?.jobRoleId,
            absenceDays: staffContract?.absenceDays,
            overTimePayHourly: staffContract?.overTimePayHourly,
            startDate: dayjs(staffContract?.startDate),
            endDate: dayjs(staffContract?.endDate),
            title: staffContract?.title,
            workingOurs: staffContract?.workingOurs,
            workingPeriodType: staffContract?.workingPeriodType,
            grossSalaryPay: staffContract?.grossSalaryPay,
            grossSalaryPeriodType: staffContract?.grossSalaryPeriodType,
            includeBankHolidays: staffContract?.includeBankHolidays,
            type: staffContract?.type,
            contractAdditionCompensations: staffContract?.contractAdditionCompensations
        })
    }, [mode, staffContract])

    const onFinish = (values) => {
        const data = values?.contractAdditionCompensations?.map((data) => ({
            name: data.name,
            payAmount: data.payAmount,
            timePeriod: data.timePeriod
        })
        )
        delete values['contractAdditionCompensations']
        values.contractAdditionCompensations = data
        const reqBody = {
            ...values,
            startDate: dayjs(values?.startDate).format('YYYY-MM-DD'),
            endDate: dayjs(values?.endDate).format('YYYY-MM-DD')
        }
        if (mode === 'Edit') {
            dispatch(
                editContractThunk({
                    staffId,
                    contractId,
                    payload: reqBody
                })
            ).then(res => {
                if (res.payload) {
                    setShowContractModal()
                    form.resetFields()
                }
            })
        } else {
            dispatch(
                addContractThunk({
                    staffId,
                    payload: reqBody
                })).then(res => {
                    if (res.payload) {
                        setShowContractModal()
                        form.resetFields()
                    }
                })
        }
    }

    return (
        <AddModal
            formId={formId}
            title={`${mode} Contract`}
            handleModal={showContractModal}
            setHandleModal={setShowContractModal}
            primaryButtonText={mode}
            secondaryButtonText="Cancel"
            secondaryButtonHandler={setShowContractModal}
        >
            <Form
                id={formId}
                onFinish={onFinish}
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <div className="b1">
                    Basic Information
                </div>
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'Please input your Title!' }]}
                >
                    <Input
                        onBlur={e => form.setFieldsValue({ 'title': e.target.value.trim() })}
                        maxLength={50}
                        placeholder="Title"
                        className='text-input-field p-2 pe-3 t1'
                    />
                </Form.Item>
                <Form.Item
                    label="Role"
                    name="jobRoleId"
                    rules={[{ required: true, message: 'Please Select Role!' }]}
                >
                    <Select
                        className="text-input-field p-1 pe-3 t1"
                        bordered={false}
                        dropdownStyle={{ zIndex: 2000 }}
                        placeholder="Role"
                        options={jobRoles}
                    />
                </Form.Item>
                <div className="d-flex align-items-center" style={{ width: '100%', gap: 15 }}>
                    <Form.Item
                        label="Start Date"
                        name="startDate"
                        rules={[{ required: true, message: 'Please Select Start Date!' }]}
                    >
                        <DatePicker
                            inputReadOnly
                            format="DD-MM-YYYY"
                            getPopupContainer={(trigger) => trigger.parentElement}
                            className="text-input-field p-2 pe-3 t1"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="End Date"
                        name="endDate"
                        rules={[{ required: true, message: 'Please Select End Date!' }]}
                    >
                        <DatePicker
                            inputReadOnly
                            format="DD-MM-YYYY"
                            getPopupContainer={(trigger) => trigger.parentElement}
                            className="text-input-field p-2 pe-3 t1"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </div>
                <div className="" style={{ color: "#7B7B7B", width: "100%" }}>
                    <Form.Item
                        label="Contract Type"
                        name="type"
                        rules={[{ required: true, message: 'Please Select Contact Type!' }]}
                    >
                        <Select
                            className="text-input-field p-1 pe-3 t1"
                            bordered={false}
                            dropdownStyle={{ zIndex: 2000 }}
                            placeholder="Contract Type"
                            options={contractTypeOptions}
                        />
                    </Form.Item>
                </div>
                <div className="b1">
                    Wage Information
                </div>
                <div className="d-flex align-items-center" style={{ width: '100%', gap: 15 }}>
                    <div className="" style={{ color: "#7B7B7B", width: "100%" }}>
                        <Form.Item
                            label="Working Hours"
                            name="workingOurs"
                            rules={[{ required: true, message: 'Please input working Hours!' }]}
                        >
                            <Input
                                type="number"
                                placeholder="Working Hours"
                                className='text-input-field p-2 pe-3 t1'
                            />
                        </Form.Item>

                    </div>
                    <div className="" style={{ color: "#7B7B7B", width: "100%" }}>

                        <Form.Item
                            label="Type"
                            name="workingPeriodType"
                            rules={[{ required: true, message: 'Please Select Working Period Type!' }]}
                        >
                            <Select
                                className="text-input-field p-1 pe-3 t1"
                                bordered={false}
                                dropdownStyle={{ zIndex: 2000 }}
                                placeholder="Contract Type"
                                options={durationOptions.filter(i => i.value !== 'year')}
                            />
                        </Form.Item>

                    </div>
                </div>
                <div className="" style={{ color: "#7B7B7B", width: "100%" }}>
                    <Form.Item
                        label="Absence Days"
                        name="absenceDays"
                        rules={[{ required: true, message: 'Please input Absence Days count!' }]}

                    >
                        <Input
                            type="number"
                            placeholder="Absence Days"
                            className='text-input-field p-2 pe-3 t1'
                        />
                    </Form.Item>

                </div>
                <div>
                    <Form.Item name="includeBankHolidays" >
                        <Checkbox value={true} >Include bank holidays</Checkbox>
                    </Form.Item>
                </div>
                <div className="d-flex align-items-center" style={{ width: '100%', gap: 15 }}>
                    <div className="" style={{ color: "#7B7B7B", width: "100%" }}>
                        <Form.Item
                            label="Gross Salary"
                            name="grossSalaryPay"
                            rules={[{ required: true, message: 'Please input Gross Salary!' }]}

                        >
                            <Input
                                type="number"
                                placeholder="Gross Salary"
                                className='text-input-field p-2 pe-3 t1'
                            />
                        </Form.Item>

                    </div>
                    <div className="" style={{ color: "#7B7B7B", width: "100%" }}>
                        <Form.Item
                            label="Type"
                            name="grossSalaryPeriodType"
                            rules={[{ required: true, message: 'Please Selecy Contract Type!' }]}
                        >
                            <Select
                                className="text-input-field p-1 pe-3 t1"
                                bordered={false}
                                dropdownStyle={{ zIndex: 2000 }}
                                placeholder="Contract Type"
                                options={durationOptions}
                            />
                        </Form.Item>

                    </div>
                </div>
                <div className="" style={{ color: "#7B7B7B", width: "100%" }}>
                    <Form.Item
                        label="Overtime Salary (per hour)"
                        name="overTimePayHourly"
                        rules={[{ required: true, message: 'Please Enter Overtime Salary (per hour)!' }]}
                    >
                        <Input
                            type="number"
                            placeholder="Overtime Salary (per hour)"
                            className='text-input-field p-2 pe-3 t1'
                        />
                    </Form.Item>

                </div>
                <div className="b1 mb-3 ">
                    Additional Compensations
                </div>
                <Form.List name="contractAdditionCompensations">
                    {(fields, { add, remove }) => {
                        return (
                            <div className="d-flex gap-3 flex-column">
                                {fields.map((field) => (
                                    <div key={field.key} className="border rounded p-3 position-relative" style={{ width: '100%', backgroundColor: '#F9F9F9' }}>
                                        <div
                                            onClick={() => remove(field.name)}
                                            className="cursor-pointer position-absolute d-flex  border rounded-circle align-items-center justify-content-center"
                                            style={{ width: 25, height: 25, backgroundColor: 'white', top: -13, right: 0 }}
                                        >
                                            <img width={16} height={16} src={deleteIcon} alt="deleteIcon" />
                                        </div>
                                        <Form.Item
                                            name={[field.name, "name"]}
                                            fieldKey={[field.name, "name"]}
                                            label="Name"
                                            rules={[{ required: true, message: 'Please input your Compensations Name!' }]}
                                        >
                                            <Input
                                                placeholder="Name"
                                                className='text-input-field p-2 pe-3 t1'
                                            />
                                        </Form.Item>
                                        <div className="d-flex align-items-center gap-3">
                                            <Form.Item
                                                fieldKey={[field.name, "payAmount"]}
                                                name={[field.name, "payAmount"]}
                                                label="Payment Amount"
                                                rules={[{ required: true, message: 'Please input your Compensations Amount!' }]}
                                            >
                                                <Input
                                                    type="number"
                                                    placeholder="Payment Amount"
                                                    className='text-input-field p-2 pe-3 t1'
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                fieldKey={[field.name, "timePeriod"]}
                                                name={[field.name, "timePeriod"]}
                                                label="Time Period"
                                                rules={[{ required: true, message: 'Please select your Compensations Type!' }]}
                                            >
                                                <Select
                                                    className="text-input-field p-1 pe-3 t1"
                                                    bordered={false}
                                                    style={{ background: 'white' }}
                                                    dropdownStyle={{ zIndex: 2000 }}
                                                    placeholder="Contract Type"
                                                    options={durationOptions.filter(i => (i.value === 'month' || i.value === 'year'))}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>

                                ))}
                                <Form.Item>
                                    <div
                                        onClick={add}
                                        className="p-2 text-center rounded cursor-pointer"
                                        style={{ width: '100%', color: '#51C2BC', border: '2px dashed #51C2BC' }}
                                    >
                                        Add New
                                    </div>
                                </Form.Item>
                            </div>
                        );
                    }}
                </Form.List>
            </Form>
        </AddModal >

    )
}