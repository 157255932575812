import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { Header } from "../../components/UI/header";
import { getLocumAttendanceJobsThunk } from "../../redux/locum-flow/Thunk";
import { LocumInformation } from "./information";
import { LocumJobsList } from "./locum-jobs";
import { LocumJobDetails } from "./locum-jobs/jobDeatil";
import { LocumDetails } from "./locumView";
import { LocumSchedule } from "./scheduler";
import { LocumSetting } from "./setting";
import { LocumTimeSheet } from "./time-sheet";
import { LocumVerification } from "./verifications";

export const LocumView = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getLocumAttendanceJobsThunk())
    }, [])
    return (
        <div className="content-view-page details-page">
            <Header showNotification showAttendance />
            <Routes>
                <Route path="/*" element={<LocumDetails />} >
                    <Route path='locum-market' element={<LocumJobsList />} />
                    <Route path='locum-market/:id' element={<LocumJobDetails />} />
                    <Route path='information' element={<LocumInformation />} />
                    <Route path='schedule' element={<LocumSchedule />} />
                    <Route path='setting' element={<LocumSetting />} />
                    <Route path='time-sheet' element={<LocumTimeSheet />} />
                    <Route path="verifications" element={<LocumVerification />} />
                    <Route
                        path="*"
                        element={
                            <Navigate to='/schedule' />
                        }
                    />
                </Route>
            </Routes>
        </div>
    );
};
